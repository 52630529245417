import {  InfoCircleOutlined } from '@ant-design/icons';

import React, {useState, useEffect} from 'react';
import { useProjectData } from '../../../auth/ProjectDataContext';
import { Container,  } from "react-bootstrap";
import BarSide2 from "../../BarSide2";
import { Form, Select, Button, Row, Col, message,  Table, Modal, Input,Flex , Tooltip, Space  } from 'antd';
import axios from "../../Axios"
import openLogo from "../../../assets/openin.png";
import BottomCanva from './BottomCanva';
// import DataTable from 'react-data-table-component';
import Markdown from 'react-markdown'
import MarkdownEditor from '@uiw/react-markdown-editor';
import { EditorView } from "@codemirror/view";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import OrderStatusTable from "../subreddit/OrderStatusTable"
const { Option } = Select;

const {TextArea} = Input;

function ProjectDetail() {
  const [orderData, setOrderData] = useState(null);

    const { selectedProject, setSearchResults, searchResults, selectedRowMain, setSelectedRowMain } = useProjectData();
    const [loading, setLoading] = useState(false);
    
    const [markdown, setMarkdown] = useState();

    // const [selectedRowMain, setSelectedRowMain] = useState([]);
    const [showBottomCanva, setShowBottomCanva] = useState(false);
    const [selectedTextId, setSelectedTextId] = useState(null);
    const [formValues, setFormValues] = useState({
        angleKeyword: '',
        basicInfo: '',
        customPrompt: '',
        angleKeywordList: '',
        customCtas: '',
        sortingOption: 'relevance', // Default value set for sorting option
        timeOption: 'week', // Default value set for time option
    });
    const [promptTextArea, setPromptTextArea] = useState('');

    const [isPosting, setIsPosting] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');

    useEffect(() => {
       
        const text = `${formValues.customPrompt}\n${formValues.basicInfo}\n${formValues.customCtas}`;
        setPromptTextArea(text);
      }, [formValues])
    
  
    const handleChange = (event) => {
      setPromptTextArea(event.target.value);
    }

    

    const handleFormChange = (name, value) => {
        setFormValues(prevValues => ({
            ...prevValues,
            [name]: value,
        }));
    };

   

    // Function to render select options for a given field
    const renderSelectOptions = (field) => {
        return selectedProject.inputFields.map((inputField, index) => {
            if (field === 'angleKeywordList' ) {
                return inputField[field].map((keyword, keywordIndex) => (
                    <Select.Option key={keywordIndex} value={keyword}>{keyword}</Select.Option>
                ));
            } else if(field === 'customCtas'){
                return inputField[field].map((keyword, keywordIndex) => (
                    <Select.Option key={keywordIndex} value={keyword}>{keyword}</Select.Option>
                ));
            }
            return <Select.Option key={index} value={inputField[field]}>{inputField[field]}</Select.Option>;
        });
    };

    const handleSubmit = async () => {
        setLoading(true); // Begin loading
        try {
            const response = await axios.post('/social/searchredditposts', {
                keyword: formValues.angleKeywordList,
                sort: formValues.sortingOption,
                time: formValues.timeOption,
            });
            // Log the response or handle it as per your application's needs
            console.log(response.data);
            response.data.results.forEach((result) => {
                result.aiReply = '';
              });
            setSearchResults(response.data.results);
            // message.success('Search completed successfully!');
        } catch (error) {
            console.error('Failed to perform search operation', error);
            const errorMessage = error.response && error.response.data && error.response.data.message 
            ? error.response.data.message 
            : 'Failed to perform search operation due to an unexpected error.';
message.error(errorMessage);
        } finally {
            setLoading(false); // End loading
        }
    };

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
          console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
          setSelectedRowMain(selectedRows)
        }
      };

      
  useEffect(() => {
    // Yeh check karega ki selectedRowMain mein koi elements hain ya nahi
    if (selectedRowMain.length > 0) {
      // selectedRows ke titles ko ek string mein combine karna
      const titlesToAdd = selectedRowMain[0].title;
      const descriptionToAdd = selectedRowMain[0].selftext;
      // Existing promptTextArea ke end mein titles ko add karna
      setPromptTextArea(prevText => `${prevText}\n${titlesToAdd}\n${descriptionToAdd}`);
    }
  }, [selectedRowMain]);





      const columns = [
        {
            title: 'Title',
            dataIndex: 'title',
            key: 'title',
            width: 180,
            render: (text, record) => (
                <>
                    {text}
                    {/* <img src={openLogo} className="newtabicon" alt="new tab open" style={{ marginLeft: 8, cursor: 'pointer' }}
                        onClick={() => {
                            setShowBottomCanva(true); // Show BottomCanva
                            setSelectedTextId(record.id); // Store the selected text ID; ensure you're using 'record.key' or the appropriate property that represents the ID
                        }}
                    /> */}
                    <span class="material-symbols-outlined" style={{ color: '#1677ff', marginLeft: 8,marginTop: 1, cursor: 'pointer', fontSize: '14px' }}  onClick={() => {
                            setShowBottomCanva(true); // Show BottomCanva
                            setSelectedTextId(record.id); // Store the selected text ID; ensure you're using 'record.key' or the appropriate property that represents the ID
                        }}>
                        open_in_new
                        </span>
                </>
            ),
        },
        {
            title: 'Description',
            dataIndex: 'selftext',
            key: 'selftext',
            render: (text, record) => (
                <>
                    <Markdown>{text}</Markdown></>),
                    
            width: 500,
            
        },
        {
            title: 'Comments',
            dataIndex: 'num_comments',
            key: 'num_comments',
            width: 150
        },
        {
            title: 'Url',
            dataIndex: 'permalink',
            key: 'permalink',
            render: (text) => <a href={`https://www.reddit.com${text}`} target="_blank" rel="noopener noreferrer">Link</a>,
            width: 90
        },
        
  
    ];

  
    const [isModalOpen, setIsModalOpen] = useState(false);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };


    const handleButtonClick = () => {
    
    };   
    const handleCloseBottomCanva = () => setShowBottomCanva(false);

    const userDataString = localStorage.getItem("userData");
    const userData = userDataString ? JSON.parse(userDataString) : null;
    const [isLoading, setIsLoading] = useState(false);

    const handleSubmitPrompt = async (e) => {
        e.preventDefault();
        if (!userData.openaiApi || userData.openaiApi === '') {
            toast.error("Please add OpenAI API key in settings page.", { position: "top-right" });
            return; // Exit the function early
        }
        setIsLoading(true); // Changed from setLoading to setIsLoading
    console.log(userData.openaiApi, userData.openaiApiModel, promptTextArea)
        try {
          const response = await axios.post('/social/openaireply', { apiKey: userData.openaiApi, model:userData.openaiApiModel || 'gpt-3.5-turbo-0125', messages: [{ "role": "user", "content": promptTextArea}] }); // Updated messages to userMessages

          console.log(response.data); // Handle the response data as needed
          const aiReplyContent = response.data.choices[0].message.content;
          setSearchResults(currentResults => {
            const selectedId = selectedRowMain[0].id; // Assuming single selection for simplicity
            return currentResults.map(item => {
              if (item.id === selectedId) {
                return { ...item, aiReply: aiReplyContent }; // Add or update the aiReply key
              }
              return item;
            });
          });
          console.log(searchResults)
          //   setMarkdown(response.data.choices[0].message.content); // Handle the response data as needed
        } catch (error) {
            if (error.response) {
                // Backend error responses
                const { status, data } = error.response;
                let errorMessage = `Error: ${data.error}`;
          
                // Checking if there is a 'solution' provided in the response
                if (data.solution) {
                  errorMessage += ` - ${data.solution}`;
                } else if (status === 500 && data.details) {
                  // For specific backend error scenario (status 500 with error details)
                  errorMessage += `. Details: ${data.details}`;
                }
          
                toast.error(errorMessage, { position: "top-right" });
              } else {
                // Other errors (not originating from the backend)
                toast.error("An error occurred while generating text. Please check your network connection and try again.", { position: "top-right" });
              }
        } finally {
          setIsLoading(false); // Changed from setLoading to setIsLoading
        }
      };
    const handleSubmitPromptWithGimini = async (e) => {
        e.preventDefault();
        if (!userData.geminiApi || userData.geminiApi === '' ) {
            toast.error("Please add Gemini API key.", { position: "top-right" });
            return; // Exit the function early
        }
        setIsLoading(true); // Changed from setLoading to setIsLoading
    console.log(userData.geminiApi, promptTextArea, userData.geminimodel)
        try {
          const response = await axios.post('/social/geminireply', { apiKey: userData.geminiApi , prompt:promptTextArea, geminimodel: userData.geminimodel || 'gemini-pro' }); // Updated messages to userMessages

          console.log(response.data); // Handle the response data as needed
          const aiReplyContent = response.data.text;
          setSearchResults(currentResults => {
            const selectedId = selectedRowMain[0].id; // Assuming single selection for simplicity
            return currentResults.map(item => {
              if (item.id === selectedId) {
                return { ...item, aiReply: aiReplyContent }; // Add or update the aiReply key
              }
              return item;
            });
          });
          
          //   setMarkdown(response.data.choices[0].message.content); // Handle the response data as needed
        } catch (error) {
          console.log(error);
          toast.error(`${error.response.data.error}`, { position: "top-right" });
        } finally {
          setIsLoading(false); // Changed from setLoading to setIsLoading
        }
      };

    const handleSubmitPromptWithopenrouter = async (e) => {
        e.preventDefault();
        if (!userData.openrouterApi || userData.openrouterApi === '') {
            toast.error("Please add OpenRouter API key in settings page.", { position: "top-right" });
            return; // Exit the function early
        }
        setIsLoading(true); // Changed from setLoading to setIsLoading
    console.log(userData.openrouterApi, promptTextArea)
        try {
          const response = await axios.post('https://seotoolbox-h6lhegca5a-uc.a.run.app/send-api', { OPENROUTER_API_KEY: userData.openrouterApi, model: userData.openrouterApiModel || 'meta-llama/llama-3-70b-instruct', content:promptTextArea }); // Updated messages to userMessages

          console.log(response.data); // Handle the response data as needed
          if (response.data && response.data.choices){
          const aiReplyContent = response.data.choices[0].message.content;
          setSearchResults(currentResults => {
            const selectedId = selectedRowMain[0].id; // Assuming single selection for simplicity
            return currentResults.map(item => {
              if (item.id === selectedId) {
                return { ...item, aiReply: aiReplyContent }; // Add or update the aiReply key
              }
              return item;
            });
          }); } else {
              toast.error(`Error: ${response.data.error.message} Code: ${response.data.error.code}`, { position: "top-right" });

          }
          
        } catch (errorResponse) {
          console.log("Complete errorResponse:", errorResponse);

        } finally {
          setIsLoading(false); // Changed from setLoading to setIsLoading
        }
      };

      const handleCommentSubmit = async (record) => {
        if (!record.aiReply || !record.id) {
            toast.error('Please select the radio button of the comment which you want to publish on Reddit.');
            return; // Exit the function early
        }
        // Destructure redditCredentials for ease of access
  const { userAgent, clientId, clientSecret, refreshToken } = userData.redditCredentials;

  // Check if any of the Reddit credentials are empty, null, or undefined
  if (!userAgent || !clientId || !clientSecret || !refreshToken) {
    toast.error("Please add Reddit credentials in the settings page.");
    return; // Exit the function early if any credential is missing
  }

  const postData = {
    postId: record.id,
    replyText: record.aiReply,
    userAgent, // Using shorthand syntax
    clientId, // Using shorthand syntax
    clientSecret, // Using shorthand syntax
    refreshToken // Using shorthand syntax
  };

    
        setIsPosting(true);
        // toast.loading('Posting comment...');
        const toastId = toast.loading('Posting comment...');
        try {
            console.log(record.id, record.aiReply, "handleCommentSubmit")
            const { data } = await axios.post('/social/post-comment', postData);
            setSearchResults(currentResults => {
              const selectedId = selectedRowMain[0].id; // Assuming single selection for simplicity
              return currentResults.map(item => {
                if (item.id === selectedId) {
                  return { ...item, publishedComment: data.data.permalink }; // Add or update the aiReply key
                }
                return item;
              });
            });
    
          if (data.success) {
          toast.success('Comment has been posted successfully!') }
        else {
            toast.error('Error posting comment.');
          }
      
        } catch (error) {
          console.log(error, "error working on")
          setErrorMessage(error.response.data.error);
          toast.error(error.response.data.error);
        } finally {
          setIsPosting(false);
          toast.dismiss(toastId);
        }
      };


      const handleMarkdownChange = (newValue, recordId) => {
        const updatedResults = searchResults.map((item) => {
          if (item.id === recordId) {
            return { ...item, aiReply: newValue };
          }
          return item;
        });
    
        setSearchResults(updatedResults);
      };


      // upvotes

      const [postLinkReddit, setRedditPostLink] = useState('');
      const [quantity, setQuantity] = useState('');
      const [username, setUsername] = useState('');
      const [checkBalanceLoading, setCheckBalanceLoading] = useState(false);

    
      const handlePostLinkChange = (e) => {
        setRedditPostLink(e.target.value);
      };

      const handleUsernameChange = (e) => {
        setUsername(e.target.value);
      };
    
      const handleQuantityChange = (e) => {
        setQuantity(e.target.value);
      };
    
      const handleQuantityBlur = () => {
        let value = Number(quantity);
        if (value < 1) {
          setQuantity(1);
        } else if (value > 300) {
          setQuantity(300);
        }
      };


      const checkBalance = async () => {

        if (!userData.redUpvotesApi) {
          toast.error('RedUpvotes API key is missing');
          return;
        }

        setCheckBalanceLoading(true);
        try {
          const response = await axios.post('/moz/check-balance', { apiKey: userData.redUpvotesApi });
          console.log(response.data)
          toast.success(`Balance: ${response.data.balance} Currency: ${response.data.currency}`);
        } catch (error) {
          console.log(error, "error handling");
          const errorMessage = error.response?.data?.error || 'Failed to fetch balance';
          toast.error(errorMessage);
        } finally {
          setCheckBalanceLoading(false);
        }
      };

  const [addOrderLoading, setAddOrderLoading] = useState(false);

const handleSubmitAddOrder = async () => {

  if (!userData.redUpvotesApi) {
    toast.error('RedUpvotes API key is required');
    return;
  }
 
  if (!postLinkReddit) {
    toast.error('Comment Link is required');
    return;
  }
  if (!quantity) {
    toast.error('Quantity is required');
    return;
  }
  if (!username) {
    toast.error('Username is required');
    return;
  }
  if (!userData._id) {
    toast.error('User ID is required');
    return;
  }

    setAddOrderLoading(true);
    try {
      const response = await axios.post('/moz/add-order', {
        apiKey: userData.redUpvotesApi,
        service: 6,
        link: postLinkReddit,
        quantity,
        username,
        userId: userData._id
      });
       toast.success('Order added successfully');
      console.log(response.data);
      if (response.data.user) {
        localStorage.setItem("userData", JSON.stringify(response.data.user));
      }     
    } catch (error) {
      console.error('Error adding order:', error);
      const errorMessage = error.response?.data?.error || 'Failed to add order';
      toast.error(errorMessage);
    } finally {
      setAddOrderLoading(false);
    }
  };

  const [multiOrderStatusLoading, setMultiOrderStatusLoading] = useState(false);


  const handleCheckStatus = async () => {
    if (!userData.redUpvotesApi) {
      toast.error('RedUpvotes API key is required');
      return;
    }
    if (!userData.redUpvotesOrders || userData.redUpvotesOrders.length === 0) {
      toast.error('You have not placed any orders yet');
      return;
    }

    setMultiOrderStatusLoading(true);
    try {
      const response = await axios.post('/moz/multi-status', {
        apiKey: userData.redUpvotesApi,
        orderIds: userData.redUpvotesOrders,
      });
      setOrderData(response.data);
      console.log(response.data);
      // Handle the response as needed
    } catch (error) {
      console.error('Error fetching multiple order statuses:', error);
      const errorMessage = error.response?.data?.error || 'Failed to fetch multiple order statuses';
      toast.error(errorMessage);
    } finally {
      setMultiOrderStatusLoading(false);
    }
  };



 

    return (
        <>
        <ToastContainer
position="top-right"
autoClose={15000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
        <main className="d-flex flex-nowrap">
        <BarSide2 />
        <Container>
            <div className="d-flex flex-column flex-grow-1 p-4">
                <div style={{ overflowY: "auto", height: "calc(100vh - 24px)" }}>
                    <div className="container mt-3">
                        <div className="row">
                            <div className="col-md-12">
                                <div className="ra-home border border-gray-300 bg-white rounded-lg">
                                    <h2>{selectedProject.projectName}</h2>
                                </div>
                                <div className="ra-home border border-gray-300 bg-white rounded-lg mt-4">
                                    {/* Form for inputFields */}
                                    <Form layout="vertical" onFinish={handleSubmit}>
                                        <Row gutter={4}>
                                            <Col span={4}>
                                                <Form.Item label="Angle Keyword" name="angleKeyword">
                                                    <Select onChange={value => handleFormChange('angleKeyword', value)}>
                                                        {renderSelectOptions('angleKeyword')}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={7}  style={{ maxWidth: '100%', overflow: 'hidden' }}>
                                                <Form.Item label="Basic Info" name="basicInfo" >
                                                    <Select onChange={value => handleFormChange('basicInfo', value)} style={{ width: 290 }}> {/* 290 */}
                                                        {renderSelectOptions('basicInfo')}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                           
                                            <Col span={8}>
                                                <Form.Item label="Custom Prompt" name="customPrompt">
                                                    <Select onChange={value => handleFormChange('customPrompt', value)} style={{ width: 330 }}>  {/* 330 */}
                                                        {renderSelectOptions('customPrompt')}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={4}>
                                                <Form.Item label="CTAs" name="customCtas">
                                                    <Select onChange={value => handleFormChange('customCtas', value)} style={{ width: '100%' }} > {/* 200 */}
                                                        {renderSelectOptions('customCtas')}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            </Row><Row gutter={4}>
                                            <Col span={5}>
                                                <Form.Item name="angleKeywordList">
                                                    <Select onChange={value => handleFormChange('angleKeywordList', value)}>
                                                        {renderSelectOptions('angleKeywordList')}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                            <Col span={5}>
                                                <Select defaultValue="relevance" style={{ width: '100%' }} onChange={value => handleFormChange('sortingOption', value)}>
                                                    <Option value="relevance">Relevance</Option>
                                                    <Option value="hot">Hot</Option>
                                                    <Option value="top">Top</Option>
                                                    <Option value="new">New</Option>
                                                    <Option value="comments">Comments</Option>
                                                </Select>
                                            </Col>
                                            <Col span={5}>
                                                <Select defaultValue="week" style={{ width: '100%' }} onChange={value => handleFormChange('timeOption', value)}>
                                                    <Option value="hour">Hour</Option>
                                                    <Option value="day">Day</Option>
                                                    <Option value="week">Week</Option>
                                                    <Option value="month">Month</Option>
                                                    <Option value="year">Year</Option>
                                                    <Option value="all">All</Option>
                                                </Select>
                                            </Col>
                                            <Col span={4}>
                                                <Button  style={{ width: '100%', color: '#fff',  }} onClick={handleSubmit}  className='mainQuoraButton ' loading={loading} >
                                                            Submit
                                                     </Button>
                                            </Col>
                                            <Col span={4}>
                                                <Button  style={{ width: '100%', color: '#fff',  }}  onClick={showModal} className='mainQuoraButton ' >
                                                      Preview Prompt
                                                     </Button>
                                                     <Modal  open={isModalOpen} onOk={handleOk} onCancel={handleCancel} closable={false} footer={[
                                                                    <Button key="back" onClick={handleCancel}>
                                                                        Return
                                                                    </Button>,
                                                                    <Button   onClick={handleOk}>
                                                                        Save
                                                                    </Button>]} width="1000">
                                                                    <TextArea rows={16} value={promptTextArea} onChange={handleChange} />
                                                    </Modal>
                                            </Col>
                                            <p class="mt-1">Use the editable prompt previews to add any specifics that you would like the AI to include in your drafted comment. It could be basic info about a case study, experience, results, before and after etc.</p>

                                        </Row>
                                    </Form>
                                </div>

                                <div className="mt-4">
                                {searchResults && searchResults.length > 0 && (
                                  
<Table dataSource={searchResults} columns={columns} rowKey="id"  tableLayout="fixed" 
pagination={{
    defaultPageSize: 50,
    showSizeChanger: true,
    pageSizeOptions: ['10',  '50', '100', '250'],
}}
rowSelection={{
    type: 'radio',
    ...rowSelection,
  }}
expandable={{
    expandedRowRender: (record) => (
        <>
        <MarkdownEditor
        value={record.aiReply}
        height="300px"
        extensions={[EditorView.lineWrapping]}
        // onChange={(value, viewUpdate) => setMarkdown(value)}
        onChange={(value) => handleMarkdownChange(value, record.id)}

      />
      {/* <Button onClick={() => handleCommentSubmit(record)} className="mt-2 ml-4 mainQuoraButtonComment"   loading={isPosting}>Publish Comment</Button>
      <p>{record.publishedComment}</p> */}
      <div style={{ display: 'flex', alignItems: 'center' }}>
      <Button onClick={() => handleCommentSubmit(record)} className="mt-2 ml-4 mainQuoraButtonComment"   loading={isPosting}>Publish Comment</Button>
      
      {record.publishedComment && (
    <a href={`https://www.reddit.com${record.publishedComment}`} target="_blank" rel="noopener noreferrer" style={{ marginLeft: '10px' }}>
      Comment Link
    </a>
  )}
  <p class="ml-1 mt-1" style={{color: "#7357eb"}}>To avoid any bot detection issues it's better to copy and paste your comment manually using the link provided. it takes just a few seconds more but keeps your accounts safer.</p>
</div>


      </>
    ),
    
  }} />
        //           <DataTable
		// 	columns={columns}
		// 	data={searchResults}
		// />

            )}
                                </div>

{/* Upvotes Started */}
<div className="container my-4 marginb20">
<div className="row">
<div className="col-md-12">
    <div className="ra-home border border-gray-300 bg-white rounded-lg">
<Flex>
  <h4 className="mb-3">Boost your Comments with High-Quality Upvotes </h4> <Tooltip placement="topLeft" title="We recommend a maximum of 80-100 upvotes per post. It's best to do around 30 upvotes within the first hour of posting for maximum impact and you can drip feed the rest."><InfoCircleOutlined className='newmargin1 ml-2'/></Tooltip>
  </Flex>
    <Row gutter={[6, 6]}>
        <Col span={6}>
          <Input placeholder="Comment link" value={postLinkReddit} onChange={handlePostLinkChange} />
        </Col>
        <Col span={4}>
          <Input type="number" value={quantity} placeholder="Quantity" onChange={handleQuantityChange} onBlur={handleQuantityBlur} min={5} max={300} />
        </Col>
        <Col span={4}>
          <Input placeholder="Username" value={username} onChange={handleUsernameChange} />
        </Col>
        <Col span={10}>
          <Space>
            <Button onClick={handleSubmitAddOrder} loading={addOrderLoading}>Submit</Button>
            <Button onClick={checkBalance} loading={checkBalanceLoading}>Check Balance</Button>
            <Button onClick={handleCheckStatus} loading={multiOrderStatusLoading}>Check Orders Status</Button>
          </Space>
        </Col>
        <Col span={24} className='mt-2'>
        {orderData && (
        <OrderStatusTable orderData={orderData} />
      )}
        </Col>
      </Row>



  </div></div></div></div>
{/* Upvotes Ended */}
                              

                                <div className="mergemain analyse-now">
                {selectedRowMain.length > 0 && (
                    <div>
                        {`${selectedRowMain.length} keyword${selectedRowMain.length > 1 ? "s" : ""} selected `}
                        {/* {isLoading && (
                            <Spinner as="span" animation="border" size="sm" role="status" aria-hidden="true" className="white-spinner" />
                        )} */}
                        <button className="btn btn-white ms-5" onClick={handleSubmitPrompt}>
                            {isLoading ? 'Loading...' : 'Reply With OpenAi'}
                            
                        </button>
                        <button className="btn btn-white ms-2" onClick={handleSubmitPromptWithGimini}>
                            {isLoading ? 'Loading...' : 'Reply With Gemini'}
                            
                        </button>
                        <button className="btn btn-white ms-2" onClick={handleSubmitPromptWithopenrouter}>
                            {isLoading ? 'Loading...' : 'Reply With OpenRouter'}
                            
                        </button>
                    </div>
                )}
            </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <BottomCanva show={showBottomCanva} onHide={handleCloseBottomCanva} selectedTextId={selectedTextId} />

        </Container>
    </main>
    </>
    );
}

export default ProjectDetail;
