import React, {useContext} from "react";
import { Row, Col } from "antd";
import { SVGFlag } from "use-flags";
// import { useGetValueContext } from "../../auth/GetValueContext";
import { KeywordContext } from "../../auth/KeywordContext";

function Header() {
//   const { getFilteredData } = useGetValueContext(); { keywordData, languageAbbreviation, countryCode }
const {  keywordData, languageAbbreviation, countryCode  } = useContext(KeywordContext); 

// console.log(keywordData, languageAbbreviation, countryCode, "keywordData, languageAbbreviation, countryCode")
const userDataString = localStorage.getItem("userData");
const userData = userDataString ? JSON.parse(userDataString) : null;

  return (
    <div className="border border-gray-200 bg-white rounded-lg p-4 xaxis">
      <Row gutter={4}>
        <Col span={18}>
          <div className="d-flex">
          <h3 className="ra-name">{keywordData?.mainSeedKeyword}</h3>
          <div className=" ra-lang ra-flag  ml-4 mr-4 mb-2">
          <h5 className=" mb-0 kwlength">{languageAbbreviation}</h5> 
            <SVGFlag country={countryCode} flagWidth="33" />
          </div>
          <div>
          <h5 className="mt-0 mb-0 kwlength">{keywordData.keywordData.length}</h5>
          <p className="mt-0 mb-0 ">Keywords</p>
        </div>
        </div>
        </Col>
        <Col span={2}>
        <div>
          <h5 className="mt-0 mb-0 kwlength">{ keywordData.keywordData.filter(({ items }) => items.length > 0).length }</h5>
          <p className="mt-0 mb-0 ">Processed</p>
        </div>
        </Col>
        <Col span={2} className="">
        <div>
          <h5 className="mt-0 mb-0 kwlength">{ keywordData.keywordData.filter(({ items }) => items.length === 0).length }</h5>
          <p className="mt-0 mb-0 ">Unprocessed</p>
        </div>
        </Col>
        <Col span={2} >
        {/* <h5 className="mt-0 mb-0"> {userData.credits}</h5> */}
        <h5 className="mt-0 mb-0"><span class="material-symbols-outlined mainfontsize">all_inclusive</span></h5>
           Credits Left
        </Col>
      </Row>
    </div>
  );
}

export default Header;
