import React, { useEffect, useState } from 'react';
import { Table, Modal, Button, Input, Col, Row  } from 'antd';
import { useProjectData } from '../../../auth/ProjectDataContext';
import axios from "../../Axios";
import { useNavigate } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ProjectFormTable() {
    const { projectData, setProjectData, setSelectedProject } = useProjectData();
    const [loadingEdit, setLoadingEdit] = useState(false);
    const [openEdit, setOpenEdit] = useState(false);
    const [projectname, setProjectName] = useState("");
    const [projectDetails, setProjectDetails] = useState({
      inputFields: [
        {
          angleKeyword: '',
          angleKeywordList: [],
          customCtas: [],
          basicInfo: '',
          customPrompt: '',
          isKeywordsModalOpen: false,
          isCtasModalOpen: false
        }
      ]
    });
    

    // const handleChange = (e, key, index, ctaIndex) => {
    //   const { value } = e.target;
    //   setProjectDetails(prevState => {
    //     const updatedFields = prevState.inputFields.map((field, i) => {
    //       if (i === index) {
    //         if (key === 'angleKeywordList' || key === 'customCtas') {
    //           const updatedList = value.split('\n');
    //           return { ...field, [key]: updatedList };
    //         } else {
    //           return { ...field, [key]: value };
    //         }
    //       } else {
    //         return field;
    //       }
    //     });
    //     return { ...prevState, inputFields: updatedFields };
    //   });
    // };
    
    const handleChange = (e, key, index, ctaIndex) => {
      const { value } = e.target;
      console.log(value, "value")
      setProjectDetails(prevState => {
        const updatedFields = prevState.inputFields.map((field, i) => {
          if (i === index) {
            if (key === 'angleKeywordList') {
              const updatedList = value.split('\n').filter(item => item.trim() !== '');
              return { ...field, [key]: updatedList };
            } else if (key === 'customCtas') {
              // Retain previous logic for customCtas
              const updatedList = field[key].map((cta, idx) => {
                return idx === ctaIndex ? value : cta;
              });
              return { ...field, [key]: updatedList };
            } else {
              return { ...field, [key]: value };
            }
          } else {
            return field;
          }
        });
        return { ...prevState, inputFields: updatedFields };
      });
    };
    
    
    
    
    
   
    
    
    const [projectIndex, setProjectIndex] = useState(null);

   

    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
   console.log(projectData);

   const handleNavigate = (projectName) => {
    // Find the project by name
    const project = projectData.find(p => p.projectName === projectName);
    if (project) {
        setSelectedProject(project);// Assuming you've added setSelectedProject to your context
      navigate(`/project/${projectName}`);
    }
  };

  // const showModalEdit = () => {
  //   setOpenEdit(true);
  // };

  const userDataString = localStorage.getItem("userData");
  const userData = userDataString ? JSON.parse(userDataString) : null;

  // const handleOkEdit = () => {
  //   // Assuming userId is available in the frontend as userData._id
  //   const updatedInputFields = projectDetails.inputFields.map(field => {
  //     const { isKeywordsModalOpen, isCtasModalOpen, ...rest } = field;
  //     return rest;
  //   });

  
  //   const userId = userData._id;
  
  //   // Assuming _id is available for the project being edited
  //   // Assuming we are using the _id of the first input field for the project
  
  //   // Prepare the data to be sent in the POST request
  //   const postData = {
  //     _id: projectname,
  //     userId: userId,
  //     inputFields: updatedInputFields
  //   };
  //   console.log(postData, "postData")
  //   // Make the POST request
  //   axios.post('/social/updateSocialOp', postData)
  //     .then(response => {
  //       setProjectData(response.data);
  //       console.log('Update successful:', response.data);
  //     })
  //     .catch(error => {
  //       console.error('Update failed:', error);
  //     });
  
  //   // Close the modal
  //   setOpenEdit(false);
  // };

  const handleOkEdit = () => {
    // Assuming you have defined projectDetails, userData, and projectname somewhere
  
    const updatedInputFields = projectDetails.inputFields.map(field => {
      const { isKeywordsModalOpen, isCtasModalOpen, ...rest } = field;
      return rest;
    });
  
    const userId = userData._id;
    
  
    const postData = {
      _id: projectname,
      userId: userId,
      inputFields: updatedInputFields
    };
  
    // Make the POST request
    toast.promise(
      axios.post('/social/updateSocialOp', postData),
      {
        pending: 'Updating project...',
        success: 'Project updated successfully!',
        error: 'Error updating project'
      }
    )
    .then(response => {
      setProjectData(response.data);
      console.log('Update successful:', response.data);
    })
    .catch(error => {
      // Handle the error response from the backend
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(error.response.data.error);
      } else {
        // If there is no error message from the backend, display a generic error message
        toast.error('An error occurred while updating the project. Please try again.');
      }
  
      console.error('Update failed:', error);
    });
  
    // Close the modal
    setOpenEdit(false);
  };

  const handleCancelEdit = () => {
    setOpenEdit(false);
  };
  
  const handleDeleteProject = async (text, record) => {
    console.log(record, userData._id, "record userData._id")
    toast.promise(
      axios.post('/social/deleteAndFetchRemaining', { _id: record._id, userId: userData._id }),
      {
        pending: 'Deleting project...',
        success: {
          render({data}) {
            // Assuming your backend sends back a success message along with remaining documents
            setProjectData(data.data.remainingDocuments); // Update your state with the remaining documents
            return data.data.message;
          },
          // Adjust the success toast settings as needed
          icon: "🗑️",
        },
        error: {
          render({data}) {
            // Handling error scenario
            return data.response?.data.message || 'An error occurred while deleting the project';
          }
        },
      },
      {
        // Optional: Global configuration for this toast
        position: "top-right",
        autoClose: 5000,
      }
    );
  };

  
//   const handleEditProject = async (text, record) => {
//     const index = record.inputFields.findIndex(field => field === record); // Finding the index of the current record
//     setOpenEdit(true);
//     setProjectIndex(index); // Setting the index of the project being edited
//     setProjectDetails({
//       ...projectDetails,
//       inputFields: record.inputFields.map(field => ({
//         angleKeyword: field.angleKeyword,
//         angleKeywordList: field.angleKeywordList.join('\n'),
//         customCtas: field.customCtas.join('\n'),
//         basicInfo: field.basicInfo,
//         customPrompt: field.customPrompt
//       }))
//     });
// };

// const handleEditProject = async (text, record) => {
//   const index = record.inputFields.findIndex(field => field === record); // Finding the index of the current record
//   setOpenEdit(true);
//   setProjectIndex(index); // Setting the index of the project being edited
//   setProjectDetails({
//       ...projectDetails,
//       inputFields: record.inputFields.map(field => ({
//           angleKeyword: field.angleKeyword,
//           angleKeywordList: field.angleKeywordList, // Keep it as an array
//           customCtas: field.customCtas, // Keep it as an array
//           basicInfo: field.basicInfo,
//           customPrompt: field.customPrompt
//       }))
//   });
// };

const handleEditProject = async (text, record) => {
  console.log(text, record, "text, record")
  setProjectName(record._id)
  const index = record.inputFields.findIndex(field => field === record);
  setOpenEdit(true);
  setProjectIndex(index);
  setProjectDetails(prevState => ({
    ...prevState,
    inputFields: record.inputFields.map(field => ({
      ...field
    }))
  }));
};

// const handleChangeCustomCta = (e, fieldIndex, ctaIndex) => {
//   const { value } = e.target;
//   setProjectDetails(prevState => ({
//     ...prevState,
//     inputFields: prevState.inputFields.map((field, i) =>
//       i === fieldIndex ? {
//         ...field,
//         customCtas: field.customCtas.map((cta, j) =>
//           j === ctaIndex ? value : cta
//         )
//       } : field
//     )
//   }));
// };



    const columns = [
        {
            title: 'PS',
            key: 'ps',
            render: (text, record, index) => index + 1,
            width: 100
        },
        {
            title: 'Project Name',
            key: 'projectName',
            dataIndex: 'projectName',
             render: (text, record) => (
                // Replace Tag with an a tag
                <a onClick={() => handleNavigate(record.projectName)} style={{ cursor: 'pointer' }}>
                    {text}
                </a>
            )
        },
        {
          title: 'Edit',
          key: 'projectName',
          width: 100,
          render: (text, record) => (
            <div onClick={() => handleEditProject(text,record)} style={{ cursor: 'pointer'}}>
            <span class="material-symbols-outlined">
              edit_note
              </span>
              </div>
          )
        }
        ,
        {
          title: '',
          key: 'projectName',
          dataIndex: 'docs',
          render: (text, record) => (
            <div onClick={() => handleDeleteProject(text,record)} style={{ cursor: 'pointer'}}>
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24"><path d="m376-300 104-104 104 104 56-56-104-104 104-104-56-56-104 104-104-104-56 56 104 104-104 104 56 56Zm-96 180q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520Zm-400 0v520-520Z" fill="red" /></svg>
            </div>
          ),
          width: 100
        }
    ];
console.log(projectData);
    
    // const dataSource = projectData.map((project, index) => ({
    //     key: project._id, 
    //     projectName: project.projectName,
    //     userId: project.userId,
       
    // }));


    useEffect(() => {
        const fetchDocuments = async () => {
          // Retrieve user data from localStorage
          const userDataString = localStorage.getItem("userData");
          const userData = userDataString ? JSON.parse(userDataString) : null;
    
          if (!userData) {
            console.error("User data not found");
            setLoading(false);
            return;
          }
    
          try {
            // Add a timestamp to the request URL to avoid caching
            const timestamp = new Date().getTime();
            const response = await axios.get(`/social/socialops/${userData._id}?t=${timestamp}`);
            setProjectData(response.data);
          } catch (error) {
            console.error("Error fetching documents:", error);
            // Optionally handle error, e.g., show a message to the user
          } finally {
            setLoading(false);
          }
        };
    
        fetchDocuments();
      }, []); // Empty dependency array means this effect runs once on mount
    
      const [loadingKeywords, setLoadingKeywords] = useState(false);
      const [openKeywordsModal, setOpenKeywordsModal] = useState(false);

      const showKeywordsModal = () => {
        setOpenKeywordsModal(true);
      };
    
      const handleOkKeywordsModal = (index) => {
        setLoadingKeywords(false);
        // setOpenKeywordsModal(false);
        setProjectDetails(prevState => ({
          ...prevState,
          inputFields: prevState.inputFields.map((field, i) =>
            i === index ? { ...field, isKeywordsModalOpen: false } : field
          )
        }));
      };
    
      const handleOpenKeywordsModal = (index) => {
        setProjectDetails(prevState => ({
          ...prevState,
          inputFields: prevState.inputFields.map((field, i) =>
            i === index ? { ...field, isKeywordsModalOpen: true } : field
          )
        }));
      };
    
      const handleCloseKeywordsModal = (index) => {
        setProjectDetails(prevState => ({
          ...prevState,
          inputFields: prevState.inputFields.map((field, i) =>
            i === index ? { ...field, isKeywordsModalOpen: false } : field
          )
        }));
      };

      const handleOpenCtasModal = (index) => {
        setProjectDetails(prevState => ({
          ...prevState,
          inputFields: prevState.inputFields.map((field, i) =>
            i === index ? { ...field, isCtasModalOpen: true } : field
          )
        }));
      };
      
      const handleCloseCtasModal = (index) => {
        setProjectDetails(prevState => ({
          ...prevState,
          inputFields: prevState.inputFields.map((field, i) =>
            i === index ? { ...field, isCtasModalOpen: false } : field
          )
        }));
      };
      
      const handleOkCtasModal = (index) => {
        setLoadingKeywords(false); // Assuming the loading state is used for both keywords and CTAs
        setProjectDetails(prevState => ({
          ...prevState,
          inputFields: prevState.inputFields.map((field, i) =>
            i === index ? { ...field, isCtasModalOpen: false } : field
          )
        }));
      };
      


    return (
        <>
          <ToastContainer
position="top-right"
autoClose={7000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
        <Modal
        visible={openEdit}
        
        onOk={handleOkEdit}
        onCancel={handleCancelEdit}
        footer={[
          <Button key="back" onClick={handleCancelEdit}>
            Return
          </Button>,
          <Button key="submit"  loading={loadingEdit} onClick={handleOkEdit}>
            Save
          </Button>
        ]}
        width={1000}
      >

{projectDetails?.inputFields?.map((field, index) => (
  <Row gutter={4} key={index} className='mt-2'>
    <Col span={4}>
      <Input value={field.angleKeyword} onChange={(e) => handleChange(e, 'angleKeyword', index)} placeholder="Angle Keyword" />
    </Col>
    <Col span={3}>
    <Button style={{ width: '100%' }} onClick={() => handleOpenKeywordsModal(index)}>Keywords</Button>
              <Modal
                visible={field.isKeywordsModalOpen}
                title="Keywords"
                onOk={() => handleOkKeywordsModal(index)}
                onCancel={() => handleCloseKeywordsModal(index)}
                footer={[
                  <Button key="back" onClick={() => handleCloseKeywordsModal(index)}>
                    Cancel
                  </Button>,
                  <Button key="submit" loading={loadingKeywords} onClick={() => handleOkKeywordsModal(index)}>
                    Save
                  </Button>,
                ]}
              >
                <Input.TextArea value={field.angleKeywordList.join('\n')} onChange={(e) => handleChange(e, 'angleKeywordList', index)} rows={4} />

              </Modal>
    </Col>
    <Col span={3}>
      
      {/* <textarea value={field.customCtas} onChange={(e) => handleChange(e, 'customCtas', index)} rows={4} /> */}
      <Button style={{ width: '100%' }} onClick={() => handleOpenCtasModal(index)}>CTAs</Button>
  <Modal
    visible={field.isCtasModalOpen}
    // title="Custom CTAs"
    onOk={() => handleOkCtasModal(index)}
    onCancel={() => handleCloseCtasModal(index)}
    footer={[
      <Button key="back" onClick={() => handleCloseCtasModal(index)}>
        Cancel
      </Button>,
      <Button key="submit" loading={loadingKeywords} onClick={() => handleOkCtasModal(index)}>
        Save
      </Button>,
    ]}
  >
    {/* <Input.TextArea value={field.customCtas.join('\n')} onChange={(e) => handleChange(e, 'customCtas', index)} rows={4} /> */}
    {/* {field.customCtas.map((cta, ctaIndex) => (
    <Input
      key={ctaIndex}
      value={cta}
      className='mt-2'
      onChange={(e) => handleChange(e, index, ctaIndex)}
      placeholder="Custom CTA"
    />
  ))} */}
    {field.customCtas.map((cta, ctaIndex) => (
    <Input
      key={ctaIndex}
      value={cta}
      className='mt-2'
      onChange={(e) => handleChange(e, 'customCtas', index, ctaIndex)}
      placeholder="Custom CTA"
    />
  ))}
  </Modal>
    
    </Col>
    <Col span={7}>
      <Input.TextArea value={field.basicInfo} onChange={(e) => handleChange(e, 'basicInfo', index)} placeholder="Basic Info" rows={1}/>
    </Col>
    <Col span={7}>
      <Input.TextArea value={field.customPrompt} onChange={(e) => handleChange(e, 'customPrompt', index)} placeholder="Custom Prompt" rows={1} />
    </Col>
  </Row>
))}

      </Modal>
            <div className="mt-4">
            {projectData.length > 0 ? (
                // <Table columns={columns} dataSource={dataSource} />
                <Table columns={columns} dataSource={projectData} />
            ) : null}
            </div>
        </>
    );
}

export default ProjectFormTable;
