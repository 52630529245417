import React, {useState, useEffect,useCallback, useContext} from 'react'
import {  InputNumber ,Checkbox  } from 'antd';
import { QuestionCircleOutlined   } from '@ant-design/icons';
import DataTable from "react-data-table-component";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { OverlayTrigger, Spinner} from "react-bootstrap";
// import { OverlayTrigger, Spinner, Tooltip} from "react-bootstrap";
import {Tooltip} from "antd";
import { FaExpandArrowsAlt, FaCompressArrowsAlt } from 'react-icons/fa';
import axios from "../../components/Axios";
import BottomOffCanvas from "../../components/BottomOffCanvas"
import { KeywordContext } from "../../auth/KeywordContext";
import { useGetValueContext } from '../../auth/GetValueContext';
import { FavoriteContext } from "../../auth/FavoriteContext";
import MonthlyVolume from "./MonthlyVolume";
import openLogo from "../../assets/openin.png";
const userDataString = localStorage.getItem("userData");
const userData = userDataString ? JSON.parse(userDataString) : null;

function Presets({mainProp}) {

 

    // useEffect(() => {
    //   // Add UUIDs if missing
    //   const dataWithIds = mainProp.keywordData.map((item, index) => ({
    //     ...item,
    //     id: item.id || `uuid-${index}`  // Replace `uuid-${index}` with a proper UUID generator
    //   }));
    //   mainProp.keywordData = dataWithIds;
    // }, [mainProp]);
  
    const [expandedRows, setExpandedRows] = useState([]);
  
    const handleExpandAll = () => {
      setExpandedRows(mainProp.keywordData.map(row => row.id));
  };
  
  const handleCollapseAll = () => {
      setExpandedRows([]);
  };
  
  const isRowExpanded = rowId => expandedRows.includes(rowId);
  
  
  
    const { languageAbbreviation, countryCode, keywordData,updateKeywordData, setSelectedKeywordData } = useContext(KeywordContext);
    
    const {  setCopyToClipboard, setGetMainKeywordData, setCreditsCount } = useGetValueContext();
    // const { toggleFavorite, favorites } = useFavorites();
    const { favorites, setFavorites } = useContext(FavoriteContext);
  
    
    const [selectedrowmain, setSelectedrowmain] = useState([]);
    const [updatedMainKeywordData, setupdatedMainKeywordData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [refreshIconLoading, setRefreshIconLoading] = useState(false);
    const [show, setShow] = useState(false);
    const [toastMessage, setToastMessage] = useState("");
  
    const [toggledClearRows, setToggleClearRows] = useState(false);
    const handleClearRows = () => {
      setToggleClearRows(prevState => !prevState);
    };
    
    
  
    const handleChange = ({ selectedRows }) => {
      setSelectedrowmain(selectedRows);
      setCopyToClipboard(selectedRows);
   
    const updatedKeywords = selectedRows.length > 0
    ? keywordData.keywordData.filter(keyword => 
        selectedRows.some(row => row.id === keyword.id))
    : mainProp.keywordData;
    // : keywordData.keywordData;
  
    setSelectedKeywordData({ ...keywordData, keywordData: updatedKeywords });
  
  
    };
  
    useEffect(() => {
      
      setSelectedKeywordData({ ...keywordData, keywordData: mainProp.keywordData });
  
    }, [mainProp])
    
  
  
    // useEffect(() => {
    //   console.log(selectedrowmain, "selectedrowmain");
    // }, [selectedrowmain]);
  
    useEffect(() => {
      console.log("is it working setSlectedKeywordData")
      setSelectedKeywordData(keywordData);
    }, [keywordData]);
  
  
    const showErrorToast = (message) => {
      toast.error(message, {
          position: "top-right",
          autoClose: 15000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
      });
  };
  
  function updateKeywordDataWithMerge(keywordData, responseQueries) {
    const responseQueriesMap = new Map(responseQueries.map(q => [q.text, q]));
    return keywordData.map(kd => responseQueriesMap.get(kd.text) || kd);
  }
  
    const handleButtonClick = () => {
  
      // Check if the length of selectedRowMain exceeds 50
      if (selectedrowmain.length > 50) {
        // toast.error('Maximum selected keywords should be 50 at a time.', {
        toast.error('Maximum 50 keywords can be analysed at a time', {
          position: "top-right",
          autoClose: 6000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "light",
        });
        return;
      }
  
      setIsLoading(true);
      setCreditsCount(prevCount => {
        return {
          ...prevCount,
          credits: prevCount.credits - selectedrowmain.length
        };
      });
      const hlcode = languageAbbreviation.toLowerCase();
      const crcode = "country" + countryCode.toUpperCase();
     
  
      console.log(
        // mozAccessId,
        // mozSecretKey,
        selectedrowmain,
        crcode,
        hlcode,
        countryCode,
        mainProp?.user,
        mainProp?._id,
        "req body"
      );
  
      const requestBody = {
        queries: selectedrowmain, // Add other data as required
        cr: crcode,
        gl: countryCode,
        hl: hlcode,
        // accessId: mozAccessId,
        // secretKey: mozSecretKey,
        userId: mainProp?.user,
        documentmainId: mainProp?._id,
      };
  
      axios
        .post("/kws/analyzingkws", requestBody)
        .then((response) => {
          // console.log(response.data);
  
          // const responseData = response.data.queries;
          const responseQueries = response.data.processedQueries.queries;
          // console.log(responseQueries, "responseQueries");
          setSelectedrowmain([]);
          handleClearRows();
      
            if (response.data.processedQueries.details) {
              showErrorToast(`${response.data.processedQueries.details}`)
          }
  
          if (response.data.pageRankErrorMain) {
            showErrorToast(`${response.data.pageRankErrorMain}`)
        }
  
          if (response.data.mozError !== null) {
            showErrorToast(`${response.data.mozError}`);
           }
          
            // Create a map from responseQueries for quick lookup
            const responseQueriesMap = new Map(responseQueries.map(q => [q.text, q]));
           console.log(responseQueriesMap, "responseQueriesMap")
            // Update only the relevant objects in keywordData
            const updatedKeywordData = keywordData.keywordData.map(kd => {
                // Check if there is an update for this item
                const update = responseQueriesMap.get(kd.text);
                // If there is an update, merge it with the current item
                return update ? { ...kd, ...update } : kd;
            });
  
         console.log(updatedKeywordData, "updatedKeywordData")
          
          updateKeywordData({ ...keywordData, keywordData: updatedKeywordData }); // Update global state
          
        })
        .catch((error) => {
          // Logging the error for debugging purposes
           console.error('Error during the API request:', error);
           setSelectedrowmain([]);
    // Handling different types of errors
    if (error.response) {
        const  errorMessage = error.response.data.error || error.response.data || errorMessage;
      showErrorToast(`${errorMessage}`);
    } else if (error.request) {
      // The request was made but no response was received
      console.error('No response received:', error.request);
      showErrorToast('No response from the server, please check your network connection.');
    } else {
      // Something happened in setting up the request and triggered an Error
      console.error('Error setting up the request:', error.message);
      showErrorToast(`Request setup error: ${error.message}`);
    }
  
    // Handling specific Axios errors (e.g., timeout)
    if (error.code === 'ECONNABORTED') {
      showErrorToast('Request timeout, please try again later.');
    }
  
    // Additional handling for network errors
    if (!navigator.onLine) {
      showErrorToast('No internet connection, please check your network.');
    }
      })
        .finally(() => {
          setIsLoading(false);
        });
    };
  
  
        const customDomainsStr = localStorage.getItem('customDomains') || '';
        const formattedStr = customDomainsStr.replace(/^"|"$/g, '').replace(/\\n/g, '\n');
        const customDomainsArray = formattedStr.split('\n').map(domain => domain.trim());
  
        const myDomainsstr = localStorage.getItem('myDomains') || '';
        const myDomainsst1 = myDomainsstr.replace(/^"|"$/g, '').replace(/\\n/g, '\n');
        const myDomainsArray = myDomainsst1.split('\n').map(domain => domain.trim());
  
        const socialMedia = [
          "quora.com",
          "reddit.com",
          "youtube.com",
          "facebook.com",
          "twitter.com",
          "linkedin.com"
        ];
    const renderOverlayTrigger = (item, index) => {
      const iconClass = getIconClass(item.root_domain);
      
      const isDomainAuthorityEmpty = item.domain_authority === "";
      const colorClass = isDomainAuthorityEmpty ? "grey" : (item.domain_authority <= 20 ? "green" : "grey");
      const tooltipContent = isDomainAuthorityEmpty 
          ? `${item.pageRankdomain} | PR → ${item.rank}`
          : `DA → ${item.domain_authority} | ${item.root_domain}`;
  
      const backgroundClass = getBackgroundClass(iconClass);
      const isSocialMedia = socialMedia.includes(item.root_domain);
      // const isCustomDomain = customDomainsArray.length > 0 && customDomainsArray.includes(item.root_domain);
     // const isMyDomain = myDomainsArray.length > 0 && myDomainsArray.includes(item.root_domain);
      const isCustomDomain = !isSocialMedia && customDomainsArray.length > 0 && customDomainsArray.includes(item.root_domain);
      const isMyDomain = !isSocialMedia && myDomainsArray.length > 0 && myDomainsArray.includes(item.root_domain);
    
  
      return (
        <Tooltip 
        
        title={tooltipContent}
        placement="top"
        color="#efefef"
        key={index}
        overlayClassName="custom-tooltip-text-color"
    >
        <span className={`ra-ws ra-wss me-3 ${colorClass} ${isCustomDomain ? 'mycustomClass1' : ''} ${isMyDomain ? 'mydomainmyClass1' : ''}`}>
            <span className={`${backgroundClass} ${isCustomDomain ? 'mycustomClass2' : ''} ${isMyDomain ? 'mydomainmyClass2' : ''}`}>{index + 1}</span>
            <i className={iconClass}></i>
        </span>
    </Tooltip>
      );
  };
  
  
    const iconClassMapping = {
      "quora.com": "bi bi-quora",
      "reddit.com": "bi bi-reddit",
      "youtube.com": "bi bi-youtube",
      "facebook.com": "bi bi-facebook",
      "twitter.com": "bi bi-twitter",
      "linkedin.com": "bi bi-linkedin"
    };
  
    const backgroundClassMapping = {
      "bi bi-quora": "quoraBackground",
      "bi bi-reddit": "redditBackground",
      "bi bi-youtube": "youtubeBackground",
      "bi bi-facebook": "facebookBackground",
      "bi bi-twitter": "twitterBackground",
      "bi bi-linkedin": "linkedinBackground",
      // Map more icon classes to background classes as needed
    };
  
    const getIconClass = (rootDomain) => {
      // return iconClassMapping[rootDomain] || "bi bi-vinyl";
      return iconClassMapping[rootDomain] || "bi bi-instagram";
    };
  
    const getBackgroundClass = (iconClass) => {
      return backgroundClassMapping[iconClass] || "";
    };
  
  
    const isAnyRowWithItems = Array.isArray(mainProp?.keywordData) && mainProp?.keywordData?.some(
      // Ensure each row is defined and has a 'items' property which is an array
      row => row && Array.isArray(row.items) && row.items.length > 0
    );
    // console.log(isAnyRowWithItems, "isAnyRowWithItems")
    const serpAnalysisWidth = isAnyRowWithItems ? "369px" : "240px";
  
    
    const getLowestDomainAuthority = (items) => {
      if (!items || items.length === 0) return "0";
      
      // Convert empty strings to 0, otherwise use the number value
      const domainAuthorities = items.map((item) => 
        item.domain_authority === "" ? 0 : Number(item.domain_authority)
      );
    
      return Math.min(...domainAuthorities);
    };
  
    const getHighestDomainAuthority = (items) => {
      if (!items || items.length === 0) return "0";
      
      // Convert empty strings to 0, otherwise use the number value
      const domainAuthorities = items.map((item) => 
        item.domain_authority === "" ? 0 : Number(item.domain_authority)
      );
    
      return Math.max(...domainAuthorities);
    };
  
    const getLowestRank = (items) => {
      if (!items || items.length === 0) return 0;
    
      // Convert empty strings to 0, otherwise use the number value
      const ranks = items.map((item) => 
        item.rank === "" ? 0 : Number(item.rank)
      );
    
      return Math.min(...ranks);
    };
    const getHighestRank = (items) => {
      if (!items || items.length === 0) return 0;
    
      // Convert empty strings to 0, otherwise use the number value
      const ranks = items.map((item) => 
        item.rank === "" ? 0 : Number(item.rank)
      );
    
      return Math.max(...ranks);
    };
      
    const customSort = (rows, selector, direction) => {
      return rows.sort((rowA, rowB) => {
        const aField = parseFloat(selector(rowA));
        const bField = parseFloat(selector(rowB));
  
        // Check if either value is not a number
        if (isNaN(aField) || isNaN(bField)) {
          if (isNaN(aField) && isNaN(bField)) {
            // Both are non-numeric, compare as strings
            return direction === "desc"
              ? -String(selector(rowA)).localeCompare(String(selector(rowB)))
              : String(selector(rowA)).localeCompare(String(selector(rowB)));
          }
          // Treat non-numeric values as greater or lesser
          return isNaN(aField) ? 1 : -1;
        }
        console.log(`Comparing: ${aField} to ${bField}`);
        // Both are numeric, compare as numbers
        let comparison = aField - bField;
  
        return direction === "desc" ? -comparison : comparison;
      });
    };
        
  const calculateKGR = (row) => {
      const totalResults = row.keyword_idea_metrics?.allintitleTotalResults;
      let monthlySearches = row.keyword_idea_metrics?.avg_monthly_searches;
      console.log(`Original monthlySearches: ${monthlySearches}`);
      // If monthlySearches is falsey (null, undefined, 0, "", NaN), set it to 10
      // Exclude the boolean false because it's not a valid input here
      monthlySearches = monthlySearches == 0 ? 10 : monthlySearches;
  
      
      console.log(`Adjusted monthlySearches: ${monthlySearches}`); // Debug log
  
      // Calculate KGR
      const kgr = totalResults / monthlySearches;
    
      // Return "N/A" if the result is Infinity
      return isFinite(kgr) ? kgr.toFixed(2) : "N/A";
  };
  
  
    const handleSubmit = async (row) => {
      try {
        const res = await axios.post('/api/processquery', { mainquery: row });
        return res.data;
      } catch (error) {
        console.error('Error in handleSubmit:', error);
        // Handle the error appropriately
      }
    };
  
    const handleIconClick = async (row) => {
      const response = await handleSubmit(row);
      return response;
    };
  
    const updateFavoriteStatusOnServer = async (row) => {
      try {
        // Making the POST request with Axios
        const response = await axios.post('/api/markAsFavorite', {
          userId: mainProp?.user,
          documentId: mainProp?._id,
          keywordDataTexts: [row.text]
        });
        console.log(response.data, "response.data fav")
        // The response data is directly accessible
        return response.data;
      } catch (error) {
        // Determine the error message based on the error type
        let errorMessage = 'Error updating favorite status';
        if (error.response) {
          // The server responded with a status code outside the 2xx range
          errorMessage = `Error from server: ${error.response.data}`;
        } else if (error.request) {
          // The request was made but no response was received
          errorMessage = 'No response from server';
        } else {
          // Something else caused the error
          errorMessage = 'Error in making the request';
        }
    
        // Display the error message using toast
        setToastMessage(errorMessage);
        setShow(true);
    
        // Re-throw the error for further handling if necessary
        throw new Error(errorMessage);
      }
    };
  
    useEffect(() => {
      const serverFavs = mainProp?.keywordData?.filter(keyword => keyword.isFavorite === true);
      setFavorites(prevState => ({
        ...prevState,
        serverFavorites: serverFavs
      }));
    }, [mainProp?.keywordData]);
    
    useEffect(() => {
      // Reset localFavorites when a new document is loaded
      setFavorites(prevState => ({
        ...prevState,
        localFavorites: [] // or fetch the favorites specific to the new document if applicable
      }));
    }, [mainProp?.keywordData?._id]); // newDocumentId should be the identifier of the currently loaded MongoDB document
    
   
    const FavoriteIcon = ({ row }) => {
      // const { toggleFavorite, favorites } = useContext(FavoriteContext); 
      // const { toggleFavorite, favorites } = useFavorites();
      // const isFavorite = favorites.includes(row);
      // const isFavorite = favorites.localFavorites.includes(row) || favorites.serverFavorites.includes(row);
      
      // Again Commenting
      // const isFavorite = favorites.localFavorites?.some(fav => fav.text === row.text) || 
      // favorites.serverFavorites?.some(fav => fav.text === row.text);
      // toggleFavorite(row); in handleFaviconClick
    
       const handleFaviconClick  = async () => {
  
        const updatedKeywordData = keywordData.keywordData.map(k => {
          if (k.text === row.text) {
            return { ...k, isFavorite: !k.isFavorite };
          }
          return k;
        });
  
        // Update the keywordData in the global state
        updateKeywordData({ ...keywordData, keywordData: updatedKeywordData });
     
        
        try {
          await updateFavoriteStatusOnServer( row);
        } catch (error) {
          console.log(error)
        }
          
      };
    
      return (
        <div style={{ textAlign: 'left' }} onClick={handleFaviconClick} className="startdiv">
          <i className={row.isFavorite ? "bi bi-star-fill" : "bi bi-star"}></i>
        </div>
      );
    };
  
  
  
    function getAverageOffPageSEODifficulty(items) {
      if (!items || items.length === 0) return "0";
  
      // Calculate Off-Page SEO Difficulty for each item
      const difficulties = items.map(item => {
          const mozDA = item.domain_authority;
          const opr = item.decimal;
          return 0.75 * mozDA + 0.25 * opr * 10;
      });
  
      // Calculate the sum of all difficulties
      const totalDifficulty = difficulties.reduce((sum, difficulty) => sum + difficulty, 0);
      const averageDifficulty = totalDifficulty / difficulties.length;
  
      // Return the average difficulty
      return `${Math.floor(averageDifficulty)}/100`;
  }
  
  const getAverageOnPageSEODifficulty = (data) => {
    if (!data.items || data.items.length === 0) return "0";
  
    const calculatePoints = (title, url, snippet, exactQuery, broadTerms) => {
        let points = 0;
  
        // Check exact query in title, URL, snippet
        if (title.includes(exactQuery)) points += 15;
        if (url.includes(exactQuery)) points += 5;
        if (snippet.includes(exactQuery)) points += 5;
  
        // Check broad terms
        const titleMatches = broadTerms.reduce((count, term) => count + title.includes(term), 0);
        const urlMatches = broadTerms.reduce((count, term) => count + url.includes(term), 0);
        const snippetMatches = broadTerms.reduce((count, term) => count + snippet.includes(term), 0);
  
        points += (titleMatches / broadTerms.length) * 25;
        points += (urlMatches / broadTerms.length) * 10;
        points += (snippetMatches / broadTerms.length) * 10;
  
        // Check for highlighted keywords in the description
        const boldedWords = (snippet.match(/<b>(.*?)<\/b>/g) || []).map(bolded => bolded.replace(/<\/?b>/g, '').toLowerCase());
        let highlightedPoints = 0;
        if (boldedWords.length > 0) {
            const exactQueryMatches = boldedWords.filter(word => exactQuery.includes(word)).length;
            const broadTermMatches = boldedWords.filter(word => broadTerms.some(term => word.includes(term))).length;
            
            highlightedPoints = ((exactQueryMatches + broadTermMatches) / (exactQuery.split(' ').length + broadTerms.length)) * 30;
        }
        points += highlightedPoints;
  
        return points; 
    };
  
    const exactQuery = data.text.toLowerCase();
    const broadTerms = exactQuery.split(' ');
    let totalPoints = 0;
  
    // data.items.forEach(item => {
    //     const title = item.title.toLowerCase();
    //     const url = item.formattedUrl.toLowerCase();
    //     const snippet = item.htmlSnippet.toLowerCase();
    //     totalPoints += calculatePoints(title, url, snippet, exactQuery, broadTerms);
    // });
  
    data.items.forEach(item => {
      const title = item.title ? item.title.toLowerCase() : '';
      const url = item.formattedUrl ? item.formattedUrl.toLowerCase() : '';
      const snippet = item.htmlSnippet ? item.htmlSnippet.toLowerCase() : '';
      totalPoints += calculatePoints(title, url, snippet, exactQuery, broadTerms);
  });
  
    const averagePoints = totalPoints / data.items.length;
    return `${Math.floor(averagePoints)}/100`;
  };

      const caseInsensitiveSort = (rowA, rowB) => {
            const a = rowA.text.toLowerCase();
            const b = rowB.text.toLowerCase();
          
            if (a > b) {
                return 1;
            }
          
            if (b > a) {
                return -1;
            }
          
            return 0;
          };
          const sortItemsLength = (rowA, rowB) => {
            const lengthA = rowA.items ? rowA.items.length : 0;
            const lengthB = rowB.items ? rowB.items.length : 0;
          
            if (lengthA > lengthB) return 1;
            if (lengthB > lengthA) return -1;
            return 0;
          };
          
          const sortByFav = (rowA, rowB) => {
            // Assuming 'isFavorite' is a boolean property
            // Rows where 'isFavorite' is true will come first
            if (rowA.isFavorite && !rowB.isFavorite) {
              return -1;
            }
            if (!rowA.isFavorite && rowB.isFavorite) {
              return 1;
            }
            return 0;
          };
          
          
          const columns = [
              {
                name: "Fav",
                selector: (row) => <div className="d-flex justify-content-between align-items-center">
                <FavoriteIcon row={row} />
                <RefreshIcon row={row} />
                
          
              </div>, 
                sortable: true,
                sortFunction: sortByFav,
                width: "72px",
                wrap: true,
                // allowOverflow: true,
                left: true,
              },
              {
                name: 'Name',
                selector: (row) => (
                  <>
                  {row.text}  
                 {row.items && row.items.length > 0  && (
                    <BottomOffCanvas row={row} onIconClick={() => handleIconClick(row)}>
                      {/* <span class="material-symbols-outlined newtabicon">
          open_in_new
          </span> */}
          <img src={openLogo} className="newtabicon" alt="new tab open" />
                    </BottomOffCanvas>
                  )}
                  
                  </>
                ), // Updated to use the 'text' property directly
                sortable: true,
                sortFunction: caseInsensitiveSort,
                wrap: true,
                // allowOverflow: true,
                width: "30%",
                // maxWidth:'100px',
              },
              {
                name: "Vol",
                selector: (row) =>
                  row.keyword_idea_metrics.avg_monthly_searches
                    ? row.keyword_idea_metrics.avg_monthly_searches
                    : "0", // Checks if avg_monthly_searches exists, else returns '0'
                sortable: true,
                width: "12%",
                // allowOverflow: true,
                wrap: true,
                style: {
                  color: "#999",
                },
              },
              {
                name: "SERP Analysis",
                cell: (row) =>
                  row.items && row.items.length > 0
                    ? row.items
                        .slice(0, 10)
                        .map((item, index) => renderOverlayTrigger(item, index))
                    : "Not analyzed yet",
          
                style: {
                  borderRight: "5px solid #999",
                },
                //  width: '365px',
                // width: "46%",
                left: true,
                sortable: true,
                sortFunction: sortItemsLength,
                wrap: true,
                // allowOverflow: true,
                style: {
                  color: "#999",
                },
              }
              
            ];

      const RefreshIcon = ({ row }) => {
            return (
              <i className="bi bi-arrow-repeat ms-2" onClick={() => handleRefreshClick(row)}></i>
            );
          };
          
          
          
          const showToast = (refreshisLoading) => {
             if (refreshisLoading) {
                toast('Analysing Keyword', {
                   position: "top-right",
                  //  autoClose: 7000,
                   hideProgressBar: false,
                   closeOnClick: true,
                   pauseOnHover: true,
                   draggable: true,
                   progress: undefined,
                   theme: "light",
                });
             } else {
                toast.dismiss();
             }
          };
          
          useEffect(() => {
             showToast(refreshIconLoading);
          }, [refreshIconLoading]);
          
          
          
           const handleRefreshClick = (row) => {
              setRefreshIconLoading(true);
          
          
              setCreditsCount(prevCount => {
                return {
                  ...prevCount,
                  credits: prevCount.credits - 1
                };
              });
              const hlcode = languageAbbreviation.toLowerCase();
              const crcode = "country" + countryCode.toUpperCase();
             
          
              console.log(
                // mozAccessId,
                // mozSecretKey,
                row,
                crcode,
                hlcode,
                countryCode,
                mainProp?.user,
                mainProp?._id,
                "req body"
              );
          
              const requestBody = {
                queries: [row], // Add other data as required
                cr: crcode,
                gl: countryCode,
                hl: hlcode,
                // accessId: mozAccessId,
                // secretKey: mozSecretKey,
                userId: mainProp?.user,
                documentmainId: mainProp?._id,
              };
          
              axios
                .post("/kws/analyzingkws", requestBody)
                .then((response) => {
                  // console.log(response.data);
          
                  // const responseData = response.data.queries;
                  const responseQueries = response.data.processedQueries.queries;
                  // console.log(responseQueries, "responseQueries");
          
               
              
                  if (response.data.processedQueries.details) {
                    showErrorToast(`${response.data.processedQueries.details}`)
                }
          
                if (response.data.pageRankErrorMain) {
                  showErrorToast(`${response.data.pageRankErrorMain}`)
              }
          
                if (response.data.mozError !== null) {
                  showErrorToast(`${response.data.mozError}`);
                 }
          
          
                   setSelectedrowmain([]);
                    // Create a map from responseQueries for quick lookup
                    const responseQueriesMap = new Map(responseQueries.map(q => [q.text, q]));
                   console.log(responseQueriesMap, "responseQueriesMap")
                    // Update only the relevant objects in keywordData
                    const updatedKeywordData = keywordData.keywordData.map(kd => {
                        // Check if there is an update for this item
                        const update = responseQueriesMap.get(kd.text);
                        // If there is an update, merge it with the current item
                        return update ? { ...kd, ...update } : kd;
                    });
          
                 console.log(updatedKeywordData, "updatedKeywordData")
                  
                  updateKeywordData({ ...keywordData, keywordData: updatedKeywordData }); // Update global state
                  
                })
                .catch((error) => {
                  // Logging the error for debugging purposes
                   console.error('Error during the API request:', error);
                   setSelectedrowmain([]);
            // Handling different types of errors
            if (error.response) {
                const  errorMessage = error.response.data.error || error.response.data || errorMessage;
              showErrorToast(`${errorMessage}`);
            } else if (error.request) {
              // The request was made but no response was received
              console.error('No response received:', error.request);
              showErrorToast('No response from the server, please check your network connection.');
            } else {
              // Something happened in setting up the request and triggered an Error
              console.error('Error setting up the request:', error.message);
              showErrorToast(`Request setup error: ${error.message}`);
            }
          
            // Handling specific Axios errors (e.g., timeout)
            if (error.code === 'ECONNABORTED') {
              showErrorToast('Request timeout, please try again later.');
            }
          
            // Additional handling for network errors
            if (!navigator.onLine) {
              showErrorToast('No internet connection, please check your network.');
            }
              })
                .finally(() => {
                 setRefreshIconLoading(false);
                });
            };
          
            const nestedColumns = [
              {
                // name: "Low DA",
                name: (
                       <Tooltip title="Lowest DA among Top 10 Search Results">
                           <span>Low DA</span>
                       </Tooltip>
                   ),
                selector: (row) => getLowestDomainAuthority(row.items),
                sortable: true,
                wrap: true,
                // center: true,
                // allowOverflow: true,
                width: "57px",
                style: {
                  color: "#999",
                  paddingRight: '8px',
                },
              },
              {
                // name: "High DA",
                name: (
                  <Tooltip title="Highest DA among Top 10 Search Results">
                      <span>High DA</span>
                  </Tooltip>
              ),
                selector: (row) => getHighestDomainAuthority(row.items),
                sortable: true,
                wrap: true,
                // allowOverflow: true,
                width: "60px",
                style: {
                  color: "#999",
                },
              },
              // {
              //   name: "KGR",
              //   selector: (row) => calculateKGR(row),
              //   sortable: true,
              //   width: "72px",
              //   wrap: true,
              //   // allowOverflow: true,
              //   style: {
              //     color: "#999",
              //   },
              // }
             
              {
                name: "KGR",
                selector: (row) => {
                  const kgrValue = calculateKGR(row);
                  let backgroundColor, color;
              
                  if (kgrValue === "N/A") {
                    backgroundColor = '#fff'; // White for N/A values
                    color = '#999';            // Grey text color
                  } else if (parseFloat(kgrValue) <= 0.25) {
                    backgroundColor = '#8BC34A'; // Soft green
                    color = 'white';             // White text for readability
                  } else if (parseFloat(kgrValue) >= 0.26 && parseFloat(kgrValue) <= 1.00) {
                    backgroundColor = '#FFEB3B'; // Muted yellow
                    color = 'black';             // Black text for readability
                  } else if(parseFloat(kgrValue) > 1.01) {
                    backgroundColor =  '#FF7043';    // White for other values
                    color = 'white';              // Default grey text color
                  }
                   else {
                    backgroundColor = '#fff';    // White for other values
                    color = '#999';              // Default grey text color
                  }
              
                  return (
                    <div style={{ textAlign: 'center' }}>
                      <span style={{
                        display: 'inline-block',
                        padding: '3px 8px',
                        backgroundColor: backgroundColor,
                        color: color,
                        borderRadius: '10px',
                        fontSize: '0.8em'
                      }}>
                        {kgrValue}
                      </span>
                    </div>
                  );
                },
                sortable: true,
                width: "80px",
                wrap: true,
              }
              
              
              
              ,
              
              
              {
                name: "AllinTitle",
                selector: (row) =>
                  row.keyword_idea_metrics.allintitleTotalResults
                    ? row.keyword_idea_metrics.allintitleTotalResults
                    : "0",
                sortable: true,
                wrap: true,
                // allowOverflow: true,
                width: "100px",
                style: {
                  color: "#999",
                },
              },{
                name: "AllinUrl",
                selector: (row) => row.keyword_idea_metrics.allinurlTotalResults ? row.keyword_idea_metrics.allinurlTotalResults : "",
                sortable: true,
                wrap: true,
                // allowOverflow: true,
                width: "93px",
                style: {
                  color: "#999",
                },
              },
              {
                name: "Off-Page SEO Difficulty",
                selector: (row) => getAverageOffPageSEODifficulty(row.items),
                sortable: true,
                wrap: true,
                // allowOverflow: true,
                width: "184px",
                style: {
                  color: "#999",
                },
              },
              {
                name: "On-Page SEO Difficulty",
                selector: (row) => getAverageOnPageSEODifficulty(row),
                sortable: true,
                wrap: true,
                // allowOverflow: true,
                width: "183px",
                style: {
                  color: "#999",
                },
              },
              {
                // name: "High PageRank",
                name: (
                  <Tooltip title="Highest Page Rank among Top 10 Search Results">
                      <span>High PageRank</span>
                  </Tooltip>
              ),
                selector: (row) => getHighestRank(row.items),
                sortable: true,
                wrap: true,
                // allowOverflow: true,
                width: "100px",
                style: {
                  color: "#999",
                },
              },
              {
                // name: "Low PageRank",
                name: (
                  <Tooltip title="Lowest Page Rank among Top 10 Search Results">
                      <span>Low PageRank</span>
                  </Tooltip>
              ),
                selector: (row) => getLowestRank(row.items),
                sortable: true,
                wrap: true,
                center: true,
                // allowOverflow: true,
                width: "105px",
                style: {
                  color: "#999",
                },
              },
              
          
              {
                name: "CPC",
                selector: (row) =>
                  row.keyword_idea_metrics.low_top_of_page_bid_micros
                    ? (
                        (row.keyword_idea_metrics.low_top_of_page_bid_micros / 1000000 / 290) 
                      ).toFixed(2)
                    : "0",
                sortable: true,
                wrap: true,
                // allowOverflow: true,
                width: "72px",
                style: {
                  color: "#999",
                },
              }
          ];
          
          const ExpandedComponent = ({ data }) => {
           
            const nestedData = [data]; // Since we are displaying data for one row
          
              return (
                  <div className="monthly-volume-chart">
                      <DataTable
                          columns={nestedColumns}
                          data={nestedData}
                          className=""
                          customStyles={customStylesNested}
                      />
                       
                          <MonthlyVolume keyword={data} />
                      
                  </div>
              );
          
          };

      const [areRowsExpanded, setAreRowsExpanded] = useState(false);
      const handleToggleRows = () => {
        if (areRowsExpanded) {
            // Collapse all rows
            handleCollapseAll(); // Your existing collapse function
        } else {
            // Expand all rows
            handleExpandAll(); // Your existing expand function
        }
        setAreRowsExpanded(!areRowsExpanded);
      };
      
      const customStylesNested = {
        headCells: {
            style: {
                fontWeight: '500', // Make the font bold
                color: '#333', // Darker text for contrast
               fontSize: '13px', // Slightly larger font size
                // textTransform: 'uppercase', // Uppercase text for a more professional look
                // letterSpacing: '0.3px', // Spacing out the letters for better readability
            },
        },
      };
      
      const customStyles = {
        rows: {
            style: {
                minHeight: '55px', // Override the row height
            },
        },
        headCells: {
            style: {
                fontWeight: '500', // Make the font bold
                color: '#333', // Darker text for contrast
               fontSize: '14px', // Slightly larger font size
                // textTransform: 'uppercase', // Uppercase text for a more professional look
                // letterSpacing: '0.3px', // Spacing out the letters for better readability
            },
        },
      };

      const [values, setValues] = useState({
        searchVolumeMin: "",
        searchVolumeMax: "",
        wordsLengthMin: "",
        wordsLengthMax: "",
        kgrRatioMin: "",
        kgrRatioMax: "",
        cpcMin: "",
        cpcMax: "",
        daMin: "",
        daMax: "",
        allInTitleMin: "",
        allInTitleMax: "",
        allInUrlsMin: "",
        allInUrlsMax: "",
        selectedCategories: [],
        goldenRuleName: ""
      });
      const [presets, setPresets] = useState(null);

      const handleChangeValues = (e, key) => {
        // Check if 'e' is an event object and has 'target' property
        if (e && e.target) {
          // Extracting value from the event object
          const { value } = e.target;
          setValues(prevValues => ({ ...prevValues, [key]: value }));
        } else {
          // Use 'e' directly as the value
          setValues(prevValues => ({ ...prevValues, [key]: e }));
        }
      };
      

      // const handleChangeValues = (e, key) => {
      //   setValues(prevValues => ({ ...prevValues, [key]: e }));

      // };


      
      // const categoryMap = {
      //   "Questions": ["who", "why", "what", "where", "when"],
      //   "Investigative Keywords": ["best", "Vs", "review", "alternative", "comparison", "case study", "pros and cons", "FAQ"],
      //   "Commercial Keywords": ["cost", "price", "pricing", "buy", "discount", "free shipping", "promo", "sale", "trial", "shop", "purchase", "coupon", "deal"],
      //   "Informational Keywords": ["Tips", "strategies", "how to", "guide", "tutorial", "analysis", "tips"]
      // };

// console.log(values, "values with onChange")

const handleCheckboxChange = (category) => {
  const isSelected = values.selectedCategories.includes(category);

  if (isSelected) {
    // Remove category if already selected
    setValues(prevState => ({
      ...prevState,
      selectedCategories: prevState.selectedCategories.filter(item => item !== category)
    }));
  } else {
    // Add category if not selected
    setValues(prevState => ({
      ...prevState,
      selectedCategories: [...prevState.selectedCategories, category]
    }));
  }
};


const presetHandleSubmit = async () => {


  try {
    toast.info("Saving preset...", { autoClose: 3000 }); // Show a loading message

    
    const response = await axios.post('/mainpresets/updatePreset', { userId: userData._id, newPreset: values });
      console.log(response, "response")
      setPresets(response.data)
    localStorage.setItem("userData", JSON.stringify(response.data));
    // If the operation is successful
    if (response.status === 200) {
      toast.success("Preset saved successfully!", { autoClose: 3000 });
    } else {
      // If the server responds with a status other than 200
      toast.error("Failed to save preset. Please try again.", { autoClose: 5000 });
    }
  } catch (error) {
    // If there's an error during the Axios request
    console.error("Error sending preset update request:", error);
    toast.error("Failed to save preset. Please try again.", { autoClose: 5000 });
  }
};



useEffect(() => {
  const fetchData = async () => {
    // Ensure loading state is true when the operation starts
    toast.info("Fetching preset...", { autoClose: 3000 });
    
    const timestamp = new Date().getTime(); 

    try {
      const response = await axios.get(`/api/user/credits/${userData._id}?timestamp=${timestamp}`);
      // Assuming the response data structure matches your state
      setPresets(response.data.credits)
      console.log(response, "response response for presets")
      // Notification for success
      // toast.success("Presets has been fetched successfully");
    } catch (error) {
      // Logging and notifying about the error
      console.error("Error fetching Reddit credentials:", error);
      toast.error("Failed to fetch presets");
    } 
  };


    fetchData();

}, []); 

const [filteredKeywords, setFilteredKeywords] = useState([]);
// const filterByMinVolume = useCallback((keywords, min) => {
//   return min ? keywords.filter(({ keyword_idea_metrics }) => parseInt(keyword_idea_metrics.avg_monthly_searches, 10) >= min) : keywords;
// }, []);

// // Filter by maximum search volume
// const filterByMaxVolume = useCallback((keywords, max) => {
//   return max ? keywords.filter(({ keyword_idea_metrics }) => parseInt(keyword_idea_metrics.avg_monthly_searches, 10) <= max) : keywords;
// }, []);

const filterBySearchVolumeRange = useCallback((keywords, searchVolumeMin, searchVolumeMax) => {
  const minVolume = searchVolumeMin ? parseInt(searchVolumeMin, 10) : -Infinity; // Default to -Infinity if not provided
  const maxVolume = searchVolumeMax ? parseInt(searchVolumeMax, 10) : Infinity; // Default to Infinity if not provided

  return keywords.filter(({ keyword_idea_metrics }) => {
    const avgMonthlySearches = parseInt(keyword_idea_metrics.avg_monthly_searches, 10);
    return avgMonthlySearches >= minVolume && avgMonthlySearches <= maxVolume;
  });
}, []);



// Combined filter for CPC with support for minimum, maximum, and both values
const filterByCPCRange = useCallback((keywords, cpcMin, cpcMax) => {
  const minCPC = cpcMin ? parseFloat(cpcMin) : -Infinity; // Default to -Infinity if not provided
  const maxCPC = cpcMax ? parseFloat(cpcMax) : Infinity; // Default to Infinity if not provided
  
  return keywords.filter(({ keyword_idea_metrics }) => {
    const highTopOfPageBidMicros = keyword_idea_metrics.low_top_of_page_bid_micros ?? '0';
    let cpcValue = parseFloat((parseInt(highTopOfPageBidMicros, 10) / 1000000) / 290).toFixed(2);
    cpcValue = parseFloat(cpcValue); // Convert to number for comparison

    return cpcValue >= minCPC && cpcValue <= maxCPC;
  });
}, []);


// Filter by words length with support for minimum, maximum, and exact length scenarios
const filterByWordsLength = useCallback((keywords, minWordsLength, maxWordsLength) => {
  const min = minWordsLength ? parseInt(minWordsLength, 10) : -Infinity; // Default to -Infinity if not provided
  const max = maxWordsLength ? parseInt(maxWordsLength, 10) : Infinity; // Default to Infinity if not provided
  
  return keywords.filter(({ text }) => {
    const wordsCount = text.split(' ').length;
    return wordsCount >= min && wordsCount <= max;
  });
}, []);

// Combined filter for allintitleTotalResults with support for minimum, maximum, and both values
const filterByAllInTitleRange = useCallback((keywords, allInTitleMin, allInTitleMax) => {
  const minAllInTitle = allInTitleMin ? parseInt(allInTitleMin, 10) : -Infinity; // Default to -Infinity if not provided
  const maxAllInTitle = allInTitleMax ? parseInt(allInTitleMax, 10) : Infinity; // Default to Infinity if not provided

  return keywords.filter(({ keyword_idea_metrics }) => {
    // Treat null or undefined allintitleTotalResults as 0
    const allInTitleResults = keyword_idea_metrics.allintitleTotalResults ? parseInt(keyword_idea_metrics.allintitleTotalResults, 10) : 0;
    return allInTitleResults >= minAllInTitle && allInTitleResults <= maxAllInTitle;
  });
}, []);

const filterByAllInUrlRange = useCallback((keywords, allInUrlsMin, allInUrlsMax) => {
  const minAllInUrls = allInUrlsMin ? parseInt(allInUrlsMin, 10) : -Infinity; // Default to -Infinity if not provided
  const maxAllInUrls = allInUrlsMax ? parseInt(allInUrlsMax, 10) : Infinity; // Default to Infinity if not provided

  return keywords.filter(({ keyword_idea_metrics }) => {
    // Treat null or undefined allinurlTotalResults as 0
    const allInUrlResults = keyword_idea_metrics.allinurlTotalResults ? parseInt(keyword_idea_metrics.allinurlTotalResults, 10) : 0;
    return allInUrlResults >= minAllInUrls && allInUrlResults <= maxAllInUrls;
  });
}, []);

const filterByDaRange = useCallback((keywords, daMin, daMax) => {
  const minDa = daMin ? parseInt(daMin, 10) : -Infinity; // Default to -Infinity if not provided
  const maxDa = daMax ? parseInt(daMax, 10) : Infinity; // Default to Infinity if not provided

  return keywords.filter((keyword) => {
    // Assuming 'items' exists within 'keyword' and contains the DA values
    const domainAuthorities = keyword.items.map((item) => 
      item.domain_authority === "" ? 0 : Number(item.domain_authority)
    );
    
    // Calculate the minimum DA for the current keyword
    const minDomainAuthority = Math.min(...domainAuthorities);

    // Check if the minimum DA for this keyword falls within the specified range
    return minDomainAuthority >= minDa && minDomainAuthority <= maxDa;
  });
}, []);

const filterByKGRRange = useCallback((keywords, kgrRatioMin, kgrRatioMax) => {
  const minKGR = kgrRatioMin ? parseFloat(kgrRatioMin) : -Infinity; // Ensure min threshold is a float
  const maxKGR = kgrRatioMax ? parseFloat(kgrRatioMax) : Infinity; // Ensure max threshold is a float

  return keywords.filter((row) => {
    const totalResults = row.keyword_idea_metrics?.allintitleTotalResults || 0;
    let monthlySearches = row.keyword_idea_metrics?.avg_monthly_searches;
    monthlySearches = !monthlySearches || monthlySearches == 0 ? 10 : monthlySearches; // Adjust for falsey to 10

    const kgr = totalResults / monthlySearches; // KGR calculation

    // Compare with min and max KGR thresholds
    return isFinite(kgr) && kgr >= minKGR && kgr <= maxKGR;
  });
}, []);

const filterBySelectedCategories = useCallback((keywords, selectedCategories) => {
  if (!selectedCategories.length) return keywords; // Return all keywords if no category is selected
  
  const categoryMap = {
    "Questions": ["who", "why", "what", "where", "when"],
    "Investigative Keywords": ["best", "Vs", "review", "alternative", "comparison", "case study", "pros and cons", "FAQ"],
    "Commercial Keywords": ["cost", "price", "pricing", "buy", "discount", "free shipping", "promo", "sale", "trial", "shop", "purchase", "coupon", "deal"],
    "Informational Keywords": ["Tips", "strategies", "how to", "guide", "tutorial", "analysis", "tips"]
  };

  // Compile all keywords from selected categories into a single array
  const selectedKeywords = selectedCategories.reduce((acc, category) => {
    if (categoryMap[category]) {
      acc.push(...categoryMap[category]);
    }
    return acc;
  }, []);

  // Filter keywords based on the presence of selected category keywords in the keyword text
  return keywords.filter(({ text }) => {
    // Check if the keyword text includes any keyword from the selected categories
    return selectedKeywords.some(keyword => text.toLowerCase().includes(keyword.toLowerCase()));
  });
}, []);



// Combine filters
const filterKeywords = useCallback((keywords) => {
  // const min = parseInt(values.searchVolumeMin, 10) || -Infinity;
  // const max = parseInt(values.searchVolumeMax, 10) || Infinity;
  
  // let result = filterByMinVolume(keywords, min);
  let result = filterBySearchVolumeRange(keywords, values.searchVolumeMin, values.searchVolumeMax);

  // result = filterByMaxVolume(result, max);
  result = filterByCPCRange(result, values.cpcMin, values.cpcMax);
  result = filterByWordsLength(result, values.wordsLengthMin, values.wordsLengthMax);
  result = filterByAllInTitleRange(result, values.allInTitleMin, values.allInTitleMax);
  result = filterByAllInUrlRange(result, values.allInUrlsMin, values.allInUrlsMax);
  result = filterByDaRange(result, values.daMin, values.daMax);
  result = filterByKGRRange(result, values.kgrRatioMin, values.kgrRatioMax);
  result = filterBySelectedCategories(result, values.selectedCategories);
  return result;
}, [values,filterBySearchVolumeRange,filterByKGRRange,filterByDaRange,filterByWordsLength ,filterByAllInUrlRange,filterByAllInTitleRange, filterByCPCRange,]);




useEffect(() => {
  const filteredData = filterKeywords(mainProp.keywordData);
  setFilteredKeywords(filteredData);
}, [values, mainProp.keywordData, filterKeywords]);
// console.log(filteredKeywords, "filteredKeywords after")

const [isColorClassApplied, setIsColorClassApplied] = useState(false);

const handleKGRClick = () => {
  setIsColorClassApplied(prevState => !prevState); // Toggle the background color state
  setValues({
   
    searchVolumeMax: isColorClassApplied ? "" : "250",
    searchVolumeMin: "",
    wordsLengthMin: "",
    wordsLengthMax: "",
    kgrRatioMin: "",
    kgrRatioMax: isColorClassApplied ? "" : "0.25",
    cpcMin: "",
    cpcMax: "",
    daMin: "",
    daMax: "",
    allInTitleMin: "",
    allInTitleMax: "",
    allInUrlsMin: "",
    allInUrlsMax: "",
    selectedCategories: [],
    goldenRuleName: ""
  });
};


const [selectedPresetId, setSelectedPresetId] = useState(null);
const handlePresetClick = (preset) => {
  
  if (preset._id === selectedPresetId) {
   
    setSelectedPresetId(null);
    setValues({
      searchVolumeMax: "",
      searchVolumeMin: "",
      wordsLengthMin: "",
      wordsLengthMax: "",
      kgrRatioMin: "",
      kgrRatioMax: "",
      cpcMin: "",
      cpcMax: "",
      daMin: "",
      daMax: "",
      allInTitleMin: "",
      allInTitleMax: "",
      allInUrlsMin: "",
      allInUrlsMax: "",
      selectedCategories: [],
      goldenRuleName: ""
    }); // Optionally clear the values or handle as per your requirement
  } else {
    // If not already selected, select the new preset and update values
    setValues(preset);
    setSelectedPresetId(preset._id);
  }
};



  return (
    <div>
      <ToastContainer
        position="top-right"
        autoClose={15000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
        <div>
        <div className=''>
              <span className="mr-1">Search Volume</span>  <InputNumber name="searchVolumeMin"  value={values.searchVolumeMin}     onChange={(value) => handleChangeValues(value, "searchVolumeMin")} placeholder="Min" width={100} style={{ width: '120px' }} /> <span className='mx-1'>~</span>  
              <InputNumber name="searchVolumeMax" value={values.searchVolumeMax} onChange={(value) => handleChangeValues(value, "searchVolumeMax")} placeholder="Max" style={{ width: '120px' }} /> 
             
  <span className="ml-2 mr-1">CPC</span>  <InputNumber name="cpcMin" value={values.cpcMin} min={0} placeholder="Min" width={100} style={{width: '120px'}} onChange={(e) => handleChangeValues(e, "cpcMin")} /> <span className='mx-1'>~</span>  <InputNumber name="cpcMax" value={values.cpcMax} style={{width: '120px'}} min={0} placeholder="Max" onChange={(e) => handleChangeValues(e, "cpcMax")}  />
  
  <span className="ml-2 mr-1">DA</span>  <InputNumber name="daMin" value={values.daMin} min={0} placeholder="Min" width={100} style={{width: '120px'}} onChange={(e) => handleChangeValues(e, "daMin")} /> <span className='mx-1'>~</span>  <InputNumber name="daMax" value={values.daMax} style={{width: '120px'}} min={0} placeholder="Max" onChange={(e) => handleChangeValues(e, "daMax")}  />
              </div>
        <div className='mt-3 '>
              <span className=" mr-1">Words Length</span>  <InputNumber name="wordsLengthMin" value={values.wordsLengthMin} onChange={(e) => handleChangeValues(e, "wordsLengthMin")} min={0}  placeholder="Min" width={100} style={{width: '120px'}} /> <span className='mx-1'>~</span>  <InputNumber name="wordsLengthMax" value={values.wordsLengthMax} onChange={(e) => handleChangeValues(e, "wordsLengthMax")} style={{width: '120px'}}  min={0} placeholder="Max"  /> 

              <span className="ml-2 mr-1">All In Title</span>  <InputNumber name="allInTitleMin" value={values.allInTitleMin} onChange={(e) => handleChangeValues(e, "allInTitleMin")} min={0}  placeholder="Min" width={100} style={{width: '120px'}} /> <span className='mx-1'>~</span>  <InputNumber name="allInTitleMax" value={values.allInTitleMax} onChange={(e) => handleChangeValues(e, "allInTitleMax")} style={{width: '120px'}}  min={0}  placeholder="Max"  /> 
              <span className="ml-2 mr-1">ALL in Urls</span>  <InputNumber name="allInUrlsMin" value={values.allInUrlsMin} onChange={(e) => handleChangeValues(e, "allInUrlsMin")} min={0}  placeholder="Min" width={100} style={{width: '120px'}} /> <span className='mx-1'>~</span>  <InputNumber name="allInUrlsMax" value={values.allInUrlsMax} onChange={(e) => handleChangeValues(e, "allInUrlsMax")} style={{width: '120px'}}  min={0}  placeholder="Max"  /> 
        </div>
        <div className='mt-3 '>
              <span className="mr-1">KGR Ratio</span>  <InputNumber name="kgrRatioMin" value={values.kgrRatioMin} onChange={(e) => handleChangeValues(e, "kgrRatioMin")}  min={0}  placeholder="Min" width={100} style={{width: '120px'}} /> <span className='mx-1'>~</span>  <InputNumber style={{width: '120px'}} name="kgrRatioMax" value={values.kgrRatioMax} onChange={(e) => handleChangeValues(e, "kgrRatioMax")}  min={0}  placeholder="Max"  /> 
        </div>
        <div className='mt-3 '>
        <Checkbox value="Questions" onChange={() => handleCheckboxChange("Questions")}>Questions</Checkbox> <Checkbox value="Investigative Keywords" onChange={() => handleCheckboxChange("Investigative Keywords")}>Investigative Keywords</Checkbox>  
        <Checkbox value="Commercial Keywords" onChange={() => handleCheckboxChange("Commercial Keywords")}>Commercial Keywords</Checkbox> <Checkbox value="Informational Keywords" onChange={() => handleCheckboxChange("Informational Keywords")}>Informational Keywords</Checkbox>
        </div>
        </div>

        <div className='mt-2 presets'>
        <h6 style={{color: '#86898c'}}><b>Presets</b> <QuestionCircleOutlined style={{color: '#86898c', marginLeft: '4px'}} /></h6>
        <div className="presetsContent mt-2">
        <span className="presetsBadge" style={{marginLeft: '5px' }}>Traffic Butler Ratio</span>
        {/* <span className="presetsBadgeCustom" style={{ }} onClick={handleKGRClick}>KGR Ratio</span> */}
        <span className={`presetsBadgeCustom ${isColorClassApplied ? 'colorClass' : ''}`} onClick={handleKGRClick}>KGR Ratio</span>

        {presets?.presets.map((preset) => (
  <span key={preset._id} className={`presetsBadgeCustom ${selectedPresetId === preset._id ? 'colorClass' : ''}`}  style={{ }} onClick={() => handlePresetClick(preset)}>{preset.goldenRuleName}</span>
))}
 <input type="text" class="presetsBadgeAdd" placeholder="Add New Preset" style={{  }} onChange={(e) => handleChangeValues(e, "goldenRuleName")} />

        <span className="presetsBadgeSave" style={{}} onClick={presetHandleSubmit}>Save</span>
        </div>
        </div>

        <div className="toggle-container">
<button className="toggle-button" onClick={handleToggleRows}>
                {areRowsExpanded ? <FaCompressArrowsAlt /> : <FaExpandArrowsAlt />}
                <span className="pl-2">{areRowsExpanded ? ' Collapse All' : ' Expand All'}</span>
            </button>

        </div>

        <DataTable
        columns={columns}
        data={filteredKeywords}
        pagination
        selectableRows
        paginationPerPage={10}
        paginationRowsPerPageOptions={[10, 25, 50, 100, 250]} // Rows per page options
        selectableRowsVisibleOnly
        clearSelectedRows={toggledClearRows}
        // paginationDefaultPage={50}
        onSelectedRowsChange={handleChange}
        sortFunction={customSort}
        className="ra-table mt-2 "
        expandableRows
        expandableRowsComponent={ExpandedComponent}
        expandableRowExpanded={row => isRowExpanded(row.id)}
        // fixedHeader
        // fixedHeaderScrollHeight="1000px"
        customStyles={customStyles}
      />

      <div className="mergemain analyse-now">
        {selectedrowmain.length > 0 && (
          <div>
            {`${selectedrowmain.length} keyword${
              selectedrowmain.length > 1 ? "s" : ""
            } selected | ${selectedrowmain.length} credit${
              selectedrowmain.length > 1 ? "s" : ""
            } will be consumed`}
            {isLoading && (
              <Spinner
                as="span"
                animation="border"
                size="sm"
                role="status"
                aria-hidden="true"
                className="white-spinner"
              />
            )}

              

            <button className="btn btn-white ms-5" onClick={handleButtonClick}>
            {isLoading ? 'Analyzing...' : 'Analyse Now'}
            </button>
          </div>
        )}
      </div>

    </div>
  )
}

export default Presets