import React, { useEffect, useState } from 'react';
import Offcanvas from 'react-bootstrap/Offcanvas';
import axios from '../../Axios';
import { message } from 'antd';
import { Grid } from 'react-loader-spinner';
import { UpOutlined, CommentOutlined, ClockCircleOutlined  } from '@ant-design/icons';
import Markdown from 'react-markdown'


function BottomCanva({ show, onHide, selectedTextId }) {
  const [threadDetails, setThreadDetails] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
console.log(threadDetails, "threadDetails");
  useEffect(() => {
    const fetchThreadDetails = async () => {
      if (!selectedTextId) return;

      setIsLoading(true);
      try {
        const timestamp = new Date().getTime();
        const response = await axios.get(`/social/thread/${selectedTextId}?t=${timestamp}`);
        setThreadDetails(response.data);
      } catch (error) {
        console.error('Failed to fetch Reddit thread details:', error);
        message.error('Failed to fetch thread details. Please try again later.');
      } finally {
        setIsLoading(false);
      }
    };

    fetchThreadDetails();
  }, [selectedTextId]);

  const formatDate = (unixTimestamp) => {
    const date = new Date(unixTimestamp * 1000); // Convert to milliseconds
    return date.toLocaleDateString("en-US", {
      year: 'numeric', month: 'long', day: 'numeric',
      hour: '2-digit', minute: '2-digit', second: '2-digit'
    });
  };

  const renderComments = (comments, level = 0) => {
    if (!comments) return null; // In case replies are not present or undefined

    return comments.map((comment, index) => (
      <div key={index} style={{...styles.comment, marginLeft: level * 20, borderLeft: level > 0 ? '2px solid #ddd' : 'none'}}>
        <p>{comment.body}</p>
        <div style={styles.commentFooter}>
          <UpOutlined style={styles.icon} /> {comment.ups} points by {comment.author} <ClockCircleOutlined style={styles.icon} className="ml-1" /> {formatDate(comment.created_utc)}
        </div>
        {/* Render replies if any */}
        {comment.replies && comment.replies.length > 0 && (
          <div style={{marginTop: '10px'}}>
            {renderComments(comment.replies, level + 1)} {/* Recursive call */}
          </div>
        )}
      </div>
    ));
  };

  return (
    <Offcanvas show={show} onHide={onHide} placement="bottom" style={styles.offcanvas}  className="offcanvas-custom-size">
      <Offcanvas.Header closeButton>
        <Offcanvas.Title>Thread Deep Dive</Offcanvas.Title>
      </Offcanvas.Header>
      <Offcanvas.Body>
        {isLoading ? (
          <div className="text-center w-100 d-flex justify-center">
            <Grid height="80" width="80" color="#1890ff" ariaLabel="grid-loading" radius="12.5" visible={true} />
          </div>
        ) : threadDetails ? (
          <div>
            <div style={styles.post}>
            <h4>{threadDetails.title}</h4>
              <Markdown>{threadDetails.selftext}</Markdown>
              <div style={styles.postFooter}>
                <UpOutlined style={styles.icon} /> {threadDetails.ups} Upvotes
                <CommentOutlined className="ml-1" style={styles.icon} /> {threadDetails.num_comments} Comments <ClockCircleOutlined style={styles.icon} className="ml-1" /> {formatDate(threadDetails.created_utc)}
              </div>
            </div>
            <div>
              <h5 style={styles.commentsHeader}>Comments:</h5>
              {renderComments(threadDetails.comments)}
            </div>
          </div>
        ) : null}
      </Offcanvas.Body>
    </Offcanvas>
  );
}

const styles = {
  // offcanvas: {
  //   maxWidth: '100%',
  //   width: '600px',
  // },
  post: {
    background: '#f0f2f5',
    padding: '20px',
    borderRadius: '8px',
    marginBottom: '20px',
  },
  postFooter: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '14px',
    color: '#888',
    marginTop: '10px',
  },
  comment: {
    background: '#fff',
    padding: '10px',
    borderRadius: '8px',
    marginBottom: '10px',
    border: '1px solid #eee',
  },
  commentFooter: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    color: '#888',
    marginTop: '10px',
  },
  commentsHeader: {
    margin: '20px 0 10px',
  },
  icon: {
    marginRight: '5px',
  },
};

export default BottomCanva;
