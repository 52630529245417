// import React from 'react'



// function Accordionmain() {
//   return (
//    <div className='mt-2'>
        
//         <div className="collapse collapse-plus bg-base-200">
//   <input type="radio" name="my-accordion-3" checked="checked" /> 
//   <div className="collapse-title text-xl font-medium">
//   Generate Keyword Ideas
//   </div>
//   <div className="collapse-content"> 
//     <p>Enter your <code>Seed Keyword</code>, select your Country, Language and click Find Keywords.</p>
//   </div>
// </div>
// <div className="collapse collapse-plus bg-base-200">
//   <input type="radio" name="my-accordion-3" /> 
//   <div className="collapse-title text-xl font-medium">
//   Select Keywords
//   </div>
//   <div className="collapse-content"> 
//     <p>Use the checkbox on the left of the <code>keyword</code> you can select as many as possible.</p>
//   </div>
// </div>
// <div className="collapse collapse-plus bg-base-200">
//   <input type="radio" name="my-accordion-3" /> 
//   <div className="collapse-title text-xl font-medium">
//   Analyse Keywords
//   </div>
//   <div className="collapse-content"> 
//     <p> Click on <code>Analyse Now</code> button, now our system will start analysing those keywords to find weak spots in SERP.</p>
//   </div>
// </div>

// <div className="collapse collapse-plus bg-base-200">
//   <input type="radio" name="my-accordion-3" /> 
//   <div className="collapse-title text-xl font-medium">
//   Find Weak Spots
//   </div>
//   <div className="collapse-content"> 
//     <p> Once the analysis is complete, you will find the <code>Weak Spots</code> in the SERP. We consider Low DA and website that is not designed for content ranking like Reddit, Quora, Files, Forums, etc as weak spots.</p>
//   </div>
// </div>
//         </div>
//       );
//   }

// export default Accordionmain


import React, { useState } from 'react';

function Accordionmain() {
  // State to track the currently selected accordion item
  const [selectedItem, setSelectedItem] = useState(0);

  // Function to handle selection change
  const handleSelectionChange = (index) => {
    setSelectedItem(index);
  };

  // Accordion item contents
  const accordionContents = [
    {
      title: "Unlock Advanced Keyword Potential",
      content: "Enter a keyword, URL, or site, pick your region and language, then click 'Discover' for high-impact keyword insights."
    },
    {
      title: "Deep-Dive Into Keyword Analysis",
      content: "Press 'Analyse Now' and our system will quickly identify key areas in SERP for enhancing your keyword strategy."    },
    {
      title: "Spot and Leverage SERP Opportunities",
      content: "After the analysis, our tool identifies SERP vulnerabilities, focusing on sites with low KGR, Domain Authority (DA), Page Rank, and highlighting Off-Page and On-Page SEO Difficulty. It also spots platforms not optimized for content ranking, such as Reddit, Quora, and various file-sharing or forum sites. This helps you strategically target areas with the greatest potential for impact."
    }
  ];

  // bg-base-200

  return (
    <div className='mt-2'>
      {accordionContents.map((item, index) => (
        <div key={index} className="collapse collapse-plus shadow bg-white ">
          <input 
            type="radio" 
            name="my-accordion-3" 
            checked={selectedItem === index} 
            onChange={() => handleSelectionChange(index)} 
          />
          <div className="collapse-title text-xl font-medium">
            {item.title}
          </div>
          <div className="collapse-content"> 
            <p>{item.content}</p>
          </div>
        </div>
      ))}
    </div>
  );
}

export default Accordionmain;
