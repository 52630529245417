import React, { useContext } from "react";
import Header from "../components/KeywordTablecomponents/Header";
import TableDatadisplay from "../components/KeywordTablecomponents/TableDatadisplay";
// import TableDatadisplaywork from "../components/KeywordTablecomponents/TableDatadisplaywork";
import { KeywordContext } from "../auth/KeywordContext";
import BarSide2 from "../components/BarSide2";

function KeywordTableDisplay() {
  const { keywordData, languageAbbreviation, countryCode } = useContext(KeywordContext);

  return (
    <>
      <main className="d-flex flex-nowrap">
        {/* <BarSide /> */}
        <BarSide2 />
        <div className="d-flex flex-column flex-grow-1 p-4 xaxis">
          <div style={{ overflowY: "auto", height: "calc(100vh - 24px)" }}>
            <div className="container mt-3">
              <div className="row">
                <div className="col-md-12">
                <Header keywordData={keywordData} languageAbbreviation={languageAbbreviation} countryCode={countryCode} />
                  
                <TableDatadisplay keywordData={keywordData} languageAbbreviation={languageAbbreviation} countryCode={countryCode} />
                {/* <TableDatadisplaywork keywordData={keywordData} languageAbbreviation={languageAbbreviation} countryCode={countryCode} /> */}

                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default KeywordTableDisplay;
