import React from 'react';
import { useLocation } from 'react-router-dom';
import RankTrackerChart from './RankTrackerChart';
import BarSide2 from '../BarSide2';
import { Container,} from "react-bootstrap";
import { Tabs,Row, Col  } from 'antd';
import { HomeOutlined } from '@ant-design/icons';
import DataTable from "react-data-table-component";
import { SVGFlag } from "use-flags";

const { TabPane } = Tabs;


function RankingDetails() {
  const location = useLocation();
  const { keywordData } = location.state;
console.log(keywordData , "keywordData from RankingDetails")
const indexedData = keywordData.searchResults.flatMap((result, index) => 
result.results.map((item, itemIndex) => ({
  ...item,
  index: index * keywordData.pruneLimit + itemIndex + 1
}))
);


const displayDate = new Date(keywordData.searchResults[0].date).toLocaleDateString();

const columns = [
  {
    name: 'Position',
    selector: row => row.index,
    sortable: true,
    width: '100px'
  },
  {
    name: 'Serp',
    selector: row => row.title,
    cell: row => (
      <div>
        <div>{row.title}</div>
        <a href={row.link} target="_blank" rel="noopener noreferrer">{row.link}</a>
      </div>
    ),
    ignoreRowClick: true,
    allowOverflow: true,
    grow: 2,
  }
  // Add other columns if needed
];


const formatTimeDifference = (dateString) => {
  const now = new Date();
  const pastDate = new Date(dateString);
  let diffInSeconds = Math.floor((now - pastDate) / 1000);

  const days = Math.floor(diffInSeconds / 86400);
  diffInSeconds -= days * 86400;
  const hours = Math.floor(diffInSeconds / 3600) % 24;
  diffInSeconds -= hours * 3600;
  const minutes = Math.floor(diffInSeconds / 60) % 60;
  diffInSeconds -= minutes * 60;
  const seconds = diffInSeconds % 60;

  if (days > 0) {
    return `${days} Days ${hours} Hours ago`;
  } else if (hours > 0) {
    return `${hours} Hours ${minutes} Minutes ago`;
  } else if (minutes > 0) {
    return `${minutes} Minutes ${seconds} Seconds ago`;
  } else {
    return `${seconds} Seconds ago`;
  }
};



// style={{ height: "calc(100vh - 24px)" }}
  return (
    <>
    <main className="d-flex flex-nowrap" style={{ overflowX: "hidden",overflowY: "auto"}}>
    <BarSide2 />
  <Container>
      <div className="p-4">
      <div >
          <div className="container mt-3">

          <div className="border border-gray-200 bg-white rounded-lg p-4 mb-3 xaxis">
      <Row gutter={16}>
        <Col span={11}>
          <h3 className="ra-name">{keywordData?.TrackingKeyword}</h3>
        </Col>
        <Col span={3}>
          <div className="ra-lang ra-flag mt-2">
          <h5 className="mb-1 kwlength">{keywordData.keywordData.languageCode.toUpperCase()}</h5> 
            <SVGFlag country={keywordData.keywordData.countryCode} flagWidth="33" />
          </div>
        </Col>
        {/* <Col span={3} className="">
        
          <h5 className="mb-0 kwlength">{keywordData.keywordData.length}</h5>
          <p className="mt-0 mb-0 ">Keywords</p> 
        </Col>*/}
        <Col span={10} >
        {/* <h5 className="mt-0 mb-0"> {userData.credits}</h5> */}
        <h5 className="mt-2 mb-0">
        Last Updated: {formatTimeDifference(keywordData.lastUpdateDate)}
        </h5>
        </Col>
      </Row>
    </div>

            <div className="row">
              <div className="col-md-12">
                <div className="ra-home border border-gray-300 bg-white rounded-lg ">
                <Tabs defaultActiveKey="1">
                        {/* Tab for the main website */}
                        <TabPane
                          tab={<span><HomeOutlined style={{ marginRight: '4px' }} />{keywordData.websiteName}</span>}
                          key="1"
                        >
                          <RankTrackerChart keywordData={keywordData} />
                        </TabPane>

                        {/* Tabs for each competitorWebsite */}
                        {keywordData.competitorRankings.map((competitor, index) => (
                          
                             <TabPane
                          tab={<span><HomeOutlined style={{ marginRight: '4px' }} />{competitor.competitorWebsite}</span>}
                          key={index + 2}
                        >
                            {/* You can include content for each competitor here */}
                            <RankTrackerChart keywordData={{ ...keywordData, historicalRankings: competitor.rankings }} />

                          </TabPane>
                        ))}
                      </Tabs>

                </div>

               

              </div>
            </div>
            <div className="row">
            <div className="col-md-12">
            <Row className='mt-6'>
            <Col span={12} className="left-text">
                <p>Serp Results</p>
            </Col>
            <Col span={12} className="right-text">
                <div>
                    <strong>Date:</strong> {displayDate}
                </div>
            </Col>
        </Row>
            <div className='border border-gray-300 bg-white rounded-lg mt-1'>
              <div style={{width:'100%'}}>
              <DataTable
        columns={columns}
        data={indexedData}
        noHeader
        defaultSortField="index"
        defaultSortAsc={true}
        // pagination
      />
            </div>
                </div>
                </div>
            </div>
          </div>
        </div>
        </div>
        
        </Container>
       
     
           
  </main>
  </>
  );
}

export default RankingDetails;
