import React, { createContext, useState, useContext } from 'react';

// Create the context
const GetValueContext = createContext();

// Provider component
export const GetValueProvider = ({ children }) => {
    const [copyToClipboard, setCopyToClipboard] = useState([]);
    const [getMainKeywordData, setGetMainKeywordData] = useState();
    const [getFilteredData, setGetFilteredData] = useState();
    const [domainCount, setDomainCount] = useState(0); 
    const [creditsCount, setCreditsCount] = useState(0); 
    // The value that will be available to the descendants of this provider
    const value = {creditsCount, setCreditsCount, getMainKeywordData, setGetMainKeywordData, copyToClipboard, setCopyToClipboard, getFilteredData, setGetFilteredData, domainCount, setDomainCount };

    return (
        <GetValueContext.Provider value={value}>
            {children}
        </GetValueContext.Provider>
    );
};

// Custom hook to use the context
export const useGetValueContext = () => useContext(GetValueContext);
