// 1. Start
import React, { createContext, useState, useContext } from 'react';

// Create a context for managing favorites
export const FavoriteContext = createContext();

export const FavoriteProvider = ({ children }) => {
  // const [favorites, setFavorites] = useState([]);
  const [favorites, setFavorites] = useState({ localFavorites: [], serverFavorites: [] });


  // Function to toggle favorite status
  // const toggleFavorite = (documentId) => {
  //   setFavorites((prevFavorites) => {
  //     if (prevFavorites.includes(documentId)) {
  //       return prevFavorites.filter(id => id !== documentId);
  //     } else {
  //       return [...prevFavorites, documentId];
  //     }
  //   });
  // };

  const toggleFavorite = (documentId) => {
    setFavorites(prevState => ({
      ...prevState,
      localFavorites: prevState.localFavorites.includes(documentId) 
        ? prevState.localFavorites.filter(id => id !== documentId) 
        : [...prevState.localFavorites, documentId]
    }));
  };
  

  return (
    <FavoriteContext.Provider value={{ favorites, setFavorites,toggleFavorite }}>
      {children}
    </FavoriteContext.Provider>
  );
};

// Custom hook for easier context usage
export const useFavorites = () => useContext(FavoriteContext);
// 1. Done
