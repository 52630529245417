import React, { createContext, useContext, useState } from 'react';

// Create a context with an empty array as the initial value
const ProjectDataContext = createContext([]);

// Custom hook for consuming context
export const useProjectData = () => useContext(ProjectDataContext);

// Provider component
export const ProjectDataProvider = ({ children }) => {
  const [projectData, setProjectData] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [searchResults, setSearchResults] = useState([]);
  const [selectedRowMain, setSelectedRowMain] = useState([]);


  const value = { selectedRowMain, setSelectedRowMain, searchResults, setSearchResults, projectData, setProjectData, selectedProject, setSelectedProject };

  return (
    <ProjectDataContext.Provider value={value}>
      {children}
    </ProjectDataContext.Provider>
  );
};
