import React, { createContext, useContext,  } from "react";

const AuthContext = createContext(null);

export const useAuth = () => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  // Load the token synchronously
//   const [jsontoken, setJsonToken] = useState(localStorage.getItem("jsonToken"));
        const jsontoken = localStorage.getItem("jsonToken");


  return (
    <AuthContext.Provider value={{ jsontoken }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
