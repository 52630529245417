import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function RedirectToKwResearch() {
  const navigate = useNavigate();

  useEffect(() => {
    navigate('/kwresearch');
  }, [navigate]);

  return null; // No UI needed as this component's sole purpose is to redirect
}

export default RedirectToKwResearch;