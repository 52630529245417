import React, { useEffect, useContext } from "react";
import BarSide2 from "../components/BarSide2";
import { Container,} from "react-bootstrap";
import { BsCaretRight } from "react-icons/bs";
import { Row, Col, Typography} from 'antd';
import MainFormRankTr from "../components/ranktracker/MainFormRankTr";
import RankTrackerTable from "../components/ranktracker/RankTrackerTable";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { KeywordContext } from "../auth/KeywordContext";
import axios from "../components/Axios";

function RankTracker() {
  const { updateranktrackerData } = useContext(KeywordContext);
  const userDataString = localStorage.getItem("userData");
  const userData = userDataString ? JSON.parse(userDataString) : null;

  const showErrorToast = (message) => {
    toast.error(message, {
        position: "top-right",
        autoClose: 15000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
};
   
useEffect(() => {
  const fetchDocuments = async () => {
      try {
        const timestamp = new Date().getTime();
          const response = await axios.get(`/rank/fetch/${userData._id}?t=${timestamp}`);
          updateranktrackerData(response.data);
      } catch (error) {
          // Handling errors and showing them in a toast
          showErrorToast(error.response?.data?.message || 'An unexpected error occurred');
      }
  };

  fetchDocuments();
}, []);

  return (
    <>
     <ToastContainer
position="top-right"
autoClose={3000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
      <main className="d-flex flex-nowrap">
        <BarSide2 />
        {/* <ProSideBar /> */}
        <Container>
        <div className="d-flex flex-column flex-grow-1 p-4">
          <div style={{ overflowY: "auto", height: "calc(100vh - 24px)" }}>
            <div className="container mt-3 ">
              <div className="row">
                <div className="col-md-12">
                  <div className="ra-home border border-gray-300 bg-white rounded-lg ">
                    <div className="d-flex justify-content-between">
                    <h4>Rank Tracker</h4>
                    <Row align="middle" className="tutDiv">
                  <Col>
                    <BsCaretRight style={{ verticalAlign: 'middle' }} className="tutColor" />
                  </Col>
                  <Col>
                    <Typography.Text className="tutColor tutText">VIDEO TUTORIAL</Typography.Text>
                  </Col>
                </Row>
                </div>
                <div className="keyword-discovery-container">
      <Typography.Paragraph className="keyword-description">
      Monitor your keywords for any changes in SERP.
      </Typography.Paragraph>
    </div>

  <MainFormRankTr />
   
               
                  </div>
             

                </div>

                <div className="col-md-12 my-6">
                 
                <RankTrackerTable />
               
                </div>

              </div>
            </div>
            
          </div>
        </div>
        </Container>
      </main>
    </>
  );
}

export default RankTracker;
