import React, {useState} from "react";
// import SignupNavbar from "../components/SignupNavbar";
// import { Form, Row, Col } from 'react-bootstrap'; // Import necessary components from react-bootstrap
import { Link, useNavigate  } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import axios from '../components/Axios';
// import {Button} from "antd"
import { Form, Input, Button, Row, Col } from 'antd';
import { UserOutlined, LockOutlined, IdcardOutlined } from '@ant-design/icons';
import coverImage from "../assets/cover.jpg"


function Forgetpassword() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    newPassword: '',
    license: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    // setFormData({ ...formData, [e.target.id]: e.target.value });
    setFormData({ ...formData, [e.target.name]: e.target.value });

  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    console.log(formData); // Logging the request body

    try {
      const response = await axios.post('/auth/update-password', formData);
      console.log(response.data);
      // Handle success (redirect or show success message)
      navigate('/loginPage');
    } catch (err) {
      setError(err.response?.data?.error || 'An error occurred');
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };




  return (
    <div>
      {/* <SignupNavbar /> style={{ marginTop: "80px" }} */}

      <div className="container" >
        <div id="main-wrapper" className="container">
          <div className="row justify-content-center pt-5">
            <div className="col-xl-10">
              <div className="card border-0">
                <div className="card-body p-0">
                  <div className="row no-gutters">
                    <div className="col-lg-6">
                      <div className="p-5" style={{ minHeight: "418px" }}>
                        <h6 className="h5 mb-3">Reset Password</h6>
                       
                        <Form
      className="signup-form"
      onFinish={handleSubmit}
    >
      {/* License Field */}
      <Form.Item
        name="license"
        rules={[{ required: true, message: 'Please input your License!' }]}
      >
        <Input 
        name="license"
          prefix={<IdcardOutlined className="site-form-item-icon" />}
          placeholder="License" 
          onChange={handleChange} 
        />
      </Form.Item>

      {/* Email Field */}
      <Form.Item
        name="email"
        rules={[{ required: true, message: 'Please input your Email!' }]}
      >
        <Input
        name="email"
          prefix={<UserOutlined className="site-form-item-icon" />}
          type="email"
          placeholder="Email"
          onChange={handleChange}
        />
      </Form.Item>

      {/* Password Field */}
      <Form.Item
        name="newPassword"
        rules={[{ required: true, message: 'Please input your Password!' }]}
      >
        <Input
        name="newPassword"
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="newPassword"
          placeholder="New Password"
          onChange={handleChange}
        />
      </Form.Item>

      {/* Loading and Error Display */}
      {loading && <BeatLoader color="#1890ff" className="mb-1" />}
      {error && <div className="alert alert-danger">{error}</div>}

      {/* Sign Up Button */}
      <Form.Item>
        <Button type="primary" htmlType="submit" className="login-form-button pb-1" onClick={handleSubmit}>
          Reset Password
        </Button>
        {/* Or <Link to="/register" className=" ">register now!</Link> */}
        Already have an account?{" "}
                <Link to="/loginPage" className="register-form ml-1">
                  Log in instead
                </Link>
      </Form.Item>
    </Form>



                      </div>
                    </div>

                    <div className="col-lg-6 d-none d-lg-inline-block">
                      
                       <img src={coverImage} />
                    </div>
                  </div>
                </div>
              </div>
              {/* <p className="text-muted text-center mt-3 mb-0">
                Already have an account?{" "}
                <Link to="/loginPage" className="text-primarymain ml-1">
                  Log in instead
                </Link>
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Forgetpassword;

