import React, {useContext, useState} from 'react'
import { KeywordContext } from "../../auth/KeywordContext"
import DataTable from "react-data-table-component";
import { useNavigate } from 'react-router-dom';
import axios from "../Axios"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const transformData = (data) => {
  let transformed = [];
  let keywordCounter = 1; // Initialize a counter for keywords

  data.forEach((site) => {
      site.keywords.forEach(keyword => {
          const latestRanking = keyword.historicalRankings.slice(-1)[0]?.position ?? -1;

          transformed.push({
              index: keywordCounter++, // Use the counter for the Site PS Index
              docId: site._id,
              TrackingKeyword: keyword.TrackingKeyword,
              websiteName: site.websiteName,
              latestRanking: latestRanking === -1 ? "Not Ranked" : latestRanking,
              historicalRankings: keyword.historicalRankings // Include historical rankings
          });
      });
  });
  return transformed;
};

const AreaChart = ({ rankings }) => {
  // Ensure there are 7 data points
  const fullRankings = [...Array(7)].map((_, i) => rankings[i] || { position: -1 });

  return (
    <div className="chart-container">
      {fullRankings.map((ranking, index) => (
        <div 
          key={index} 
          className="chart-column" 
          style={{ height: ranking.position === -1 ? '1%' : `${100 - ranking.position}%` }}
          data-rank={`Rank: ${ranking.position}`}
        />
      ))}
    </div>
  );
};


  
  
  
function RankTrackerTable() {
    const { rankTrackerResp, updateranktrackerData } = useContext(KeywordContext);
    const [isLoading, setLoading] = useState(false);
    const userDataString = localStorage.getItem("userData");
    const userData = userDataString ? JSON.parse(userDataString) : null;
    const navigate = useNavigate();
    if (!rankTrackerResp || rankTrackerResp.length === 0) {
        return null;
      }
     
      // const findKeywordData = (keyword) => {
      //   // Find the site that contains the keyword
      //   for (let site of rankTrackerResp) {
      //     const foundKeyword = site.keywords.find(k => k.TrackingKeyword === keyword);
      //     if (foundKeyword) {
      //       return { ...foundKeyword, websiteName: site.websiteName };
      //     }
      //   }
      //   return null;
      // };
    
      const findKeywordData = (keyword) => {
        // Find the site that contains the keyword
        for (let site of rankTrackerResp) {
          const foundKeyword = site.keywords.find(k => k.TrackingKeyword === keyword);
          if (foundKeyword) {
            // Include the pruneLimit in the returned object
            return { ...foundKeyword, websiteName: site.websiteName, pruneLimit: site.pruneLimit, lastUpdateDate: site.lastUpdateDate };
          }
        }
        return null;
      };

      
  const showErrorToast = (message) => {
    toast.error(message, {
        position: "top-right",
        autoClose: 15000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
};

      const handleDeleteKeyword = async (docId,keyword) => {
        console.log(keyword, docId, "handleDeleteKeyword")
        setLoading(true);
        const loadingToastId = toast.loading("Removing keyword...", { theme: "light" });

        try {
          const response = await axios.delete(`/rank/remove-tracking-keyword/${userData._id}/${docId}/${keyword}`);
         console.log(response.data)
          // Refresh the data or navigate as needed
          updateranktrackerData(response.data);
          toast.update(loadingToastId, { render: "Keyword removed successfully", type: "success", isLoading: false, autoClose: 3000 });


        } catch (error) {
          console.log(error)
          if (!error.response) {
            // Network error or request was not completed
            showErrorToast("Network error, please check your internet connection.");
          } else {
            // Server responded with a status other than 2xx
            const message = error.response.data.message || "Error occurred while removing the keyword.";
            showErrorToast(message);
          }
          toast.dismiss(loadingToastId);
        } finally {
          setLoading(false); // This will execute whether the try block succeeds or an error is caught
        }
      };
      
      const columns = [
        {
          name: 'PS',
          selector: row => row.index,
          sortable: true,
          width: "70px"
        },
        {
          name: 'Keyword',
          selector: row => row.TrackingKeyword,
          // cell: row => <span onClick={() => navigate(`/ranking-details/${row.TrackingKeyword}`, { state: { keywordData: row } })} style={{ cursor: 'pointer', textDecoration: 'underline' }}>{row.TrackingKeyword}</span>,
          // cell: row => (
          //   <span
          //     onClick={() => navigate(`/ranking-details/${row.TrackingKeyword}`, { state: { keywordData: row } })}
          //     style={{
          //       cursor: 'pointer',
          //       textDecoration: 'none',
          //       // color: '#007bff', // Bootstrap primary color for a modern look
          //       // fontWeight: '400',
          //       // backgroundColor: '#f8f9fa', // Light background for emphasis
          //       // borderRadius: '4px',
          //       padding: '3px 6px',
          //       // boxShadow: '0 2px 4px rgba(0, 123, 255, 0.25)', // Soft shadow for depth
          //       // transition: 'background-color 0.2s ease', // Smooth transition for hover effect
          //     }}
          //     // onMouseOver={(e) => e.currentTarget.style.backgroundColor = '#e2e6ea'} // Darker background on hover
          //     // onMouseOut={(e) => e.currentTarget.style.backgroundColor = '#f8f9fa'} // Revert background on mouse out
          //   >
          //     {row.TrackingKeyword}
          //   </span>
          // ), 
          cell: row => (
            <span
              onClick={() => {
                const keywordData = findKeywordData(row.TrackingKeyword);
                if (keywordData) {
                  navigate(`/ranking-details/${row.TrackingKeyword}`, { state: { keywordData } });
                }
              }}
              style={{
                cursor: 'pointer',
                textDecoration: 'none',
                padding: '3px 6px',
                // ... [Other styles]
              }}
            >
              {row.TrackingKeyword}
            </span>
          ),         
          sortable: true,
          
        },
        {
          name: 'Website',
          selector: row => row.websiteName,
          sortable: true,
        },
        {
          name: 'Ranking Position',
          selector: row => row.latestRanking,
          sortable: true,
        },
        {
          name: '7 Day Trend',
          cell: row => <AreaChart rankings={row.historicalRankings} />,
          ignoreRowClick: true,
          allowOverflow: true,
          button: true,
        },
        {
          name: '',
          cell: (row) => (
            <svg 
              xmlns="http://www.w3.org/2000/svg" 
              height="24" 
              viewBox="0 -960 960 960" 
              width="24"
              onClick={() => handleDeleteKeyword(row.docId, row.TrackingKeyword)}
              style={{ cursor: 'pointer' }}
            >
              <path d="m376-300 104-104 104 104 56-56-104-104 104-104-56-56-104 104-104-104-56 56 104 104-104 104 56 56Zm-96 180q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520Zm-400 0v520-520Z" fill="red" />
            </svg>
          ),
          allowOverflow: true,
          button: true,
          ignoreRowClick: true,
          width: '56px', // Adjust as needed
        }
      ];

    const transformedData = transformData(rankTrackerResp);
   
  
  

  return (
    <>
     <ToastContainer
position="top-right"
autoClose={3000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
     <div className="ra-home border border-gray-300 bg-white rounded-lg ">
    <DataTable
    columns={columns}
    data={transformedData}
    noHeader
    
  />
  </div>
  </>
  )
}

export default RankTrackerTable