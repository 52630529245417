import React , { useContext, useEffect, useState  } from 'react';
// import {  Col, Row, Table, } from 'react-bootstrap';
// import { KeywordContext } from "../auth/KeywordContext";
import { useGetValueContext,   } from "../../auth/GetValueContext";
import { Col, Row, Table} from "antd"


function Competitorswork({keywordData }) {
    // const { keywordData } = useContext(KeywordContext);
    const {  setDomainCount, getMainKeywordData } = useGetValueContext();
    const [domainData, setDomainData] = useState([]);
   
// console.log(keywordData, "keywordData from work")
console.log(domainData, "keywordData from work")
    // const [show, setShow] = useState(true); // Initially set to true to show the Offcanvas
    // const handleClose = () => setShow(false); // Function to close the Offcanvas
  


    useEffect(() => {
        const processKeywordData = async () => {
          
                // Assuming keywordData is asynchronously fetched, 
                // wait for it to be fully loaded (this is just an example)
                // const data = await getMainKeywordData;

                const domainCounts = new Map();
                keywordData.keywordData.forEach(keyword => {
                    keyword.items.forEach(item => {
                        const url = new URL(item.formattedUrl);
                        const domain = url.hostname;

                        if (!domainCounts.has(domain)) {
                            domainCounts.set(domain, { count: 1, da: item.domain_authority });
                        } else {
                            domainCounts.get(domain).count++;
                        }
                    });
                });

                const domainArray = Array.from(domainCounts, ([domain, { count, da }]) => ({ domain, count, da }));
                setDomainData(domainArray);
                setDomainCount(domainArray.length);
           
        };

        processKeywordData();
    }, [keywordData]);


    const columns = [
        {
          title: 'Domain',
          dataIndex: 'domain',
          key: 'domain',
          width: '70%',
          // other column properties
        },
        {
          title: 'Keywords',
          dataIndex: 'count',
          key: 'count',
          // other column properties
        },
        {
          title: 'DA',
          dataIndex: 'da',
          key: 'da',
          // other column properties
        },
      ];
  return (
    <>
     
     <div id="competitions_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
  <h2>SERP Competition</h2> {/* Heading for the section */}
  
  <Row className="dt-row mt-6">
    <Col sm={24}>
    <Table columns={columns} dataSource={domainData} style={{ width: '100%' }} />

    </Col>
  </Row>
</div>

     
    </>
  );
}

export default Competitorswork;
