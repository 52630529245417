import React, { useState } from 'react';
import { Row, Col, Input, Button, Modal,   } from 'antd'; // Import Input component from antd
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "../../Axios"
// import ProjectFormTable from "./ProjectFormTable"
import { useProjectData } from "../../../auth/ProjectDataContext"

const { TextArea } = Input;


function ProjectForm() {
  // Initialize state for the project name
  const { setProjectData } = useProjectData();
  const [projectName, setProjectName] = useState('');
  const [inputFields, setInputFields] = useState([
    { angleKeyword: '', basicInfo: '', customPrompt: '', angleKeywordList: [], customCtas: [''] },
  ]);
  

  const [loading, setLoading] = useState(false);
  const [mainloading, setMainLoading] = useState(false);
  const [open, setOpen] = useState(false);
    const [keywordModalOpen, setKeywordModalOpen] = useState(false);
    const [currentEditingIndex, setCurrentEditingIndex] = useState(null); // Track the current index for keyword addition

// Cta Start
    const [ctaModalVisible, setCtaModalVisible] = useState(false);
    const [ctaLoading, setCtaLoading] = useState(false);
    const [currentCtaEditingIndex, setCurrentCtaEditingIndex] = useState(null);
   // Function to show the modal for editing CTAs, with 'cta' prefix
   const showCtaModal = (index) => {
    setCurrentCtaEditingIndex(index);
    setCtaModalVisible(true);
  };

  // Function to handle the "OK" action of the CTA modal
  const handleCtaOk = () => {
    setCtaLoading(true);
    setTimeout(() => {
      setCtaLoading(false);
      setCtaModalVisible(false);
    }, 500);
  };

  // Function to handle the cancel/close action of the CTA modal
  const handleCtaCancel = () => {
    setCtaModalVisible(false);
  };

  // Function to handle changes to any of the CTAs within the modal
  const handleCtaChange = (index, event) => {
    const newCtas = [...inputFields[currentCtaEditingIndex].customCtas];
    newCtas[index] = event.target.value;
    const newInputFields = [...inputFields];
    newInputFields[currentCtaEditingIndex].customCtas = newCtas;
    setInputFields(newInputFields);
  };

  // Function to add a new CTA field within the modal
  const addCtaField = () => {
    const newInputFields = [...inputFields];
    newInputFields[currentCtaEditingIndex].customCtas.push('');
    setInputFields(newInputFields);
  };

  // Function to remove a CTA field from the modal
  const removeCtaField = (index) => {
    const newInputFields = [...inputFields];
    newInputFields[currentCtaEditingIndex].customCtas.splice(index, 1);
    setInputFields(newInputFields);
  };


// CTA ENd
  const showModal = () => {
    setOpen(true);
  };
  const showKeywordModal = (index) => {
    setCurrentEditingIndex(index);
    setKeywordModalOpen(true);
  };
  const handleKeywordModalOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setKeywordModalOpen(false);
    }, 500);
  };

  const handleKeywordModalCancel = () => setKeywordModalOpen(false);

  const handleOk = () => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
      setOpen(false);
    }, 500);
  };
  const handleCancel = () => {
    setOpen(false);
  };

  // const handleInputChange = (index, event) => {
  //   const values = [...inputFields];
  //   values[index][event.target.name] = event.target.value;
  //   setInputFields(values);
  // };

  const handleInputChange = (index, event) => {
    const { name, value } = event.target;
    setInputFields(currentFields =>
      currentFields.map((field, i) => 
        i === index ? { ...field, [name]: value } : field
      )
    );
  };
  

  // New Above Added

  const handleAddFields = () => {
    setInputFields([...inputFields, { angleKeyword: '', basicInfo: '', customPrompt: '', angleKeywordList: [], customCtas: [''] }]);
  };

  const handleRemoveFields = index => {
    const values = [...inputFields];
    values.splice(index, 1);
    setInputFields(values);
    // Reset currentEditingIndex if it's no longer valid
    if (index === currentEditingIndex || index < currentEditingIndex || currentEditingIndex >= values.length) {
        setCurrentEditingIndex(null);
        setKeywordModalOpen(false); // Also close the keyword modal if the current editing field is removed
    }
};


  const handleKeywordsChange = (event) => {
    const keywords = event.target.value.split('\n');
    if (currentEditingIndex !== null) {
      const values = [...inputFields];
      values[currentEditingIndex].angleKeywordList = keywords;
      setInputFields(values);
    }
  };

  const userDataString = localStorage.getItem("userData");
  const userData = userDataString ? JSON.parse(userDataString) : null;

  const showErrorToast = (message) => {
    toast.error(message, {
        position: "top-right",
        autoClose: 15000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
};

const handleSubmit = async () => {

  setMainLoading(true)

  const projectData = {
    userId: userData._id, // Corrected to _id, which is the common notation in MongoDB
    projectName,
    inputFields,
  };
console.log(projectData)

  try {
    const response = await axios.post('social/socialops', projectData);

    console.log(response.data); // For debugging, remove in production
    setProjectData(response.data); // For debugging, remove in production
    setMainLoading(false)
  } catch (error) {
    console.error('Error submitting project data:', error.response ? error.response.data : error.message);
    showErrorToast(error.response && error.response.data.message ? error.response.data.message : 'An error occurred while submitting project data');
    setMainLoading(false)
  }

};



  return (
    <>
    <ToastContainer
position="top-right"
autoClose={15000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
    
    <div style={{ marginTop: '14px' }}>
      <Row gutter={6}>
        <Col span={8}>
        
          <Input
            style={{ width: '100%' }} 
            value={projectName}
            onChange={e => setProjectName(e.target.value)}
            placeholder="Project Name"
          />
        </Col>
        <Col span={12}>
        <Button  onClick={showModal} icon={<PlusOutlined />} style={{ width: '100%' }} >
        Add Angles
      </Button>
      <Modal
        open={open}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Return
          </Button>,
          <Button key="submit"  loading={loading} onClick={handleOk}>
            Submit
          </Button>
        ]}
        width={1000}
        closable={false}
      >
            
            <div style={{ marginTop: '14px' }}>
      {inputFields.map((inputField, index) => (
        <Row key={index} gutter={6} style={{ marginBottom: '10px' }}>
          <Col span={4}>
            <Input
              name="angleKeyword"
              value={inputField.angleKeyword}
              onChange={event => handleInputChange(index, event)}
              placeholder="Angle Name"
            />
          </Col>
           <Col span={4}>
                    <Button onClick={() => showKeywordModal(index)} style={{ marginLeft: '10px',  }} icon={<PlusOutlined />}> 
                      Add Keywords
                    </Button>
            <Modal
        title="Add Keywords"
        open={keywordModalOpen}
        onOk={handleKeywordModalOk}
        onCancel={handleKeywordModalCancel}
        footer={[
          <Button key="back" onClick={handleKeywordModalCancel}>
            Return
          </Button>,
          <Button key="submit" loading={loading} onClick={handleKeywordModalOk}>
            Submit
          </Button>,
        ]}
      >
       {currentEditingIndex !== null && (
          <Input.TextArea
            rows={6}
            value={inputFields[currentEditingIndex]?.angleKeywordList.join('\n')}
            onChange={handleKeywordsChange}
            placeholder="Enter keywords, one per line"
          />
        )}
      </Modal>
                  </Col>

                  <Col span={3}>

                  <Button  onClick={() => showCtaModal(index)} icon={<PlusOutlined />}>
          Add CTAs
          </Button>

                  <Modal
        title="Edit CTAs"
        open={ctaModalVisible}
        onOk={handleCtaOk}
        onCancel={handleCtaCancel}
        footer={[
          <Button key="back" onClick={handleCtaCancel}>
            Return
          </Button>,
          <Button key="submit"  loading={ctaLoading} onClick={handleCtaOk}>
            Submit
          </Button>,
        ]}
      >
        {currentCtaEditingIndex !== null && inputFields[currentCtaEditingIndex].customCtas.map((cta, index) => (
          <div key={index}>
            <TextArea
              rows={1}
              value={cta}
              onChange={(e) => handleCtaChange(index, e)}
              placeholder="Enter CTA"
              style={{ width: '85%', marginTop: '7px'}}
            />
            {index > 0 && (
              // <Button onClick={() => removeCtaField(index)} type="danger" style={{ margin: '10px 0' }}>
              //   Remove
              // </Button>
              <MinusCircleOutlined style={{ width: '20px', marginTop: '7px', marginLeft: '3px' }}  onClick={() => removeCtaField(index)} />

            )}
          </div>
        ))}
        <Button onClick={addCtaField} style={{ marginTop: '10px'}}>
          Add More
        </Button>
      </Modal>
</Col>

          <Col span={5}>
            <TextArea
            rows={1}
              name="basicInfo"
              value={inputField.basicInfo}
              onChange={event => handleInputChange(index, event)}
              placeholder="Basic Info"
            />
            
          </Col>
          <Col span={7}>
          <TextArea
            rows={1}
              name="customPrompt"
              value={inputField.customPrompt}
              onChange={event => handleInputChange(index, event)}
              placeholder="Custom Prompt"
            />
          </Col>
          <Col span={1}>
            {index > 0 && (
              <MinusCircleOutlined style={{ width: '100%', marginTop: '7px' }}  onClick={() => handleRemoveFields(index)} />
            )}
          </Col>
        </Row>
      ))}
      <Button  onClick={handleAddFields} icon={<PlusOutlined />}>
        Add More
      </Button>
    </div>
            
      </Modal>
        </Col>
        <Col span={4}>
        <Button
            key="1"
            type="primary"
            style={{ width: '100%' }} 
            className='mainQuoraButton'
            onClick={handleSubmit} 
            loading={mainloading}
          >
            Save
          </Button>
        </Col>
      </Row>

     

    </div>

    </>
  );
}

export default ProjectForm;
