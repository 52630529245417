import React, {useState, useEffect} from 'react'
import SettingsSidebar from '../components/settings/SettingsSidebar';
import BarSide2 from '../components/BarSide2';
// import ProSideBar from '../components/ProSideBar';
import { Container, Row, Col, Form,  Tooltip, OverlayTrigger } from 'react-bootstrap';
import useLocalStorage from 'use-local-storage';
import { Modal, Button, Input, Space, Select  } from 'antd';
import { PlusOutlined, MinusCircleOutlined } from '@ant-design/icons';
import axios from "../components/Axios";
import BeatLoader from "react-spinners/BeatLoader";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const { Option } = Select;

function DomSettings() {
  const [customDomains, setCustomDomains] = useLocalStorage('customDomains', '');
  const [maxDA, setMaxDA] = useLocalStorage('maxDA', '20');
  const [myDomains, setMyDomains] = useLocalStorage('myDomains', '');
  const [scrapingRobotmyApi, setscrapingRobotmyApi] = useLocalStorage('scrapingApi', '');
  
  
  // const [mozAccessId, setMozAccessId] = useLocalStorage('mozAccessId', '');
  // const [mozSecretKey, setMozSecretKey] = useLocalStorage('mozSecretKey', '');
  const [isLoading, setIsLoading] = useState(false);

  const [isModalOpen, setIsModalOpen] = useState(false);
  // const [apps, setApps] = useState([{ appId: '', appSecret: '' }]);
console.log(scrapingRobotmyApi, "scrapingRobotmyApi")
  const [apps, setApps] = useState([{ appId: '', appSecret: '' }]);

  const [isNewModalVisible, setIsNewModalVisible] = useState(false);
  const [apiInput, setApiInput] = useState('');
  const [isNewLoading, setIsNewLoading] = useState(false);

  const [serpLoading, setSerpLoading] = useState(false);
  const [serpOpen, setSerpOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [serpApiKeys, setSerpApiKeys] = useState([]);


  const userDataString = localStorage.getItem("userData");
    const userData = userDataString ? JSON.parse(userDataString) : null;



  const handleCustomDomainsChange = (e) => {
    // Convert textarea input into an array
    const domainsArray = e.target.value.split('\n').map(domain => domain.trim());
    // Convert array back into a string for storage
    setCustomDomains(domainsArray.join('\n'));
  };



  const handleMaxDAChange = (e) => setMaxDA(e.target.value);
  const handleMyDomainsChange = (e) => {
    // Convert textarea input into an array
    const domainsArray = e.target.value.split('\n').map(domain => domain.trim());
    // Convert array back into a string for storage
    setMyDomains(domainsArray.join('\n'));
  };
  // const handleMozAccessIdChange = (e) => setMozAccessId(e.target.value);
  // const handleMozSecretKeyChange = (e) => setMozSecretKey(e.target.value);

  const [showConfirmation, setShowConfirmation] = useState(false);

  const handleSaveClick = () => {
    // Add logic to save the values
    setShowConfirmation(true);
    setTimeout(() => setShowConfirmation(false), 3000); // Hide the message after 3 seconds
  };

// Moz multiple Apis

const showErrorToast = (message) => {
  toast.error(message, {
      position: "top-right",
      autoClose: 15000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
  });
};

const [domdetailerApi, setDomDetailerApi] = useState('');

const showModal = async () => {
  setIsModalOpen(true);
 setDomDetailerApi(userData?.domDetailer?.length > 0 ? userData.domDetailer : "")
};

const handleOk = async () => {
  setIsLoading(true);

  try {
   const response = await axios.post('/domdetailer/updatedomdetailerApi', {
      userId: userData._id,
      domDetailer: domdetailerApi
    });
    localStorage.setItem("userData", JSON.stringify(response.data.updatedUser));
    setIsModalOpen(false);
  } catch (error) {
    showErrorToast('Error updating credentials: ' + error.message);
  } finally {
    setIsLoading(false);
  }
};

const handleCancel = () => {
  setIsModalOpen(false);
};



const modalFooter = (
  <div>
    <Button onClick={handleCancel}>Cancel</Button>
    <Button className='ml-2' onClick={handleOk}>Save</Button>
  </div>
);

// Scraping APi Modal
const showNewModal = () => {
  setIsNewModalVisible(true);
  setApiInput(userData.scrapingrobotApi.length > 0 ? userData.scrapingrobotApi : "")
  setIsNewLoading(false);
};

const handleNewModalOk = async  () => {
  setIsNewModalVisible(false);
  setIsNewLoading(true)
  try {
    const response = await axios.post('/rank/updatescrapingrobotApi', {
        userId: userData._id,
        scrapingrobotApi: apiInput
    });
    console.log(response.data); // handle the response as needed
    setIsNewModalVisible(false);
    // setIsLoading(false);
} catch (error) {
    showErrorToast(error.response?.data || "An error occurred while updating the API");
    setIsNewModalVisible(true);
    // setIsLoading(false);
} finally {
    setIsLoading(false);
}

};

const handleNewModalCancel = () => {
  setIsNewModalVisible(false);
};

const handleApiInputChange = (e) => {
  setApiInput(e.target.value);
  setscrapingRobotmyApi(e.target.value)
  // Handle the API change here
};





const showSerpModal = async () => {
  setSerpOpen(true);
  
  try {
    setLoading(true);
    const timestamp = new Date().getTime(); // Adding timestamp to avoid cache
    const response = await axios.get(`/api/user/credits/${userData?._id}?timestamp=${timestamp}`);

    console.log(response.data.credits);
    // Assuming response.data contains the user object with serpApiKeys field
    const keys = response.data.credits.serpApiKeys && response.data.credits.serpApiKeys.length > 0 ? response.data.credits.serpApiKeys : [''];
    setSerpApiKeys(keys);
  } catch (error) {
    console.error("Error fetching SERP API keys:", error);
    setSerpApiKeys(['']); // Ensure there's at least one input if fetch fails or no keys
  } finally {
    setLoading(false);
  }
 
  
};

const handleSerpOk = async () => {
  console.log(serpApiKeys, "serpApiKeys")
  setSerpLoading(true);
  try {
    const response = await axios.post('/social/updateserpApiKeys', {
      userId: userData._id, // Use the userId from your userData object
      serpApiKeys: serpApiKeys // Use the serpApiKeys from the component's state
    });

    console.log('Update success:', response.data);
    localStorage.setItem("userData", JSON.stringify(response.data.updatedUser));
    // Handle success response
    // You might want to give feedback to the user or close the modal here
    setSerpOpen(false);
  } catch (error) {
    console.error('Error updating SERP API keys:', error.response ? error.response.data : error.message);
    // Handle error response
    // You might want to give feedback to the user here
  } finally {
    setSerpLoading(false);
  }
};

const handleSerpCancel = () => {
  setSerpOpen(false);
};

const handleInputChange = (value, index) => {
  const newKeys = [...serpApiKeys];
  newKeys[index] = value;
  setSerpApiKeys(newKeys);
};

const addSerpApi = () => {
  setSerpApiKeys([...serpApiKeys, '']); // Add a new empty string to the array
};

const removeSerpApi = (index) => {
  const newKeys = serpApiKeys.filter((_, i) => i !== index); // Remove the key at the specified index
  setSerpApiKeys(newKeys);
};


const [isProcessingRequest, setIsProcessingRequest] = useState(false);
  const [isAIResponseModalOpen, setIsAIResponseModalOpen] = useState(false);
  const [selectedModel, setSelectedModel] = useState('gpt-3.5-turbo-0125');
  // const [selectedModel, setSelectedModel] = useLocalStorage('openaiApi', '');
  const [apiKey, setApiKey] = useState('');
  const [geminiApiKey, setGeminiApiKey] = useState('');
  const [geminimodel, setGeminiModel] = useState('');
  const [openrouterApiKey, setOpenRouterApiKey] = useState('');
  const [openrouterModal, setOpenRouterModal] = useState('');

  // const [apiKey, setApiKey] = useLocalStorage('openaiApi', '');


  const handleApiKeyChange = (e) => {
    setApiKey(e.target.value); // Update the API key state with the new value
  };
  const handleChangeopenroiter = (value) => {
    setOpenRouterModal(value);
    console.log(`Selected OpenRouter model: ${value}`); // Optional: for debugging
  };
  const handleChangegemini = (value) => {
    setGeminiModel(value);
    console.log(`Selected Gemini model: ${value}`); // Optional: for debugging
  };
  const handleChange = (value) => {
    setSelectedModel(value);
    console.log(`Selected model: ${value}`); // Optional: for debugging
  };
  const handleGeminiApiKeyChange = (e) => {
    setGeminiApiKey(e.target.value);
  };
  const handleOpenRouterApiKeyChange = (e) => {
    setOpenRouterApiKey(e.target.value);
  };

  const showAIResponseModal = async () => {
    setIsAIResponseModalOpen(true);

    try {
   setIsProcessingRequest(true);
    const timestamp = new Date().getTime(); // Adding timestamp to avoid cache
    const response = await axios.get(`/api/user/credits/${userData?._id}?timestamp=${timestamp}`);

    console.log(response.data.credits);
  setApiKey(response.data.credits.openaiApi)
  setSelectedModel(response.data.credits.openaiApiModel)
  setGeminiApiKey(response.data.credits.geminiApi)
  setOpenRouterApiKey(response.data.credits.openrouterApi)
  setOpenRouterModal(response.data.credits.openrouterApiModel)
  setGeminiModel(response.data.credits.geminimodel)
  } catch (error) {
    console.error("Error fetching SERP API keys:", error);
  
  } finally {
    setIsProcessingRequest(false);
  }

  };

  const handleAIResponseOk = async () => {
    setIsProcessingRequest(true);
    try {
      const response = await axios.post('/social/updateopenaiApi', {
        userId: userData._id, // Use the userId from your userData object
        openaiApi: apiKey,
        openaiApiModel: selectedModel,
        geminiApi: geminiApiKey,
        openrouterApi: openrouterApiKey,
        openrouterApiModel: openrouterModal,
        geminimodel: geminimodel
      });
  
      console.log('Update success:', response.data);
      localStorage.setItem("userData", JSON.stringify(response.data.updatedUser));

      setIsAIResponseModalOpen(false);
    } catch (error) {
      console.error('Error updating Open Ai API keys:', error.response ? error.response.data.errorDetails : error.response.data.errorMessage );
      // Handle error response
      // You might want to give feedback to the user here
    } finally {
      setIsProcessingRequest(false);
    }
  };

  const handleAIResponseCancel = () => {
    setIsAIResponseModalOpen(false);
  };


  // Reddit 

  const [loadingReddit, setLoadingReddit] = useState(false);
  const [openReddit, setOpenReddit] = useState(false);
  const [redditCredentials, setRedditCredentials] = useState({
    userAgent: '',
    clientId: '',
    clientSecret: '',
    refreshToken: '',
  });
  // Rename functions to include "Reddit"
  const showModalReddit = async () => {
    setOpenReddit(true);
    setLoadingReddit(true);

    const timestamp = new Date().getTime(); 

    try {
      const response = await axios.get(`/api/user/credits/${userData._id}?timestamp=${timestamp}`);
      // Assuming the response data structure matches your redditCredentials state
      setRedditCredentials(response.data.credits.redditCredentials);
      // console.log(response.data);
      toast.success("Reddit credentials fetched successfully");
    } catch (error) {
      console.error("Error fetching Reddit credentials:", error);
      toast.error("Failed to fetch Reddit credentials");
    } finally {
      setLoadingReddit(false);
    }

  };

  const handleOkReddit = async () => {
    setLoadingReddit(true);

    // Assuming you have the userId available somehow, maybe from context or props
    // const userId = "yourUserId"; // Replace with actual user ID

    try {
      const response = await axios.post('/social/update-reddit-credentials', {
        userId: userData._id,
        redditCredentials,
      });

      console.log(response.data); // Success handling
      localStorage.setItem("userData", JSON.stringify(response.data.updatedUser));
      toast.success(response.data.message);

    } catch (error) {
      console.error('Error updating Reddit credentials:', error);
      toast.error(error.response?.data?.message || "Error updating Reddit credentials");

      // Error handling
    }

    setLoadingReddit(false);
    setOpenReddit(false);
  };

  const handleInputChangeReddit = (e) => {
    const { name, value } = e.target;
    setRedditCredentials({
      ...redditCredentials,
      [name]: value,
    });
  };

  const handleCancelReddit = () => {
    setOpenReddit(false);
  };

  const [domdetailerCredits, setDomdetailerCredits] = useState("")

  const checkCredits = () => {
    axios.post('/domdetailer/check-credits', { apikey: domdetailerApi })
      .then(response => {
        // Handle response
        setDomdetailerCredits(response.data.credits)
        console.log(response.data); // Or update state, UI, etc., based on response
        toast.success(`Credits: ${response.data.credits}`);
      })
      .catch(error => {
        // Handle error
        console.error('Error fetching credit balance:', error);
        const errorMessage = error.response && error.response.data && error.response.data.error 
          ? error.response.data.error 
          : "An error occurred while trying to fetch your credit balance.";
        toast.error(errorMessage);
      });
  };


  const [isLicenseModalOpen, setIsLicenseModalOpen] = useState(false);
  const [loadinglicense, setLoadingLicense] = useState(false);
  const [trafficButlerLicense, setTrafficButlerLicense] = useState('');
  const [redditTrafficOpsLicense, setRedditTrafficOpsLicense] = useState('');
  const [topicalMappingLicense, SetTopicalMappingLicense] = useState('');


  const showLicenseModal = () => {
    setIsLicenseModalOpen(true);
  };
  
  const handleLicenseOk = () => {
    setLoadingLicense(true);

    axios.post('/moz/licensemanagement', {
      trafficButlerLicense,
      redditTrafficOpsLicense,
      topicalMappingLicense,
      userId: userData._id
    })
    .then(response => {
      console.log(response.data); // You might want to handle the successful response
      const data = response.data;
console.log(data, "data from reddit tool");
      setLoadingLicense(false);
      setIsLicenseModalOpen(false);
      localStorage.setItem("userData", JSON.stringify(data.user));
      toast.success(data.message);
      setTimeout(() => {
        window.location.href = '/'; // Redirects to the homepage
      }, 2000);

    //   if (data && data.email) {
    //   localStorage.setItem("userData", JSON.stringify(data));
    //   toast.success("License has been verified and Reddit Butler Full Version has been added");
    //   setTimeout(() => {
    //     window.location.href = '/'; // Redirects to the homepage
    //   }, 2000);
    // } else if (data && data.message) {
    //   // Handle messages such as verification success
    //   toast.success(data.message);
    //   setTimeout(() => {
    //     window.location.href = '/'; // Redirects to the homepage
    //   }, 2000);
    // } else {
    //   // Handle unexpected responses
    //   toast.error("Unexpected response from the server.");
    // }

    })
    .catch(error => {
      console.log(error, "error is")
      toast.error(`Error: ${error.response ? error.response.data.message : 'Unknown error'}`);
      setLoadingLicense(false);
    });
  };
  
  const handleLicenseCancel = () => {
    setIsLicenseModalOpen(false);
  };
  const onChangeTrafficButler = (e) => {
    setTrafficButlerLicense(e.target.value);
  };

  const onChangeRedditTrafficOps = (e) => {
    setRedditTrafficOpsLicense(e.target.value);
  };
  const onChangeSubRedditTrafficOps = (e) => {
    SetTopicalMappingLicense
    
    (e.target.value);
  };


// Red votes modal

const [loadingReducpvotes, setLoadingReducpvotes] = useState(false);
  const [openReducpvotes, setOpenReducpvotes] = useState(false);
  const [redupvotesApi, setReducpvotesApi] = useState('');

  const handleInputChangeUpvotes = (e) => {
    setReducpvotesApi(e.target.value);
  };

  const showModalReducpvotes = () => {
    setOpenReducpvotes(true);
    console.log(userData, "userData from show Modal upvotes Api")
    setReducpvotesApi(userData?.redUpvotesApi?.length > 0 ? userData.redUpvotesApi : "")
  };

  const handleOkReducpvotes = async () => {
    if (!redupvotesApi) {
      toast.error('Please add RedUpvotes API');
      return;
    }
    console.log(redupvotesApi, "redupvotesApi");
    setLoadingReducpvotes(true);
    try {
      const response = await axios.post('/moz/updateredUpvotesApi', {
        userId: userData._id, // Use the userId from your userData object
        redUpvotesApi: redupvotesApi // Use the redupvotesApi from the component's state
      });

      console.log('Update success:', response.data);
      localStorage.setItem("userData", JSON.stringify(response.data.updatedUser));
      toast.success('RedUpvotes API updated successfully');
      setOpenReducpvotes(false);
    } catch (error) {
      console.error('Error updating RedUpvotes API:', error.response ? error.response.data : error.message);
      toast.error('Error updating RedUpvotes API');
    } finally {
      setLoadingReducpvotes(false);
    }
  };

  const handleCancelReducpvotes = () => {
    setOpenReducpvotes(false);
  };

  // medium token

   // Medium token states
   const [mediumToken, setMediumToken] = useState('');
   const [loadingMediumToken, setLoadingMediumToken] = useState(false);
   const [openMediumToken, setOpenMediumToken] = useState(false);

   const handleInputChangeMediumToken = (e) => {
    setMediumToken(e.target.value);
  };

  const showModalMediumToken = () => {
    setOpenMediumToken(true);
    console.log(userData, "userData from show Modal Medium Token")
    setMediumToken(userData?.mediumToken?.length > 0 ? userData.mediumToken : "")
  };

  const handleOkMediumToken = async () => {
    if (!mediumToken) {
      toast.error('Please add Medium access token');
      return;
    }
    console.log(mediumToken, "mediumToken");
    setLoadingMediumToken(true);
    try {
      const response = await axios.post('/moz/updatemediumtoken', {
        userId: userData._id, // Use the userId from your userData object
        mediumToken: mediumToken // Use the mediumToken from the component's state
      });

      console.log('Update success:', response.data);
      localStorage.setItem("userData", JSON.stringify(response.data.updatedUser));
      toast.success('Medium access token updated successfully');
      setOpenMediumToken(false);
    } catch (error) {
      console.error('Error updating Medium access token:', error.response ? error.response.data : error.message);
      toast.error('Error updating Medium access token');
    } finally {
      setLoadingMediumToken(false);
    }
  };

  const handleCancelMediumToken = () => {
    setOpenMediumToken(false);
  };



  return (
    <>
    <ToastContainer
position="top-right"
autoClose={8000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
  <main className="d-flex flex-nowrap">
  <BarSide2 />
  {/* <ProSideBar /> */}
  <div className="d-flex flex-column flex-grow-1  p-4">
            <div style={{ overflowY: 'auto', height: 'calc(100vh - 24px)' }}>
                
<div className="container mt-3">
    <div className="row">
        <div className="col-md-12 ">
        <div className="ra-home border border-gray-300 bg-white rounded-lg p-4">
      <h4>
        Keyword Research Settings
        <OverlayTrigger
          placement="right"
          overlay={<Tooltip>Keywords from Google Autocomplete</Tooltip>}
        >
          <i style={{ fontSize: '14px' }} className="bi bi-info-circle ms-2"></i>
        </OverlayTrigger>
      </h4>
      <p style={{ marginBottom: '1.5rem' }}>A global settings for all your keyword reports.</p>
      <Form action="" method="post" enctype="multipart/form-data">
       
        <Row className="mb-3">
          <Col md={6}>
            <Form.Group>
              <Form.Label>Custom Weakspot Domains</Form.Label>
              <Form.Control as="textarea" placeholder="One domain per line: example.com" rows={10} value={customDomains} onChange={handleCustomDomainsChange} />
            </Form.Group>
          </Col>
          <Col md={3}>
            <Form.Group>
              <Form.Label>Max. DA</Form.Label>
              <Form.Control type="text" placeholder="0 - 100" defaultValue="20" value={maxDA} onChange={handleMaxDAChange} />
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label>My Domains</Form.Label>
              <Form.Control as="textarea" placeholder="One domain per line: example.com" rows={6} value={myDomains} onChange={handleMyDomainsChange} />
            </Form.Group>
          </Col>
          <Col md={3}>
       

<Button onClick={showModal} className='mozmodalFirdt' size='large'>
        Dom Detailer Api
      </Button>
      <Modal  open={isModalOpen} onCancel={handleCancel} footer={modalFooter}>
      {isLoading ? (
          <div style={{ textAlign: 'center' }}>
            <BeatLoader color="#1890ff" className="mb-1" />
          </div>
        ) : (
            <>
      <Input placeholder="Enter your Dom Detailer API here" value={domdetailerApi} onChange={(e) => setDomDetailerApi(e.target.value)}  />
        <div style={{display: "flex"}} className='mt-2'>
        <Button className='mr-2'><a href="https://domdetailer.com/" target="_blank">Get Dom Detailer Api</a></Button>
        <Button onClick={checkCredits} >Check Credits</Button>

        </div>
        {domdetailerCredits && (
        <div className="description-text mt-2">
          Remaining Credits: {domdetailerCredits}
        </div>
      )}</>
        )}
      </Modal>
      
      <Button onClick={showNewModal} className='mozmodal' size='large'>
                Scraping Robot API
            </Button>
            <Modal title="Enter API" open={isNewModalVisible} onOk={handleNewModalOk} onCancel={handleNewModalCancel} footer={[ <Button key="back" onClick={handleNewModalCancel}> Cancel </Button>, <Button key="submit"  onClick={handleNewModalOk}> Save </Button>, ]}>
            {isNewLoading ? (
                    <BeatLoader color="#1890ff" />
                ) : (
               <>
                <Input placeholder="Enter your API here" onChange={handleApiInputChange} value={apiInput} />
                <a href="https://scrapingrobot.com/" target="_blank" style={{ marginTop: '10px', display: 'inline-block' }}>
              <Button >
                  Get API
              </Button>
    </a></>
    )}
              
            </Modal>

            <Button onClick={showSerpModal} className='mozmodal' size='large'>
         SERP API Keys
      </Button>
      <Modal
        open={serpOpen}
        title="Manage SERP API Keys"
        onOk={handleSerpOk}
        onCancel={handleSerpCancel}
        footer={[
          <Button key="back" onClick={handleSerpCancel}>
            Return
          </Button>,
          <Button key="submit"  loading={serpLoading} onClick={handleSerpOk}>
            Submit
          </Button>,
        ]}
      >
        {loading ? (
          <div style={{ textAlign: 'center' }}>
          <BeatLoader color="#1890ff" className="mb-1" />
        </div>
        ) : (
          <>
            {serpApiKeys.map((key, index) => (
              <div key={index} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                <Input
                  style={{ flex: 1, marginRight: '8px' }}
                  value={key}
                  onChange={(e) => handleInputChange(e.target.value, index)}
                  placeholder="Enter SERP API Key"
                />
                {serpApiKeys.length > 1 && (
                  <MinusCircleOutlined
                    onClick={() => removeSerpApi(index)}
                    style={{  cursor: 'pointer' }}
                  />
                )}
              </div>
            ))}
            <Button onClick={addSerpApi} icon={<PlusOutlined />} >
              Add More
            </Button>
            <Button className='ml-2'><a href="https://serpapi.com/dashboard" target="_blank">Get Api</a></Button>


          </>
        )}
      </Modal>

     <div><Button onClick={showAIResponseModal} className='mozmodal ' size='large' >Ai Apis</Button></div> 
      <Modal
        open={isAIResponseModalOpen}
        closable={false}
        onOk={handleAIResponseOk}
        onCancel={handleAIResponseCancel}
        footer={[
          <Button key="back" onClick={handleAIResponseCancel}>
            Return
          </Button>,
          <Button key="submit"  loading={isProcessingRequest} onClick={handleAIResponseOk}>
            Submit
          </Button>,
        ]}
      >
      {isProcessingRequest ? (
          <div style={{ textAlign: 'center' }}>
            <BeatLoader color="#1890ff" className="mb-1" />
          </div>
        ) : (<>
       <Input 
          placeholder="Enter your Open Ai API key" 
          style={{ width: '100%' }} 
          value={apiKey}
          onChange={handleApiKeyChange}
        />

        <Select
        defaultValue="gpt-3.5-turbo-0125"
      value={selectedModel} // You might want to change this to a valid default value
      style={{ width: '100%', marginTop: '10px' }}
      onChange={handleChange}
      options={[
        { value: 'gpt-3', label: 'GPT-3' },
        { value: 'gpt-3.5-turbo-0125', label: 'GPT-3.5 Turbo' },
        { value: 'gpt-4', label: 'GPT-4' },
        { value: 'gpt-4-0125-preview', label: 'GPT-4 Turbo' },
        { value: 'gpt-4o', label: 'GPT-4o' },
      ]}
    />
<Input 
        placeholder="Enter your Gemini API key" 
        style={{ width: '100%', marginTop: '10px', marginBottom: '10px' }} // Added margin for visual separation
        value={geminiApiKey}
        onChange={handleGeminiApiKeyChange}
      />

<Select
        defaultValue="gemini-1.5-flash"
      value={geminimodel} // You might want to change this to a valid default value
      style={{ width: '100%', marginBottom: '10px' }}
      onChange={handleChangegemini}
      options={[
        { value: 'gemini-1.5-flash', label: 'Gemini 1.5 Flash' },
        { value: 'gemini-1.5-pro', label: 'Gemini 1.5 Pro' },
        { value: 'gemini-pro', label: 'Gemini 1.0 Pro' },

      ]}
    />

<Input 
        placeholder="Enter your OpenRouter API key" 
        style={{ width: '100%',  marginBottom: '10px' }} // Added margin for visual separation
        value={openrouterApiKey}
        onChange={handleOpenRouterApiKeyChange}
      />

<Select
        defaultValue="meta-llama/llama-3-70b-instruct"
      value={openrouterModal} // You might want to change this to a valid default value
      style={{ width: '100%', marginBottom: '10px' }}
      onChange={handleChangeopenroiter}
      options={[
        { value: 'anthropic/claude-3-haiku', label: 'Anthropic: Claude 3 Haiku' },
        { value: 'anthropic/claude-3-sonnet', label: 'Anthropic: Claude 3 Sonnet' },
        { value: 'anthropic/claude-3-opus', label: 'Anthropic: Claude 3 Opus' },
        { value: 'anthropic/claude-2.1', label: 'Anthropic: Claude v2.1' },
        { value: 'mistralai/mistral-small', label: 'Mistral Small' },
        { value: 'mistralai/mistral-medium', label: 'Mistral Medium' },
        { value: 'mistralai/mistral-large', label: 'Mistral Large' },
        { value: 'mistralai/mixtral-8x7b-instruct:nitro', label: 'Mixtral 8x7B Instruct (nitro)' },
        { value: 'mistralai/mixtral-8x22b-instruct', label: 'Mistral: Mixtral 8x22B Instruct' },
        { value: 'meta-llama/llama-3-70b-instruct', label: 'Meta: Llama 3 70B Instruct' },
        { value: 'meta-llama/llama-3-8b-instruct', label: 'Meta: Llama 3 8B Instruct' },
        { value: 'meta-llama/llama-3-8b-instruct:nitro', label: 'Meta: Llama 3 8B Instruct (nitro)' },
        { value: 'meta-llama/llama-3-70b-instruct:nitro', label: 'Meta: Llama 3 70B Instruct (nitro)' },
      ]}
    />
<Button className=''><a href="https://platform.openai.com/account/api-keys" target="_blank">Get Open Ai Api</a></Button>
<Button className='ml-2'><a href="https://aistudio.google.com/app/apikey" target="_blank">Get Gemini Api</a></Button>
<Button className='ml-2'><a href="https://openrouter.ai/keys" target="_blank">Get OpenRouter Api</a></Button>


        </>)}
      </Modal>


      <div><Button  className='mozmodal ' size='large' onClick={showModalReddit}>Reddit Credentials</Button></div> 
      <Modal
        open={openReddit}
        // title="Title"
        closable={false}
        onOk={handleOkReddit}
        onCancel={handleCancelReddit}
        footer={[
          <Button key="back" onClick={handleCancelReddit}>
            Return
          </Button>,
          <Button key="submit"  loading={loadingReddit} onClick={handleOkReddit}>
            Save
          </Button>,
        ]}
        width={1100}
      >

{loadingReddit ? (
          <div style={{ textAlign: 'center' }}>
            <BeatLoader color="#1890ff" className="mb-1" />
          </div>
        ) : (<>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
       <Input
          placeholder="User Agent"
          name="userAgent"
          value={redditCredentials?.userAgent}
          onChange={handleInputChangeReddit}
          style={{ width: 'calc(25% - 5px)' }}
        />
        <Input
          placeholder="Client ID"
          name="clientId"
          value={redditCredentials?.clientId}
          onChange={handleInputChangeReddit}
          style={{  width: 'calc(25% - 5px)' }}
        />
        
        <Input
          placeholder="Client Secret"
          name="clientSecret"
          value={redditCredentials?.clientSecret}
          onChange={handleInputChangeReddit}
          style={{  width: 'calc(25% - 5px)'  }}
        />
        <Input
          placeholder="Refresh Token"
          name="refreshToken"
          value={redditCredentials?.refreshToken}
          onChange={handleInputChangeReddit}
          style={{  width: 'calc(25% - 5px)'  }}
        /> 
        </div>
         <div class="containerGuide">
      {/* <h1 class="title">Reddit Credentials Setup Guide</h1> */}
      <div class="guide-list">
        <div>1. Log in to Reddit and go to <a href="https://www.reddit.com/prefs/apps" target="_blank">https://www.reddit.com/prefs/apps</a>.</div>
        <div>2. Click on "Create App" or "Create Another App" button.</div>
        <div>3. Fill in any name for your app in the "name" field.</div>
        <div>4. Select the "web app" radio button.</div>
        <div>5. In the "redirect uri" field, enter <a href="https://not-an-aardvark.github.io/reddit-oauth-helper/" target="_blank">https://not-an-aardvark.github.io/reddit-oauth-helper/</a></div>
        <div>6. Click "Create App" to finish setting up your Reddit application.</div>
        <div>7. After creating the app, note down the "client ID" and "client secret".</div>
        <div>8. Open <a href="https://not-an-aardvark.github.io/reddit-oauth-helper/" target="_blank">https://not-an-aardvark.github.io/reddit-oauth-helper/</a>.</div>
        <div>9. Enter your "client ID" and "client secret" into the respective fields.</div>
        <div>10. Click on "permanent" and then select all scopes.</div>
        <div>11. Click the button to generate a refresh token.</div>
        <div>12. For the userAgent, use the format: <code>yourAppName:v1.1.0 (by /u/yourRedditUsername)</code></div>
      </div>
    </div>
        </> )}
      </Modal>

      <Button className='mozmodal ' size='large' onClick={showLicenseModal}>
         License Management
      </Button>
      <Modal 
        title="" 
        open={isLicenseModalOpen} 
        onOk={handleLicenseOk} 
        onCancel={handleLicenseCancel}
        footer={[
          <Button key="back" onClick={handleLicenseCancel}>
            Return
          </Button>,
          <Button key="submit"  loading={loadinglicense} onClick={handleLicenseOk}>
            Submit
          </Button>]}
      >
         <Input 
          placeholder="Enter Traffic Butler License"
          value={trafficButlerLicense}
          onChange={onChangeTrafficButler}
        />
        <Input
          placeholder="Enter Reddit Traffic Ops License"
          value={redditTrafficOpsLicense}
          onChange={onChangeRedditTrafficOps}
          style={{ marginTop: '16px' }} // Adds some space between the two inputs
        />
        <Input
          placeholder="Enter Topical Mapping License"
          value={topicalMappingLicense}
          onChange={onChangeSubRedditTrafficOps}
          style={{ marginTop: '16px' }} // Adds some space between the two inputs
        />
      </Modal>

      {/* Red Upvotes Api */}
      <Button className='mozmodal ' size='large' onClick={showModalReducpvotes}>
        RedUpvotes Api
      </Button>
      <Modal
        open={openReducpvotes}
        // title=""
        closable={false}
        onOk={handleOkReducpvotes}
        onCancel={handleCancelReducpvotes}
        footer={[
          <Button key="back" onClick={handleCancelReducpvotes}>
            Return
          </Button>,
          <Button key="submit"  loading={loadingReducpvotes} onClick={handleOkReducpvotes}>
            Submit
          </Button>
        ]}
      >
       <Input
          style={{ width: '100%' }}
          // size="large"
          placeholder="Enter RedUpvotes API"
          value={redupvotesApi}
          onChange={handleInputChangeUpvotes}
        />
      </Modal>

      {/* Medium Token */}

       {/* Button to open Medium Token modal */}
       <Button className='mozmodal' size='large' onClick={showModalMediumToken}>
        Medium Token
      </Button>

      {/* Medium Token Modal */}
      <Modal
        open={openMediumToken}
        closable={false}
        onOk={handleOkMediumToken}
        onCancel={handleCancelMediumToken}
        footer={[
          <Button key="back" onClick={handleCancelMediumToken}>
            Return
          </Button>,
          <Button key="submit" loading={loadingMediumToken} onClick={handleOkMediumToken}>
            Submit
          </Button>
        ]}
      >
        <Input
          style={{ width: '100%' }}
          placeholder="Please add medium access token"
          value={mediumToken}
          onChange={handleInputChangeMediumToken}
        />
      </Modal>

          </Col>
        </Row>
        <Row>
          <Col md={4}>
            <Button   className=" w-100 btn btn-neutral savebutton" onClick={handleSaveClick}>Save</Button>
            {showConfirmation && 
                <div className="mt-2" style={{ color: '#28a745' }}>Settings Updated Successfully!</div>}
          </Col>
        </Row>
      </Form>
    </div>
        </div>



        {/* Col md 3 */}
        {/* <div className="col-md-3">
            <SettingsSidebar />
        </div> */}
    </div>
</div>

            </div>
        </div>
  </main>
 
    </>
  )
}



export default DomSettings