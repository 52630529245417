import React from 'react';
import CreatableSelect from 'react-select/creatable';

const components = {
  DropdownIndicator: null,
};

const createOption = (label) => ({
  label,
  value: label,
});


function Creatableinput({ onInputChange }) {
    const [inputValue, setInputValue] = React.useState('');
    const [value, setValue] = React.useState([]);
  
    const handleKeyDown = (event) => {
      if (!inputValue) return;
      switch (event.key) {
        case 'Enter':
        case 'Tab':
          const newValue = [...value, createOption(inputValue)];
          setValue(newValue);
          onInputChange(newValue); // Update parent component
          setInputValue('');
          event.preventDefault();
      }
    };
  
    const handleInputChange = (inputVal) => {
      setInputValue(inputVal);
    };
  
    const handleChange = (newValue) => {
      setValue(newValue);
      onInputChange(newValue); // Update parent component
    };
  
    return (
      <CreatableSelect
        components={components}
        inputValue={inputValue}
        isClearable
        isMulti
        menuIsOpen={false}
        onChange={handleChange}
        onInputChange={handleInputChange}
        onKeyDown={handleKeyDown}
        placeholder="Type Tag and press enter..."
        value={value}
      />
    );
  }
  


export default Creatableinput