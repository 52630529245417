import React, { useState, useRef,useMemo  } from 'react';
import { useLocation } from 'react-router-dom';
import { Table, Button, Input, Space, Tooltip,  InputNumber,Modal, Form, Row, Col , Select   } from 'antd';
import BarSide2 from '../../BarSide2';
import { Container } from 'react-bootstrap';
import { CSVLink } from 'react-csv'; 
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { toast, ToastContainer } from 'react-toastify'; // For toast notifications
import 'react-toastify/dist/ReactToastify.css';
import { RiOpenaiFill } from "react-icons/ri";
import Highlighter from 'react-highlight-words';
// import { SearchOutlined } from '@ant-design/icons';
import axios from "../../../components/Axios"
// import { PlusOutlined } from '@ant-design/icons';
import { 
  PlusOutlined, 
  SearchOutlined, 
  RobotOutlined, 
  CopyOutlined, 
  DownloadOutlined 
} from '@ant-design/icons';

const { TextArea } = Input;


const KeywordDetails = () => {
  const location = useLocation();
  const { keywordData } = location.state;
console.log(keywordData, "keywordData")
  const [searchText, setSearchText] = useState('');
  const [searchedColumn, setSearchedColumn] = useState('');
  const searchInput = useRef(null);
  // const [filterType, setFilterType] = useState('include');
  const userDataString = localStorage.getItem("userData");
  const userData = userDataString ? JSON.parse(userDataString) : null;
   
  const [filteredInfo, setFilteredInfo] = useState({});
  const [selectedRowKeys, setSelectedRowKeys] = useState([]); // Track selected row keys
  const [selectedRows, setSelectedRows] = useState([]); // Track selected row objects
 
  const [kgrLoading, setkgrLoading] = useState(false);

  const [keywordState, setKeywordState] = useState(keywordData);

  // if (!keywordData) {
  //   return <div>No data available</div>;
  // }

  console.log(keywordData, "keywordData"); // Ensure keywordData is logged for debugging


  // const flattenedData = keywordData.entities.flatMap((entity) =>
  //   entity.topics.flatMap((topic) =>
  //     topic.subtopics.flatMap((subtopic) =>
  //       subtopic.keywords.map((keyword, index) => ({
  //         _id: `${subtopic._id}-${index}-${keyword}`,
  //         keyword: keyword,
  //         subtopic: subtopic.name,
  //         topic: topic.name,
  //         entity: entity.name,
  //       }))
  //     )
  //   )
  // );

  // Helper function for KGR tooltips
const getKGRDescription = (kgr) => {
  if (kgr <= 0.25) return 'Low Competition (KGR ≤ 0.25)';
  if (kgr <= 1) return 'Medium Competition (0.25 < KGR ≤ 1)';
  return 'High competition (KGR > 1)';
};

// Use useMemo to compute flattenedData and have it update when keywordState changes


  // const flattenedData = keywordData.entities.flatMap((entity) =>
  //   entity.topics.flatMap((topic) =>
  //     topic.subtopics.flatMap((subtopic) =>
  //       subtopic.keywords.map((keyword, index) => ({
  //         _id: `${subtopic._id}-${index}-${keyword}`,
  //         keyword: keyword,
  //         searchVolume: subtopic.keywordMetrics?.[keyword] || '', // Add search volume from keywordMetrics
  //         allInTitle: subtopic.keywordMetrics?.[`${keyword}_allInTitle`] || '',
  //         kgr: subtopic.keywordMetrics?.[`${keyword}_kgr`] || '',
  //         subtopic: subtopic.name,
  //         topic: topic.name,
  //         entity: entity.name,
  //       }))
  //     )
  //   )
  // );
  const flattenedData = useMemo(() => 
    keywordState.entities.flatMap((entity) =>
      entity.topics.flatMap((topic) =>
        topic.subtopics.flatMap((subtopic) =>
          subtopic.keywords.map((keyword, index) => ({
            _id: `${subtopic._id}-${index}-${keyword}`,
            keyword: keyword,
            searchVolume: subtopic.keywordMetrics?.[keyword] || '0',
            allInTitle: subtopic.keywordMetrics?.[`${keyword}_allInTitle`] || '',
            kgr: subtopic.keywordMetrics?.[`${keyword}_kgr`] || '',
            subtopic: subtopic.name,
            topic: topic.name,
            entity: entity.name,
          }))
        )
      )
    ), [keywordState]);

  // Extract unique values for filtering
  const uniqueTopics = [...new Set(flattenedData.map((item) => item.topic))];
  const uniqueSubtopics = [...new Set(flattenedData.map((item) => item.subtopic))];

  // Extract unique entities for filtering
const uniqueEntities = [...new Set(flattenedData.map((item) => item.entity))];

// Create filter objects for each unique entity
const entityFilters = uniqueEntities.map((entity) => ({ text: entity, value: entity }));


  // Create filter objects for each unique value
  const topicFilters = uniqueTopics.map((topic) => ({ text: topic, value: topic }));
  const subtopicFilters = uniqueSubtopics.map((subtopic) => ({ text: subtopic, value: subtopic }));

  const handleChange = (pagination, filters, sorter) => {
    setFilteredInfo(filters);
  };


    const handleChatGptClick = () => {
      toast.info('If ChatGPT page is not opening, please refresh the page and try again.', {
        autoClose: 2000
      });
      const data = {
        cluster: selectedRows.map(({ _id, ...rest }) => rest), // Exclude _id from each row
      };
  console.log(data, "data of cluster");
      // Send message to content script
      window.postMessage(
        {
          source: 'react-app', // Unique identifier
          type: 'OPEN_CHATGPT',
          payload: data,
        },
        '*'
      );
    };

    const handleClaudeClick = () => {
      // const data = { cluster: selectedRows };
      toast.info('If Claude page is not opening, please refresh the page and try again.', {
        autoClose: 2000
      });
      const data = {
        cluster: selectedRows.map(({ _id, ...rest }) => rest), // Exclude _id from each row
      };
      console.log(data, "data of cluster for Claude");
      window.postMessage(
        {
          source: 'react-app',
          type: 'OPEN_CLAUDE',
          payload: data,
        },
        '*'
      );
    };
    
    const handleGeminiClick = () => {
      // const data = { cluster: selectedRows };
      toast.info('If Gemini page is not opening, please refresh the page and try again.', {
        autoClose: 2000
      });
      const data = {
        cluster: selectedRows.map(({ _id, ...rest }) => rest), // Exclude _id from each row
      };
      console.log(data, "data of cluster for Gemini");
      window.postMessage(
        {
          source: 'react-app',
          type: 'OPEN_GEMINI',
          payload: data,
        },
        '*'
      );
    };  

// 
const [includeKeyword, setIncludeKeyword] = useState('');
const [excludeKeyword, setExcludeKeyword] = useState('');
const [minVolume, setMinVolume] = useState('');
const [maxVolume, setMaxVolume] = useState('');
const [wordLength, setWordLength] = useState('');

// Filter functions
const includeFilter = (item) => {
  if (!includeKeyword) return true;
  const includes = includeKeyword.toLowerCase().split(',');
  return includes.every(term => item.keyword.toLowerCase().includes(term.trim()));
};

const excludeFilter = (item) => {
  if (!excludeKeyword) return true;
  return !item.keyword.toLowerCase().includes(excludeKeyword.toLowerCase().trim());
};

const minVolumeFilter = (item) => {
  if (!minVolume) return true;
  return item.searchVolume >= parseInt(minVolume);
};

const maxVolumeFilter = (item) => {
  if (!maxVolume) return true;
  return item.searchVolume <= parseInt(maxVolume);
};

const wordLengthFilter = (item) => {
  if (!wordLength) return true;
  return item.keyword.split(' ').length === parseInt(wordLength);
};

const filterBySubtopic = (filteredInfo) => (item) => {
  if (!filteredInfo.subtopic || filteredInfo.subtopic.length === 0) return true;
  return filteredInfo.subtopic.includes(item.subtopic);
};

const filterByTopic = (filteredInfo) => (item) => {
  if (!filteredInfo.topic || filteredInfo.topic.length === 0) return true;
  return filteredInfo.topic.includes(item.topic);
};

const filterByEntity = (filteredInfo) => (item) => {
  if (!filteredInfo.entity || filteredInfo.entity.length === 0) return true;
  return filteredInfo.entity.includes(item.entity);
};


// Apply all filters using useMemo
const filteredData = useMemo(() => {
  return flattenedData
    .filter(filterByTopic(filteredInfo))
    .filter(filterBySubtopic(filteredInfo))
    .filter(filterByEntity(filteredInfo))
    .filter(includeFilter)
    .filter(excludeFilter)
    .filter(minVolumeFilter)
    .filter(maxVolumeFilter)
    .filter(wordLengthFilter);
}, [
  flattenedData, 
  filteredInfo, 
  includeKeyword, 
  excludeKeyword, 
  minVolume, 
  maxVolume, 
  wordLength
]);

const getColumnSearchProps = (dataIndex) => ({
  filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters, close }) => (
    <div style={{ padding: 8 }} onKeyDown={(e) => e.stopPropagation()}>
      {dataIndex === 'keyword' && (
        <>
          <Input
            placeholder="Include keywords (comma-separated)"
            value={includeKeyword}
            onChange={(e) => setIncludeKeyword(e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <Input
            placeholder="Exclude keywords"
            value={excludeKeyword}
            onChange={(e) => setExcludeKeyword(e.target.value)}
            style={{ marginBottom: 8, display: 'block' }}
          />
          <InputNumber
            placeholder="Word Length"
            value={wordLength}
            onChange={(value) => setWordLength(value)}
            style={{ marginBottom: 8, display: 'block', width: '100%' }}
          />
        </>
      )}

      {dataIndex === 'searchVolume' && (
        <>
          <InputNumber
            placeholder="Min Volume"
            value={minVolume}
            onChange={(value) => setMinVolume(value)}
            style={{ marginBottom: 8, display: 'block', width: '100%' }}
          />
          <InputNumber
            placeholder="Max Volume"
            value={maxVolume}
            onChange={(value) => setMaxVolume(value)}
            style={{ marginBottom: 8, display: 'block', width: '100%' }}
          />
        </>
      )}

      <Space>
        <Button
          onClick={() => {
            confirm();
          }}
          size="small"
          style={{ width: 90 }}
        >
          Filter
        </Button>
        <Button
          onClick={() => {
            clearFilters();
            setIncludeKeyword('');
            setExcludeKeyword('');
            setMinVolume('');
            setMaxVolume('');
            setWordLength('');
            confirm();
          }}
          size="small"
          style={{ width: 90 }}
        >
          Reset
        </Button>
        <Button type="link" size="small" onClick={close}>
          Close
        </Button>
      </Space>
    </div>
  ),
  filterIcon: (filtered) => (
    <SearchOutlined style={{ 
      color: (dataIndex === 'keyword' && (includeKeyword || excludeKeyword || wordLength)) ||
             (dataIndex === 'searchVolume' && (minVolume || maxVolume)) 
        ? '#1677ff' 
        : undefined 
    }} />
  ),
});

// 
 const columns = [
  {
    title: 'No',
    dataIndex: 'no',
    key: 'no',
    width: 35,
    render: (text, record, index) => index + 1, // Display row number starting from 1
  },
  {
    title: 'Keyword',
    dataIndex: 'keyword',
    key: 'keyword',
    width: 200,
    ...getColumnSearchProps('keyword'),
    onCell: () => {
      return {
        style: {
          paddingLeft: '30px',
        },
      };
    },
    onHeaderCell: () => {
      return {
        style: {
          paddingLeft: '30px',
        },
      };
    },
  },{
    title: 'Vol',
    dataIndex: 'searchVolume',
    key: 'searchVolume',
    // width: 100,
    ...getColumnSearchProps('searchVolume'),
    sorter: (a, b) => a.searchVolume - b.searchVolume,
  },
  {
    title: () => (
      <Tooltip title="Keyword Golden Ratio = Search Volume / AllInTitle">
        <span>KGR</span>
      </Tooltip>
    ),
    dataIndex: 'kgr',
    key: 'kgr',
    // width: 110,
    render: (value) => {
      if (!value || value === 'N/A') return '-';
      const kgrValue = parseFloat(value);
      let color = '';
      if (kgrValue <= 0.25) color = '#4CAF50';  // Green
      else if (kgrValue <= 1) color = '#FFA726';  // Orange
      else color = '#EF5350';  // Red
      
      return (
        <Tooltip title={getKGRDescription(kgrValue)}>
          <span style={{ color }}>{value}</span>
        </Tooltip>
      );
    },
    sorter: (a, b) => {
      const kgrA = parseFloat(a.kgr) || 0;
      const kgrB = parseFloat(b.kgr) || 0;
      return kgrA - kgrB;
    },
  },
  {
    title: 'Subtopic',
    dataIndex: 'subtopic',
    key: 'subtopic',
    width: 200,
    filters: subtopicFilters,
    filteredValue: filteredInfo.subtopic || null,
    onFilter: (value, record) => record.subtopic.includes(value), 
  },
  {
    title: 'Topic',
    dataIndex: 'topic',
    key: 'topic',
    width: 200,
    filters: topicFilters,
    filteredValue: filteredInfo.topic || null,
    onFilter: (value, record) => record.topic.includes(value),
  },{
    title: 'Entity',
    dataIndex: 'entity',
    key: 'entity',
    width: 200,
    filters: entityFilters,  // Add entity filters
    filteredValue: filteredInfo.entity || null,  // Track filtered value for entity
    onFilter: (value, record) => record.entity.includes(value),  // Filtering logic
  }
  
];

  const rowSelection = {
    selectedRowKeys, // Controlled selected row keys
    onChange: (selectedKeys, selectedRows) => {
      setSelectedRowKeys(selectedKeys);
      setSelectedRows(selectedRows);
      console.log(`selectedRowKeys: ${selectedKeys}`, 'selectedRows: ', selectedRows);
    },
  };
  
  // const headers = [
  //   { label: 'Keyword', key: 'keyword' },
  //   { label: 'Subtopic', key: 'subtopic' },
  //   { label: 'Topic', key: 'topic' },
  //   { label: 'Entity', key: 'entity' },
  // ];
  const headers = [
    { label: 'Keyword', key: 'keyword' },
    { label: 'Search Volume', key: 'searchVolume' },
    { label: 'KGR', key: 'kgr' },
    { label: 'Subtopic', key: 'subtopic' },
    { label: 'Topic', key: 'topic' },
    { label: 'Entity', key: 'entity' }
  ];
  
const keywordsToCopy = selectedRows.map(row => row.keyword).join('\n'); // Use '\n' for new lines or ', ' for commas



const handleGetKGR = async () => {
  if (!selectedRows || selectedRows.length === 0) {
    toast.warning('Please select at least one keyword');
    return;
  }

  // Create loading toast
  const loadingToastId = toast.loading('Loading...');
  setkgrLoading(true);

  try {
    // Extract keywords from selected rows
    const keywords = selectedRows.map(row => row.keyword);
    console.log(keywordData._id, keywordData.userId, keywords, "req body");

    // Prepare request data
    const requestData = {
      documentId: keywordData._id,
      userId: keywordData.userId,
      keywords: keywords
    };

  
    // Send request
    const response = await axios.post('/cluster/get-allintitle', requestData);
    
    if (response.data.success) {
      // Update loading toast to success
      setKeywordState(response.data.data.updatedDocument);
      setSelectedRowKeys([]);
      setSelectedRows([]);
      toast.update(loadingToastId, {
        render: 'KGR Values updated successfully',
        type: 'success',
        isLoading: false,
        autoClose: 3000,
      });}
      console.log(response.data.data.updatedDocument.entities, "response.data.success.data.updatedDocument.entities")
    
  } catch (error) {
    console.error('Error getting KGR:', error);
    // Update loading toast to error
    toast.update(loadingToastId, {
      render: error.message || 'Failed to get KGR values',
      type: 'error',
      isLoading: false,
      autoClose: 5000,
    });
  } finally {
    setkgrLoading(false);
  }
};


const [isModalOpen, setIsModalOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
    form.resetFields();
  };

  // const handleSubmit = async (values) => {
  //   // Split keywords by newline and filter empty lines
  //   const keywords = values.keywords
  //     .split('\n')
  //     .map(keyword => keyword.trim())
  //     .filter(keyword => keyword !== '').slice(0, 998);;

  //   if (keywords.length === 0) {
  //     toast.error('Please enter at least one keyword');
  //     return;
  //   }

  //   if (keywords.length > 1000) {
  //     toast.error('Maximum 1000 keywords allowed');
  //     return;
  //   }

  //   setLoading(true);
  //   const loadingToast = toast.loading('Processing keywords...');

  //   try {
  //     const response = await axios.post('/cluster/add-keywords', {
  //       documentId: keywordData._id,
  //       userId: keywordData.userId,
  //       openaiapiKey: keywordData.openaiapiKey,
  //       keywords: keywords
  //     });
  //  setKeywordState(response.data.data);
  //     console.log('Keywords added successfully:', response.data);
      
  //     toast.update(loadingToast, {
  //       render: 'Keywords added successfully!',
  //       type: 'success',
  //       isLoading: false,
  //       autoClose: 3000
  //     });

  //     setIsModalOpen(false);
  //     form.resetFields();

  //   } catch (error) {
  //     console.error('Error adding keywords:', error);
      
  //     toast.update(loadingToast, {
  //       render: error.response?.data?.error || 'Failed to add keywords',
  //       type: 'error',
  //       isLoading: false,
  //       autoClose: 5000
  //     });
  //   } finally {
  //     setLoading(false);
  //   }
  // };



  const [selectedModel, setSelectedModel] = useState('openai');

const handleSubmit = async (values) => {
  const keywords = values.keywords
    .split('\n')
    .map(keyword => keyword.trim())
    .filter(keyword => keyword !== '').slice(0, 998);

  if (keywords.length === 0) {
    toast.error('Please enter at least one keyword');
    return;
  }

  if (keywords.length > 1000) {
    toast.error('Maximum 1000 keywords allowed');
    return;
  }

  // API key validation based on selected model
  if (selectedModel === 'openai' && !keywordData.openaiapiKey) {
    toast.error('OpenAI API key is required');
    return;
  } else if (selectedModel === 'gemini' && !keywordData.geminiApiKey) {
    toast.error('Gemini API key is required');
    return;
  } else if (selectedModel === 'openrouter' && !keywordData.openrouterApiKey) {
    toast.error('OpenRouter API key is required');
    return;
  }

  setLoading(true);
  const loadingToast = toast.loading('Processing keywords...');

  try {
    const response = await axios.post('/cluster/add-keywords', {
      documentId: keywordData._id,
      userId: keywordData.userId,
      openaiapiKey: keywordData.openaiapiKey,
      geminiApiKey: keywordData.geminiApiKey,
      openrouterApiKey: keywordData.openrouterApiKey,
      keywords: keywords,
      selectedModel: selectedModel
    });

    setKeywordState(response.data.data);
    toast.update(loadingToast, {
      render: `Keywords added successfully using ${selectedModel.toUpperCase()}!`,
      type: 'success',
      isLoading: false,
      autoClose: 3000
    });

    setIsModalOpen(false);
    form.resetFields();
  } catch (error) {
    toast.update(loadingToast, {
      render: error.response?.data?.error || 'Failed to add keywords',
      type: 'error',
      isLoading: false,
      autoClose: 5000
    });
  } finally {
    setLoading(false);
  }
};





  return (
    <>
     <ToastContainer 
        position="top-right" 
        autoClose={5000} 
        hideProgressBar={false} 
        newestOnTop={false} 
        closeOnClick 
        rtl={false} 
        pauseOnFocusLoss 
        draggable 
        pauseOnHover 
      />
<main className="d-flex flex-nowrap">
  <BarSide2 />
  <Container>
    <div className="d-flex flex-column flex-grow-1 p-4">
      <div style={{ overflowY: "auto", height: "calc(100vh - 24px)" }}>
        <div className="container mt-3">
          <div className="row">
            <div className="col-md-12">
              <div className="ra-home border border-gray-300 bg-white rounded-lg">
                {/* <div className="d-flex justify-content-between">
               <h3>{keywordData.keyword}</h3> 
               <div style={{ marginBottom: '6px' }}>
               <Button 
        type="primary" 
        onClick={showModal}
        style={{ backgroundColor: "#1677ff", marginRight: '7px' }}
      >
        Add Your Own Keywords
      </Button>



               <Button
      type="primary"
      style={{ backgroundColor: "#1677ff", marginRight: '7px' }}
      onClick={handleGetKGR}
      loading={kgrLoading}
    >
      Check KGR
    </Button>


               <Button
  type="primary"
  style={{ backgroundColor: "#1677ff", marginRight: '7px' }}
  onClick={handleChatGptClick}
>
  Use ChatGPT
</Button> <Button
  type="primary"
  style={{ backgroundColor: "#1677ff", marginRight: '7px' }}
  onClick={handleClaudeClick}
>
  Use Claude
</Button>


<Button
  type="primary"
  style={{ backgroundColor: "#1677ff", marginRight: '7px' }}
  onClick={handleGeminiClick}
>
  Use Gemini
</Button>
               


<CopyToClipboard 
  text={keywordsToCopy}
  onCopy={() => {
    if (keywordsToCopy.length > 0) {
      toast.success('Keywords copied to clipboard!');
    } else {
      toast.info('No keywords selected to copy.');
    }
  }}
>
  <Button type="primary" style={{ backgroundColor: "#1677ff", marginRight: '8px'}}>
    Copy To Clipboard
  </Button>
</CopyToClipboard>

  <CSVLink data={filteredData} headers={headers} filename="keywords.csv"  style={{ textDecoration: 'none' }}>
    <Button type="primary" style={{ backgroundColor: "#1677ff"}}>Export Data</Button>
  </CSVLink>
</div>
                </div> */}
                
                <Row gutter={[16, 16]} align="middle">
          {/* Title Section */}
          <Col xs={24} sm={24} md={8} lg={6} xl={6}>
            <h3 style={{ margin: 0, padding: '6px 0' }}>{keywordData.keyword}</h3>
          </Col>
          
          {/* Buttons Section */}
          <Col xs={24} sm={24} md={16} lg={18} xl={18}>
            <Row justify="end">
              <Space 
                wrap 
                style={{ 
                  justifyContent: 'flex-end', 
                  width: '100%',
                  gap: '8px'
                }}
                size={[8, 8]} // Consistent spacing horizontally and vertically
              >
                {/* Group 1: Primary Actions */}
                <Space.Compact>
                  <Button 
                    type="primary" 
                    onClick={showModal}
                    style={{ backgroundColor: "#1677ff" }}
                    icon={<PlusOutlined />}
                  >
                    Add Keywords
                  </Button>
                  <Button
                    type="primary"
                    style={{ backgroundColor: "#1677ff" }}
                    onClick={handleGetKGR}
                    loading={kgrLoading}
                    icon={<SearchOutlined />}
                  >
                    Check KGR
                  </Button>
                </Space.Compact>

                {/* Group 2: AI Tools */}
                <Space.Compact>
                  <Button
                    type="primary"
                    style={{ backgroundColor: "#1677ff" }}
                    onClick={handleChatGptClick}
                    // icon={<RobotOutlined />}
                  >
                    Use ChatGPT
                  </Button>
                  <Button
                    type="primary"
                    style={{ backgroundColor: "#1677ff" }}
                    onClick={handleClaudeClick}
                    // icon={<RobotOutlined />}
                  >
                   Use Claude
                  </Button>
                  <Button
                    type="primary"
                    style={{ backgroundColor: "#1677ff" }}
                    onClick={handleGeminiClick}
                    // icon={<RobotOutlined />}
                  >
                   Use Gemini
                  </Button>
                </Space.Compact>

                {/* Group 3: Export Actions */}
                <Space.Compact>
                  <CopyToClipboard 
                    text={keywordsToCopy}
                    onCopy={() => {
                      if (keywordsToCopy.length > 0) {
                        toast.success('Keywords copied to clipboard!');
                      } else {
                        toast.info('No keywords selected to copy.');
                      }
                    }}
                  >
                    <Button 
                      type="primary" 
                      style={{ backgroundColor: "#1677ff" }}
                      icon={<CopyOutlined />}
                    >
                      Copy
                    </Button>
                  </CopyToClipboard>

                  <CSVLink 
                    data={filteredData} 
                    headers={headers} 
                    filename="keywords.csv" 
                    style={{ textDecoration: 'none' }}
                  >
                    <Button 
                      type="primary" 
                      style={{ backgroundColor: "#1677ff" }}
                      icon={<DownloadOutlined />}
                    >
                      Export
                    </Button>
                  </CSVLink>
                </Space.Compact>
              </Space>
            </Row>
          </Col>
        </Row>

                 {/* Close justify-content-between div */}
              </div> {/* Close ra-home div */}
            </div> {/* Close col-md-12 div */}
          </div> {/* Close row div */}





    <div className='row'>
    <div className="container mt-3">
          <div className="row">
            <div className="col-md-12">
            <div className="ra-home border border-gray-300 bg-white rounded-lg">
            <div className="d-flex justify-content-between">
            <Table
                          dataSource={filteredData}
                          columns={columns}
                          rowKey="_id" // Ensure 'key' is unique; adjust if necessary
                          rowSelection={rowSelection} // Add rowSelection
                          // pagination={false}
                          pagination={{ pageSize: 500 }}
                          scroll={{ x: 'max-content' }}
                          onChange={handleChange}
                          style={{ width: '100%' }}
                        />

              </div></div></div></div></div>
    </div>


        </div> {/* Close container div */}
        
      </div> {/* Close auto overflow div */}

    {/*  */} 
   

    </div> {/* Close flex-column div */}
  </Container> {/* Close Container */}
</main> {/* Close main */}

{/* Modal */}
{/* <Modal
        title="Add Keywords"
        open={isModalOpen}
        onCancel={handleCancel}
        footer={null}
        width={600}
      >
        <Form
          form={form}
          onFinish={handleSubmit}
          layout="vertical"
        >
          <Form.Item
            name="keywords"
            rules={[
              { required: true, message: 'Please enter keywords' },
             
            ]}
          >
            <TextArea
              placeholder="Enter keywords (one per line)
Example:
seo training institute
digital marketing course
learn seo online
..."
              rows={10}
              style={{ fontFamily: 'monospace' }}
            />
          </Form.Item>

          <Form.Item>
            <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '7px' }}>
              <Button onClick={handleCancel}>
                Cancel
              </Button>
              <Button 
                type="primary" 
                htmlType="submit"
                loading={loading}
                style={{ backgroundColor: "#1677ff" }}
              >
                Add Keywords
              </Button>
            </div>
          </Form.Item>
        </Form>
      </Modal> */}

<Modal
    title="Add Keywords"
    open={isModalOpen}
    onCancel={handleCancel}
    footer={null}
    width={600}
  >
    <Form
      form={form}
      onFinish={handleSubmit}
      layout="vertical"
    >
      <Form.Item
        name="model"
        label="Select Model"
        initialValue="openai"
      >
        <Select
          value={selectedModel}
          onChange={setSelectedModel}
          style={{ width: '100%' }}
        >
          <Select.Option value="openai">OpenAI</Select.Option>
          <Select.Option value="gemini">Gemini</Select.Option>
          <Select.Option value="openrouter">OpenRouter</Select.Option>
        </Select>
      </Form.Item>

      <Form.Item
        name="keywords"
        rules={[{ required: true, message: 'Please enter keywords' }]}
      >
        <TextArea
          placeholder="Enter keywords (one per line)
Example:
seo training institute
digital marketing course
learn seo online
..."
          rows={10}
          style={{ fontFamily: 'monospace' }}
        />
      </Form.Item>

      <Form.Item>
        <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '7px' }}>
          <Button onClick={handleCancel}>Cancel</Button>
          <Button 
            type="primary" 
            htmlType="submit"
            loading={loading}
            style={{ backgroundColor: "#1677ff" }}
          >
            Add Keywords
          </Button>
        </div>
      </Form.Item>
    </Form>
  </Modal>
{/* Modal */}
    </>
  );
};

export default KeywordDetails;
