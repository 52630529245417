import React, { useState, useEffect, useRef, useCallback, useContext } from "react";
import { KeywordContext } from "../auth/KeywordContext";
import { SVGFlag } from "use-flags";
import { Link, useNavigate } from "react-router-dom";
import { Checkbox, } from 'antd';
// import Skeleton from "react-loading-skeleton";


import BarSide2 from "../components/BarSide2";
import { FiSearch } from "react-icons/fi";
import { BsCaretRight } from "react-icons/bs";

import { Button, Form, Input, Row, Col, Tabs, Select,Typography, Spin,  Modal ,Tooltip   } from 'antd';
import { AiOutlinePlus } from "react-icons/ai";


import "react-loading-skeleton/dist/skeleton.css";

import axios from "../components/Axios";
// import DataTable from "../components/DataTable";
import DataTable from "react-data-table-component";
import Creatableinput from "../components/Creatableinput";
import { useData } from "../auth/DataContext";
import { HomeOutlined, GlobalOutlined, ProfileOutlined, InfoCircleOutlined, LoadingOutlined, UploadOutlined , PlusOutlined    } from '@ant-design/icons';
// import { Tabs } from 'antd';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function KeywordResearch() {
  const {  updateKeywordData  } = useContext(KeywordContext); // Use the context
  const navigate = useNavigate();
  const { isDataFetched, setIsDataFetched } = useData();
  const [activeTab, setActiveTab] = useState("1");

  const [keyword, setKeyword] = useState("");
  const [site, setSite] = useState("");
  const [url, setUrl] = useState("");
  const [language, setLanguage] = useState(
    languages.find((lang) => lang.label === "English").langcriteriaid
  );
  const [country, setCountry] = useState(2840);
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");
  const [show, setShow] = useState(false);
  const [toastMessage, setToastMessage] = useState("");
  const [toastType, setToastType] = useState("success");
  const [data, setData] = useState(null);
  // const [searchTerm, setSearchTerm] = useState('');
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [tagsList, setTagsList] = useState([]); // State to hold the tags
  // const [documentId, setDocumentId] = useState('');
  // const [selectedDocumentId, setSelectedDocumentId] = useState(null);
  const [selectedDocumentIds, setSelectedDocumentIds] = useState([]);
  const [selectedfortoggle, setSelectedfortoggle] = useState([]);
  const [toggleClearRows, setToggleClearRows] = useState(false);

  const [showModal, setShowModal] = useState(false);
  const [selectedTags, setSelectedTags] = useState([]);
  const [lastClickedTag, setLastClickedTag] = useState(null);
  const [showOriginalData, setShowOriginalData] = useState(true);

  const isFetchingKeywords = useRef(false);
  const isFetchingTags = useRef(false);
  const [selectedRadio, setSelectedRadio] = useState("keyword");
  const handleRadioChange = (event) => {
    setSelectedRadio(event.target.id);
  };
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [textareaKeywords, setTextareaKeywords] = useState([]);
  const [projectName, setProjectName] = useState("");
  const [textareaValue, setTextareaValue] = useState(""); // State to hold the textarea value
// New Fetch Functions
// console.log(selectedDocumentIds, "selectedDocumentIds New 2024")
//
const fetchAllKeywordToggleArchive = async () => {
  const timestamp = new Date().getTime(); // Current timestamp
  const loadingKeywordId = toast.loading("Keywords Loading...", { theme: "light" });

  try {
    setLoading(true);
    const response = await axios.get(`/api/userkeywords/all/${userData._id}?_=${timestamp}`);
    setData(response.data);
    toast.dismiss(loadingKeywordId);
    console.log(response.data);
  } catch (err) {
   
    toast.dismiss(loadingKeywordId);
    toast.error(`Error: ${err.response.data.message || "Error occurred"}`, {
      position: "top-right",
      autoClose: 30000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
    

    setShow(true);
  } finally {
    setLoading(false);
    isFetchingKeywords.current = false;
  }
}

function showToastMessage(condition) {
  toast.error(condition ? "Please select less than 35 keywords." : "Please select the keywords in the checkbox that you want to archive", {
      position: "top-right",
      autoClose: 10000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
  });
}

const updateToggleArchive = async () => {

  // if (selectedfortoggle.length == 0) {
  //   toast.error("Please select the keywords in the checkbox that you want to archive", {
  //     position: "top-right",
  //     autoClose:  10000,
  //     hideProgressBar: false,
  //     closeOnClick: true,
  //     pauseOnHover: true,
  //     draggable: true,
  //     theme: "light",
  //   });
  
  //   return;
  // }

  if (selectedfortoggle.length == 0 || selectedfortoggle.length > 35) {
    showToastMessage(selectedfortoggle.length > 35);
    return;
}

  const loadingToastId = toast.loading("Updating Keywords Archive Status...", { theme: "light" });

  try {
    // Set the loading state to true before the request
    setLoading(true); // setLoading should update the component's state to show loading feedback

    // Prepare the request payload
    const payload = {
      userId: userData._id,
      keywordIds: selectedfortoggle,
    };

    // Perform the POST request to toggle the archive status
    const response = await axios.post('/api/toggleArchiveStatus', payload);

    // Update the local data with the response, if needed
    // setData(response.data); // Assuming you have a setData function to update your component's state

    // Dismiss the loading toast and show a success message
    toast.dismiss(loadingToastId);
    toast.success(`${response.data.message}`, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
    });

    setData(response.data.userKeywords); // Log or handle the updated keywords as needed
    setSelectedfortoggle([]); // Clear selected for toggle
      setToggleClearRows(!toggleClearRows);
  } catch (error) {
    // Dismiss the loading toast and show an error message
    toast.dismiss(loadingToastId);
    toast.error(`Error: ${error.response?.data.error || "Error occurred while updating archive status"}`, {
      position: "top-right",
      autoClose: 30000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      theme: "light",
    });

    console.error('Error toggling archive status:', error);
  } finally {
    // Set the loading state to false after the request is completed
    setLoading(false); // Reset the loading state in your component
  }
};

//
const fetchUserKeywords = useCallback(async (userId) => {
  if (!isFetchingKeywords.current) {
    isFetchingKeywords.current = true;

    const timestamp = new Date().getTime(); // Current timestamp
    const loadingKeywordId = toast.loading("Keywords Loading...", { theme: "light" });

    try {
      setLoading(true);
      const response = await axios.get(`/api/userkeywords/${userId}?_=${timestamp}`);
      setData(response.data);
      toast.dismiss(loadingKeywordId);
      console.log(response.data);
    } catch (err) {
     
      toast.dismiss(loadingKeywordId);
      toast.error(`Error: ${err.response.data.message || "Error occurred"}`, {
        position: "top-right",
        autoClose: 30000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      

      setShow(true);
    } finally {
      setLoading(false);
      isFetchingKeywords.current = false;
    }
  }
}, []);

const fetchAllTags = useCallback(async (userId) => {
  if (!isFetchingTags.current) {
    isFetchingTags.current = true;

    setLoading(true);
    const timestamp = new Date().getTime(); // Current timestamp
    try {
      const response = await axios.get(
        `/api/fetchAllTags?userId=${userId}&_=${timestamp}`
      );
      setTagsList(response.data.tags); // Assuming you have a state variable 'setTags' for storing tags
      console.log("Tags fetched:", response.data.tags);
      setLoading(false);
    } catch (err) {
      setErrorMessage(err.response.data.message || "Error occurred");
      setToastMessage(`Error: ${errorMessage}`);
      setToastType("danger");
      setShow(true);
      // setLoading(false)
    } finally {
      setLoading(false);
      isFetchingTags.current = false;
    }
  }
}, []);



// // Initial fetch
// useEffect(() => {
//   fetchUserKeywords(page, perPage);
// }, []);

  // New Fetch Functions
  const handleTextareaChange = (e) => {
    const { value } = e.target; // Get the current value of the textarea
    setTextareaValue(value); // Update the textarea value state
    const lines = value.split('\n').filter(line => line.trim() !== ''); // Split by new line and filter out empty lines
    if (lines.length <= 2000) { // Check to ensure no more than 2000 keywords
      setTextareaKeywords(lines);
    }
  };
  const showModalNew = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };
  
  // commenthing both useCallback 
  
  // const fetchUserKeywords = useCallback(async (userId) => {
  //   if (!isFetchingKeywords.current) {
  //     isFetchingKeywords.current = true;

  //     const timestamp = new Date().getTime(); // Current timestamp
  //     const loadingKeywordId = toast.loading("Keywords Loading...", { theme: "light" });

  //     try {
  //       setLoading(true);
  //       const response = await axios.get(
  //         `/api/userkeywords/${userId}?_=${timestamp}`
  //       );
  //       setData(response.data);
  //       // toast.update(loadingKeywordId, { render: "Keywords added successfully", type: "success", autoClose: 3000 });
  //       toast.dismiss(loadingKeywordId);
  //       console.log(response.data);
  //     } catch (err) {
  //       // setErrorMessage(err.response.data.message || "Error occurred");
  //       // setToastMessage(`Error: ${errorMessage}`);
  //       // setToastType("danger");
  //       toast.dismiss(loadingKeywordId);
  //       toast.error(`Error: ${err.response.data.message || "Error occurred"}`, {
  //         position: "top-right",
  //         autoClose: 30000,
  //         hideProgressBar: false,
  //         closeOnClick: true,
  //         pauseOnHover: true,
  //         draggable: true,
  //         progress: undefined,
  //         theme: "light",
  //       });
        

  //       setShow(true);
  //     } finally {
  //       setLoading(false);
  //       isFetchingKeywords.current = false;
  //     }
  //   }
  // }, []);

  // const fetchAllTags = useCallback(async (userId) => {
  //   if (!isFetchingTags.current) {
  //     isFetchingTags.current = true;

  //     setLoading(true);
  //     const timestamp = new Date().getTime(); // Current timestamp
  //     try {
  //       const response = await axios.get(
  //         `/api/fetchAllTags?userId=${userId}&_=${timestamp}`
  //       );
  //       setTagsList(response.data.tags); // Assuming you have a state variable 'setTags' for storing tags
  //       console.log("Tags fetched:", response.data.tags);
  //       setLoading(false);
  //     } catch (err) {
  //       setErrorMessage(err.response.data.message || "Error occurred");
  //       setToastMessage(`Error: ${errorMessage}`);
  //       setToastType("danger");
  //       setShow(true);
  //       // setLoading(false)
  //     } finally {
  //       setLoading(false);
  //       isFetchingTags.current = false;
  //     }
  //   }
  // }, []);

  const userDataString = localStorage.getItem("userData");
  const userData = userDataString ? JSON.parse(userDataString) : null;

  // useEffect(() => {

  //   if (userData?._id) {
  //     fetchUserKeywords(userData._id).then(() => {
  //       // Call fetchAllTags after fetchUserKeywords is successful
  //       fetchAllTags(userData._id);
  //   });

  //   }

  // }, []);

  // Comment For design

  useEffect(() => {
    const fetchData = async () => {
      if (!isDataFetched && userData?._id) {
        try {
          await fetchUserKeywords(userData._id)
            .then(() => fetchAllTags(userData._id));
        } catch (error) {
          console.error('Error in fetching data:', error);
        } finally {
          setIsDataFetched(true);
        }
      }
    };

    fetchData();
  }, [userData._id, isDataFetched, fetchUserKeywords, fetchAllTags])
 
  useEffect(() => {
    return () => {
      setIsDataFetched(false);
    };
  }, []);



  // commenthing both useCallback 
   const handleSubmitByUpload = async (e) => {
    e.preventDefault();

    if (textareaKeywords.length === 0) {
      toast.error('Please add at least one keyword.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
      return; // Stop execution if no keywords are provided
    }

    if (!projectName || projectName.length < 1) {
      toast.error('Please write a project name', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
      return; // Stops the function from continuing
    }
    
    if (textareaKeywords.length > 2000) {
      toast.error('Keywords must be 2000 or fewer.', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "light",
      });
      return; // Early return to stop function execution
    }

    setLoading(true);

    console.log(language, country, textareaKeywords, userData._id, "language, country, textareaKeywords, userData._id");

    try {
      const response = await axios.post("/api/byuploadkeyword", {
        inputValue: textareaKeywords,
        countryCode: country,
        languageCode: language,
        userId: userData._id, // Replace with actual user ID
        projectName,
      });
      console.log(response.data);
      // Display success message
      fetchUserKeywords(userData._id);
      setShow(false);
    } catch (error) {
      // Display error message in the toast
      console.log(error.response.data.message, "error");
      setErrorMessage(error.response.data.message); // Optional Chaining was not working
      toast.error(`Error: ${error.response.data.message}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      
    } finally {
      setLoading(false); // Turn off loading state
      setKeyword("");
    }
  };
 


  const handleSubmit = async (e) => {
    e.preventDefault();

    setLoading(true);

    console.log(language, country, keyword, userData._id, site, url);

    try {
      const response = await axios.post("/api/atomkeywords", {
        inputValue: keyword,
        countryCode: country,
        languageCode: language,
        userId: userData._id, // Replace with actual user ID
        site,
        url
      });
      console.log(response.data);
      // Display success message
      fetchUserKeywords(userData._id);
      setShow(false);
    } catch (error) {
      // Display error message in the toast
      console.log(error.response.data.message, "error");
      setErrorMessage(error.response.data.message); // Optional Chaining was not working
      toast.error(`Error: ${error.response.data.message}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      // setToastMessage(`Error: ${errorMessage}`);
      // setToastType("danger");
      // setShow(true); // Show the toast on error
    } finally {
      setLoading(false); // Turn off loading state
      setKeyword("");
      setSite("");
      setUrl("");
    }
  };

  // const handleLanguageChange = (selectedOption) => {
  //   setLanguage(selectedOption.langcriteriaid);
  // };
  const handleLanguageChange = (value) => {
    console.log(value, "Selected langcriteriaid");
    setLanguage(value);
  };
  

  // const handleCountryChange = (selectedOption) => {
  //   console.log(selectedOption, "selectedOption new")
  //   setCountry(selectedOption.criteriaid);
  // };
  const handleCountryChange = (value) => {
    console.log(value, "Selected criteriaid");
    setCountry(value);
  };
  
  const filterOption = (input, option) =>
  option.label.toLowerCase().includes(input.toLowerCase());


  // const findCountryCode = (countryCode, countries) => {
  //   const country = countries.find((c) => c.criteriaid === countryCode);
  //   return country ? country.code : null;
  // };
  const findCountryCode = (countryCode, countries) => {
    console.log('Searching for:', countryCode);
    const country = countries.find(c => c.criteriaid === countryCode);
    console.log('Found country:', country);
    return country ? country.code : null;
  };
  

  const findLanguageAbbreviation = (langcriteriaid) => {
    return languageAbbreviations[langcriteriaid] || null;
  };

  console.log(selectedDocumentIds.length, "selectedDocumentIds.length")

  const customStyles = {
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused ? "#66afe9" : "white", // Bootstrap-like focus color
      color: state.isFocused ? "white" : "grey",
      // Other styles can be customized here as needed
    }),
    // ... other custom styles if needed
  };

 

  useEffect(() => {
    console.log("Current selectedDocumentId: ", selectedDocumentIds);
    // Actions that depend on the updated selectedDocumentId
  }, [selectedDocumentIds]);


  const handleChange = ({ selectedRows }) => {
    // You can set state or dispatch with something like Redux so we can use the retrieved data
    console.log('Selected Rows: ', selectedRows);
    setSelectedDocumentIds(selectedRows)
    setSelectedfortoggle(selectedRows)
  };
  // const onCheckboxChange = (isChecked, documentId) => {
  //   setIsCheckboxChecked(isChecked);
  //   setSelectedDocumentIds(prevIds => {
  //     if (isChecked) {
  //       // Add ID to the array if checked
  //       return [...prevIds, documentId];
  //     } else {
  //       // Remove ID from the array if unchecked
  //       return prevIds.filter(id => id !== documentId);
  //     }
  //   });
  //   setSelectedfortoggle(prevIds => {
  //     if (isChecked) {
  //       // Add ID to the array if checked
  //       return [...prevIds, documentId];
  //     } else {
  //       // Remove ID from the array if unchecked
  //       return prevIds.filter(id => id !== documentId);
  //     }
  //   });
  // }

  
 

  const handleTagsSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setShowModal(false);

    if (selectedTags.length === 0) {
      setLoading(false);
      // setToastMessage("Error: Please add Tag");
      // setToastType("danger");
      // setShow(true);
      toast.error('Error: Please add Tag', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      
      return; // Exit the function early
    }

    console.log(selectedDocumentIds, selectedTags, userData._id);
    try {
      const response = await axios.post("/api/addtag", {
        documentIds: selectedDocumentIds,
        tags: selectedTags,
        userId: userData._id, // Use the selectedTags state here
      });
      setTagsList(response.data.tags);
      console.log(response.data);

      toast.success('tag added successfully', {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      

      setLoading(false);
      // setShow(false);
    } catch (error) {
      setLoading(false);
      // setErrorMessage(error.response.data.message);
      toast.error(`Error: ${error.response.data.message}`, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      console.log(error, "error");
      // setToastMessage(`Error: ${errorMessage}`);
      // setToastType("danger");
      // setShow(true);
    }
  };

  const handleButtonModal = () => {
    if (selectedDocumentIds && selectedDocumentIds.length > 0) {
      setShowModal(true);
    }    
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const handleTagChange = (newTags) => {
    // Assuming newTags is an array of objects with 'label' and 'value'
    setSelectedTags(newTags.map((tag) => tag.value)); // Store only the values in the state
    console.log(newTags, "NewTags");
  };

  const fetchDocumentsByTag = async (tagValue) => {
    const userId = userData._id;
    try {
      const response = await axios.get(
        `/api/fetchByTag?tag=${tagValue}&userId=${userId}`
      );
      console.log("Documents fetched:", response.data.documents);
      setData(response.data.documents);
      // Here you can set state or perform other actions with the fetched documents
    } catch (error) {
      console.error(
        "Error fetching documents:",
        error.response?.data?.message || error.message
      );
      // Implement your error handling logic here
    }
  };

  // const handleTagButtonClick = (tagValue) => {
  //   console.log("Tag clicked:", tagValue);
  //   fetchDocumentsByTag(tagValue)
  //   // Implement logic to fetch specific object based on tagValue
  // };

  const handleTagButtonClick = (tagValue) => {
    console.log("Tag clicked:", tagValue);

    // Check if the same tag is clicked again
    if (tagValue === lastClickedTag) {
      // Toggle between showing original data and tag-specific data
      if (showOriginalData) {
        // Fetch documents by tag
        fetchDocumentsByTag(tagValue);
      } else {
        // Fetch the original user keywords
        fetchUserKeywords(userData._id); // Replace 'userId' with the actual user ID
      }
      setShowOriginalData(!showOriginalData); // Toggle the state
    } else {
      // If a different tag is clicked, always fetch documents by tag
      fetchDocumentsByTag(tagValue);
      setLastClickedTag(tagValue);
      setShowOriginalData(false); // Set to show tag-specific data
    }
  };
  const getTabClass = (key) => {
    return activeTab === key ? 'tab-selected' : 'tab-unselected';
  };
 
  const colors = [
    "rgb(230, 231, 224)", "rgb(202, 209, 202)", "rgb(215, 213, 200)", "rgb(234, 235, 230)",
    "rgb(216, 212, 206)", "rgb(231, 227, 217)", "rgb(220, 219, 214)",
    "rgb(186, 192, 190)", "rgb(207, 209, 201)", 
  ];


  const handleNavigate = (item) => {
    console.log(item, "item from handleNavigate")
    const updatedItemData = {
      ...item,
      keywordData: item.keywordData.map((keywordItem, index) => ({
        ...keywordItem,
        id: keywordItem.id || `uuid-${index}`
      }))
    };

    // Slice the keywordData array to contain only the first 450 objects
    const slicedKeywordData = updatedItemData.keywordData.slice(0, 150);

    // Update the item with sliced keywordData
    const itemWithSlicedKeywordData = {
        ...updatedItemData,
        keywordData: slicedKeywordData
    };

    // Store the item with sliced keywordData in local storage
    localStorage.setItem('temporaryKeywordData', JSON.stringify(itemWithSlicedKeywordData));

   
    
console.log(updatedItemData, "updatedItemData from handleNavigate")
    updateKeywordData(updatedItemData);
    // localStorage.setItem('temporaryKeywordData', JSON.stringify(updatedItemData));

    let path = item.mainSeedKeyword;
   // Check if the path is a full URL
   if (path.startsWith('http://') || path.startsWith('https://')) {
    // Create a URL object
    const url = new URL(path);
    // Extract hostname (domain name)
    path = url.hostname;

    // Remove 'www.' if present
    if (path.startsWith('www.')) {
        path = path.substring(4);
    }
}

    // setKeywordData(item); // Setting the item in context
    navigate(`/keyword-detail/${path}`);
  };

  //   const handleCheckboxChange = (e, documentId) => {
  //   const isChecked = e.target.checked;
  //   console.log("isChecked:", isChecked, "documentId:", documentId);
  //   // onCheckboxChange(isChecked, documentId);
  // };

  const columns = [
    // {
    //   name: 'Tag',
    //   cell: row => (
    //     <Checkbox 
    //       onChange={e => handleCheckboxChange(e, row._id)}
    //     />
    //   ),
    //   ignoreRowClick: true,
    //   allowOverflow: true,
    //   button: true,
    //   width: '50px',
    //   center: true,
    // },
    {
      name: 'Name',
      cell: row => (
      
          <Link onClick={() => handleNavigate(row)} className="keyword-link">
            {row.mainSeedKeyword}
          </Link>
        
      ),
      sortable: true,
      wrap: true,
    },
    {
      name: 'Country',
      cell: row => (
        <SVGFlag
          country={findCountryCode(row.countryCode, countries)?.toLowerCase()}
          flagWidth="33"
        />
      ),
      center: true,
      width: '100px',
    },
    {
      name: 'Language',
      selector: row => findLanguageAbbreviation(row.languageCode),
      center: true,
      width: '90px',
    },
    {
      name: 'ANALYZE',
      cell: row => row.keywordData.reduce((total, keywordItem) => total + (keywordItem.items && keywordItem.items.length > 0 ? 1 : 0), 0),
      center: true,
      width: '90px',
    },
    {
      name: 'Total',
      selector: row => row.keywordData ? row.keywordData.length : 0,
      center: true,
      width: '80px',
    },
    {
      name: 'Created',
      selector: row => new Date(row.createdAt).toLocaleDateString('en-US', {
        month: 'short',
        day: 'numeric',
        year: 'numeric',
      }),
      center: true,
      sortable: true,
      width: '120px',
    },{
      name: '',
      cell: row => (
        <div>
      {row.isArchived ? (
        <span class="material-symbols-outlined">
          unarchive
        </span>
      ) : (
        <div></div>
      )}
    </div>
      ),
      button: true,
      width: '20px',
      center: true,
    },
    {
      name: '',
      cell: row => (
        <div className="d-flex justify-center deleteed" onClick={() => handleDelete(row.user, row.mainSeedKeyword)}>
          <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
            <path d="m376-300 104-104 104 104 56-56-104-104 104-104-56-56-104 104-104-104-56 56 104 104-104 104 56 56Zm-96 180q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520Zm-400 0v520-520Z" fill="red" />
          </svg>
        </div>
      ),
      ignoreRowClick: true,
      allowOverflow: true,
      button: true,
      width: '60px',
      center: true,
    },
  ];

  const handleDelete = async (userId, mainSeedKeyword) => {
    try {
      const response = await axios.delete("/api/deleteKeyword", {
        data: { userId, mainSeedKeyword },
      });

      if (response.status === 200) {
        console.log("Keyword deleted successfully");
        // Additional logic to update UI accordingly
        fetchUserKeywords(userData._id);
      } else {
        console.error("Error deleting keyword");
      }
    } catch (error) {
      console.error(
        "Error:",
        error.response ? error.response.data : error.message
      );
    }
  };

  return (
    <>
    <ToastContainer
position="top-right"
autoClose={3000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
      <main className="d-flex flex-nowrap">
        {/* <BarSide /> */}
        {/* <ProSideBar /> */}
        <BarSide2 />
        <div className="d-flex flex-column flex-grow-1 p-4 ">
          <div style={{ overflowY: "auto", height: "calc(100vh - 24px)" }}>
            <div className="container mt-3">
              <div className="row">
                <div className="col-md-12 ">
                  {/*  */}
                  <div className="ra-home bg-white shadow-sm">
                    <div className="d-flex justify-content-between">
                     
                      <div class="user-name usermain">Keyword Research</div>
                      {/* <div><BsCaretRight /> VIDEO TUTORIAL</div> */}
                      <Row align="middle" className="tutDiv">
      <Col>
        <BsCaretRight style={{ verticalAlign: 'middle' }} className="tutColor" />
      </Col>
      <Col>
        <Typography.Text className="tutColor tutText">VIDEO TUTORIAL</Typography.Text>
      </Col>
    </Row>

{/* Adding dynamic Toast */}

                      

{/* Adding dynamic Toast */}
                    </div>
                    {/* <span style={{ color: "grey", fontSize: "95%" }}>
                      {" "}
                      Find longtail, high-intent keywords and analyse them to
                      find weakspots
                    </span> */}
                    {/* <p className="keywordResearchmainparagraph">
                     
                    </p> */}
                    {/* Form Start */}
                    <div className="keyword-discovery-container">
      <Typography.Paragraph className="keyword-description">
      you can discover new topics and angles, unearthing numerous relevant keywords connected to your main term. Once you have your initial list of keywords, our Jaro Winkler distance clustering algorithm allows you to categorize them efficiently at the click of a button. Additionally, you can analyze each keyword in-depth, examining metrics like SEO Difficulty, Off-Page Difficulty, On-Page Difficulty, Domain Authority (DA), Page Authority (PA), PageRank, Keyword Golden Ratio (KGR), All in Title and search volume. Transforming raw keyword data into valuable insights takes only minutes.
      </Typography.Paragraph>
    </div>


                  
                      <div className="row my-3 pb-4">
                        {/* Tab  */}

                        <div className="col-md-12 ">
                         <Tabs defaultActiveKey="1" onTabClick={(key) => setActiveTab(key)}>
            <Tabs.TabPane tab={ <span className={getTabClass("1")}> <ProfileOutlined /> Search by Keyword </span> } key="1" > 
            <Form layout="vertical" >
      <Row gutter={16} align="bottom">
        <Col span={11}> {/* Adjust the span value as needed for desired width */}
          <Form.Item
            label="Seed Keyword"
            tooltip={{ title: 'Enter a keyword or keyword combination and get keyword ideas around the seed keyword', icon: <InfoCircleOutlined /> }}
          >
            <Input placeholder="e.g. Google" size="large" className="custom-input"  onChange={(e) => setKeyword(e.target.value)} />
          </Form.Item>
        </Col>
        <Col span={5}>
        <Form.Item
            label="Country"
          >
            <Select
              showSearch
              size="large"
              optionFilterProp="label"
              filterOption={filterOption}
              options={countries.map(country => ({
                value: country.criteriaid,
                label: country.label,
              }))}
              onChange={handleCountryChange}
              value={country}
              // defaultValue={country}
              // defaultValue={countries.find(c => c.criteriaid === country)}

            >
              
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
        <Form.Item
            label="Language"
          >
             <Select
        // className="custom-select"
        size="large"
        showSearch
        optionFilterProp="label"
        filterOption={(input, option) =>
          option.label.toLowerCase().includes(input.toLowerCase())
        }
        value={language}
        onChange={handleLanguageChange}
        options={languages.map(lang => ({
          value: lang.langcriteriaid,
          label: lang.label,
        }))}
      />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item>

          {
  loading ? (
    <Button type="primary" className="btn btn-info w-100 btn-sm btn-info-customsearch text-white">
      <Spin indicator={<LoadingOutlined style={{ fontSize: 30, color: "#fff" }} spin />} />
    </Button>
  ) : (
    <button type="submit" onClick={handleSubmit} className="btn btn-info w-100 btn-sm btn-info-customsearch text-white">
      <span className="icon-text-container">
        <span className="search-icon"><FiSearch /></span> 
        <span>Search</span>
      </span>
    </button>
  )
}



          </Form.Item>
        </Col>
      </Row>
     
    </Form>
            </Tabs.TabPane>
            {/* Tab 2nd */}
            <Tabs.TabPane tab={ <span className={getTabClass("2")}> <HomeOutlined /> Search by Website </span> } key="2" > 
            <Form layout="vertical" onSubmit={handleSubmit}>
      <Row gutter={16} align="bottom">
        <Col span={11}> {/* Adjust the span value as needed for desired width */}
          <Form.Item
            label="Website"
            tooltip={{ title: 'Enter a domain and view all keywords the Website ranks for', icon: <InfoCircleOutlined /> }}
          >
            <Input placeholder="google.com" size="large" className="custom-input" onChange={(e) => setSite(e.target.value)} />
          </Form.Item>
        </Col>
        <Col span={5}>
        <Form.Item
            label="Country"
          >
            <Select
             
              // className="custom-select"
              showSearch
              size="large"
              optionFilterProp="label"
              filterOption={filterOption}
              options={countries.map(country => ({
                value: country.criteriaid,
                label: country.label,
              }))}
              onChange={handleCountryChange}
              defaultValue={country}
              // defaultValue={countries.find(c => c.criteriaid === country)}

            >
              
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
        <Form.Item
            label="Language"
          >
             <Select
        // className="custom-select"
        size="large"
        showSearch
        optionFilterProp="label"
        filterOption={(input, option) =>
          option.label.toLowerCase().includes(input.toLowerCase())
        }
        defaultValue={language}
        onChange={handleLanguageChange}
        options={languages.map(lang => ({
          value: lang.langcriteriaid,
          label: lang.label,
        }))}
      />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item>
         
{
  loading ? (
    <Button type="primary" className="btn btn-info w-100 btn-sm btn-info-customsearch text-white">
      <Spin indicator={<LoadingOutlined style={{ fontSize: 30, color: "#fff" }} spin />} />
    </Button>
  ) : (
    <button type="submit" onClick={handleSubmit} className="btn btn-info w-100 btn-sm btn-info-customsearch text-white">
      <span className="icon-text-container">
        <span className="search-icon"><FiSearch /></span> 
        <span>Search</span>
      </span>
    </button>
  )
}

          </Form.Item>
        </Col>
      </Row>
     
    </Form>
            </Tabs.TabPane>
            {/* Tab 3rd */}
            <Tabs.TabPane tab={ <span className={getTabClass("3")}> <GlobalOutlined /> Search by Page </span> } key="3" > 
            
            <Form layout="vertical" onSubmit={handleSubmit}>
      <Row gutter={16} align="bottom">
        <Col span={11}> {/* Adjust the span value as needed for desired width */}
          <Form.Item
            label="Web Page"
            tooltip={{ title: 'Enter a URL and view all keywords the URL ranks for', icon: <InfoCircleOutlined /> }}
          >
            <Input placeholder="google.com/abc" size="large" className="custom-input" onChange={(e) => setUrl(e.target.value)} />
          </Form.Item>
        </Col>
        <Col span={5}>
        <Form.Item
            label="Country"
          >
            <Select
             
              // className="custom-select"
              showSearch
              size="large"
              optionFilterProp="label"
              filterOption={filterOption}
              options={countries.map(country => ({
                value: country.criteriaid,
                label: country.label,
              }))}
              onChange={handleCountryChange}
              defaultValue={country}
              // defaultValue={countries.find(c => c.criteriaid === country)}

            >
              
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
        <Form.Item
            label="Language"
          >
             <Select
        // className="custom-select"
        size="large"
        showSearch
        optionFilterProp="label"
        filterOption={(input, option) =>
          option.label.toLowerCase().includes(input.toLowerCase())
        }
        defaultValue={language}
        onChange={handleLanguageChange}
        options={languages.map(lang => ({
          value: lang.langcriteriaid,
          label: lang.label,
        }))}
      />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item>

{
  loading ? (
    <Button type="primary" className="btn btn-info w-100 btn-sm btn-info-customsearch text-white">
      <Spin indicator={<LoadingOutlined style={{ fontSize: 30, color: "#fff" }} spin />} />
    </Button>
  ) : (
    <button type="submit" onClick={handleSubmit} className="btn btn-info w-100 btn-sm btn-info-customsearch text-white">
      <span className="icon-text-container">
        <span className="search-icon"><FiSearch /></span> 
        <span>Search</span>
      </span>
    </button>
  )
}

          </Form.Item>
        </Col>
      </Row>
     
    </Form>
             </Tabs.TabPane>
            {/* Tab 4th */}

            <Tabs.TabPane tab={ <span className={getTabClass("4")}> <UploadOutlined /> Add Your Own Keywords </span> } key="4" > 
            <Form layout="vertical" onSubmit={handleSubmitByUpload}>
      <Row gutter={16} align="bottom">
        <Col span={7}> 
        <Button  onClick={showModalNew} className="w-100 mb-4" size="large" icon={<PlusOutlined />}>
        Add Your Keywords
      </Button>
      <Modal  open={isModalOpen}
      footer={[
        // Define the buttons in the footer
        <Button key="back" onClick={handleCancel}>
          Close
        </Button>,
        <Button key="submit"  onClick={handleOk}>
          Save
        </Button>,
      ]}
      >
         <Form.Item>
         <Input.TextArea 
            rows={7} 
            placeholder="One keyword per row"
            value={textareaValue} // Bind the textarea value to state
            onChange={handleTextareaChange} // Use the more descriptively named function
          />
        </Form.Item>
        <p>Maximum 2000 keywords can be added at a time.</p>
      </Modal>
        </Col>
        
<Col span={4}>
<Form.Item
            label="Project Name"
          >
            <Input placeholder="Project Name" value={projectName} size="large" onChange={(e) => setProjectName(e.target.value)} required />
          </Form.Item>
</Col>

        <Col span={5}>
        <Form.Item
            label="Country"
          >
            <Select
             
              // className="custom-select"
              showSearch
              size="large"
              optionFilterProp="label"
              filterOption={filterOption}
              options={countries.map(country => ({
                value: country.criteriaid,
                label: country.label,
              }))}
              onChange={handleCountryChange}
              defaultValue={country}
              // defaultValue={countries.find(c => c.criteriaid === country)}

            >
              
            </Select>
          </Form.Item>
        </Col>
        <Col span={5}>
        <Form.Item
            label="Language"
          >
             <Select
        // className="custom-select"
        size="large"
        showSearch
        optionFilterProp="label"
        filterOption={(input, option) =>
          option.label.toLowerCase().includes(input.toLowerCase())
        }
        defaultValue={language}
        onChange={handleLanguageChange}
        options={languages.map(lang => ({
          value: lang.langcriteriaid,
          label: lang.label,
        }))}
      />
          </Form.Item>
        </Col>
        <Col span={3}>
          <Form.Item>

{
  loading ? (
    <Button type="primary" className="btn btn-info w-100 btn-sm btn-info-customsearch text-white">
      <Spin indicator={<LoadingOutlined style={{ fontSize: 30, color: "#fff" }} spin />} />
    </Button>
  ) : (
    <button type="submit" onClick={handleSubmitByUpload} className="btn btn-info w-100 btn-sm btn-info-customsearch text-white">
      <span className="icon-text-container">
        <span className="search-icon"><FiSearch /></span> 
        <span>Search</span>
      </span>
    </button>
  )
}

          </Form.Item>
        </Col>
      </Row>
     
    </Form>
          
             </Tabs.TabPane>

  </Tabs>
                        </div>

                        {/* Tab  */}
                       
                      </div>
                    
                    {/* Form End */}
                  </div>


                 
{data && <>
     


<div className="tag-container mt-5 mb-6 d-flex">
 

  {/* Dynamic tags */}
  {tagsList.map((tag, index) => (
    <div
      key={index}
      className="tag-item"
      style={{ backgroundColor: colors[(index + 1) % colors.length], marginBottom: '10px',marginLeft: '5px', paddingLeft: '10px', paddingRight: '10px' }}
      onClick={() => handleTagButtonClick(tag)}
    >
      <span className="tag-label"><span className="tag-hash">#</span>{tag}</span>

    </div>
  ))}


 
  {selectedDocumentIds.length > 0 && <div className="tag-add-button"  onClick={handleButtonModal}><AiOutlinePlus /></div> }
  <Tooltip title="Are your projects loading slow? Try archiving some keyword projects to speed up the server load. You can make them active again at any time. " color="#efefef" overlayClassName="custom-tooltip-wide" placement="topLeft" >
          
        <div className="tag-add-button1"  onClick={updateToggleArchive}>Move to Archive</div>  </Tooltip>
  <div className="tag-add-button2"  onClick={updateToggleArchive}>Move to Active</div> 
  <div className="tag-add-button3"  onClick={fetchAllKeywordToggleArchive}>Show All Keywords</div> 



</div>


{/* Converting it in Antd */}
<Modal
      // title="" // Title is set here in Ant Design, not in Modal.Body
      centered
      visible={showModal} // Controls the visibility of the modal
      onCancel={handleCloseModal} // Handles the modal close action
      footer={[
        // Define the buttons in the footer
        <Button key="back" onClick={handleCloseModal}>
          Close
        </Button>,
        <Button key="submit"  onClick={handleTagsSubmit}>
          Save
        </Button>,
      ]}
    >
     <h5 className="text-center mt-5">Tag my report/s</h5>
    <p className="text-center mb-3">What tag would you like to add tag to report?</p>
      <Creatableinput onInputChange={handleTagChange} />
    </Modal>

                  <div className="ra-table mt-2">
                    {/* <DataTable
                      data={data}
                      loading={loading}
                      findCountryCode={findCountryCode}
                      countries={countries}
                      findLanguageAbbreviation={findLanguageAbbreviation}
                      fetchUserKeywords={fetchUserKeywords}
                      userData={userData._id}
                      onCheckboxChange={onCheckboxChange}
                    /> */}

<DataTable
  columns={columns}
  data={data}
  customStyles={{
    headCells: {
      style: {
        backgroundColor: '#fafafa', 
      },
    },
  }}
  responsive={true}
  highlightOnHover
  selectableRows
  onSelectedRowsChange={handleChange}
  clearSelectedRows={toggleClearRows}

/>

                  </div>

</>}


                </div>

              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default KeywordResearch;

const languages = [
  {
    langcriteriaid: 1000,
    label: "English",
  },
  {
    langcriteriaid: 1019,
    label: "Arabic",
  },
  {
    langcriteriaid: 1056,
    label: "Bengali",
  },
  {
    langcriteriaid: 1020,
    label: "Bulgarian",
  },
  {
    langcriteriaid: 1038,
    label: "Catalan",
  },
  {
    langcriteriaid: 1017,
    label: "Chinese Simplified",
  },
  {
    langcriteriaid: 1018,
    label: "Chinese Traditional",
  },
  {
    langcriteriaid: 1039,
    label: "Croatian",
  },
  {
    langcriteriaid: 1021,
    label: "Czech",
  },
  {
    langcriteriaid: 1009,
    label: "Danish",
  },
  {
    langcriteriaid: 1010,
    label: "Dutch",
  },

  {
    langcriteriaid: 1043,
    label: "Estonian",
  },
  {
    langcriteriaid: 1042,
    label: "Filipino",
  },
  {
    langcriteriaid: 1011,
    label: "Finnish",
  },
  {
    langcriteriaid: 1002,
    label: "French",
  },
  {
    langcriteriaid: 1001,
    label: "German",
  },
  {
    langcriteriaid: 1022,
    label: "Greek",
  },
  {
    langcriteriaid: 1072,
    label: "Gujarati",
  },
  {
    langcriteriaid: 1027,
    label: "Hebrew",
  },
  {
    langcriteriaid: 1023,
    label: "Hindi",
  },
  {
    langcriteriaid: 1024,
    label: "Hungarian",
  },
  {
    langcriteriaid: 1026,
    label: "Icelandic",
  },
  {
    langcriteriaid: 1025,
    label: "Indonesian",
  },
  {
    langcriteriaid: 1004,
    label: "Italian",
  },
  {
    langcriteriaid: 1005,
    label: "Japanese",
  },
  {
    langcriteriaid: 1086,
    label: "Kannada",
  },
  {
    langcriteriaid: 1012,
    label: "Korean",
  },
  {
    langcriteriaid: 1028,
    label: "Latvian",
  },
  {
    langcriteriaid: 1029,
    label: "Lithuanian",
  },
  {
    langcriteriaid: 1102,
    label: "Malay",
  },
  {
    langcriteriaid: 1098,
    label: "Malayalam",
  },
  {
    langcriteriaid: 1101,
    label: "Marathi",
  },
  {
    langcriteriaid: 1013,
    label: "Norwegian",
  },
  {
    langcriteriaid: 1064,
    label: "Persian",
  },
  {
    langcriteriaid: 1030,
    label: "Polish",
  },
  {
    langcriteriaid: 1014,
    label: "Portuguese",
  },
  {
    langcriteriaid: 1032,
    label: "Romanian",
  },
  {
    langcriteriaid: 1031,
    label: "Russian",
  },
  {
    langcriteriaid: 1035,
    label: "Serbian",
  },
  {
    langcriteriaid: 1033,
    label: "Slovak",
  },
  {
    langcriteriaid: 1034,
    label: "Slovenian",
  },
  {
    langcriteriaid: 1003,
    label: "Spanish",
  },
  {
    langcriteriaid: 1015,
    label: "Swedish",
  },
  {
    langcriteriaid: 1130,
    label: "Tamil",
  },
  {
    langcriteriaid: 1131,
    label: "Telugu",
  },
  {
    langcriteriaid: 1044,
    label: "Thai",
  },
  {
    langcriteriaid: 1037,
    label: "Turkish",
  },
  {
    langcriteriaid: 1036,
    label: "Ukrainian",
  },
  {
    langcriteriaid: 1041,
    label: "Urdu",
  },
  {
    langcriteriaid: 1040,
    label: "Vietnamese",
  },
];

const countries = [
  {
    code: "US",
    criteriaid: 2840,
    label: "United States",
  },
  {
    code: "GB",
    criteriaid: 2826,
    label: "United Kingdom",
  },
  {
    code: "AU",
    criteriaid: 2036,
    label: "Australia",
  },
  {
    code: "DE",
    criteriaid: 2276,
    label: "Germany",
  },
  {
    code: "CA",
    criteriaid: 2124,
    label: "Canada",
  },
  {
    code: "AF",
    criteriaid: 2004,
    label: "Afghanistan",
  },
  {
    code: "AL",
    criteriaid: 2008,
    label: "Albania",
  },
  {
    code: "AQ",
    criteriaid: 2010,
    label: "Antarctica",
  },
  {
    code: "DZ",
    criteriaid: 2012,
    label: "Algeria",
  },
  {
    code: "AS",
    criteriaid: 2016,
    label: "American Samoa",
  },
  {
    code: "AD",
    criteriaid: 2020,
    label: "Andorra",
  },
  {
    code: "AO",
    criteriaid: 2024,
    label: "Angola",
  },
  {
    code: "AG",
    criteriaid: 2028,
    label: "Antigua and Barbuda",
  },
  {
    code: "AZ",
    criteriaid: 2031,
    label: "Azerbaijan",
  },
  {
    code: "AR",
    criteriaid: 2032,
    label: "Argentina",
  },

  {
    code: "AT",
    criteriaid: 2040,
    label: "Austria",
  },
  {
    code: "BS",
    criteriaid: 2044,
    label: "The Bahamas",
  },
  {
    code: "BH",
    criteriaid: 2048,
    label: "Bahrain",
  },
  {
    code: "BD",
    criteriaid: 2050,
    label: "Bangladesh",
  },
  {
    code: "AM",
    criteriaid: 2051,
    label: "Armenia",
  },
  {
    code: "BB",
    criteriaid: 2052,
    label: "Barbados",
  },
  {
    code: "BE",
    criteriaid: 2056,
    label: "Belgium",
  },
  {
    code: "BT",
    criteriaid: 2064,
    label: "Bhutan",
  },
  {
    code: "BO",
    criteriaid: 2068,
    label: "Bolivia",
  },
  {
    code: "BA",
    criteriaid: 2070,
    label: "Bosnia and Herzegovina",
  },
  {
    code: "BW",
    criteriaid: 2072,
    label: "Botswana",
  },
  {
    code: "BR",
    criteriaid: 2076,
    label: "Brazil",
  },
  {
    code: "BZ",
    criteriaid: 2084,
    label: "Belize",
  },
  {
    code: "SB",
    criteriaid: 2090,
    label: "Solomon Islands",
  },
  {
    code: "BN",
    criteriaid: 2096,
    label: "Brunei",
  },
  {
    code: "BG",
    criteriaid: 2100,
    label: "Bulgaria",
  },
  {
    code: "MM",
    criteriaid: 2104,
    label: "Myanmar (Burma)",
  },
  {
    code: "BI",
    criteriaid: 2108,
    label: "Burundi",
  },
  {
    code: "BY",
    criteriaid: 2112,
    label: "Belarus",
  },
  {
    code: "KH",
    criteriaid: 2116,
    label: "Cambodia",
  },
  {
    code: "CM",
    criteriaid: 2120,
    label: "Cameroon",
  },

  {
    code: "CV",
    criteriaid: 2132,
    label: "Cape Verde",
  },
  {
    code: "CF",
    criteriaid: 2140,
    label: "Central African Republic",
  },
  {
    code: "LK",
    criteriaid: 2144,
    label: "Sri Lanka",
  },
  {
    code: "TD",
    criteriaid: 2148,
    label: "Chad",
  },
  {
    code: "CL",
    criteriaid: 2152,
    label: "Chile",
  },
  {
    code: "CN",
    criteriaid: 2156,
    label: "China",
  },
  {
    code: "CX",
    criteriaid: 2162,
    label: "Christmas Island",
  },
  {
    code: "CC",
    criteriaid: 2166,
    label: "Cocos (Keeling) Islands",
  },
  {
    code: "CO",
    criteriaid: 2170,
    label: "Colombia",
  },
  {
    code: "KM",
    criteriaid: 2174,
    label: "Comoros",
  },
  {
    code: "CG",
    criteriaid: 2178,
    label: "Republic of the Congo",
  },
  {
    code: "CD",
    criteriaid: 2180,
    label: "Democratic Republic of the Congo",
  },
  {
    code: "CK",
    criteriaid: 2184,
    label: "Cook Islands",
  },
  {
    code: "CR",
    criteriaid: 2188,
    label: "Costa Rica",
  },
  {
    code: "HR",
    criteriaid: 2191,
    label: "Croatia",
  },
  {
    code: "CY",
    criteriaid: 2196,
    label: "Cyprus",
  },
  {
    code: "CZ",
    criteriaid: 2203,
    label: "Czechia",
  },
  {
    code: "BJ",
    criteriaid: 2204,
    label: "Benin",
  },
  {
    code: "DK",
    criteriaid: 2208,
    label: "Denmark",
  },
  {
    code: "DM",
    criteriaid: 2212,
    label: "Dominica",
  },
  {
    code: "DO",
    criteriaid: 2214,
    label: "Dominican Republic",
  },
  {
    code: "EC",
    criteriaid: 2218,
    label: "Ecuador",
  },
  {
    code: "SV",
    criteriaid: 2222,
    label: "El Salvador",
  },
  {
    code: "GQ",
    criteriaid: 2226,
    label: "Equatorial Guinea",
  },
  {
    code: "ET",
    criteriaid: 2231,
    label: "Ethiopia",
  },
  {
    code: "ER",
    criteriaid: 2232,
    label: "Eritrea",
  },
  {
    code: "EE",
    criteriaid: 2233,
    label: "Estonia",
  },
  {
    code: "GS",
    criteriaid: 2239,
    label: "South Georgia and the South Sandwich Islands",
  },
  {
    code: "FJ",
    criteriaid: 2242,
    label: "Fiji",
  },
  {
    code: "FI",
    criteriaid: 2246,
    label: "Finland",
  },
  {
    code: "FR",
    criteriaid: 2250,
    label: "France",
  },
  {
    code: "PF",
    criteriaid: 2258,
    label: "French Polynesia",
  },
  {
    code: "TF",
    criteriaid: 2260,
    label: "French Southern and Antarctic Lands",
  },
  {
    code: "DJ",
    criteriaid: 2262,
    label: "Djibouti",
  },
  {
    code: "GA",
    criteriaid: 2266,
    label: "Gabon",
  },
  {
    code: "GE",
    criteriaid: 2268,
    label: "Georgia",
  },
  {
    code: "GM",
    criteriaid: 2270,
    label: "The Gambia",
  },

  {
    code: "GH",
    criteriaid: 2288,
    label: "Ghana",
  },
  {
    code: "KI",
    criteriaid: 2296,
    label: "Kiribati",
  },
  {
    code: "GR",
    criteriaid: 2300,
    label: "Greece",
  },
  {
    code: "GD",
    criteriaid: 2308,
    label: "Grenada",
  },
  {
    code: "GU",
    criteriaid: 2316,
    label: "Guam",
  },
  {
    code: "GT",
    criteriaid: 2320,
    label: "Guatemala",
  },
  {
    code: "GN",
    criteriaid: 2324,
    label: "Guinea",
  },
  {
    code: "GY",
    criteriaid: 2328,
    label: "Guyana",
  },
  {
    code: "HT",
    criteriaid: 2332,
    label: "Haiti",
  },
  {
    code: "HM",
    criteriaid: 2334,
    label: "Heard Island and McDonald Islands",
  },
  {
    code: "VA",
    criteriaid: 2336,
    label: "Vatican City",
  },
  {
    code: "HN",
    criteriaid: 2340,
    label: "Honduras",
  },
  {
    code: "HU",
    criteriaid: 2348,
    label: "Hungary",
  },
  {
    code: "IS",
    criteriaid: 2352,
    label: "Iceland",
  },
  {
    code: "IN",
    criteriaid: 2356,
    label: "India",
  },
  {
    code: "ID",
    criteriaid: 2360,
    label: "Indonesia",
  },
  {
    code: "IQ",
    criteriaid: 2368,
    label: "Iraq",
  },
  {
    code: "IE",
    criteriaid: 2372,
    label: "Ireland",
  },
  {
    code: "IL",
    criteriaid: 2376,
    label: "Israel",
  },
  {
    code: "IT",
    criteriaid: 2380,
    label: "Italy",
  },
  {
    code: "CI",
    criteriaid: 2384,
    label: "Cote d'Ivoire",
  },
  {
    code: "JM",
    criteriaid: 2388,
    label: "Jamaica",
  },
  {
    code: "JP",
    criteriaid: 2392,
    label: "Japan",
  },
  {
    code: "KZ",
    criteriaid: 2398,
    label: "Kazakhstan",
  },
  {
    code: "JO",
    criteriaid: 2400,
    label: "Jordan",
  },
  {
    code: "KE",
    criteriaid: 2404,
    label: "Kenya",
  },
  {
    code: "KR",
    criteriaid: 2410,
    label: "South Korea",
  },
  {
    code: "KW",
    criteriaid: 2414,
    label: "Kuwait",
  },
  {
    code: "KG",
    criteriaid: 2417,
    label: "Kyrgyzstan",
  },
  {
    code: "LA",
    criteriaid: 2418,
    label: "Laos",
  },
  {
    code: "LB",
    criteriaid: 2422,
    label: "Lebanon",
  },
  {
    code: "LS",
    criteriaid: 2426,
    label: "Lesotho",
  },
  {
    code: "LV",
    criteriaid: 2428,
    label: "Latvia",
  },
  {
    code: "LR",
    criteriaid: 2430,
    label: "Liberia",
  },
  {
    code: "LY",
    criteriaid: 2434,
    label: "Libya",
  },
  {
    code: "LI",
    criteriaid: 2438,
    label: "Liechtenstein",
  },
  {
    code: "LT",
    criteriaid: 2440,
    label: "Lithuania",
  },
  {
    code: "LU",
    criteriaid: 2442,
    label: "Luxembourg",
  },
  {
    code: "MG",
    criteriaid: 2450,
    label: "Madagascar",
  },
  {
    code: "MW",
    criteriaid: 2454,
    label: "Malawi",
  },
  {
    code: "MY",
    criteriaid: 2458,
    label: "Malaysia",
  },
  {
    code: "MV",
    criteriaid: 2462,
    label: "Maldives",
  },
  {
    code: "ML",
    criteriaid: 2466,
    label: "Mali",
  },
  {
    code: "MT",
    criteriaid: 2470,
    label: "Malta",
  },
  {
    code: "MR",
    criteriaid: 2478,
    label: "Mauritania",
  },
  {
    code: "MU",
    criteriaid: 2480,
    label: "Mauritius",
  },
  {
    code: "MX",
    criteriaid: 2484,
    label: "Mexico",
  },
  {
    code: "MC",
    criteriaid: 2492,
    label: "Monaco",
  },
  {
    code: "MN",
    criteriaid: 2496,
    label: "Mongolia",
  },
  {
    code: "MD",
    criteriaid: 2498,
    label: "Moldova",
  },
  {
    code: "ME",
    criteriaid: 2499,
    label: "Montenegro",
  },
  {
    code: "MA",
    criteriaid: 2504,
    label: "Morocco",
  },
  {
    code: "MZ",
    criteriaid: 2508,
    label: "Mozambique",
  },
  {
    code: "OM",
    criteriaid: 2512,
    label: "Oman",
  },
  {
    code: "NA",
    criteriaid: 2516,
    label: "Namibia",
  },
  {
    code: "NR",
    criteriaid: 2520,
    label: "Nauru",
  },
  {
    code: "NP",
    criteriaid: 2524,
    label: "Nepal",
  },
  {
    code: "NL",
    criteriaid: 2528,
    label: "Netherlands",
  },
  {
    code: "CW",
    criteriaid: 2531,
    label: "Curacao",
  },
  {
    code: "SX",
    criteriaid: 2534,
    label: "Sint Maarten",
  },
  {
    code: "BQ",
    criteriaid: 2535,
    label: "Caribbean Netherlands",
  },
  {
    code: "NC",
    criteriaid: 2540,
    label: "New Caledonia",
  },
  {
    code: "VU",
    criteriaid: 2548,
    label: "Vanuatu",
  },
  {
    code: "NZ",
    criteriaid: 2554,
    label: "New Zealand",
  },
  {
    code: "NI",
    criteriaid: 2558,
    label: "Nicaragua",
  },
  {
    code: "NE",
    criteriaid: 2562,
    label: "Niger",
  },
  {
    code: "NG",
    criteriaid: 2566,
    label: "Nigeria",
  },
  {
    code: "NU",
    criteriaid: 2570,
    label: "Niue",
  },
  {
    code: "NF",
    criteriaid: 2574,
    label: "Norfolk Island",
  },
  {
    code: "NO",
    criteriaid: 2578,
    label: "Norway",
  },
  {
    code: "MP",
    criteriaid: 2580,
    label: "Northern Mariana Islands",
  },
  {
    code: "UM",
    criteriaid: 2581,
    label: "United States Minor Outlying Islands",
  },
  {
    code: "FM",
    criteriaid: 2583,
    label: "Federated States of Micronesia",
  },
  {
    code: "MH",
    criteriaid: 2584,
    label: "Marshall Islands",
  },
  {
    code: "PW",
    criteriaid: 2585,
    label: "Palau",
  },
  {
    code: "PK",
    criteriaid: 2586,
    label: "Pakistan",
  },
  {
    code: "PA",
    criteriaid: 2591,
    label: "Panama",
  },
  {
    code: "PG",
    criteriaid: 2598,
    label: "Papua New Guinea",
  },
  {
    code: "PY",
    criteriaid: 2600,
    label: "Paraguay",
  },
  {
    code: "PE",
    criteriaid: 2604,
    label: "Peru",
  },
  {
    code: "PH",
    criteriaid: 2608,
    label: "Philippines",
  },
  {
    code: "PN",
    criteriaid: 2612,
    label: "Pitcairn Islands",
  },
  {
    code: "PL",
    criteriaid: 2616,
    label: "Poland",
  },
  {
    code: "PT",
    criteriaid: 2620,
    label: "Portugal",
  },
  {
    code: "GW",
    criteriaid: 2624,
    label: "Guinea-Bissau",
  },
  {
    code: "TL",
    criteriaid: 2626,
    label: "Timor-Leste",
  },
  {
    code: "QA",
    criteriaid: 2634,
    label: "Qatar",
  },
  {
    code: "RO",
    criteriaid: 2642,
    label: "Romania",
  },
  {
    code: "RU",
    criteriaid: 2643,
    label: "Russia",
  },
  {
    code: "RW",
    criteriaid: 2646,
    label: "Rwanda",
  },
  {
    code: "SH",
    criteriaid: 2654,
    label: "Saint Helena, Ascension and Tristan da Cunha",
  },
  {
    code: "KN",
    criteriaid: 2659,
    label: "Saint Kitts and Nevis",
  },
  {
    code: "LC",
    criteriaid: 2662,
    label: "Saint Lucia",
  },
  {
    code: "PM",
    criteriaid: 2666,
    label: "Saint Pierre and Miquelon",
  },
  {
    code: "VC",
    criteriaid: 2670,
    label: "Saint Vincent and the Grenadines",
  },
  {
    code: "SM",
    criteriaid: 2674,
    label: "San Marino",
  },
  {
    code: "ST",
    criteriaid: 2678,
    label: "Sao Tome and Principe",
  },
  {
    code: "SA",
    criteriaid: 2682,
    label: "Saudi Arabia",
  },
  {
    code: "SN",
    criteriaid: 2686,
    label: "Senegal",
  },
  {
    code: "RS",
    criteriaid: 2688,
    label: "Serbia",
  },
  {
    code: "SC",
    criteriaid: 2690,
    label: "Seychelles",
  },
  {
    code: "SL",
    criteriaid: 2694,
    label: "Sierra Leone",
  },
  {
    code: "SG",
    criteriaid: 2702,
    label: "Singapore",
  },
  {
    code: "SK",
    criteriaid: 2703,
    label: "Slovakia",
  },
  {
    code: "VN",
    criteriaid: 2704,
    label: "Vietnam",
  },
  {
    code: "SI",
    criteriaid: 2705,
    label: "Slovenia",
  },
  {
    code: "SO",
    criteriaid: 2706,
    label: "Somalia",
  },
  {
    code: "ZA",
    criteriaid: 2710,
    label: "South Africa",
  },
  {
    code: "ZW",
    criteriaid: 2716,
    label: "Zimbabwe",
  },
  {
    code: "ES",
    criteriaid: 2724,
    label: "Spain",
  },
  {
    code: "SD",
    criteriaid: 2736,
    label: "Sudan",
  },
  {
    code: "SR",
    criteriaid: 2740,
    label: "Suriname",
  },
  {
    code: "SZ",
    criteriaid: 2748,
    label: "Eswatini",
  },
  {
    code: "SE",
    criteriaid: 2752,
    label: "Sweden",
  },
  {
    code: "CH",
    criteriaid: 2756,
    label: "Switzerland",
  },
  {
    code: "TJ",
    criteriaid: 2762,
    label: "Tajikistan",
  },
  {
    code: "TH",
    criteriaid: 2764,
    label: "Thailand",
  },
  {
    code: "TG",
    criteriaid: 2768,
    label: "Togo",
  },
  {
    code: "TK",
    criteriaid: 2772,
    label: "Tokelau",
  },
  {
    code: "TO",
    criteriaid: 2776,
    label: "Tonga",
  },
  {
    code: "TT",
    criteriaid: 2780,
    label: "Trinidad and Tobago",
  },
  {
    code: "AE",
    criteriaid: 2784,
    label: "United Arab Emirates",
  },
  {
    code: "TN",
    criteriaid: 2788,
    label: "Tunisia",
  },
  {
    code: "TR",
    criteriaid: 2792,
    label: "Turkey",
  },
  {
    code: "TM",
    criteriaid: 2795,
    label: "Turkmenistan",
  },
  {
    code: "TV",
    criteriaid: 2798,
    label: "Tuvalu",
  },
  {
    code: "UG",
    criteriaid: 2800,
    label: "Uganda",
  },
  {
    code: "UA",
    criteriaid: 2804,
    label: "Ukraine",
  },
  {
    code: "MK",
    criteriaid: 2807,
    label: "North Macedonia",
  },
  {
    code: "EG",
    criteriaid: 2818,
    label: "Egypt",
  },
  {
    code: "GG",
    criteriaid: 2831,
    label: "Guernsey",
  },
  {
    code: "JE",
    criteriaid: 2832,
    label: "Jersey",
  },
  {
    code: "TZ",
    criteriaid: 2834,
    label: "Tanzania",
  },
  {
    code: "BF",
    criteriaid: 2854,
    label: "Burkina Faso",
  },
  {
    code: "UY",
    criteriaid: 2858,
    label: "Uruguay",
  },
  {
    code: "UZ",
    criteriaid: 2860,
    label: "Uzbekistan",
  },
  {
    code: "VE",
    criteriaid: 2862,
    label: "Venezuela",
  },
  {
    code: "WF",
    criteriaid: 2876,
    label: "Wallis and Futuna",
  },
  {
    code: "WS",
    criteriaid: 2882,
    label: "Samoa",
  },
  {
    code: "YE",
    criteriaid: 2887,
    label: "Yemen",
  },
  {
    code: "ZM",
    criteriaid: 2894,
    label: "Zambia",
  },
];

const languageAbbreviations = {
  1000: "EN",
  1019: "AR",
  1056: "BN",
  1020: "BG",
  1038: "CA",
  1017: "ZH",
  1018: "ZH",
  1039: "HR",
  1021: "CS",
  1009: "DA",
  1010: "NL",
  1043: "ET",
  1042: "TL",
  1011: "FI",
  1002: "FR",
  1001: "DE",
  1022: "EL",
  1072: "GU",
  1027: "HE",
  1023: "HI",
  1024: "HU",
  1026: "IS",
  1025: "ID",
  1004: "IT",
  1005: "JA",
  1086: "KN",
  1012: "KO",
  1028: "LV",
  1029: "LT",
  1102: "MS",
  1098: "ML",
  1101: "MR",
  1013: "NO",
  1064: "FA",
  1030: "PL",
  1014: "PT",
  1032: "RO",
  1031: "RU",
  1035: "SR",
  1033: "SK",
  1034: "SL",
  1003: "ES",
  1015: "SV",
  1130: "TA",
  1131: "TE",
  1044: "TH",
  1037: "TR",
  1036: "UK",
  1041: "UR",
  1040: "VI",
  // Add other languages as needed
};
