import React, {useEffect, useState} from 'react'
import { Grid } from 'react-loader-spinner'
import { Table } from 'antd';
import axios from "../../../components/Axios";
import BarSide2 from "../../BarSide2";
import { Container } from "react-bootstrap";


function JobStatusPage() {
    const userDataString = localStorage.getItem("userData");
    const userData = userDataString ? JSON.parse(userDataString) : null;
    const userId = userData ? userData._id : null;
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchJobStatus = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`/newbackground/userjobs/${userId}`);
            setData(response.data);
        console.log(response.data)
        } catch (error) {
           console.log(error)
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchJobStatus();
    }, []);

    const columns = [
        { title: 'Keyword Group', dataIndex: 'KeywordGroupName', key: 'KeywordGroupName' },
        { title: 'Total KWs', dataIndex: 'KeywordsAnalyzing', key: 'KeywordsAnalyzing' },
        { title: 'Remaining KWs', dataIndex: 'remainingKeywords', key: 'remainingKeywords' },
        { title: 'Status', dataIndex: 'status', key: 'status' },
        { title: 'Error', dataIndex: 'error', key: 'error', width: '35%' },
        { title: 'Created At', dataIndex: 'createdAt', key: 'createdAt' },
        { title: 'Updated At', dataIndex: 'updatedAt', key: 'updatedAt' },
    ];


    return (
        <main className="d-flex flex-nowrap">
          <BarSide2 />
          <Container>
            <div className="d-flex flex-column flex-grow-1 p-4">
              <div style={{ overflowY: "auto", height: "calc(100vh - 24px)" }}>
                <div className="container mt-3">
                  <div className="row">
                    <div className="col-md-12">
                      {loading ? (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }}
                          className="py-3"
                        >
                          <Grid
                            height="80"
                            width="80"
                            color="#1890ff"
                            ariaLabel="grid-loading"
                            radius="12.5"
                            wrapperStyle={{}}
                            wrapperClass=""
                            visible={true}
                          />
                        </div>
                      ) : (
                        <Table
                          columns={columns}
                          dataSource={data}
                          rowKey="jobId"
                          loading={loading}
                          style={{ marginTop: 20 }}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Container>
        </main>
      );
    };

export default JobStatusPage;