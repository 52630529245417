import React from 'react'
import ReactApexChart from "react-apexcharts";


function MonthlyVolume({ keyword }) {
  // console.log(keyword, "keyword from onthlyvolume")
    const monthlySearchVolumes = keyword.keyword_idea_metrics.monthly_search_volumes;
  
    // Extract month names and monthly search volumes from the data
    const months = monthlySearchVolumes.map((entry) => entry.month);
    const searchVolumes = monthlySearchVolumes.map((entry) => parseInt(entry.monthly_searches));
  
    // Define the chart options
    const chartOptions = {
      chart: {
        id: 'monthly-volume-chart',
      },
      xaxis: {
        categories: months,
      },
    };
  
    // Define the chart series
    const chartSeries = [
      {
        name: 'Monthly Searches',
        data: searchVolumes,
      },
    ];
  
    return (
      <div className="monthly-volume-chart">
        <ReactApexChart options={chartOptions} series={chartSeries} type="area" height={300} />
      </div>
    );
  }
  

export default MonthlyVolume