import React, { useState, useEffect } from "react";
import Offcanvas from "react-bootstrap/Offcanvas";
import { Grid } from 'react-loader-spinner'
import { Table, Tooltip } from "antd";

function BottomOffCanvas({ children, row, onIconClick  }) {
  // console.log(row, "row from bottomoffcavas")
  const [show, setShow] = useState(false);
  const [tableData, setTableData] = useState([]);

  const handleClose = () => setShow(false);

  const [response, setResponse] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const iconClassMapping = {
    "quora.com": "bi bi-quora",
    "reddit.com": "bi bi-reddit",
    "youtube.com": "bi bi-youtube",
    "facebook.com": "bi bi-facebook",
    "twitter.com": "bi bi-twitter",
    "linkedin.com": "bi bi-linkedin",
    // Add more domain-to-icon mappings as needed
  };

  const backgroundClassMapping = {
    "bi bi-quora": "quoraBackground",
    "bi bi-reddit": "redditBackground",
    "bi bi-youtube": "youtubeBackground",
    "bi bi-facebook": "facebookBackground",
    "bi bi-twitter": "twitterBackground",
    "bi bi-linkedin": "linkedinBackground",
    // Map more icon classes to background classes as needed
  };

  const getIconClass = (domain) => {
    console.log("Domain:", domain);
  
    // Extract the root domain from the full domain
    const rootDomain = domain.split('.').slice(-2).join('.');
    console.log("Root Domain:", rootDomain);
  
    // const iconClass = iconClassMapping[rootDomain] || "bi bi-vinyl";
    const iconClass = iconClassMapping[rootDomain] || "bi bi-instagram";
    console.log("Icon Class:", iconClass);
    return iconClass;
  };
  
  
  
  const getBackgroundClass = (iconClass) => {
    console.log("Icon Class for Background:", iconClass);
    const backgroundClass = backgroundClassMapping[iconClass] || "";
    console.log("Background Class:", backgroundClass);
    return backgroundClass;
  };
  


  const [error, setError] = useState(null);



  const handleIconClick = async () => {
   

    setShow(true); // Show the offcanvas
    setIsLoading(true);

    try {
      const responseData = await onIconClick();
      setTableData(formatDataForTable(responseData.items, true));

      // setResponse(responseData);
      console.log(responseData, "responseData")
    } catch (error) {
      // Handle error appropriately
      console.log(error)
    } finally {
      setIsLoading(false);
    }

 

  };
  const getColumns = (includeDomainAge) => {

  const columns = [
    {
      title: 'Pos',
      dataIndex: 'position',
      key: 'position',
      width: 60,
      align: 'center',
    },
    {
      title: 'Result',
      dataIndex: 'result',
      key: 'result',
      render: (text, record) => (
        <>
          {record.title}
          <span style={{ display: "block", lineHeight: "10px" }}>
            <a href={record.formattedUrl} target="_blank" rel="nofollow noreferrer noopener" className="text-xsmall">
              {record.formattedUrl}
            </a>
          </span>
        </>
      ),
    },
    {
      title: 'WS',
      dataIndex: 'ws',
      key: 'ws',
      width: 70,
      align: 'center',
      render: (text, record) => {
        console.log("Record: from ", record);
        const iconClass = getIconClass(record.result.pageRankdomain);
        const isDomainAuthorityEmpty = record.domain_authority === "";
        const colorClass = isDomainAuthorityEmpty 
          ? "grey" 
          : record.domain_authority <= 20 ? "green" : "grey";
        const backgroundClass = getBackgroundClass(iconClass);

        return (
          <span className={`ra-ws ra-wsssupdate me-3 ${colorClass}`}>
            <span className={`${backgroundClass}`}>
              {text}
            </span>
            <i className={iconClass}></i>
          </span>
        );
      },
    },
    {
      title: (
        <Tooltip title="Domain Authority from Moz">
          DA
        </Tooltip>
      ),
      dataIndex: 'domain_authority',
      key: 'domain_authority',
      width: 55,
      align: 'center',
      
    },
    {
      title: (
        <Tooltip title="Page Authority from Moz">
          PA
        </Tooltip>
      ),
      dataIndex: 'page_authority',
      key: 'page_authority',
      width: 55,
      align: 'center',
      
    },
    {
      title:  (
        <Tooltip title="Trust Flow from Majestics">
          TF
        </Tooltip>
      ),
      dataIndex: 'majesticTF',
      key: 'majesticTF',
      width: 50,
      align: 'center',
    },
    {
      title: (
        <Tooltip title="Citation Flow from Majestics">
          CF
        </Tooltip>
      ),
      dataIndex: 'majesticCF',
      key: 'majesticCF',
      width: 50,
      align: 'center',
    },
    {
      title: (
        <Tooltip title="Number of External Links">
          EL
        </Tooltip>
      ),
      dataIndex: 'mozLinks',
      key: 'mozLinks',
      width: 90,
      align: 'center',
    },
    
    {
      title: (
        <Tooltip title="Number of Referring Domains">
          RD
        </Tooltip>
      ),
      dataIndex: 'majesticRefDomains',
      key: 'majesticRefDomains',
      width: 80,
      align: 'center',
    },
    {
      title: (
        <Tooltip title="Facebook Shares">
          FBS
        </Tooltip>
      ),
      dataIndex: 'FB_shares',
      key: 'FB_shares',
      width: 70,
      align: 'center',
    },
    {
      title: (
        <Tooltip title="Facebook Comments">
          FBC
        </Tooltip>
      ),
      dataIndex: 'FB_comments',
      key: 'FB_comments',
      width: 70,
      align: 'center',
    },
    
    {
      title: (
        <Tooltip title="Page Rank">
          PR
        </Tooltip>
      ),
      dataIndex: 'rank',
      key: 'rank',
      // You can adjust width, alignment, etc. here
      width: 100,
      align: 'center',
    }
// ,
//     {
//       title: 'Domain Age',
//       dataIndex: 'domain_age',
//       key: 'domain_age',
//       width: 180,
//     },
  ];

  if (includeDomainAge) {
    columns.push({
      title: 'Domain Age',
      dataIndex: 'domain_age',
      key: 'domain_age',
      width: 130,
      // ... other column settings ...
    });
  }

  return columns;
  }

  // Prepare data for the table
  // const data = response && response.items ? response.items.map((item, index) => ({
  //   key: index,
  //   position: index + 1,
  //   title: item.title,
  //   formattedUrl: item.formattedUrl,
  //   result: item,
  //   rank: item.rank,
  //   ws: index + 1,
  //   domain_authority: item.domain_authority,
  //   domain_age: item.domain_age,
  // })) : [];

  const formatDataForTable = (data, includeDomainAge = false) => {
    return data.map((item, index) => ({
      key: index,
      position: index + 1,
      title: item.title,
      formattedUrl: item.formattedUrl,
      result: item,
      rank: item.rank,
      ws: index + 1,
      domain_authority: item.domain_authority,
      page_authority: item.page_authority,
      majesticTF: item.majesticTF,
      majesticCF: item.majesticCF,
      majesticLinks: item.majesticLinks,
      mozLinks: item.mozLinks,
      majesticRefDomains: item.majesticRefDomains,
      FB_shares: item.FB_shares,
      FB_comments: item.FB_comments,
      majesticIPs: item.majesticIPs,
      mozRank: item.mozRank,
      ...(includeDomainAge && { domain_age: item.domain_age }),
    }));
  };

  useEffect(() => {
    if (row && row.items) {
      setTableData(formatDataForTable(row.items));
    }
  }, [row]);

  return (
    <>
      <span onClick={handleIconClick} style={{ cursor: "pointer" }}>
        {children}
      </span>

      <Offcanvas
        show={show}
        onHide={handleClose}
        placement="bottom"
        className="offcanvas-custom-size"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Search results for : {row.text}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
        {/* {isLoading ? (
            <div className="text-center spinnerparent">
              <Grid
  height="80"
  width="80"
  color="#1890ff"
  ariaLabel="grid-loading"
  radius="12.5"
  wrapperStyle={{}}
  wrapperClass=""
  visible={true}
/>
            </div>
          ) : (

<div className="col-md-12">
      <Table columns={columns} dataSource={data} bordered />
    </div>

)} */}
<div className="col-md-12">
            <Table columns={getColumns(!isLoading)} dataSource={tableData} bordered tableLayout="fixed"  />
          </div>


        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default BottomOffCanvas;
