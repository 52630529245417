import React, { useState } from 'react';
import stringSimilarity from 'string-similarity';
import { Grid } from 'react-loader-spinner';
import { Select, Button, Card, Col, Row, Typography  } from 'antd';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { CSVLink } from 'react-csv';

// const threshold = 0.7; // Separate threshold variable
const { Option } = Select;
const { Title } = Typography;

// Threshold level mappings
const thresholdLevels = {
  'Extra Broad': 0.3,
  'Broad': 0.5,
  'Medium': 0.7,
  'Strict': 0.85,
  'Extra Strict': 0.9
};




function createClusters(keywords, threshold) {
    const clusters = [];
    for (let i = 0; i < keywords.length; i++) {
        let newCluster = true;
        for (let j = 0; j < clusters.length; j++) {
            const cluster = clusters[j];
            const similarity = stringSimilarity.compareTwoStrings(cluster[0], keywords[i]);
            if (similarity >= threshold) {
                cluster.push(keywords[i]);
                newCluster = false;
                break;
            }
        }
        if (newCluster) {
            clusters.push([keywords[i]]);
        }
    }
    return clusters.filter(cluster => cluster.length > 2);
}

function ClusterNew({ keywordData }) {
    const [isLoading, setIsLoading] = useState(false);
    const [clusters, setClusters] = useState([]);
    const [threshold, setThreshold] = useState(0.7); // Default threshold
    const [selectedThresholdKey, setSelectedThresholdKey] = useState('Medium'); // Default threshold key


    const handleClusterCreation = () => {
        setIsLoading(true);
        console.log("Creating clusters with threshold:", threshold);
        // Make sure keywordData is in the expected format
        console.log("keywordData:", keywordData);

        const newKeywords = keywordData.keywordData.map(item => item.text);
        console.log("newKeywords:", newKeywords);

        const newClusters = createClusters(newKeywords, threshold);
        console.log("newClusters:", newClusters);

        setClusters(newClusters);
        setIsLoading(false);
    };

    const handleThresholdChange = (value) => {
        console.log("Threshold changed to:", value);
        setThreshold(thresholdLevels[value]);
        setSelectedThresholdKey(value);
      };

    console.log(clusters, "clusters")
    const thresholdDescriptions = {
        'Extra Broad': "Extra Broad = Maximum keyword grouping, highest inconsistency/false matches.",
        'Broad': "Broad = More keywords grouped, probable inconsistency/false matches.",
        'Medium': "Medium = Balanced keyword grouping with moderate accuracy.",
        'Strict': "Strict = Smaller, uniform keyword groups, fewer false matches.",
        'Extra Strict': "Extra Strict = Very small, highly accurate keyword groups, most restrictive."
      };

      const formatClusterDataForCopy = (cluster) => {
        // return `${cluster[0]} [${cluster.length}]:\n${cluster.join('\n')}`;
        return `${cluster.join('\n')}`;
      };

      const transformDataForCSV = (clusters) => {
        let csvData = [];
        clusters.forEach(cluster => {
          cluster.forEach(keyword => {
            csvData.push({ keywordText: keyword });
          });
        });
        return csvData;
      };
    
      const headers = [
        { label: 'Keywords', key: 'keywordText' },
      ];
    
      const csvReport = {
        data: transformDataForCSV(clusters), // use your cluster data here
        headers: headers,
        filename: 'ExportedData.csv'
      };

    return (
        <div className='mt-3'>
            {isLoading ? (
                <Grid
                    height="80"
                    width="80"
                    color="#1890ff"
                    ariaLabel="grid-loading"
                    radius="12.5"
                    wrapperStyle={{}}
                    wrapperClass=""
                    visible={true}
                />
            ) : (
                <>
                 {/* <Select defaultValue="Medium" style={{ width: 120, marginRight: "20px" }} onChange={handleThresholdChange}>
        {Object.keys(thresholdLevels).map(level => (
          <Option key={level} value={level}>{level}</Option>
        ))}
      </Select>
      <Button className='no-hover-effect shadow-sm mb-3' onClick={handleClusterCreation}>
                        Create Clusters
                    </Button>
            <span>{`${clusters.length} Keywords Groups.`}</span>   
            <Button className='no-hover-effect shadow-sm mb-3' >
                        Export
                    </Button> */}
                      <Row gutter={16} >
        <Col span={12}>
          <Select defaultValue="Medium" style={{ width: 120, marginRight: "20px" }} onChange={handleThresholdChange}>
            {Object.keys(thresholdLevels).map(level => (
              <Option key={level} value={level}>{level}</Option>
            ))}
          </Select>
          <Button className='no-hover-effect shadow-sm mb-3' onClick={handleClusterCreation}>
            Create Clusters
          </Button>
        </Col>
        <Col span={12} style={{ display: 'flex', justifyContent: 'end', alignItems: 'center' }}>
          {/* <span className='clastersGroundName'>{`${clusters.length} Keywords Groups.`}</span> */}
          {clusters.length > 0 && (
            <>
          <Title level={4} className='clustersGroundName' style={{ fontSize: '24px', lineHeight: '24px', color: '#1890ff', paddingRight: '10px', marginBottom: '20px' }}>{`${clusters.length} Keywords Groups.`}</Title>
          <CSVLink {...csvReport}>
          <Button className='no-hover-effect shadow-sm mb-3' style={{ marginLeft: '10px', display: 'flex', alignItems: 'center' }}>
  <span className="material-symbols-outlined" style={{ fontSize: '16px' }}>export_notes</span>
  <span style={{ marginLeft: '5px' }}>Export</span>
</Button>
</CSVLink>
</>
       )}
        </Col>
     
      </Row>
      <div className="description-text">
      {thresholdDescriptions[selectedThresholdKey]}
      </div>
                    
                    <Row gutter={[16, 16]}>
    {clusters.map((cluster, index) => (
        <Col key={index} xs={24} sm={12} md={8} lg={8}>
            <Card 
                // title={`${cluster[0]} [${cluster.length}]`}
                title={
                    <div>
                       {/* <Tooltip title="Copy keywords to clipboard"> */}
                  <CopyToClipboard text={formatClusterDataForCopy(cluster)}>
                    <span className="material-symbols-outlined copy-icon">file_copy</span>
                  </CopyToClipboard>
                {/* </Tooltip> */}
                      {`${cluster[0]} [${cluster.length}]`}
                    </div>
                  }
                style={{ height: '400px', marginBottom: "10px", overflow: 'hidden', border: 'none',  }} 
                className='shadow-sm' >
                <div style={{ overflowY: 'auto', height: '350px', padding: '10px', wordWrap: 'break-word' }}>
                    {cluster.map((keyword, keywordIndex) => (
                        <p key={keywordIndex} style={{ margin: 0, padding: 0 }}>
                            {keyword}
                        </p>
                    ))}
                </div>
            </Card>
        </Col>
    ))}
</Row>
                </>
            )}
        </div>
    );
}

export default ClusterNew;
