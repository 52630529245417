import React, {useState, useEffect, useCallback, useContext, useMemo } from 'react';
import { Tabs,Popover, Checkbox, InputNumber, Button, Space, Input } from 'antd';
import { CSVLink } from 'react-csv';
import Competitorswork from "../KeywordTablecomponents/Competitorswork"
import { QuestionCircleOutlined, DatabaseOutlined, GoogleOutlined, SearchOutlined, StarOutlined, TeamOutlined, ClusterOutlined,DownOutlined ,CloudDownloadOutlined, AppstoreAddOutlined  } from '@ant-design/icons';
import MainTableKeywords from '../MainTableKeywords';
import ClusterNew from './ClusterNew';
import CompetitorsDomains from '../CompetitorsDomains';
import { useGetValueContext } from '../../auth/GetValueContext';
import { KeywordContext } from "../../auth/KeywordContext";
import Questions from './Questions';
import Presets from './Presets';
import {CopyToClipboard} from 'react-copy-to-clipboard';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "../../components/Axios";
const userDataString = localStorage.getItem("userData");
const userData = userDataString ? JSON.parse(userDataString) : null;


const { Search } = Input;
const { TabPane } = Tabs;
function TableDatadisplay() {
  // const TableDatadisplay = () => {

  const { keywordData, updateKeywordData,  selectedKeywordData} = useContext(KeywordContext);
  const [isCopied, setIsCopied] = useState(false);
  // const {   getMainKeywordData } = useGetValueContext();





    const [activeTab, setActiveTab] = useState("1");
    // const [filteredData, setFilteredData] = useState([]);


    const [filters, setFilters] = useState({
        minVolume: '',
        maxVolume: '',
        minWords: '',
        maxWords: '',
        minCpc: '',
        maxCpc: '',
        minDa: '',
        maxDa: '',
        minPa: '',
        maxPa: '',
        minmozLinks: '',
        maxmozLinks: '',
        minmajesticRefDomains: '',
        maxmajesticRefDomains: '',
        allWords: '',
        anyWords: '',
        excludeWords: '',
        searchTerm: '',
        allInTitleMin: "",
        allInTitleMax: "",
        allInUrlsMin: "",
        allInUrlsMax: "",
        kgrRatioMin: "",
        kgrRatioMax: "",
        selectedCategories: [],
        goldenRuleName: ""
      });
      // const [searchTerm, setSearchTerm] = useState("");
      const [presets, setPresets] = useState(null);

      const handleFilterChange = useCallback((filterName, newValue) => {
        setFilters(prevFilters => ({ ...prevFilters, [filterName]: newValue }));
      }, []);

      const handleCheckboxChange = (category) => {
        const isSelected = filters.selectedCategories.includes(category);
      
        if (isSelected) {
          // Remove category if already selected
          setFilters(prevState => ({
            ...prevState,
            selectedCategories: prevState.selectedCategories.filter(item => item !== category)
          }));
        } else {
          // Add category if not selected
          setFilters(prevState => ({
            ...prevState,
            selectedCategories: [...prevState.selectedCategories, category]
          }));
        }
      };
    
      const volumeContent = (
        <Space direction="horizontal">
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>Min</label>
            <InputNumber 
              min={0} 
              value={filters.minVolume}
              onChange={(value) => handleFilterChange('minVolume', value)} 
              style={{ width: 130 }}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>Max</label>
            <InputNumber 
              min={0} 
              value={filters.maxVolume} 
              onChange={(value) => handleFilterChange('maxVolume', value)} 
              style={{ width: 130 }}
            />
          </div>
        </Space>
      );
      const includeContent = (
        <Space direction="vertical" size="small">
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>All Words</label>
            <Input 
              value={filters.allWords}
              onChange={(e) => handleFilterChange('allWords', e.target.value)} 
              style={{ width: 200 }}
              placeholder="Enter words separated by commas"
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>Any Words</label>
            <Input 
              value={filters.anyWords}
              onChange={(e) => handleFilterChange('anyWords', e.target.value)} 
              style={{ width: 200 }}
              placeholder="Enter words separated by commas"
            />
          </div>
        </Space>
      );
      
      const excludeContent = (
        <Space direction="vertical" size="small">
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>Exclude Words</label>
            <Input 
              value={filters.excludeWords}
              onChange={(e) => handleFilterChange('excludeWords', e.target.value)} 
              style={{ width: 200 }}
              placeholder="Enter words separated by commas"
            />
          </div>
        </Space>
      );

      const daContent = (
        <Space direction="vertical" size="small">
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>Min</label>
            <InputNumber 
              min={0} 
              value={filters.minDa}
              onChange={(value) => handleFilterChange('minDa', value)} 
              style={{ width: 130 }}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>Max</label>
            <InputNumber 
              min={0} 
              value={filters.maxDa} 
              onChange={(value) => handleFilterChange('maxDa', value)} 
              style={{ width: 130 }}
            />
          </div>
        </Space>
      );
      const paContent = (
        <Space direction="vertical" size="small">
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>Min</label>
            <InputNumber 
              min={0} 
              value={filters.minPa}
              onChange={(value) => handleFilterChange('minPa', value)} 
              style={{ width: 130 }}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>Max</label>
            <InputNumber 
              min={0} 
              value={filters.maxPa} 
              onChange={(value) => handleFilterChange('maxPa', value)} 
              style={{ width: 130 }}
            />
          </div>
        </Space>
      );
      const linksContent = (
        <Space direction="vertical" size="small">
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>Min</label>
            <InputNumber 
              min={0} 
              value={filters.minmozLinks}
              onChange={(value) => handleFilterChange('minmozLinks', value)} 
              style={{ width: 130 }}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>Max</label>
            <InputNumber 
              min={0} 
              value={filters.maxmozLinks} 
              onChange={(value) => handleFilterChange('maxmozLinks', value)} 
              style={{ width: 130 }}
            />
          </div>
        </Space>
      );
      const refContent = (
        <Space direction="vertical" size="small">
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>Min</label>
            <InputNumber 
              min={0} 
              value={filters.minmajesticRefDomains}
              onChange={(value) => handleFilterChange('minmajesticRefDomains', value)} 
              style={{ width: 130 }}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>Max</label>
            <InputNumber 
              min={0} 
              value={filters.maxmajesticRefDomains} 
              onChange={(value) => handleFilterChange('maxmajesticRefDomains', value)} 
              style={{ width: 130 }}
            />
          </div>
        </Space>
      );

      const cpcContent = (
        <Space direction="vertical" size="small">
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>Min</label>
            <InputNumber 
              min={0} 
              value={filters.minCpc}
              onChange={(value) => handleFilterChange('minCpc', value)} 
              style={{ width: 130 }}
            //   step={0.01}
            />
          </div>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <label>Max</label>
            <InputNumber 
              min={0} 
              value={filters.maxCpc} 
              onChange={(value) => handleFilterChange('maxCpc', value)} 
              style={{ width: 130 }}
            //   step={0.01}
            />
          </div>
        </Space>
      );

    const content = (
    <Space direction="horizontal">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <label>Min </label>
        <InputNumber 
          min={1} 
          value={filters.minWords}
          onChange={(value) => handleFilterChange('minWords', value)} 
          style={{ width: 130, }}
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <label>Max </label>
        <InputNumber 
          min={1} 
          max={10000000000000} 
          value={filters.maxWords} 
          onChange={(value) => handleFilterChange('maxWords', value)} 
          style={{ width: 130, }}
        />
      </div>
    </Space>
  );  

  const AllinTitleContent = (
    <Space direction="horizontal">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <label>Min </label>
        <InputNumber 
          min={1} 
          value={filters.allInTitleMin}
          onChange={(value) => handleFilterChange('allInTitleMin', value)} 
          style={{ width: 130, }}
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <label>Max </label>
        <InputNumber 
          min={1} 
          max={10000000000000} 
          value={filters.allInTitleMax} 
          onChange={(value) => handleFilterChange('allInTitleMax', value)} 
          style={{ width: 130, }}
        />
      </div>
    </Space>
  );  

  const AllinUrlContent = (
    <Space direction="horizontal">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <label>Min </label>
        <InputNumber 
          min={1} 
          value={filters.allInUrlsMin}
          onChange={(value) => handleFilterChange('allInUrlsMin', value)} 
          style={{ width: 130, }}
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <label>Max </label>
        <InputNumber 
          min={1} 
          max={10000000000000} 
          value={filters.allInUrlsMax} 
          onChange={(value) => handleFilterChange('allInUrlsMax', value)} 
          style={{ width: 130, }}
        />
      </div>
    </Space>
  );  

  const AllinKGRContent = (
    <Space direction="horizontal">
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <label>Min </label>
        <InputNumber 
         
          value={filters.kgrRatioMin}
          onChange={(value) => handleFilterChange('kgrRatioMin', value)} 
          style={{ width: 130, }}
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <label>Max </label>
        <InputNumber 
          
          max={10000000000000} 
          value={filters.kgrRatioMax} 
          onChange={(value) => handleFilterChange('kgrRatioMax', value)} 
          style={{ width: 130, }}
        />
      </div>
    </Space>
  );  

//   const onSearch = (value, _e, info) => console.log(info?.source, value);
// const onSearch = (value, _e, info) => {
//     console.log(info?.source, value);
//     setSearchTerm(value); // This line will update the searchTerm state with the new value
//   };

    const getTabClass = (key) => {
        return activeTab === key ? 'tab-selected' : 'tab-unselected'; 
      };



    //   const getSuggestionsValue = function(keywords) {
    //     return keywords.filter(keyword => keyword.id?.startsWith("suggestion"));
    // };      

    // const allKeywordFilter = {  ...keywordData, keywordData: keywordData.keywordData.filter(keyword => keyword.text && keyword.text.length > 0)};
    //   const keywordDataSuggestionFilter = { ...keywordData, keywordData: keywordData.keywordData.filter(keyword => keyword.id?.startsWith("suggestion")) };
    // const keywordRelatedFilter = { ...keywordData, keywordData: keywordData.keywordData.filter(keyword => keyword.id?.startsWith("related")) };
    // const keywordFavouriteFilter = { ...keywordData, keywordData: keywordData.keywordData.filter(keyword => keyword.isFavorite) };
  

          // Function to apply filters on keyword data
      const applyFilters = useCallback((keywordData, filters, activeTabKey) => {
       
        let filteredData = keywordData.keywordData;

        switch (activeTabKey) {
          case '1': // All Keywords
            // Apply default filters
            filteredData = filteredData.filter(keyword => keyword.text && keyword.text.length > 0);
            break;
          case '2': // Google Suggestions
            // Apply filters for Google Suggestions
            filteredData = filteredData.filter(keyword => keyword.id?.startsWith("suggestion"));
            break;
          case '3': // Google Auto Complete
            // Apply filters for Google Auto Complete
            filteredData = filteredData.filter(keyword => keyword.id?.startsWith("related"));
            break;
          case '4': // Favourites
            // Apply filters for Favourites
            filteredData = filteredData.filter(keyword => keyword.isFavorite);
            break;
          default:
            // Fallback or general filter logic
            break;
        }

        // Destructuring filters for clarity
          const {minmozLinks, maxmozLinks, minmajesticRefDomains, maxmajesticRefDomains, selectedCategories, kgrRatioMin, kgrRatioMax, allInUrlsMin,allInUrlsMax, searchTerm, minVolume, maxVolume, minWords, maxWords, minCpc, maxCpc, minDa, maxDa,minPa,maxPa, allWords, anyWords, excludeWords, allInTitleMin, allInTitleMax } = filters;

          // Volume Filter
          // if (minVolume || maxVolume) {
          //   const minVol = parseFloat(minVolume);
          //   const maxVol = parseFloat(maxVolume);

          //   filteredData = filteredData.filter(item => {
          //     const volume = parseFloat(item.keyword_idea_metrics.avg_monthly_searches || '0');
          //     return volume >= minVol && volume <= maxVol;
          //   });
          // }
          const minVol = parseFloat(minVolume);
      const maxVol = parseFloat(maxVolume);

      if (minVolume && maxVolume) {
        filteredData = filteredData.filter(item => {
          const volume = parseFloat(item.keyword_idea_metrics.avg_monthly_searches || '0');
          return volume >= minVol && volume <= maxVol;
        });
      } else if (minVolume) {
        filteredData = filteredData.filter(item => {
          const volume = parseFloat(item.keyword_idea_metrics.avg_monthly_searches || '0');
          return volume >= minVol;
        });
      } else if (maxVolume) {
        filteredData = filteredData.filter(item => {
          const volume = parseFloat(item.keyword_idea_metrics.avg_monthly_searches || '0');
          return volume <= maxVol;
        });
      }

      // Category filter
  if (selectedCategories && selectedCategories.length > 0) {
    const categoryMap = {
      "Questions": ["who", "why", "what", "where", "when"],
      "Investigative Keywords": ["best", "Vs", "review", "alternative", "comparison", "case study", "pros and cons", "FAQ"],
      "Commercial Keywords": ["cost", "price", "pricing", "buy", "discount", "free shipping", "promo", "sale", "trial", "shop", "purchase", "coupon", "deal"],
      "Informational Keywords": ["Tips", "strategies", "how to", "guide", "tutorial", "analysis", "tips"]
    };

    const selectedKeywords = selectedCategories.reduce((acc, category) => {
      if (categoryMap[category]) {
        acc.push(...categoryMap[category]);
      }
      return acc;
    }, []);

    filteredData = filteredData.filter(({ text }) =>
      selectedKeywords.some(keyword => text.toLowerCase().includes(keyword.toLowerCase()))
    );
  }


      if (allInTitleMin && allInTitleMax) {
        filteredData = filteredData.filter(({ keyword_idea_metrics }) => {
          const allInTitleResults = keyword_idea_metrics.allintitleTotalResults ? parseInt(keyword_idea_metrics.allintitleTotalResults, 10) : 0;
          return allInTitleResults >= parseInt(allInTitleMin, 10) && allInTitleResults <= parseInt(allInTitleMax, 10);
        });
      } else if (allInTitleMin) {
        filteredData = filteredData.filter(({ keyword_idea_metrics }) => {
          const allInTitleResults = keyword_idea_metrics.allintitleTotalResults ? parseInt(keyword_idea_metrics.allintitleTotalResults, 10) : 0;
          return allInTitleResults >= parseInt(allInTitleMin, 10);
        });
      } else if (allInTitleMax) {
        filteredData = filteredData.filter(({ keyword_idea_metrics }) => {
          const allInTitleResults = keyword_idea_metrics.allintitleTotalResults ? parseInt(keyword_idea_metrics.allintitleTotalResults, 10) : 0;
          return allInTitleResults <= parseInt(allInTitleMax, 10);
        });
      }

      if (allInUrlsMin && allInUrlsMax) {
        filteredData = filteredData.filter(({ keyword_idea_metrics }) => {
          const allInUrlResults = keyword_idea_metrics.allinurlTotalResults ? parseInt(keyword_idea_metrics.allinurlTotalResults, 10) : 0;
          return allInUrlResults >= parseInt(allInUrlsMin, 10) && allInUrlResults <= parseInt(allInUrlsMax, 10);
        });
      } else if (allInUrlsMin) {
        filteredData = filteredData.filter(({ keyword_idea_metrics }) => {
          const allInUrlResults = keyword_idea_metrics.allinurlTotalResults ? parseInt(keyword_idea_metrics.allinurlTotalResults, 10) : 0;
          return allInUrlResults >= parseInt(allInUrlsMin, 10);
        });
      } else if (allInUrlsMax) {
        filteredData = filteredData.filter(({ keyword_idea_metrics }) => {
          const allInUrlResults = keyword_idea_metrics.allinurlTotalResults ? parseInt(keyword_idea_metrics.allinurlTotalResults, 10) : 0;
          return allInUrlResults <= parseInt(allInUrlsMax, 10);
        });
      }
      
// Assuming filteredData is already filtered by previous conditions and now further filtering based on kgrRatioMin and kgrRatioMax

// Apply filters for kgrRatioMin and kgrRatioMax
if (kgrRatioMin && kgrRatioMax) {
  filteredData = filteredData.filter((row) => {
    const totalResults = row.keyword_idea_metrics?.allintitleTotalResults || 0;
    let monthlySearches = row.keyword_idea_metrics?.avg_monthly_searches;
    monthlySearches = !monthlySearches || monthlySearches === 0 ? 10 : monthlySearches; // Adjust for falsey or zero to 10
    
    const kgr = totalResults / monthlySearches; // KGR calculation
    return isFinite(kgr) && kgr >= parseFloat(kgrRatioMin) && kgr <= parseFloat(kgrRatioMax);
  });
} else if (kgrRatioMin) {
  filteredData = filteredData.filter((row) => {
    const totalResults = row.keyword_idea_metrics?.allintitleTotalResults || 0;
    let monthlySearches = row.keyword_idea_metrics?.avg_monthly_searches;
    monthlySearches = !monthlySearches || monthlySearches === 0 ? 10 : monthlySearches;
    
    const kgr = totalResults / monthlySearches;
    return isFinite(kgr) && kgr >= parseFloat(kgrRatioMin);
  });
} else if (kgrRatioMax) {
  filteredData = filteredData.filter((row) => {
    const totalResults = row.keyword_idea_metrics?.allintitleTotalResults || 0;
    let monthlySearches = row.keyword_idea_metrics?.avg_monthly_searches;
    monthlySearches = !monthlySearches || monthlySearches === 0 ? 10 : monthlySearches;
    
    const kgr = totalResults / monthlySearches;
    return isFinite(kgr) && kgr <= parseFloat(kgrRatioMax);
  });
}

// Continue with further filters or operations as needed


                  // Words Count Filter
          // if (minWords && maxWords) {
          //   filteredData = filteredData.filter(item => {
          //     const wordCount = item.text.split(/\s+/).length;
          //     return wordCount >= minWords && wordCount <= maxWords;
          //   });
          // }

          if (minWords && maxWords) {
            filteredData = filteredData.filter(item => {
              const wordCount = item.text.split(/\s+/).length;
              return wordCount >= minWords && wordCount <= maxWords;
            });
          } else if (minWords) {
            filteredData = filteredData.filter(item => {
              const wordCount = item.text.split(/\s+/).length;
              return wordCount >= minWords;
            });
          } else if (maxWords) {
            filteredData = filteredData.filter(item => {
              const wordCount = item.text.split(/\s+/).length;
              return wordCount <= maxWords;
            });
          }



        // Domain Authority Filter
        // if (minDa && maxDa) {
        //   filteredData = filteredData.filter(item => {
        //     if (!item.items || item.items.length === 0) return false;
        //     const lowestDA = Math.min(...item.items.map(i => i.domain_authority));
        //     const highestDA = Math.max(...item.items.map(i => i.domain_authority));
        //     return lowestDA >= minDa && highestDA <= maxDa;
        //   });
        // }

          // Updated
          if (minDa || maxDa) {
            filteredData = filteredData.filter(item => {
                if (!item.items || item.items.length === 0) return false;
                const domainAuthorities = item.items.map(i => i.domain_authority);
                const lowestDA = Math.min(...domainAuthorities);
                const highestDA = Math.max(...domainAuthorities);
                return (minDa ? lowestDA >= minDa : true) && (maxDa ? highestDA <= maxDa : true);
            });
        }
        if (minPa || maxPa) {
          filteredData = filteredData.filter(item => {
              if (!item.items || item.items.length === 0) return false;
              const pageAuthorities = item.items.map(i => i.page_authority);
              const lowestDA = Math.min(...pageAuthorities);
              const highestDA = Math.max(...pageAuthorities);
              return (minPa ? lowestDA >= minPa : true) && (maxPa ? highestDA <= maxPa : true);
          });
      }

          if (minmozLinks || maxmozLinks) {
            filteredData = filteredData.filter(item => {
              if (!item.items || item.items.length === 0) return false;
              const mozLinkCounts = item.items.map(i => parseInt(i.mozLinks));
              const lowestMozLink = Math.min(...mozLinkCounts);
              const highestMozLink = Math.max(...mozLinkCounts);

              return (!minmozLinks || lowestMozLink >= minmozLinks) && 
                    (!maxmozLinks || highestMozLink <= maxmozLinks);
            });
          }

                if (minmajesticRefDomains || maxmajesticRefDomains) {
                    filteredData = filteredData.filter(item => {
                      if (!item.items || item.items.length === 0) return false;
                      const majesticRefDomainsCounts = item.items.map(i => parseInt(i.majesticRefDomains));
                      const lowestMajesticRefDomainCount = Math.min(...majesticRefDomainsCounts);
                      const highestMajesticRefDomainCount = Math.max(...majesticRefDomainsCounts);

                      return (!minmajesticRefDomains || lowestMajesticRefDomainCount >= minmajesticRefDomains) && 
                            (!maxmajesticRefDomains || highestMajesticRefDomainCount <= maxmajesticRefDomains);
                    });
                  }



         // Cost Per Click Filter
          if (minCpc || maxCpc) {
            const minCpcMake = minCpc ? parseFloat(minCpc) : 0;
            const maxCpcMake = maxCpc ? parseFloat(maxCpc) : Infinity;

            filteredData = filteredData.filter(item => {
              const highTopOfPageBidMicros = item.keyword_idea_metrics?.low_top_of_page_bid_micros ?? 0;
              const convertedValue = ((highTopOfPageBidMicros / 1000000) ).toFixed(2);
              const avgCpc = parseFloat(convertedValue);
              return avgCpc >= minCpcMake || avgCpc <= maxCpcMake;
            });
          }

            // All Words Filter
            if (allWords) {
              const allWordsArray = allWords.split(',').map(word => word.trim().toLowerCase());
              filteredData = filteredData.filter(item => allWordsArray.every(word => item.text.toLowerCase().includes(word)));
            }

            // Any Words Filter
            if (anyWords) {
              const anyWordsArray = anyWords.split(',').map(word => word.trim().toLowerCase());
              filteredData = filteredData.filter(item => anyWordsArray.some(word => item.text.toLowerCase().includes(word)));
            }

             // Exclude Words Filter
              if (excludeWords) {
                const excludeWordsArray = excludeWords.split(',').map(word => word.trim().toLowerCase());
                filteredData = filteredData.filter(item => !excludeWordsArray.some(word => item.text.toLowerCase().includes(word)));
              }

              // Search Term Filter
              if (searchTerm) {
                filteredData = filteredData.filter(item =>
                  item.text.toLowerCase().includes(searchTerm.toLowerCase())
                );
              }



        return filteredData;
      
      }, []);
// console.log(searchTerm, "searchTerm")

      const filteredKeywordData = useMemo(() => {
        return {
          ...keywordData,
          keywordData: applyFilters(keywordData, filters, activeTab)
        };
      }, [keywordData, filters, activeTab]);

      const handleTabChange = key => {
        setActiveTab(key);
      }; 

    

      // Determine which keyword data to use based on the presence of getMainKeywordData
// const effectiveKeywordData = getMainKeywordData ? getMainKeywordData : keywordData;


      // Applying filters to different data sets
      // const allKeywordFilter = {
      //   ...effectiveKeywordData,
      //   keywordData: applyFilters(keywordData, filters, 'default')
      // };

      // const keywordDataSuggestionFilter = {
      //   ...effectiveKeywordData,
      //   keywordData: applyFilters(keywordData, filters, 'suggestion')
      // };

      // const keywordRelatedFilter = {
      //   ...effectiveKeywordData,
      //   keywordData: applyFilters(keywordData, filters, 'related')
      // };

      // const keywordFavouriteFilter = {
      //   ...effectiveKeywordData,
      //   keywordData: applyFilters(keywordData, filters, 'favourite')
      // };

      // console.log(effectiveKeywordData, "effectiveKeywordData")

      // const allKeywordFilter = useMemo(() => ({
      //   ...keywordData,
      //   keywordData: applyFilters(keywordData, filters, 'default')
      // }), [keywordData, keywordData, filters]);
      
      // const keywordDataSuggestionFilter = useMemo(() => ({
      //   ...keywordData,
      //   keywordData: applyFilters(keywordData, filters, 'suggestion')
      // }), [keywordData, keywordData, filters]);
      
      // const keywordRelatedFilter = useMemo(() => ({
      //   ...keywordData,
      //   keywordData: applyFilters(keywordData, filters, 'related')
      // }), [keywordData, keywordData, filters]);
      
      // const keywordFavouriteFilter = useMemo(() => ({
      //   ...keywordData,
      //   keywordData: applyFilters(keywordData, filters, 'favourite')
      // }), [keywordData, keywordData, filters]);
      
    

    // console.log(keywordDataSuggestionFilter, "keywordDataSuggestionFilter")
   
    //   useEffect(() => {
    //     let filteredData = keywordData?.keywordData;
    //     // let filteredData = keywordData?.keywordData ? [...keywordData.keywordData] : [];

    //     // Tab specific filtering
    //     // switch (activeTab) {
    //     //     case '1':
    //     //         filteredData = keywordData?.keywordData
    //     //         break;
    //     //     case '2':
    //     //         filteredData = filteredData.filter(keyword => keyword.id?.startsWith("suggestion"));
    //     //         break;
    //     //     case '3':
    //     //         filteredData = filteredData.filter(keyword => keyword.id?.startsWith("related"));
    //     //         break;
    //     //     case '4':
    //     //         filteredData = filteredData.filter(keyword => keyword.isFavorite);
    //     //         break;
    //     //     default:
    //     //         filteredData = [];
    //     // }

    //     // let filteredData;
        
        
    //     // Now use filteredData for your purposes
        
        
   

    //     // Apply other filters
    //     const { minVolume, maxVolume, minWords, maxWords, minCpc, maxCpc, minDa, maxDa, allWords, anyWords, excludeWords } = filters;

    //     // if (minVolume !== '' && maxVolume !== '') {
    //     if (minVolume  && maxVolume ) {
    //         const minVol = parseFloat(minVolume);
    //         const maxVol = parseFloat(maxVolume);
        
    //         filteredData = filteredData.filter(item => {
    //           const volume = parseFloat(item.keyword_idea_metrics.avg_monthly_searches || '0'); // Corrected path
    //           return volume >= minVol && volume <= maxVol;
    //         });
    //         console.log("After Volume Filter: ", filteredData);
    //       }

    //     if (minWords && maxWords) {
    //         filteredData = filteredData.filter(item => {
    //             const wordCount = item.text.split(/\s+/).length;
    //             return wordCount >= minWords && wordCount <= maxWords;
    //         });
    //     }

    //     // ... similar logic for minCpc, maxCpc, minDa, maxDa
       

    //     if (minDa && maxDa) {
    //         filteredData = filteredData.filter(item => {
    //           if (!item.items || item.items.length === 0) return false;
    //           const lowestDA = Math.min(...item.items.map(i => i.domain_authority));
    //           const highestDA = Math.max(...item.items.map(i => i.domain_authority));
    //           return lowestDA >= minDa && highestDA <= maxDa;
    //       });
    //       }

    //       if (minCpc || maxCpc) {
    //         const minCpcMake = minCpc ? parseFloat(minCpc) : 0; // Default to 0 if not provided
    //         const maxCpcMake = maxCpc ? parseFloat(maxCpc) : Infinity; // Default to Infinity if not provided
        
    //         filteredData = filteredData.filter(item => {
    //             const highTopOfPageBidMicros = item.keyword_idea_metrics?.high_top_of_page_bid_micros ?? 0;
    //             const convertedValue = ((highTopOfPageBidMicros / 1000000) * 290).toFixed(2);
    //             const avgCpc = parseFloat(convertedValue);
    //             return avgCpc >= minCpcMake && avgCpc <= maxCpcMake;
    //         });
    //     }


    //     //   if (minCpc) {
    //     //     const minCpcMake = parseFloat(minCpc);
    //     //     filteredData = filteredData.filter(item => {
    //     //         const highTopOfPageBidMicros = item.keyword_idea_metrics?.high_top_of_page_bid_micros ?? 0;
    //     //         const convertedValue = ((highTopOfPageBidMicros / 1000000) * 290).toFixed(2);
    //     //         const avgCpc = parseFloat(convertedValue);
    //     //         return avgCpc >= minCpcMake;
    //     //     });
    //     // }
        
    //     // if (maxCpc) {
    //     //     const maxCpcMake = parseFloat(maxCpc);
    //     //     filteredData = filteredData.filter(item => {
    //     //         const highTopOfPageBidMicros = item.keyword_idea_metrics?.high_top_of_page_bid_micros ?? 0;
    //     //         const convertedValue = ((highTopOfPageBidMicros / 1000000) * 290).toFixed(2);
    //     //         const avgCpc = parseFloat(convertedValue);
    //     //         return avgCpc <= maxCpcMake;
    //     //     });
    //     // }
        

    //     if (allWords) {
    //         const allWordsArray = allWords.split(',').map(word => word.trim().toLowerCase());
    //         filteredData = filteredData.filter(item => {
    //             return allWordsArray.every(word => item.text.toLowerCase().includes(word));
    //         });
    //     }

    //     if (anyWords) {
    //         const anyWordsArray = anyWords.split(',').map(word => word.trim().toLowerCase());
    //         filteredData = filteredData.filter(item => {
    //             return anyWordsArray.some(word => item.text.toLowerCase().includes(word));
    //         });
    //     }

    //     if (excludeWords) {
    //         const excludeWordsArray = excludeWords.split(',').map(word => word.trim().toLowerCase());
    //         filteredData = filteredData.filter(item => {
    //             return !excludeWordsArray.some(word => item.text.toLowerCase().includes(word));
    //         });
    //     }


    //     filteredData = filteredData.filter((item) =>
    //   item.text.toLowerCase().includes(searchTerm.toLowerCase())
    // );

    //     // setTableKeywordData({ ...keywordData, keywordData: filteredData });
    //     setTableKeywordData ({ ...keywordData, keywordData: filteredData });

    // }, [activeTab, keywordData, filters, searchTerm, ]);
    
    
  

    // const transformDataForCSV = (data) => {
    //     let csvData = [];
    //     let previousKeyword = null;
    //   console.log(data, "from csv");
    //   data?.keywordData?.forEach(keyword => {
    //         if (keyword.items.length === 0) {
    //             // For keywords with no items, still include the keyword and its metrics
    //             const monthlySearchVolumes = keyword.keyword_idea_metrics.monthly_search_volumes.map(volume => `${volume.month}-${volume.year}: ${volume.monthly_searches}`).join('; ');
    //             csvData.push({
    //                 keywordText: keyword.text,
    //                 avgMonthlySearches: keyword.keyword_idea_metrics?.avg_monthly_searches ?? 0,
    //                 highTopOfPageBidMicros: keyword.keyword_idea_metrics?.high_top_of_page_bid_micros ?? 0,
    //                 monthlySearchVolumes: monthlySearchVolumes,
    //                 itemTitle: '',
    //                 itemFormattedUrl: '',
    //                 itemPageAuthority: '',
    //                 // ... Any other item properties set to empty
    //             });
    //         } else {
    //             keyword.items.forEach((item, index) => {
    //                 // Flatten monthly search volumes
    //                 const monthlySearchVolumes = keyword.keyword_idea_metrics.monthly_search_volumes.map(volume => `${volume.month}-${volume.year}: ${volume.monthly_searches}`).join('; ');
      
    //                 const isFirstOccurrence = previousKeyword !== keyword.text;
    //                 const keywordText = isFirstOccurrence ? keyword.text : '';
    //                 const avgMonthlySearches = isFirstOccurrence ? keyword.keyword_idea_metrics?.avg_monthly_searches ?? 0 : '';
    //                 const highTopOfPageBidMicros = isFirstOccurrence ? keyword.keyword_idea_metrics?.high_top_of_page_bid_micros ?? 0 : '';
    //                 const formattedMonthlySearchVolumes = isFirstOccurrence ? monthlySearchVolumes : '';
      
    //                 csvData.push({
    //                     keywordText: keywordText,
    //                     avgMonthlySearches: avgMonthlySearches,
    //                     highTopOfPageBidMicros: highTopOfPageBidMicros,
    //                     monthlySearchVolumes: formattedMonthlySearchVolumes,
    //                     itemTitle: item.title,
    //                     itemFormattedUrl: item.formattedUrl,
    //                     itemDomainAuthority: item.domain_authority,
    //                     itemPageAuthority: item.page_authority,
    //                     spamScore: item.spam_score,
    //                     // ... Add other item properties as needed
    //                 });
      
    //                 // Update the previousKeyword variable for the next iteration
    //                 if (index === 0) {
    //                     previousKeyword = keyword.text;
    //                 }
    //             });
    //         }
    //     });
      
    //     return csvData;
    //   };

  //   const transformDataForCSV = (data) => {
  //     let csvData = [];
  //     console.log(data , "daTa from transformDataForCSV")
  //     data?.keywordData?.forEach(keyword => {
  //         // Simply add the keyword text to the csvData array
  //         csvData.push({
  //             keywordText: keyword.text,
              
  //         });
  //     });
  
  //     return csvData;
  // };
 const transformDataForCSV = (data) => {
    let csvData = [];
    let previousKeyword = null;

    data?.keywordData?.forEach(keyword => {
        const hasItems = keyword.items.length > 0;

        // Calculate monthly search volumes string
        const monthlySearchVolumes = keyword.keyword_idea_metrics.monthly_search_volumes.map(volume => `${volume.month}-${volume.year}: ${volume.monthly_searches}`).join('; ');
        
        // Ensure avgMonthlySearches is set to 10 if it's 0, an empty string, or undefined
        let avgMonthlySearches = keyword.keyword_idea_metrics?.avg_monthly_searches || 0;
        avgMonthlySearches = avgMonthlySearches === 0 || avgMonthlySearches === '' ? 10 : avgMonthlySearches;

        // Calculate KGR, defaulting to an empty string if necessary values aren't available
        const allInTitle = keyword.keyword_idea_metrics?.allintitleTotalResults ?? '';
        const kgr = allInTitle && avgMonthlySearches ? (allInTitle / avgMonthlySearches).toFixed(2) : '';

        const baseData = {
            keywordText: keyword.text,
            avgMonthlySearches: keyword.keyword_idea_metrics?.avg_monthly_searches || 0,
            allInTitle: allInTitle,
            KGR: kgr,
            monthlySearchVolumes: hasItems ? monthlySearchVolumes : '',
        };

        if (!hasItems) {
            csvData.push({
                ...baseData,
                pageRank: '',
                itemTitle: '',
                itemFormattedUrl: '',
                itemPageAuthority: '',
                // Add any other item properties as needed, set to empty
            });
        } else {
            keyword.items.forEach((item, index) => {
                const isFirstOccurrence = previousKeyword !== keyword.text;
                const keywordText = isFirstOccurrence ? keyword.text : '';
                const formattedMonthlySearchVolumes = isFirstOccurrence ? monthlySearchVolumes : '';
                const displayKGR = isFirstOccurrence ? kgr : ''; // Only display KGR for the first item occurrence

                csvData.push({
                    keywordText: keywordText,
                    avgMonthlySearches: isFirstOccurrence ? avgMonthlySearches : '',
                    allInTitle: isFirstOccurrence ? allInTitle : '',
                    KGR: displayKGR,
                    monthlySearchVolumes: formattedMonthlySearchVolumes,
                    pageRank: item.rank ?? '',
                    itemTitle: item.title,
                    itemFormattedUrl: item.formattedUrl,
                    itemDomainAuthority: item.domain_authority,
                    itemPageAuthority: item.page_authority,
                    spamScore: item.spam_score,
                    // ... Add other item properties as needed
                });

                if (index === 0) {
                    previousKeyword = keyword.text;
                }
            });
        }
    });

    return csvData;
};


    const headers = [
        { label: 'Keywords', key: 'keywordText' },
        { label: 'Average Monthly Searches', key: 'avgMonthlySearches' },
        { label: 'All in Title', key: 'allInTitle' },
        { label: 'KGR', key: 'KGR' },
        { label: 'Monthly Search Volumes', key: 'monthlySearchVolumes' },
        { label: 'Title', key: 'itemTitle' },
        { label: 'URL', key: 'itemFormattedUrl' },
        { label: 'Domain Authority', key: 'itemDomainAuthority' },
        { label: 'Page Authority', key: 'itemPageAuthority' },
        { label: 'Spam Score', key: 'spamScore' },
        { label: 'Page Rank', key: 'pageRank' }
        // ... Add other headers as needed
        ];
        
        const csvReport = {
        data: transformDataForCSV(selectedKeywordData),
        headers: headers,
        filename: 'ExportedData.csv'
        };

        // onTabClick={(key) => setActiveTab(key)}
        const textToCopy = selectedKeywordData?.keywordData.map(item => item.text).join('\n');
        const handleCopy = () => {
          setIsCopied(true);
          setTimeout(() => setIsCopied(false), 3000); // Revert back after 3 seconds
        };
      
        const [isColorClassApplied, setIsColorClassApplied] = useState(false);

      
const handleKGRClick = () => {
  setIsColorClassApplied(prevState => !prevState); // Toggle the background color state
  setFilters({
    minVolume: '',
    maxVolume: isColorClassApplied ? "" : "250",
    minWords: '',
    maxWords: '',
    minCpc: '',
    maxCpc: '',
    minDa: '',
    maxDa: '',
    allWords: '',
    anyWords: '',
    excludeWords: '',
    searchTerm: '',
    allInTitleMin: "",
    allInTitleMax: "",
    allInUrlsMin: "",
    allInUrlsMax: "",
    kgrRatioMin: "",
    kgrRatioMax: isColorClassApplied ? "" : "0.25",
    selectedCategories: [],
    goldenRuleName: ""
  });
};
const [selectedPresetId, setSelectedPresetId] = useState(null);
const handlePresetClick = (preset) => {
  
  if (preset._id === selectedPresetId) {
   
    setSelectedPresetId(null);
    setFilters({
      minVolume: '',
        maxVolume: '',
        minWords: '',
        maxWords: '',
        minCpc: '',
        maxCpc: '',
        minDa: '',
        maxDa: '',
        allWords: '',
        anyWords: '',
        excludeWords: '',
        searchTerm: '',
        allInTitleMin: "",
        allInTitleMax: "",
        allInUrlsMin: "",
        allInUrlsMax: "",
        kgrRatioMin: "",
        kgrRatioMax: "",
        selectedCategories: [],
        goldenRuleName: ""
    }); // Optionally clear the values or handle as per your requirement
  } else {
    // If not already selected, select the new preset and update values
    setFilters(preset);
    setSelectedPresetId(preset._id);
  }
};
const presetHandleSubmit = async () => {


  try {
    toast.info("Saving preset...", { autoClose: 3000 }); // Show a loading message

    
    const response = await axios.post('/mainpresets/updatePreset', { userId: userData._id, newPreset: filters });
      console.log(response, "response")
      setPresets(response.data)
    localStorage.setItem("userData", JSON.stringify(response.data));
    // If the operation is successful
    if (response.status === 200) {
      toast.success("Preset saved successfully!", { autoClose: 3000 });
    } else {
      // If the server responds with a status other than 200
      toast.error("Failed to save preset. Please try again.", { autoClose: 5000 });
    }
  } catch (error) {
    // If there's an error during the Axios request
    console.error("Error sending preset update request:", error);
    toast.error("Failed to save preset. Please try again.", { autoClose: 5000 });
  }
};



useEffect(() => {
  const fetchData = async () => {
    // Ensure loading state is true when the operation starts
    toast.info("Fetching preset...", { autoClose: 3000 });
    
    const timestamp = new Date().getTime(); 

    try {
      const response = await axios.get(`/api/user/credits/${userData._id}?timestamp=${timestamp}`);
      // Assuming the response data structure matches your state
      setPresets(response.data.credits)
      console.log(response, "response response for presets")
      // Notification for success
      // toast.success("Presets has been fetched successfully");
    } catch (error) {
      // Logging and notifying about the error
      console.error("Error fetching Reddit credentials:", error);
      toast.error("Failed to fetch presets");
    } 
  };


    fetchData();

}, []); 

console.log(filters)

    return (
        <>
        <ToastContainer
        position="top-right"
        autoClose={15000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
        <div className='my-4 border border-gray-300 bg-white rounded-lg xaxis p-4'>

      

            <Tabs defaultActiveKey="1"   className="custom-tabs xaxis"  onTabClick={handleTabChange}>
           
               
                <TabPane 
                    tab={
                        <span className={getTabClass("1")}>
                            <DatabaseOutlined className='mr-1' />
                            All Keywords
                        </span>
                    } 
                    key="1">
                            
            
            <div className='mt-2 mb-3'>
                <Space wrap>
                <Popover content={content} trigger="click" placement="bottom">
                <Button className='no-hover-effect shadow-sm' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' , marginBottom: "1px"}}>Words <DownOutlined style={{ fontSize: '12px', marginLeft: '7px', }} /></Button>
                </Popover>
                <Popover content={volumeContent} trigger="click" placement="bottom">
                <Button className='no-hover-effect shadow-sm' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: "1px" , marginBottom: "1px"}}>Search Volume <DownOutlined style={{ fontSize: '12px', marginLeft: '7px', }} /></Button>
                </Popover>
                <Popover content={includeContent} trigger="click" placement="bottom">
                <Button className='no-hover-effect shadow-sm' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: "1px" , marginBottom: "1px"}}>Include <DownOutlined style={{ fontSize: '12px', marginLeft: '7px', }} /></Button>
                </Popover>
                <Popover content={excludeContent} trigger="click" placement="bottom">
                <Button className='no-hover-effect shadow-sm' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: "1px" , marginBottom: "1px"}}>Exclude <DownOutlined style={{ fontSize: '12px', marginLeft: '7px', }} /></Button>
                </Popover>
                <Popover content={cpcContent} trigger="click" placement="bottom">
                <Button className='no-hover-effect shadow-sm' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: "1px" , marginBottom: "1px"}}>CPC <DownOutlined style={{ fontSize: '12px', marginLeft: '7px', }} /></Button>
                </Popover>
                <Popover content={daContent} trigger="click" placement="bottom">
                <Button className='no-hover-effect shadow-sm' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: "1px" , marginBottom: "1px"}}>DA <DownOutlined style={{ fontSize: '12px', marginLeft: '7px', }} /></Button>
                </Popover>
                <Popover content={paContent} trigger="click" placement="bottom">
                <Button className='no-hover-effect shadow-sm' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: "1px" , marginBottom: "1px"}}>PA <DownOutlined style={{ fontSize: '12px', marginLeft: '7px', }} /></Button>
                </Popover>
                <Popover content={linksContent} trigger="click" placement="bottom">
                <Button className='no-hover-effect shadow-sm' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: "1px" , marginBottom: "1px"}}>Number of Backlinks <DownOutlined style={{ fontSize: '12px', marginLeft: '7px', }} /></Button>
                </Popover>
                <Popover content={refContent} trigger="click" placement="bottom">
                <Button className='no-hover-effect shadow-sm' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: "1px" , marginBottom: "1px"}}>Number of referring domains <DownOutlined style={{ fontSize: '12px', marginLeft: '7px', }} /></Button>
                </Popover>
                <Popover content={AllinTitleContent} trigger="click" placement="bottom">
                <Button className='no-hover-effect shadow-sm' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: "1px" , marginBottom: "1px"}}>All in Title <DownOutlined style={{ fontSize: '12px', marginLeft: '7px', }} /></Button>
                </Popover>
                <Popover content={AllinUrlContent} trigger="click" placement="bottom">
                <Button className='no-hover-effect shadow-sm' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: "1px" , marginBottom: "1px"}}>All in Url <DownOutlined style={{ fontSize: '12px', marginLeft: '7px', }} /></Button>
                </Popover>
                <Popover content={AllinKGRContent} trigger="click" placement="bottom">
                <Button className='no-hover-effect shadow-sm' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: "1px" , marginBottom: "1px"}}>KGR Ratio <DownOutlined style={{ fontSize: '12px', marginLeft: '7px', }} /></Button>
                </Popover>
                <Search
        placeholder="search keyword"
        allowClear
        // onSearch={onSearch}
        value={filters.searchTerm}
        onChange={(e) => handleFilterChange('searchTerm', e.target.value)}
        style={{ width: 270, display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: "1px" , marginBottom: "1px"}}
            />


<CopyToClipboard text={textToCopy} onCopy={handleCopy}>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
      }}>
        <i className={isCopied ? "bi bi-check-lg" : "bi bi-clipboard"} 
           style={{ fontSize: '1.5em', marginBottom: "3px" }}>
        </i>
      </div>
    </CopyToClipboard>
         {/* <Button className='no-hover-effect shadow-sm' style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: "1px" , marginBottom: "10px"}}><CloudDownloadOutlined style={{ fontSize: '15px', marginLeft: '3px', }} /> Export</Button> */}
         <CSVLink 
    data={csvReport.data} 
    headers={csvReport.headers} 
    filename={"my-file.csv"} 
    // className="btn btn-dark"
>
    <Button 
        className='no-hover-effect shadow-sm' 
        style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: "1px", marginBottom: "1px"}}
    >
        <CloudDownloadOutlined style={{ fontSize: '15px', marginLeft: '3px' }} />
        Export
    </Button>
</CSVLink>


                <Checkbox checked={filters.selectedCategories.includes("Questions")} onChange={() => handleCheckboxChange("Questions")}>Questions</Checkbox> <Checkbox checked={filters.selectedCategories.includes("Investigative Keywords")} onChange={() => handleCheckboxChange("Investigative Keywords")}>Investigative Keywords</Checkbox>  
        <Checkbox checked={filters.selectedCategories.includes("Commercial Keywords")} onChange={() => handleCheckboxChange("Commercial Keywords")}>Commercial Keywords</Checkbox> <Checkbox checked={filters.selectedCategories.includes("Informational Keywords")} onChange={() => handleCheckboxChange("Informational Keywords")}>Informational Keywords</Checkbox>
       

                </Space>

<div> 

</div>
       
</div>

<div className='mt-2 presets'>
        <h6 style={{color: '#86898c'}}><b>Presets</b> <QuestionCircleOutlined style={{color: '#86898c', marginLeft: '4px'}} /></h6>
        <div className="presetsContent mt-2">
        <span className="presetsBadge" style={{marginLeft: '5px' }}>Traffic Butler Ratio</span>
        {/* <span className="presetsBadgeCustom" style={{ }} onClick={handleKGRClick}>KGR Ratio</span> */}
        <span className={`presetsBadgeCustom ${isColorClassApplied ? 'colorClass' : ''}`} onClick={handleKGRClick}>KGR Ratio</span>

        {presets?.presets.map((preset) => (
  <span key={preset._id} className={`presetsBadgeCustom ${selectedPresetId === preset._id ? 'colorClass' : ''}`}  style={{ }} onClick={() => handlePresetClick(preset)}>{preset.goldenRuleName}</span>
))}
 <input type="text" class="presetsBadgeAdd" value={filters.goldenRuleName} placeholder="Add New Preset" style={{  }} onChange={(e) => handleFilterChange('goldenRuleName', e.target.value)}  />

        <span className="presetsBadgeSave" style={{}} onClick={presetHandleSubmit}>Save</span>
        </div>
        </div>

                   <MainTableKeywords mainProp={filteredKeywordData }  />
                </TabPane>
                <TabPane 
                    tab={
                        <span className={getTabClass("2")}>
                            <GoogleOutlined className='mr-1' />
                            Google Suggestions
                        </span>
                    } 
                    key="2">
                       
                       <div className='mt-2 mb-4'>
                <Space wrap>
                <Popover content={content} trigger="click" placement="bottom">
                <Button className='no-hover-effect shadow-sm' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' , marginBottom: "10px"}}>Words <DownOutlined style={{ fontSize: '12px', marginLeft: '7px', }} /></Button>
                </Popover>
                <Popover content={volumeContent} trigger="click" placement="bottom">
                <Button className='no-hover-effect shadow-sm' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: "1px" , marginBottom: "10px"}}>Search Volume <DownOutlined style={{ fontSize: '12px', marginLeft: '7px', }} /></Button>
                </Popover>
                <Popover content={includeContent} trigger="click" placement="bottom">
                <Button className='no-hover-effect shadow-sm' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: "1px" , marginBottom: "10px"}}>Include <DownOutlined style={{ fontSize: '12px', marginLeft: '7px', }} /></Button>
                </Popover>
                <Popover content={excludeContent} trigger="click" placement="bottom">
                <Button className='no-hover-effect shadow-sm' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: "1px" , marginBottom: "10px"}}>Exclude <DownOutlined style={{ fontSize: '12px', marginLeft: '7px', }} /></Button>
                </Popover>
                <Popover content={cpcContent} trigger="click" placement="bottom">
                <Button className='no-hover-effect shadow-sm' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: "1px" , marginBottom: "10px"}}>CPC <DownOutlined style={{ fontSize: '12px', marginLeft: '7px', }} /></Button>
                </Popover>
                <Popover content={daContent} trigger="click" placement="bottom">
                <Button className='no-hover-effect shadow-sm' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: "1px" , marginBottom: "10px"}}>DA <DownOutlined style={{ fontSize: '12px', marginLeft: '7px', }} /></Button>
                </Popover>
                <Search
        placeholder="search keyword"
        allowClear
        // onSearch={onSearch}
        onChange={(e) => handleFilterChange('searchTerm', e.target.value)}

        style={{ width: 270, display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: "1px" , marginBottom: "10px"}}
            />
            <CopyToClipboard text={textToCopy} onCopy={handleCopy}>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
      }}>
        <i className={isCopied ? "bi bi-check-lg" : "bi bi-clipboard"} 
           style={{ fontSize: '1.5em', marginBottom: "8px" }}>
        </i>
      </div>
    </CopyToClipboard>

         {/* <Button className='no-hover-effect shadow-sm' style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: "1px" , marginBottom: "10px"}}><CloudDownloadOutlined style={{ fontSize: '15px', marginLeft: '3px', }} /> Export</Button> */}
         <CSVLink 
    data={csvReport.data} 
    headers={csvReport.headers} 
    filename={"my-file.csv"} 
    // className="btn btn-dark"
>
    <Button 
        className='no-hover-effect shadow-sm' 
        style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: "1px", marginBottom: "10px"}}
    >
        <CloudDownloadOutlined style={{ fontSize: '15px', marginLeft: '3px' }} />
        Export
    </Button>
</CSVLink>

                
                </Space>

<div> 

</div>
       
</div>

                   <MainTableKeywords mainProp={filteredKeywordData }  />


                </TabPane>
                <TabPane 
                    tab={
                        <span className={getTabClass("3")}>
                            <SearchOutlined className='mr-1' />
                            Google Auto Complete
                        </span>
                    } 
                    key="3">
                      <div className='mt-2 mb-4'>
                <Space wrap>
                <Popover content={content} trigger="click" placement="bottom">
                <Button className='no-hover-effect shadow-sm' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' , marginBottom: "10px"}}>Words <DownOutlined style={{ fontSize: '12px', marginLeft: '7px', }} /></Button>
                </Popover>
                <Popover content={volumeContent} trigger="click" placement="bottom">
                <Button className='no-hover-effect shadow-sm' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: "1px" , marginBottom: "10px"}}>Search Volume <DownOutlined style={{ fontSize: '12px', marginLeft: '7px', }} /></Button>
                </Popover>
                <Popover content={includeContent} trigger="click" placement="bottom">
                <Button className='no-hover-effect shadow-sm' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: "1px" , marginBottom: "10px"}}>Include <DownOutlined style={{ fontSize: '12px', marginLeft: '7px', }} /></Button>
                </Popover>
                <Popover content={excludeContent} trigger="click" placement="bottom">
                <Button className='no-hover-effect shadow-sm' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: "1px" , marginBottom: "10px"}}>Exclude <DownOutlined style={{ fontSize: '12px', marginLeft: '7px', }} /></Button>
                </Popover>
                <Popover content={cpcContent} trigger="click" placement="bottom">
                <Button className='no-hover-effect shadow-sm' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: "1px" , marginBottom: "10px"}}>CPC <DownOutlined style={{ fontSize: '12px', marginLeft: '7px', }} /></Button>
                </Popover>
                <Popover content={daContent} trigger="click" placement="bottom">
                <Button className='no-hover-effect shadow-sm' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: "1px" , marginBottom: "10px"}}>DA <DownOutlined style={{ fontSize: '12px', marginLeft: '7px', }} /></Button>
                </Popover>
                <Search
        placeholder="search keyword"
        allowClear
        // onSearch={onSearch}
        onChange={(e) => handleFilterChange('searchTerm', e.target.value)}

        style={{ width: 270, display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: "1px" , marginBottom: "10px"}}
            />
        <CopyToClipboard text={textToCopy} onCopy={handleCopy}>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
      }}>
        <i className={isCopied ? "bi bi-check-lg" : "bi bi-clipboard"} 
           style={{ fontSize: '1.5em', marginBottom: "8px" }}>
        </i>
      </div>
    </CopyToClipboard>

         {/* <Button className='no-hover-effect shadow-sm' style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: "1px" , marginBottom: "10px"}}><CloudDownloadOutlined style={{ fontSize: '15px', marginLeft: '3px', }} /> Export</Button> */}
         <CSVLink 
    data={csvReport.data} 
    headers={csvReport.headers} 
    filename={"my-file.csv"} 
    // className="btn btn-dark"
>
    <Button 
        className='no-hover-effect shadow-sm' 
        style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: "1px", marginBottom: "10px"}}
    >
        <CloudDownloadOutlined style={{ fontSize: '15px', marginLeft: '3px' }} />
        Export
    </Button>
</CSVLink>

                
                </Space>

<div> 

</div>
       
</div>

                   <MainTableKeywords mainProp={filteredKeywordData}  />
                </TabPane>
                <TabPane 
                    tab={
                        <span className={getTabClass("4")}>
                            <StarOutlined className='mr-1' />
                            Favourite
                            {/* <Badge count={5} offset={[10, 0]} /> */}
                        </span>
                    } 
                    key="4">
                      <div className='mt-2 mb-4'>
                <Space wrap>
                <Popover content={content} trigger="click" placement="bottom">
                <Button className='no-hover-effect shadow-sm' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' , marginBottom: "10px"}}>Words <DownOutlined style={{ fontSize: '12px', marginLeft: '7px', }} /></Button>
                </Popover>
                <Popover content={volumeContent} trigger="click" placement="bottom">
                <Button className='no-hover-effect shadow-sm' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: "1px" , marginBottom: "10px"}}>Search Volume <DownOutlined style={{ fontSize: '12px', marginLeft: '7px', }} /></Button>
                </Popover>
                <Popover content={includeContent} trigger="click" placement="bottom">
                <Button className='no-hover-effect shadow-sm' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: "1px" , marginBottom: "10px"}}>Include <DownOutlined style={{ fontSize: '12px', marginLeft: '7px', }} /></Button>
                </Popover>
                <Popover content={excludeContent} trigger="click" placement="bottom">
                <Button className='no-hover-effect shadow-sm' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: "1px" , marginBottom: "10px"}}>Exclude <DownOutlined style={{ fontSize: '12px', marginLeft: '7px', }} /></Button>
                </Popover>
                <Popover content={cpcContent} trigger="click" placement="bottom">
                <Button className='no-hover-effect shadow-sm' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: "1px" , marginBottom: "10px"}}>CPC <DownOutlined style={{ fontSize: '12px', marginLeft: '7px', }} /></Button>
                </Popover>
                <Popover content={daContent} trigger="click" placement="bottom">
                <Button className='no-hover-effect shadow-sm' style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: "1px" , marginBottom: "10px"}}>DA <DownOutlined style={{ fontSize: '12px', marginLeft: '7px', }} /></Button>
                </Popover>
                <Search
        placeholder="search keyword"
        allowClear
        // onSearch={onSearch}
        onChange={(e) => handleFilterChange('searchTerm', e.target.value)}

        style={{ width: 270, display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: "1px" , marginBottom: "10px"}}
            />
        <CopyToClipboard text={textToCopy} onCopy={handleCopy}>
      <div style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '100%'
      }}>
        <i className={isCopied ? "bi bi-check-lg" : "bi bi-clipboard"} 
           style={{ fontSize: '1.5em', marginBottom: "8px" }}>
        </i>
      </div>
    </CopyToClipboard>

         {/* <Button className='no-hover-effect shadow-sm' style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: "1px" , marginBottom: "10px"}}><CloudDownloadOutlined style={{ fontSize: '15px', marginLeft: '3px', }} /> Export</Button> */}
         <CSVLink 
    data={csvReport.data} 
    headers={csvReport.headers} 
    filename={"my-file.csv"} 
    // className="btn btn-dark"
>
    <Button 
        className='no-hover-effect shadow-sm' 
        style={{display: 'flex', alignItems: 'center', justifyContent: 'center', marginLeft: "1px", marginBottom: "10px"}}
    >
        <CloudDownloadOutlined style={{ fontSize: '15px', marginLeft: '3px' }} />
        Export
    </Button>
</CSVLink>

                
                </Space>

<div> 

</div>
       
</div>

                   <MainTableKeywords mainProp={filteredKeywordData}  />
                </TabPane>
                <TabPane 
                    tab={
                        <span className={getTabClass("5")}>
                            <TeamOutlined className='mr-1' />
                            Competitors
                        </span>
                    } 
                    key="5">
                   <Competitorswork keywordData={keywordData}/>
                </TabPane>
                <TabPane 
                    tab={
                        <span className={getTabClass("6")}>
                            <ClusterOutlined className='mr-1' />
                            Clusters
                        </span>
                    } 
                    key="6">
                    <ClusterNew keywordData={keywordData} />
                </TabPane>

                <TabPane 
                    tab={<span className={getTabClass("7")}>
                            <QuestionCircleOutlined className='mr-1' />
                            Questions
                        </span>} 
                    key="7">

                    <Questions  />
                </TabPane>

                {/* <TabPane 
                    tab={<span className={getTabClass("8")}>
                            <AppstoreAddOutlined className='mr-1' />
                            Presets
                        </span>} 
                    key="8">

                    <Presets mainProp={keywordData} />
                </TabPane> */}

            </Tabs>

          
        </div>
        </>
    )
}

export default TableDatadisplay;
