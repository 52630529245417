import React from 'react';
import ReactApexChart from 'react-apexcharts';

function RankTrackerChart({ keywordData }) {
  // Sort the data in descending order and take the latest entries as per pruneLimit
  const sortedAndLimitedData = keywordData.historicalRankings
    .sort((a, b) => new Date(b.date) - new Date(a.date))
    .slice(0, keywordData.pruneLimit)
    .map((entry) => ({
      x: new Date(entry.date).toISOString().substring(0, 10),
      y: entry.position === -1 ? 100 : entry.position, // Convert -1 position to 100
    }));

  const today = new Date();
  const lastNDays = new Array(keywordData.pruneLimit).fill(null).map((_, index) => {
    const date = new Date(today);
    date.setDate(today.getDate() - index);
    return date.toISOString().substring(0, 10);
  });

  lastNDays.forEach((date) => {
    const existingData = sortedAndLimitedData.find((entry) => entry.x === date);
    if (!existingData) {
      sortedAndLimitedData.push({ x: date, y: 100 }); // Default ranking to 100 if data is missing
    }
  });

  sortedAndLimitedData.sort((a, b) => new Date(a.x) - new Date(b.x));

  const options = {
    chart: {
      id: 'historical-rankings',
    },
    xaxis: {
      type: 'datetime',
    },
    yaxis: {
      title: {
        text: 'Ranking',
      },
      opposite: true, // Display y-axis on the right side
      reversed: true,
    }
  };

  return (
    <>
      <ReactApexChart
        options={options}
        series={[
          {
            name: 'Ranking',
            data: sortedAndLimitedData,
          },
        ]}
        type="line"
        height={300}
      />
    </>
  );
}

export default RankTrackerChart;
