import {  InfoCircleOutlined, UploadOutlined, PlusOutlined  } from '@ant-design/icons';
import React, { useState, useEffect } from 'react';
import BarSide2 from "../../BarSide2";
import { Container } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import { Upload,Space, Row, Col, Button, Input, Tabs,  Modal, Select, Flex , Tooltip } from "antd";
import 'react-toastify/dist/ReactToastify.css';
import { useParams } from 'react-router-dom';
import { useSubredditProjects } from '../../../auth/SubredditProjectsContext';
import TextAreaComponent from './TextAreaComponent';
import axios from "../../../components/Axios";
import MarkdownEditor from '@uiw/react-markdown-editor';
import { EditorView } from "@codemirror/view";
import OrderStatusTable from "./OrderStatusTable";
import pdfToText from "react-pdftotext";




const { TextArea } = Input;
const { Option } = Select;



const ProjectDetailsSubReddit = () => {

  const [favorites, setFavorites] = useState([]);
  const [selectedFavorite, setSelectedFavorite] = useState('');

  const [extractedText, setExtractedText] = useState('');

  const [orderData, setOrderData] = useState(null);
    const { subredditProjects } = useSubredditProjects();
    const { projectId } = useParams();
    const project = subredditProjects.find(p => p._id === projectId);
  
    const userDataString = localStorage.getItem("userData");
    const userData = userDataString ? JSON.parse(userDataString) : null;
    const { globalCtas } = userData;
    const [selectedCta, setSelectedCta] = useState("");
    const handleSelectChange = (value) => {
        setSelectedCta(value);
        console.log(`Selected CTA: ${value}`); // Optional: Log the value for debugging
      };

      const [loading, setLoading] = useState(false);
      const [open, setOpen] = useState(false);
      const showModal = () => {
        setOpen(true);
      };
      const handleOk = () => {
        setLoading(true);
        setTimeout(() => {
          setLoading(false);
          setOpen(false);
        }, 200);
      };
      const handleCancel = () => {
        setOpen(false);
      };

console.log(selectedCta, "selectedCta")
      const [textAreaValues, setTextAreaValues] = useState({
        tab1: `Craft an attention-grabbing introduction hook for a Reddit post based on the following video transcript: {[videoTranscript]}. The introduction should:
        Establish relevance to the target subreddit and topic
        Summarize the key information the post will cover in 1-2 concise sentences
        Naturally incorporate relevant keywords or phrases
        Pique curiosity and encourage users to read further
        Be short, scannable, and written in a conversational, relatable tone
        The goal is to create an introduction that immediately resonates with the Reddit community, sparks interest in the topic, and sets the stage for an informative and engaging post.
        `,
        tab2: `First, craft a compelling introduction hook for the Reddit post based on the following video transcript: {[videoTranscript]}. The introduction should:
        Clearly establish relevance to the target subreddit and topic
        Summarize the key information the post will cover in 1-2 concise sentences
        Naturally incorporate relevant keywords or phrases
        Pique curiosity and encourage users to read further
        Be short, scannable, and written in a conversational, relatable tone
        Next, analyze the video transcript and generate a comprehensive outline for an in-depth Reddit post on the topic. Structure the outline with 5-8 main sections using descriptive headings (using #) that incorporate relevant keywords or phrases from the video content. Under each main heading, include 2-4 subheadings (using ##) that break down the section into logical sub-topics or key points.
        Then, draft the post body following the outline. Write concise paragraphs (2-4 sentences) focusing on one main idea per paragraph. Use bullet points, numbered lists, and formatting (bold, italics, etc.) to enhance readability and scannability. Incorporate relevant keywords and phrases naturally throughout the text.
        Ensure the language is clear, conversational, and easy to understand for the target subreddit audience. Use relatable examples, analogies, and a friendly tone to keep readers engaged.
        Aim to provide valuable, actionable information that addresses the community's interests and needs. Encourage discussion by asking thought-provoking questions or inviting personal experiences.
        Conclude with a summary of key takeaways and a call-to-action that encourages further engagement, such as sharing personal stories, asking follow-up questions, or suggesting related resources.
        At 2-3 strategic points throughout the post body, identify opportunities to embed short video clips that directly illustrate or reinforce the main concepts being discussed. For each clip, follow these guidelines:
        Isolate a 5-10 second segment from the source video that clearly demonstrates a key step, technique, or example related to that specific section of the post.
        Ensure the clip is visually clear, informative, and easy to understand for the target Reddit audience.
        Briefly describe the clip's relevance in 1-2 sentences, providing context on how it enhances the reader's understanding of that particular topic or section.
        Include the video title, channel, and the specific time range for the isolated clip segment.
        Provide the YouTube embed code for that clip, properly formatted for seamless integration within the Reddit post.
        The goal is to strategically incorporate 2-3 highly relevant video clips throughout the post body that directly supplement the written content and provide visual aids to help readers grasp key concepts more effectively. These clips should be concise, informative, and enhance the overall value and engagement of the post for the target subreddit audience.
        Finally, proofread vigilantly for any errors and optimize for Reddit's formatting and community guidelines and Also add this CTA {[SelectedCta]} to the article and write in a persuasive copywriting style and tone.
        `,
        tab3: `Craft an engaging, attention-grabbing title for the Reddit post based on the video transcript: {[videoTranscript]}. The title should:
        Incorporate relevant keywords or phrases
        Pique curiosity and encourage clicks
        Be concise and easy to read
        Align with the tone and style of the target subreddit
        The goal is to create a title that stands out in the Reddit feed, accurately represents the post's content, and entices users to click and engage with the post.
        `,
        tab4: `Based on the video transcript: {[videoTranscript]}, please suggest 3-5 relevant subreddits where this content would be valuable and well-received. For each subreddit suggestion, provide a brief explanation (1-2 sentences) on why the content aligns with the subreddit's topic, interests, and community guidelines.`
      }); 

  

      const handleTextChange = (key, newValue) => {
        setTextAreaValues(prevValues => ({ ...prevValues, [key]: newValue }));
      };

      const [loadingOpenAI, setLoadingOpenAI] = useState(false);
      const [responses, setResponses] = useState([]);
      const [tab3TitleContent, setTab3TitleContent] = useState('');
      const [tab4TitleContent, setTab4TitleContent] = useState('');

      const sendOpenAIRequest = async (messageContent) => {
        // Replace the placeholder with the actual video transcript
        console.log(messageContent)
        const placeholder = "[videoTranscript]";
        const placeholder2 = "[SelectedCta]";
        if (messageContent.includes(placeholder)) {
          messageContent = messageContent.replace(placeholder, project.videoTranscript);
        } 
        if (messageContent.includes(placeholder2)) {
          messageContent = messageContent.replace(placeholder2, selectedCta);
        }
        console.log(messageContent, "After Conditions")
        try {
          const response = await axios.post('/moz/writewithopenai', {
            apiKey: userData.openaiApi,
            model: userData.openaiApiModel || 'gpt-3.5-turbo-0125',
            messages: [{ role: 'user', content: messageContent }]
          });
    
          return response.data.choices[0].message.content;
        } catch (error) {
          if (error.response) {
            toast.error(`Error ${error.response.status}: ${error.response.data.error}`);
            console.error('Error Response:', error.response.data);
          } else if (error.request) {
            toast.error('No response was received');
            console.error('Error Request:', error.request);
          } else {
            toast.error('Error setting up request');
            console.error('Error Message:', error.message);
          }
          return null;  // Return null in case of error
        }
      };

      const delay = (ms) => new Promise(resolve => setTimeout(resolve, ms));

    
      const handleSubmitAllTabs = async (event) => {
        event.preventDefault();
        setLoadingOpenAI(true);
    
        const newResponses = [];
    
        // Loop through the keys of textAreaValues to submit each tab's value
        for (const tabKey in textAreaValues) {
          if (textAreaValues.hasOwnProperty(tabKey)) {
            const randomDelay = Math.floor(Math.random() * (4000 - 2000 + 1)) + 2000;
            await delay(randomDelay);
            let content = await sendOpenAIRequest(textAreaValues[tabKey]);
            if (content !== null) {
              content = content.replace(/^"|"$/g, '').trim();
              newResponses.push({ tab: tabKey, content });
            }
          }
        }
    
        setResponses(newResponses);  // Update the responses state
        setLoadingOpenAI(false);
  
      };
      const [markdownEditorContent, setMarkdownEditorContent] = useState('');

      useEffect(() => {
        console.log(responses, "responses All Prompts")
        const tab3Response = responses.find((response) => response.tab === 'tab3');
        setTab3TitleContent(tab3Response ? tab3Response.content : '');
        const tab4esponse = responses.find((response) => response.tab === 'tab4');
        setTab4TitleContent(tab4esponse ? tab4esponse.content : '');
        const concatenatedContent = responses.slice(0, 2).map((record) => record.content).join('\n\n');
        setMarkdownEditorContent(concatenatedContent)
      }, [responses])
      
     
      

     
      const handleTitleChange = (event) => {
        setTab3TitleContent(event.target.value);
      };
     
      const handleMarkdownChange = (value) => {
        setMarkdownEditorContent(value);
      };
      const handle4TitleChange = (event) => {
        setTab4TitleContent(event.target.value);
      };

      const [subreddit, setSubreddit] = useState('');
      const [loadingSubreddit, setLoadingSubreddit] = useState(false);
      const [postLink, setPostLink] = useState(null);


console.log(subreddit, "subreddit is updating")

      const handlePostSubmit = async (e) => {

        if (!subreddit.trim()) {
          toast.error('Please enter a subreddit name before submitting.');
          return; // Stop execution if subreddit is invalid
        }

        if (!tab3TitleContent.trim()) {
          toast.error('Please enter a title for your post.');
          return; // Stop execution if title is invalid
        }

        const requestBody = {
          title: tab3TitleContent,
          content: markdownEditorContent,
          subreddit: subreddit,
          ...userData.redditCredentials
        };

        e.preventDefault();
        setLoadingSubreddit(true)
        try {
          const response = await axios.post('/moz/subreddit-post-publish', requestBody);
          console.log(response.data);
           // Extract the post link from the response data
          const submission = response.data.submission;
          const link = `https://www.reddit.com/r/${subreddit}/comments/${submission.name}`;
          setPostLink(link);
          toast.success(response.data.message);
        } catch (error) {
          console.error('Error while publishing post:', error);
          if (error.response && error.response.data && error.response.data.error) {
            toast.error(`Error: ${error.response.data.details}`);
          } else {
            toast.error('An internal server error occurred');
          }
        } finally {
       
          setLoadingSubreddit(false);
        }
        
      };


      // Upvotes

      const [postLinkReddit, setRedditPostLink] = useState('');
      const [quantity, setQuantity] = useState('');
      const [username, setUsername] = useState('');
      const [checkBalanceLoading, setCheckBalanceLoading] = useState(false);

    
      const handlePostLinkChange = (e) => {
        setRedditPostLink(e.target.value);
      };

      const handleUsernameChange = (e) => {
        setUsername(e.target.value);
      };
    
      const handleQuantityChange = (e) => {
        setQuantity(e.target.value);
      };
    
      const handleQuantityBlur = () => {
        let value = Number(quantity);
        if (value < 5) {
          setQuantity(5);
        } else if (value > 300) {
          setQuantity(300);
        }
      };


      const checkBalance = async () => {

        if (!userData.redUpvotesApi) {
          toast.error('RedUpvotes API key is missing');
          return;
        }

        setCheckBalanceLoading(true);
        try {
          const response = await axios.post('/moz/check-balance', { apiKey: userData.redUpvotesApi });
          console.log(response.data)
          toast.success(`Balance: ${response.data.balance} Currency: ${response.data.currency}`);
        } catch (error) {
          console.log(error, "error handling");
          const errorMessage = error.response?.data?.error || 'Failed to fetch balance';
          toast.error(errorMessage);
        } finally {
          setCheckBalanceLoading(false);
        }
      };

  const [addOrderLoading, setAddOrderLoading] = useState(false);

const handleSubmitAddOrder = async () => {

  if (!userData.redUpvotesApi) {
    toast.error('RedUpvotes API key is required');
    return;
  }
 
  if (!postLinkReddit) {
    toast.error('Reddit Post Link is required');
    return;
  }
  if (!quantity) {
    toast.error('Quantity is required');
    return;
  }
  if (!username) {
    toast.error('Username is required');
    return;
  }
  if (!userData._id) {
    toast.error('User ID is required');
    return;
  }

    setAddOrderLoading(true);
    try {
      const response = await axios.post('/moz/add-order', {
        apiKey: userData.redUpvotesApi,
        service: 1,
        link: postLinkReddit,
        quantity,
        username,
        userId: userData._id
      });
       toast.success('Order added successfully');
      console.log(response.data);
      if (response.data.user) {
        localStorage.setItem("userData", JSON.stringify(response.data.user));
      }     
    } catch (error) {
      console.error('Error adding order:', error);
      const errorMessage = error.response?.data?.error || 'Failed to add order';
      toast.error(errorMessage);
    } finally {
      setAddOrderLoading(false);
    }
  };

  const [multiOrderStatusLoading, setMultiOrderStatusLoading] = useState(false);


  const handleCheckStatus = async () => {
    if (!userData.redUpvotesApi) {
      toast.error('RedUpvotes API key is required');
      return;
    }
    if (!userData.redUpvotesOrders || userData.redUpvotesOrders.length === 0) {
      toast.error('You have not placed any orders yet');
      return;
    }

    setMultiOrderStatusLoading(true);
    try {
      const response = await axios.post('/moz/multi-status', {
        apiKey: userData.redUpvotesApi,
        orderIds: userData.redUpvotesOrders,
      });
      setOrderData(response.data);
      console.log(response.data);
      // Handle the response as needed
    } catch (error) {
      console.error('Error fetching multiple order statuses:', error);
      const errorMessage = error.response?.data?.error || 'Failed to fetch multiple order statuses';
      toast.error(errorMessage);
    } finally {
      setMultiOrderStatusLoading(false);
    }
  };


  // PDF SETUP

let loadingToastId;


const extractText = (file) => {
  return pdfToText(file)
    .then((text) => {
      console.log(text);
      setExtractedText(text);
      toast.dismiss(loadingToastId);
       // Optionally update textAreaValues based on extracted text
     
      toast.success("File Uploaded successfully!");
    })
    .catch((error) => {
      console.error("Failed to extract text from pdf", error);
      toast.dismiss(loadingToastId);
      toast.error("Failed to extract text from PDF", error);
    });
};

useEffect(() => {
  
  if(extractedText.length > 5){
    setTextAreaValues((prevValues) => ({
      ...prevValues,
      tab2: `${prevValues.tab2} I have added a sample of marketing emails from an affiliate marketer {${extractedText}}, and I would like to copy the style and tone of this copywriting.`
    }));
  }
  
 
}, [extractedText])


const handleChange = (info) => {
  if (info.file.status !== 'uploading') {
    console.log(info.file, info.fileList);
  }
  if (info.file.status === 'done') {
    extractText(info.file.originFileObj);
  } else if (info.file.status === 'error') {
    toast.dismiss();
    toast.error(`${info.file.name} file upload failed.`);
  }
};

const beforeUpload = (file) => {
  const isPDF = file.type === 'application/pdf';
  if (!isPDF) {
    toast.error('You can only upload PDF files!');
  } else {
    loadingToastId = toast.loading("Uploading file...");
  }
  return isPDF || Upload.LIST_IGNORE;
};


const [geminiLoading, setGeminiLoading] = useState(false);
  
// const handleSubmitGeminiReplyFirst = async (e) => {
//   e.preventDefault();
//   if (!userData.geminiApi || userData.geminiApi === '' ) {
//     toast.error("Please add Gemini API key.", { position: "top-right" });
//     return; // Exit the function early
// }
//   setGeminiLoading(true);

//   try {
//     const response = await axios.post('/moz/writewithgemini', {
//       prompt: "Write a short poem about a cat exploring a magical forest.",
//       apiKey: userData.geminiApi, 
//       geminiModel: userData.geminimodel || 'gemini-1.5-flash', 
//     });

//     console.log(response.data.generatedText);
//   } catch (error) {
//     // Robust Error Handling
//     if (error.response) {
//       // Server-side Errors
//       const serverError = error.response.data;

//       if (serverError.details) {
//         // Detailed Gemini error
//         toast.error(`Gemini Error: ${serverError.details.message}`, {
//           autoClose: false, // Keep error toast visible until dismissed
//         });
//       } else if (serverError.error) {
//         // General backend error
//         toast.error(
//           `Server Error: ${serverError.error || 'An unexpected error occurred.'}`,
//           { autoClose: false }
//         );
//       } else {
//         // Unknown server error format
//         toast.error('Unexpected error format from the server.', { autoClose: false });
//       }
//     } else if (error.request) {
//       // Network Errors
//       toast.error('No response from server. Please check your network connection.', { autoClose: false });
//     } else {
//       // Client-side Errors (e.g., request setup)
//       toast.error('Error: An error occurred while processing your request.', { autoClose: false });
//       console.error('Request Error:', error.message); // Log for debugging
//     }
//   } finally {
//     setGeminiLoading(false);
//   }
// };


const handleSubmitGeminiReply = async (messageContent) => { // Add messageContent as an argument
 

  // Placeholder Handling (similar to sendOpenAIRequest)
  const placeholder = "[videoTranscript]";
  const placeholder2 = "[SelectedCta]";
  if (messageContent.includes(placeholder)) {
    messageContent = messageContent.replace(placeholder, project.videoTranscript);
  }
  if (messageContent.includes(placeholder2)) {
    messageContent = messageContent.replace(placeholder2, selectedCta);
  }

  try {
    const response = await axios.post('/moz/writewithgemini', {
      prompt: messageContent,   // Use the modified messageContent
      apiKey: userData.geminiApi,
      geminiModel: userData.geminimodel || 'gemini-1.5-flash',
    });

    console.log(response.data.generatedText);

    // Return the generated text (similar to sendOpenAIRequest)
    return response.data.generatedText;
  } catch (error) {
    if (error.response) {
      // Server-side Errors
      const serverError = error.response.data;

      if (serverError.details) {
        // Detailed Gemini error
        toast.error(`Gemini Error: ${serverError.details.message}`, {
          autoClose: false, // Keep error toast visible until dismissed
        });
      } else if (serverError.error) {
        // General backend error
        toast.error(
          `Server Error: ${serverError.error || 'An unexpected error occurred.'}`,
          { autoClose: false }
        );
      } else {
        // Unknown server error format
        toast.error('Unexpected error format from the server.', { autoClose: false });
      }
    } else if (error.request) {
      // Network Errors
      toast.error('No response from server. Please check your network connection.', { autoClose: false });
    } else {
      // Client-side Errors (e.g., request setup)
      toast.error('Error: An error occurred while processing your request.', { autoClose: false });
      console.error('Request Error:', error.message); // Log for debugging
    }
  } 
};

const handleSubmitAllGeminiReply = async (e) => {
  if (!userData.geminiApi || userData.geminiApi === '') {
    toast.error("Please add Gemini API key.", { position: "top-right" });
    return;
  }
  e.preventDefault();
  setGeminiLoading(true);

  const newResponses = [];

  // Loop through the keys of textAreaValues (assuming it's accessible)
  for (const tabKey in textAreaValues) {
    if (textAreaValues.hasOwnProperty(tabKey)) {
      // Optional: Add a random delay (like in handleSubmitAllTabs)
      await delay(Math.random() * (4000 - 2000 + 1) + 2000);

      let content = await handleSubmitGeminiReply(textAreaValues[tabKey]); // Call updated function

      if (content !== null) { 
        // Handle Gemini's response format if needed
        // (e.g., remove quotes or extra whitespace)
        content = content.replace(/^"|"$/g, '').trim(); 

        newResponses.push({ tab: tabKey, content });
      }
    }
  }

  setResponses(newResponses);
  setGeminiLoading(false);
};

const [isLoading, setIsLoading] = useState(false);

// const handleSubmitPromptWithopenrouter = async (e) => {
//   e.preventDefault();
//   if (!userData.openrouterApi || userData.openrouterApi === '') {
//       toast.error("Please add OpenRouter API key in settings page.", { position: "top-right" });
//       return; // Exit the function early
//   }
//   setIsLoading(true); // Changed from setLoading to setIsLoading

//   try {
//     const response = await axios.post('https://seotoolbox-h6lhegca5a-uc.a.run.app/send-api', { OPENROUTER_API_KEY: userData.openrouterApi, model: userData.openrouterApiModel || 'meta-llama/llama-3-70b-instruct', content: "how to learn seo" }); 

//     console.log(response.data); // Handle the response data as needed
//     if (response.data && response.data.choices){
//     const aiReplyContent = response.data.choices[0].message.content;
//       console.log(aiReplyContent);
//   } else {
//         toast.error(`Error: ${response.data.error.message} Code: ${response.data.error.code}`, { position: "top-right" });

//     }
    
//   } catch (errorResponse) {
//     console.log("Complete errorResponse:", errorResponse);

//   } finally {
//     setIsLoading(false); // Changed from setLoading to setIsLoading
//   }
// };

const handleSubmitPromptWithopenrouter = async (messageContent) => {
 



  try {
      // Placeholder Replacements (if needed)
      const placeholder = "[videoTranscript]";
      const placeholder2 = "[SelectedCta]";
      if (messageContent.includes(placeholder)) {
          messageContent = messageContent.replace(placeholder, project.videoTranscript);
      }
      if (messageContent.includes(placeholder2)) {
          messageContent = messageContent.replace(placeholder2, selectedCta);
      }

      // OpenRouter API Call
      const response = await axios.post('https://seotoolbox-h6lhegca5a-uc.a.run.app/send-api', { 
          OPENROUTER_API_KEY: userData.openrouterApi,
          model: userData.openrouterApiModel || 'meta-llama/llama-3-70b-instruct',
          content: messageContent  
      }); 

      if (response.data && response.data.choices){
          const aiReplyContent = response.data.choices[0].message.content;
          console.log(aiReplyContent);
          return aiReplyContent; // Return the content here
      } else {
          toast.error(`Error: ${response.data.error.message} Code: ${response.data.error.code}`, { position: "top-right" });
          return null; // Return null in case of API error
      }
  } catch (error) {
      if (error.response) {
          toast.error(`Error ${error.response.status}: ${error.response.data.error}`);
          console.error('Error Response:', error.response.data);
      } else if (error.request) {
          toast.error('No response was received');
          console.error('Error Request:', error.request);
      } else {
          toast.error('Error setting up request');
          console.error('Error Message:', error.message);
      }
      return null; // Return null in case of general error
  } 
};

const handleSubmitAllOpenRouter = async (e) => {
  if (!userData.openrouterApi || userData.openrouterApi === '') {
    toast.error("Please add OpenRouter API key in settings page.", { position: "top-right" });
    return null; // Return null in case of missing API key
}

  e.preventDefault();
  setIsLoading(true); // Start the loading state

  
  const newOpenRouterResponses = []; // Array for OpenRouter responses
  

  for (const tabKey in textAreaValues) {
      if (textAreaValues.hasOwnProperty(tabKey)) {
          await delay(Math.random() * (4000 - 2000 + 1) + 2000); // Random delay

          // Call OpenRouter API
          let openRouterContent = await handleSubmitPromptWithopenrouter(textAreaValues[tabKey]); 
          if (openRouterContent !== null) {
              newOpenRouterResponses.push({ tab: tabKey, content: openRouterContent });
          }
      }
  }
  
  setResponses(newOpenRouterResponses); // Update Gemini responses
  // Update OpenRouter responses (if applicable)
  // setOpenRouterResponses(newOpenRouterResponses);   <- You'll need to create this state if you want to use it

  setIsLoading(false); // End the loading state
};

// local storage values of tony task to show previous values

useEffect(() => {
  // Retrieve data from local storage on mount
  const storedTab3TitleContent = localStorage.getItem('tab3TitleContent');
  const storedMarkdownEditorContent = localStorage.getItem('markdownEditorContent');
  const storedTab4TitleContent = localStorage.getItem('tab4TitleContent');
  const storedPostLink = localStorage.getItem('postLink');

  if (storedTab3TitleContent) setTab3TitleContent(storedTab3TitleContent);
  if (storedMarkdownEditorContent) setMarkdownEditorContent(storedMarkdownEditorContent);
  if (storedTab4TitleContent) setTab4TitleContent(storedTab4TitleContent);
  if (storedPostLink) setPostLink(storedPostLink);
}, []);

useEffect(() => {
  // Save data to local storage on state change
  localStorage.setItem('tab3TitleContent', tab3TitleContent);
}, [tab3TitleContent]);

useEffect(() => {
  localStorage.setItem('markdownEditorContent', markdownEditorContent);
}, [markdownEditorContent]);

useEffect(() => {
  localStorage.setItem('tab4TitleContent', tab4TitleContent);
}, [tab4TitleContent]);

useEffect(() => {
  localStorage.setItem('postLink', postLink);
}, [postLink]);



//  Subreddit fav



useEffect(() => {
  const fetchFavorites = async () => {
    try {
      const response = await axios.get(`/moz/favorites/${userData._id}`);
      console.log(response.data)
      setFavorites(response.data);
    } catch (err) {
      console.error(err);
      const errorMessage = err.response ? err.response.data.message : err.message;
      toast.error(`Failed to fetch favorite subreddits: ${errorMessage}`);
    }
  };

  fetchFavorites();
}, []);


const [isSavingFavorite, setIsSavingFavorite] = useState(false);


const handleSaveFavorite = async () => {
  if (!subreddit.trim()) { // Check if the subreddit input is not empty
    toast.error('Please enter a subreddit name.');
    return;
  }

  setIsSavingFavorite(true); // Start loading indicator
  try {
    const response = await axios.post('/moz/addFavorite', { userId: userData._id, subreddit });
    setFavorites(response.data.updatedUser.favoriteSubreddits);
    toast.success('Subreddit added to favorites');
  } catch (err) {
    console.error(err);
    const errorMessage = err.response ? err.response.data : 'Failed to add subreddit to favorites';
    toast.error(errorMessage);
  } finally {
    setIsSavingFavorite(false); // Reset loading indicator
  }
};

const handleRemoveFavorite = async (value) => {
  try {
    const response = await axios.post('/moz/removeFavorite', { userId: userData._id, subreddit: value });
    setFavorites(response.data.updatedUser.favoriteSubreddits);
    toast.success('Subreddit removed from favorites');
  } catch (err) {
    console.error(err);
    toast.error('Failed to remove subreddit from favorites');
  }
};

const handleSelectFavorite = (value) => {
  setSelectedFavorite(value);
  setSubreddit(value);
};


    return (
        <>
<ToastContainer
position="top-right"
autoClose={10000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
<main className="d-flex flex-nowrap">
<BarSide2 />
<Container>
    <div className="d-flex flex-column flex-grow-1 p-4">
        <div style={{ overflowY: "auto", height: "calc(100vh - 24px)" }}>
            <div className="container mt-3">
                <div className="row">
                    <div className="col-md-12">
                        <div className="ra-home border border-gray-300 bg-white rounded-lg">
                        <h4>{project?.projectName}</h4>
                        </div>
                    </div>
                </div>
            </div>

            {/*  */}
            <div className="container mt-3">
                <div className="row">
                    <div className="col-md-12">
                        <div className="ra-home border border-gray-300 bg-white rounded-lg">
                        <Row gutter={[3]}>
                        <Col span={6}>
                        {globalCtas && globalCtas.length > 0 && (
                                <Select
                                    placeholder="Select a CTA"
                                   style={{width: "100%"}}
                                    onChange={handleSelectChange}
                                >
                                    {globalCtas.map((cta, index) => (
                                    <Select.Option key={index} value={cta}>
                                        {cta}
                                    </Select.Option>
                                    ))}
                                </Select>
                                )}
</Col>
<Col span={3.5}>
              <Upload
      name="file"
      beforeUpload={beforeUpload}
      onChange={handleChange}
      showUploadList={false}
      customRequest={({ file, onSuccess }) => {
        const loadingToastId = toast.loading("Uploading file...");
        setTimeout(() => {
          onSuccess("ok");
          toast.dismiss(loadingToastId);
          extractText(file);
        }, 0);
      }}
    >
    <Button style={{width: "100%"}}  icon={<UploadOutlined />}>Upload PDF</Button>
  </Upload></Col>
  <Col span={3.5}>
<Button  onClick={showModal}>
        Preview Prompts
      </Button></Col>
      <Modal
        open={open}
        title=""
        onOk={handleOk}
        onCancel={handleCancel}
        width={1000}
        footer={[
          <Button key="back" onClick={handleCancel}>
            Return
          </Button>,
          <Button key="submit"  loading={loading} onClick={handleOk}>
            Submit
          </Button>
        ]}
      >
  <Tabs defaultActiveKey="1" onChange={(key) => console.log(`Active tab key: ${key}`)}>
          <Tabs.TabPane tab="Prompt for Introduction Hook" key="1">
            <TextAreaComponent
              initialValue={textAreaValues.tab1}
              placeholder="Craft an attention-grabbing introduction hook..."
              onChange={(value) => handleTextChange('tab1', value)}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Prompt for Post Body" key="2">
            <TextAreaComponent
              initialValue={textAreaValues.tab2}
              placeholder="Another placeholder for different content..."
              onChange={(value) => handleTextChange('tab2', value)}
            />
          </Tabs.TabPane>
          <Tabs.TabPane tab="Prompt for Engaging Title" key="3">
            <TextAreaComponent
              initialValue={textAreaValues.tab3}
              placeholder="Further placeholder for yet more content..."
              onChange={(value) => handleTextChange('tab3', value)}
            />
          </Tabs.TabPane>
        </Tabs>
      </Modal> 
      <Col span={3.5}>
      
      
      <Button style={{width: "100%"}} loading={loadingOpenAI} onClick={handleSubmitAllTabs}>Write with OpenAI</Button></Col>
      <Col span={3.5}>
      <Button style={{width: "100%"}} loading={geminiLoading} onClick={handleSubmitAllGeminiReply}>Write with Gemini</Button></Col>
      <Col span={4}>
      <Button style={{width: "100%"}} loading={isLoading} onClick={handleSubmitAllOpenRouter}>Write with OpenRouter</Button>
      </Col>
      </Row>
                        </div>
                    </div>
                </div>
            </div>

        {/* Third */}

        {tab3TitleContent && (
  <div className="container mt-3 mb-8">
    <div className="row">
      <div className="col-md-12">
        <div className="ra-home border border-gray-300 bg-white rounded-lg">
          <div style={{ marginTop: 5 }}>
            <Input
              size="large"
              value={tab3TitleContent}
              onChange={handleTitleChange}
            />
          </div>
          <br />
          {markdownEditorContent && (
            <div style={{ marginTop: 10 }}>
              <MarkdownEditor
                value={markdownEditorContent}
                height="800px"
                extensions={[EditorView.lineWrapping]}
                onChange={handleMarkdownChange}
              />
            </div>
          )}

<div className='my-3'>
      <TextArea 
        value={tab4TitleContent} 
        onChange={handle4TitleChange} 
        rows={9} 
        placeholder="Relevant Subreddit" 
      />
    </div>

    <div className="mb-3 mt-2">
      
      <Row gutter={6} style={{ alignItems: 'center' }}>

      <Col span={8}>
  <Select
    style={{ width: '100%' }}
    placeholder="Select favorite subreddit"
    value={selectedFavorite}
    onChange={handleSelectFavorite}
    dropdownRender={menu => (
      <div>
        {menu}
      </div>
    )}
  >
    {favorites.map((fav) => (
      <Option key={fav} value={fav}>
        {fav}
        <Button
          type="link"
          onClick={() => handleRemoveFavorite(fav)}
          style={{ float: 'right', padding: 0, marginLeft: '10px' }} // Ensure the button is to the right and styling is compact
        >
          Remove
        </Button>
      </Option>
    ))}
  </Select>
</Col>


        <Col span={8}>
          <Input
            type="text"
            value={subreddit}
            onChange={(e) => setSubreddit(e.target.value)}
            required
            placeholder="Enter subreddit name"
            style={{ width: '100%' }}
          />
        </Col>
        <Col span={4}>
          <Button  loading={loadingSubreddit} onClick={handlePostSubmit} style={{ width: '100%' }}>
            Publish Post
          </Button>
        </Col>

        <Col span={4}>
        <Button onClick={handleSaveFavorite} loading={isSavingFavorite} style={{ width: '100%' }}>
          Save Favorite
        </Button>
      </Col>

    

      </Row>

      {postLink && postLink.length > 8 && (
  <div style={{ marginTop: '10px' }}>
    <Flex gap="middle" align="start">
      <h6 className='mr-1'>Published Post Link:</h6>
      <a href={postLink} className='newmargin' target="_blank" rel="noopener noreferrer" style={{ fontSize: '16px', color: '#1890ff' }}>
        {postLink}
      </a>
    </Flex>
  </div>
)}
      {/* {postLink && (
        
        <div style={{ marginTop: '10px',  }} >
          <Flex gap="middle" align="start">
          <h6 className='mr-1'>Published Post Link:</h6>
          <a href={postLink} className='newmargin' target="_blank" rel="noopener noreferrer" style={{ fontSize: '16px', color: '#1890ff' }}>
            {postLink}
          </a></Flex>
        </div>
       )}  */}



      <ToastContainer />
    </div>


   


        </div>

      </div>
    </div>
  </div>
)}


<div className="container my-4 marginb20">
<div className="row">
<div className="col-md-12">
    <div className="ra-home border border-gray-300 bg-white rounded-lg">
<Flex>
  <h4 className="mb-3">Boost your Reddit Posts with High-Quality Upvotes </h4> <Tooltip placement="topLeft" title="We recommend a maximum of 80-100 upvotes per post. It's best to do around 30 upvotes within the first hour of posting for maximum impact and you can drip feed the rest."><InfoCircleOutlined className='newmargin1 ml-2'/></Tooltip>
  </Flex>
    <Row gutter={[6, 6]}>
        <Col span={6}>
          <Input placeholder="Post link" value={postLinkReddit} onChange={handlePostLinkChange} />
        </Col>
        <Col span={4}>
          <Input type="number" value={quantity} placeholder="Quantity" onChange={handleQuantityChange} onBlur={handleQuantityBlur} min={5} max={300} />
        </Col>
        <Col span={4}>
          <Input placeholder="Username" value={username} onChange={handleUsernameChange} />
        </Col>
        <Col span={10}>
          <Space>
            <Button onClick={handleSubmitAddOrder} loading={addOrderLoading}>Submit</Button>
            <Button onClick={checkBalance} loading={checkBalanceLoading}>Check Balance</Button>
            <Button onClick={handleCheckStatus} loading={multiOrderStatusLoading}>Check Orders Status</Button>
          </Space>
        </Col>
        <Col span={24} className='mt-2'>
        {orderData && (
        <OrderStatusTable orderData={orderData} />
      )}
        </Col>
      </Row>



  </div></div></div></div>

        </div>
    </div>
</Container>
</main>
</>
    );
};

export default ProjectDetailsSubReddit;
