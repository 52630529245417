import React, {useState, useEffect} from 'react';
import BarSide2 from "../../BarSide2";
import { Container } from "react-bootstrap";
import { ToastContainer, toast } from 'react-toastify';
import {Typography, Row, Col, Button, Input, Table, Modal } from "antd"
import 'react-toastify/dist/ReactToastify.css';
import { BsCaretRight } from "react-icons/bs";
import axios from "../../../components/Axios";
import { useSubredditProjects } from '../../../auth/SubredditProjectsContext';
import { Link } from 'react-router-dom';



function SubRedditPosterMain() {


    const [projectName, setProjectName] = useState('');
    const [youtubeLink, setYoutubeLink] = useState('');
    const [projects, setProjects] = useState([]);
    const [projectLoading, setProjectLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [selectedTranscript, setSelectedTranscript] = useState("");
    const { subredditProjects, setSubredditProjects } = useSubredditProjects();

  
    const userDataString = localStorage.getItem("userData");
    const userData = userDataString ? JSON.parse(userDataString) : null;
  
  
    const handleSubmit = async () => {
      console.log("Project Name:", projectName);
      console.log("YouTube Video Link:", youtubeLink);
      setProjectLoading(true)
      const errorMessage = 
      !projectName.trim() ? "Project name is required." :
      !youtubeLink.trim() || !/^https:\/\/(www\.)?youtube\.com\/watch\?v=[\w-]+(&[\w-]+=([\w-]+)*)*$/.test(youtubeLink) ? "Please enter a valid YouTube link." :
      !userData._id.trim() ? "User ID is required." :
      "";
  
    if (errorMessage) {
      toast.error(errorMessage);
      setProjectLoading(false);
      return;
    }

      const requestBody = {
        projectName: projectName,
        youtubeLink: youtubeLink,
        userId: userData._id
      };

      try {
        const response = await axios.post('/moz/get-transcript', requestBody);
        console.log('Response:', response.data);

        if (response.data.error) {
          toast.error( response.data.error);
          setProjectLoading(false);
          return; // Prevent further execution if there's an error
        }

        setProjects(response.data.allDocuments);
        setSubredditProjects(response.data.allDocuments);
        toast.success("Project has been submitted successfully!");
        console.log(projects, "projects")
      } catch (error) {
        console.error('Error submitting data:', error);
        toast.error("Failed to submit project: " + (error.response?.data?.error || "Network error"));
      } finally {
        setProjectLoading(false)
      }

    };

    const handleDeleteProject = async (text, record) => {
      console.log(record, userData._id, "record userData._id");
      toast.promise(
        axios.post('/moz/deleteAndFetchRemaining', { _id: record._id, userId: userData._id }),
        {
          pending: 'Deleting project...',
          success: {
            render({ data }) {
              // Assuming your backend sends back a success message along with remaining documents
              setProjects(data.data.allDocuments); // Update your state with the remaining documents
              setSubredditProjects(data.data.allDocuments);
              return data.data.message;
            },
            icon: "🗑️",
          },
          error: {
            render({ data }) {
              // Handling error scenario
              return data.response?.data.error || 'An error occurred while deleting the project';
            }
          },
        },
        {
          position: "top-right",
          autoClose: 5000,
        }
      );
    };

    useEffect(() => {
      const userId = userData._id;
      const fetchUserProjects = async () => {
        if (!userId) {
          toast.error("User ID is missing.");
          return;
        }
  
        try {
          const timestamp = new Date().getTime();
          const response = await axios.get(`/moz/fetchUserProjects/${userId}?t=${timestamp}`);
          setProjects(response.data.projects);
          setSubredditProjects(response.data.projects);
          // toast.success(response.data.message);
        } catch (error) {
          console.error('Error fetching projects:', error);
          toast.error("Failed to fetch projects: " + (error.response?.data?.error || "An error occurred"));
        }
      };
  
      fetchUserProjects();
    }, []);


    const handleViewTranscript = (record) => {
      setSelectedTranscript(record.videoTranscript);
      setIsModalVisible(true);
    };
  
    const handleOk = () => {
      setIsModalVisible(false);
    };
  
    const handleCancel = () => {
      setIsModalVisible(false);
    };
    


    const columns = [
      {
        title: 'PS',
        key: 'ps',
        render: (text, record, index) => index + 1,
        width: 50
    },
      {
        title: 'Project Name',
        dataIndex: 'projectName',
        key: 'projectName',
        render: (text, record) => (
          <Link to={`/projectsubreddit/${record._id}`} style={{ cursor: 'pointer' }}>
            {text}
          </Link>
        ),
        width: 200
      },
      {
        title: 'YouTube Video Link',
        dataIndex: 'videoId',
        key: 'videoId',
        render: (text) => <a href={`https://www.youtube.com/watch?v=${text}`} target="_blank" rel="noopener noreferrer">{`https://www.youtube.com/watch?v=${text}`}</a>
      },
      {
        title: 'Video Transcript',
        key: 'videoTranscript',
        render: (text, record) => (
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%', cursor: 'pointer' }} onClick={() => handleViewTranscript(record)}>
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
              <path d="M200-120q-33 0-56.5-23.5T120-200v-560q0-33 23.5-56.5T200-840h560q33 0 56.5 23.5T840-760v560q0 33-23.5 56.5T760-120H200Zm0-80h560v-480H200v480Zm280-80q-82 0-146.5-44.5T240-440q29-71 93.5-115.5T480-600q82 0 146.5 44.5T720-440q-29 71-93.5 115.5T480-280Zm0-60q56 0 102-26.5t72-73.5q-26-47-72-73.5T480-540q-56 0-102 26.5T306-440q26 47 72 73.5T480-340Zm0-100Zm0 60q25 0 42.5-17.5T540-440q0-25-17.5-42.5T480-500q-25 0-42.5 17.5T420-440q0 25 17.5 42.5T480-380Z" fill="#5b5b5b" />
            </svg>
          </div>
        ),
        width: 170
      },
      {
        title: '',
        key: 'delete',
        render: (text, record) => (
          <div onClick={() => handleDeleteProject(text, record)} style={{ cursor: 'pointer' }}>
            <svg xmlns="http://www.w3.org/2000/svg" height="24" viewBox="0 -960 960 960" width="24">
              <path d="m376-300 104-104 104 104 56-56-104-104 104-104-56-56-104 104-104-104-56 56 104 104-104 104 56 56Zm-96 180q-33 0-56.5-23.5T200-200v-520h-40v-80h200v-40h240v40h200v80h-40v520q0 33-23.5 56.5T680-120H280Zm400-600H280v520h400v-520Zm-400 0v520-520Z" fill="red" />
            </svg>
          </div>
        ),
        width: 75
      }

    ];    


      const [ctaValue, setCtaValue] = useState('');
const handleChange = (e) => {
    // Update state with new input value
    setCtaValue(e.target.value);
  };

  const [ctaLoading, setCtaLoading] = useState(false);

  const handleSubmitCta = async () => {
    console.log( userData._id, "record userData._id");
    const payload = { userId: userData._id, globalCta: ctaValue };

    if (!ctaValue) {
      toast.error('Please enter a value for Global CTA!');
      return;
    }

    setCtaLoading(true);

    try {
      const response = await axios.post('/moz/update-global-cta', payload);
      toast.success(response.data.message || 'Global CTA updated successfully!');
      console.log(response.data);
      localStorage.setItem("userData", JSON.stringify(response.data.user));
    } catch (error) {
      console.error('Error updating Global CTA:', error);
      if (error.response && error.response.data && error.response.data.error) {
        toast.error(error.response.data.error);
      } else {
        toast.error('An unexpected error occurred. Please try again later.');
      }
    } finally {
      setCtaLoading(false);
    }
  };


  return (
    <>
      <ToastContainer
        position="top-right"
        autoClose={10000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
      <main className="d-flex flex-nowrap">
        <BarSide2 />
        <Container>
          <div className="d-flex flex-column flex-grow-1 p-4">
            <div style={{ overflowY: "auto", height: "calc(100vh - 24px)" }}>
              <div className="container mt-3">
                <div className="row">
                  <div className="col-md-12">
                    <div className="ra-home border border-gray-300 bg-white rounded-lg">
                    <div className="d-flex justify-content-between">
                    <h4>Subreddit Article Poster</h4>
                    <Row align="middle" className="tutDiv">
                  <Col>
                  <BsCaretRight style={{ verticalAlign: 'middle' }} className="tutColor" />
                  </Col>
                  <Col>
                    <Typography.Text className="tutColor tutText">
                    <a className='colorNamea' href="https://www.youtube.com/watch?v=h_kMhbWHNyc" target="_blank" rel="noopener noreferrer">
                      VIDEO TUTORIAL
                    </a>
                    </Typography.Text>
                  </Col>
                </Row>
                </div>
                <div className="keyword-discovery-container">
      <Typography.Paragraph className="keyword-description">
        Details About Subreddit Article Poster
      </Typography.Paragraph>
    </div>


    <div style={{ marginTop: '14px' }}>
      <Row gutter={8}>
        <Col span={8}>
          <Input
            style={{ width: '100%' }} 
            value={projectName}
            onChange={e => setProjectName(e.target.value)}
            placeholder="Project Name"
          />
        </Col>
        <Col span={12}>
          <Input
            style={{ width: '100%' }}
            value={youtubeLink}
            onChange={e => setYoutubeLink(e.target.value)}
            placeholder="YouTube Video Link"
          />
        </Col>
        <Col span={4}>
          <Button className='mainQuoraButton' loading={projectLoading} onClick={handleSubmit} style={{ width: '100%', color: '#fff', }}>
            Submit
          </Button>
        </Col>
        </Row>
        <Row className="mt-2" gutter={8}>
        <Col span={20}>
           <Input
           style={{ width: '100%' }}
        placeholder="Global CTAs"
        value={ctaValue}
        onChange={handleChange}
      />
        </Col>

<Col span={4}>
          <Button className='mainQuoraButton' loading={ctaLoading} onClick={handleSubmitCta} style={{ width: '100%', color: '#fff', }}>
            Submit
          </Button>
        </Col>
        </Row>
        

     
    </div>
                    </div>
                  
                  </div>
                </div>

                {/* Table */}
                <div className="row">
                  <div className="col-md-12">
                  <div className='mt-5'>
      {projects.length > 0 ? (
        <Table dataSource={projects} columns={columns} rowKey="_id" />
      ) : (
        null
      )}

<Modal title="Video Transcript" width={1000} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}   footer={[
          <Button key="back" onClick={handleCancel}>
            Return
          </Button>,
          <Button key="submit" onClick={handleOk}>
            Submit
          </Button>]}>
        <p>{selectedTranscript}</p>
      </Modal>
    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </main>
    </>
  );
}

export default SubRedditPosterMain;
