import React from "react";

import Dropdown from "react-bootstrap/Dropdown";
import { Link, useMatch } from "react-router-dom";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

function BarSide() {

  const handleLogout = () => {
    localStorage.removeItem("jsonToken"); // Remove the token
    localStorage.removeItem("userData");
    window.location.href = '/'; // Redirect to the home page
  };

  const matchKwResearch = useMatch("/kwresearch");
  const matchRankTracker = useMatch("/ranktracker");
  const matchSettings = useMatch("/settings");

  return (
    <div
      className="d-flex flex-column flex-shrink-0 "
      style={{
        width: "4.5rem",
        borderRight: "solid 1px #efefef",
        height: "100vh",
      }}
    >
      <Link
        to="/"
        className="d-block p-3 link-body-emphasis text-decoration-none navbar-brand"
      >
        <img
          className="mainlogoImage"
          src="https://app.rankatom.com/static/home/img/favicon.png"
          alt="Logo"
        />
      </Link>
      <ul className="nav nav-pills nav-flush flex-column mb-auto text-center">
        <li className="nav-item">
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={
              <Tooltip id="tooltip-keyword-research">Keyword Research</Tooltip>
            }
          >
            <Link
              to="/kwresearch"
              id="keywordresearch"
              className={`nav-link py-3 border-bottom border-top rounded-0 ${
                matchKwResearch ? "active" : ""
              }`}
            >
              <i className="bi bi-lightning-charge"></i>
            </Link>
          </OverlayTrigger>
        </li>
        {/* ... other list items ... */}
        <li className="nav-item">
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={<Tooltip id="tooltip-rank-tracker">Rank Tracker</Tooltip>}
          >
            <Link
              to="/ranktracker"
              id="ranktracker"
              className={`nav-link py-3 border-bottom border-top rounded-0 ${
                matchRankTracker ? "active" : ""
              }`}
            >
              <i className="bi bi-tropical-storm"></i>
            </Link>
          </OverlayTrigger>
        </li>

        <li className="nav-item">
          <OverlayTrigger
            placement="right"
            delay={{ show: 250, hide: 400 }}
            overlay={<Tooltip id="tooltip-settings">Settings</Tooltip>}
          >
            <Link
              to="/settings"
              id="settings"
              className={`nav-link py-3 border-bottom border-top rounded-0 ${
                matchSettings ? "active" : ""
              }`}
            >
              <i className="bi bi-gear-wide-connected"></i>
            </Link>
          </OverlayTrigger>
        </li>
      </ul>
      <Dropdown drop="end" className="border-top" id="profile">
        <Dropdown.Toggle
          as="a"
          className="d-flex align-items-center justify-content-center p-3 link-body-emphasis text-decoration-none"
        >
          <span className="dashboard-logo">AH</span>
        </Dropdown.Toggle>

        <Dropdown.Menu
          style={{ zIndex: 999999, marginBottom: "10px" }}
          className="shadow"
        >
          {/* ... dropdown items ... */}

          <Dropdown.Item as={Link} to="/settings">
            Settings</Dropdown.Item>
          <Dropdown.Divider />
          
          <Dropdown.Item onClick={handleLogout}>Sign out</Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </div>
  );
}

export default BarSide;
