import React, {useEffect} from 'react'
import BarSide2 from "../components/BarSide2";
import { Container,} from "react-bootstrap";
import { BsCaretRight } from "react-icons/bs";
import { Row, Col, Typography, Tabs } from 'antd';
import { RedditOutlined, AndroidOutlined } from '@ant-design/icons';

import ProjectForm from "../components/projects/reddit/ProjectForm"
import ProjectFormTable from "../components/projects/reddit/ProjectFormTable"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import axios from "../components/Axios"


function Projects() {

  // useEffect(() => {
  //   async function fetchCredits() {
  //     const userDataString = localStorage.getItem("userData");
  //     const userData = userDataString ? JSON.parse(userDataString) : null;

  //     if (!userData || !userData._id) {
  //       toast.error("No user data available.");
  //       return;
  //     }

  //     const timestamp = new Date().getTime();
  //     const url = `/api/user/credits/${userData._id}?t=${timestamp}`;

  //     try {
  //       const response = await axios.get(url);
  //       // Here, we update the local storage with only the credits data
  //       localStorage.setItem("userData", JSON.stringify(response.data.credits));
  //     } catch (error) {
  //       // Logs the error and shows an error toast
  //       console.error('Failed to fetch credits:', error);
  //       toast.error(error.response ? error.response.data.message : "Failed to load user credits.");
  //     }
  //   }

  //   fetchCredits();
  // }, []);


  return (
    <>
    <ToastContainer
        position="top-right"
        autoClose={15000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    <main className="d-flex flex-nowrap">
        <BarSide2 />
        <Container>
        <div className="d-flex flex-column flex-grow-1 p-4">
          <div style={{ overflowY: "auto", height: "calc(100vh - 24px)" }}>
            <div className="container mt-3 ">
              <div className="row">
                <div className="col-md-12">
          
              
                <Tabs defaultActiveKey="1">
    <Tabs.TabPane
      tab={
        <span>
          <RedditOutlined style={{ marginRight: 4 }} />
          Reddit
        </span>
      }
      key="1"
    >
        <div className="ra-home border border-gray-300 bg-white rounded-lg ">
                    <div className="d-flex justify-content-between">
                    <h4>Reddit</h4>
                    <Row align="middle" className="tutDiv">
                  <Col>
                  <BsCaretRight style={{ verticalAlign: 'middle' }} className="tutColor" />
                  </Col>
                  <Col>
                    <Typography.Text className="tutColor tutText">
                    <a className='colorNamea' href="https://www.youtube.com/watch?v=h_kMhbWHNyc" target="_blank" rel="noopener noreferrer">
                      VIDEO TUTORIAL
                    </a>
                    </Typography.Text>
                  </Col>
                </Row>
                </div>
                <div className="keyword-discovery-container">
      <Typography.Paragraph className="keyword-description">
      Introducing our Reddit Discussion Finder – the ultimate tool for savvy marketers looking to capitalize on the surging traffic on Reddit. With Reddit's traffic tripling in recent months and its increased prominence in search engine rankings, engaging with discussions on the platform has become a traffic goldmine for marketers. Our tool intelligently streamlines this process, leveraging ChatGPT to identify and join relevant conversations effortlessly. By strategically participating in discussions within your niche, you can establish authority and drive organic traffic to your website. With customizable prompts and pre-written responses ensure that every interaction resonates with your target audience, making the Reddit Discussion Finder indispensable for marketers seeking to boost their online visibility and drive website traffic.
      </Typography.Paragraph>
    </div>

    <ProjectForm /></div> 
    <ProjectFormTable />
               
          
                  
    </Tabs.TabPane>
    {/* <Tabs.TabPane
      tab={
        <span>
          <AndroidOutlined style={{ marginRight: 4 }} />
          Tab 2
        </span>
      }
      key="2"
    >
      Content of Tab Pane 2
    </Tabs.TabPane>*/}
  </Tabs> 
 
                </div> 
              </div> 
            </div>
          </div> 
        </div>
      </Container>
    </main>
    </>
  )
}

export default Projects