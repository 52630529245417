import React from "react";
import CacheBuster from 'react-cache-buster';
import ProjectDetail from "./components/projects/reddit/ProjectDetail";
import packageInfo from '../package.json';
import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider, } from "react-router-dom";
import Home from "./pages/Home";
import Projects from "./pages/Projects";
import KeywordResearch from "./pages/KeywordResearch";
import RankTracker from "./pages/RankTracker";
// import Settings from "./pages/Settings";
import DomSettings from "./pages/DomSettings";
import RegisterPage from "./pages/RegisterPage";
import Forgetpassword from "./pages/Forgetpassword";
import LoginPage from "./pages/LoginPage";
import AuthProvider from './auth/AuthProvider';
import PrivateRoutes from './auth/PrivateRoutes';
import {DataProvider } from './auth/DataContext';
import { ProjectDataProvider } from './auth/ProjectDataContext';
import { SubredditProjectsProvider } from './auth/SubredditProjectsContext';

// import KeywordDataItem from "./pages/KeywordDataItem";
import KeywordTableDisplay from "./pages/KeywordTableDisplay";
import { KeywordProvider } from "./auth/KeywordContext";
import { TabProvider } from "./auth/TabContext";
import { GetValueProvider  } from "./auth/GetValueContext";
import { FavoriteProvider } from "./auth/FavoriteContext";
import RedirectToKwResearch from "./components/RedirectToKwResearch";
// import Header from "./components/Header";
import RankingDetails from "./components/ranktracker/RankingDetails";

import SubRedditPosterMain from "./components/projects/subreddit/SubRedditPosterMain";
import ProjectDetailsSubReddit from "./components/projects/subreddit/ProjectDetailsSubReddit";
// Medium Tool
import MediumPosterMain from "./components/projects/medium/MediumPosterMain";
import MediumDetailsSubReddit from "./components/projects/medium/MediumDetailsSubReddit";
// Medium Tool ClusterTool

import ClusterTool from "./components/projects/cluster/ClusterTool";
import KeywordDetails from './components/projects/cluster/KeywordDetails';



import JobStatusPage from './components/projects/subreddit/JobStatusPage';

const userDataString = localStorage.getItem("userData");
const userData = userDataString ? JSON.parse(userDataString) : null;


const router = createBrowserRouter(
  createRoutesFromElements(


    
    <>
  
      <Route element={<PrivateRoutes />}>
      
        {/* <Route path="/" element={<Home />} /> */}

      {/*   {userData?.isRedditTool ? (
          <Route path="/" element={<Projects />} />
        ) : (
          <Route path="/" element={<Home />} />
        )}*/}

      {/* {userData ? (
          userData.isRedditTool && userData.isSubRedditTool ? (
            <Route path="/" element={<Projects />} />
          ) : userData.isRedditTool ? (
            <Route path="/" element={<Projects />} />
          ) : userData.isSubRedditTool ? (
            <Route path="/" element={<SubRedditPosterMain />} /> // Redirect to SubRedditPosterMain
          ) : (
            <Route path="/" element={<Home />} />
          )
        ) : (
          <Route path="/" element={<Home />} />
        )} */}

{userData ? (
  userData.isRedditTool && userData.isTopicalMappingTool ? (
    <Route path="/" element={<Projects />} />
  ) : userData.isRedditTool ? (
    <Route path="/" element={<Projects />} />
  ) : userData.isTopicalMappingTool ? (
    <Route path="/" element={<ClusterTool />} />
  ) : (
    <Route path="/" element={<Home />} />
  )
) : (
  <Route path="/" element={<Home />} />
)}
    
        <Route path="/keyword-detail/:mainSeedKeyword" element={<KeywordTableDisplay />} />
        <Route path="/ranking-details/:keyword" element={<RankingDetails />} />
        <Route path="/project/:projectName" element={<ProjectDetail />} />
        <Route path="/kwresearch" element={<KeywordResearch />} />
        <Route path="/ranktracker" element={<RankTracker />} />
        <Route path="/settings" element={<DomSettings />} />
        <Route path="/projects" element={<Projects />} />
        <Route path="/job-status" element={<JobStatusPage />} />

        <Route path="/projectsubreddit/:projectId" element={<ProjectDetailsSubReddit />} />
        <Route path="/subredditarticleposter" element={<SubRedditPosterMain />} />
        {/* Medium */}
        <Route path="/mediumDetailsSubReddit" element={<MediumPosterMain />} />
        <Route path="/mediumPosterMain/:projectId" element={<MediumDetailsSubReddit />} />
        {/* cluster */}
        <Route path="/cluster" element={<ClusterTool />} />
        <Route path="/keyword/:keywordId" element={<KeywordDetails />} />


      </Route>
      <Route path="/register" element={<RegisterPage />} />
      <Route path="/loginPage" element={<LoginPage />} />
      
      <Route path="/reset-password" element={<Forgetpassword />} />
      <Route path="*" element={<RedirectToKwResearch />} />

    </>
  )
);


function App() {

  const version = packageInfo.version;

 
  return (
    <CacheBuster currentVersion={version} isEnabled={true} isVerboseMode={false}>
    
    <AuthProvider>
      <DataProvider>
    <KeywordProvider>
    <TabProvider>
    <GetValueProvider>
      <FavoriteProvider>
        <ProjectDataProvider>
        <SubredditProjectsProvider>
      <RouterProvider router={router} />
      </SubredditProjectsProvider>
      </ProjectDataProvider>
      </FavoriteProvider>
      </GetValueProvider>
      </TabProvider>
      </KeywordProvider>
      </DataProvider>
    </AuthProvider>
  
  </CacheBuster>
  );
}

export default App;
