import React, { createContext, useState, useContext } from 'react';

// Create a context
const DataContext = createContext();

// Provider Component
export const DataProvider = ({ children }) => {
  const [isDataFetched, setIsDataFetched] = useState(false);

  // The value that will be given to the components
  const value = {
    isDataFetched,
    setIsDataFetched
  };

  return (
    <DataContext.Provider value={value}>
      {children}
    </DataContext.Provider>
  );
};

// Custom hook to use the DataContext
export const useData = () => {
  const context = useContext(DataContext);
  if (!context) {
    throw new Error('useData must be used within a DataProvider');
  }
  return context;
};
