import React from 'react'
import {Accordion} from 'react-bootstrap'

function SettingsSidebar() {
  return (
    <div>
        <div>
        <iframe className="ra-yt shadow rounded" width="100%" height="100%" src="https://www.youtube.com/embed/bWicUnUgvM4?si=S8k5u_B42vxotv4L" title="YouTube video player"  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" ></iframe>
        </div>
        <div>
        <h5 className="mt-4">Getting Started</h5>
        <p className="text-small">Keep track of your SERP ranking for the keywords you are getting ranked.</p>
        </div>
        <div>
        <Accordion defaultActiveKey="0">
          <Accordion.Item eventKey="0">
            <Accordion.Header>Change Settings</Accordion.Header>
            <Accordion.Body>
            Change settings parameters according to your needs and save them.
            </Accordion.Body>
          </Accordion.Item>
          <Accordion.Item eventKey="1">
            <Accordion.Header>Open Report/Tracker</Accordion.Header>
            <Accordion.Body>
            Open any report on Keyword Research or Rank Tracker to see the applied settings
            </Accordion.Body>
          </Accordion.Item>

          <Accordion.Item eventKey="2">
            <Accordion.Header>Or | Refresh Page</Accordion.Header>
            <Accordion.Body>
            In case you have any report opened just refresh the page.
            </Accordion.Body>
          </Accordion.Item>

        </Accordion>
        </div>
    </div>
  )
}

export default SettingsSidebar
