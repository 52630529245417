import React from 'react';
import { Outlet, Navigate } from 'react-router-dom';
import { useAuth } from './AuthProvider';

const PrivateRoutes = () => {
  const { jsontoken } = useAuth();

  return jsontoken ? <Outlet /> : <Navigate to='/register' />;
};

export default PrivateRoutes;
