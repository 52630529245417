// TabContext.js
import React, { useState, createContext } from 'react';

// Create the context
export const TabContext = createContext();

export const TabProvider = ({ children }) => {
    const [currentTab, setCurrentTab] = useState('allKeywords');
    const [keywordCount, setKeywordCount] = useState(0);
    const [suggestionCount, setSuggestionCount] = useState(0);
    const [relatedCount, setRelatedCount] = useState(0);
    const [favCount, setfavCount] = useState(0);
    // Function to change the tab
    const changeTab = (tab) => setCurrentTab(tab);

    return (
        <TabContext.Provider value={{ favCount, setfavCount, currentTab, changeTab, keywordCount, setKeywordCount, suggestionCount, setSuggestionCount, relatedCount, setRelatedCount }}>
            {children}
        </TabContext.Provider>
    );
};
