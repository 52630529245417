import React, {useState, useMemo} from 'react'
import { Select, Row, Col, Input, Button, Table, DatePicker } from 'antd';
import axios from "../Axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const { Option } = Select;
const { RangePicker } = DatePicker;

function timeAgo(datePast) {
  const seconds = Math.floor((new Date() - datePast) / 1000);
  let interval = seconds / 31536000; // Number of seconds in a year

  if (interval > 1) {
    return Math.floor(interval) + " year" + (Math.floor(interval) > 1 ? "s" : "") + " ago";
  }
  interval = seconds / 2592000; // Number of seconds in a month
  if (interval > 1) {
    return Math.floor(interval) + " month" + (Math.floor(interval) > 1 ? "s" : "") + " ago";
  }
  interval = seconds / 604800; // Number of seconds in a week
  if (interval > 1) {
    return Math.floor(interval) + " week" + (Math.floor(interval) > 1 ? "s" : "") + " ago";
  }
  interval = seconds / 86400; // Number of seconds in a day
  if (interval > 1) {
    return Math.floor(interval) + " day" + (Math.floor(interval) > 1 ? "s" : "") + " ago";
  }
  interval = seconds / 3600; // Number of seconds in an hour
  if (interval > 1) {
    return Math.floor(interval) + " hour" + (Math.floor(interval) > 1 ? "s" : "") + " ago";
  }
  interval = seconds / 60; // Number of seconds in a minute
  if (interval > 1) {
    return Math.floor(interval) + " minute" + (Math.floor(interval) > 1 ? "s" : "") + " ago";
  }
  return "just now";
}


function Questions() {
  const [service, setService] = useState('reddit');
  const [subreddit, setSubreddit] = useState('');
  const [keyword, setKeyword] = useState('');
  const [sortingOption, setSortingOption] = useState('relevance'); // State to store sorting option
  const [timeOption, setTimeOption] = useState('week');
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [numQuestions, setNumQuestions] = useState(4);
  const userDataString = localStorage.getItem("userData");
  const userData = userDataString ? JSON.parse(userDataString) : null;

  const showErrorToast = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 8000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "light",
    });
  };

  console.log(data)
  const handleButtonClick = async () => {
    console.log(startDate, endDate, keyword, service);
    if (service === 'reddit' && (!subreddit || !keyword)) {
      showErrorToast('Subreddit and keyword fields cannot be empty.');
      return;
    } else if ((service === 'quora' || service === 'peopleAlsoAsk') && !keyword) {
      showErrorToast('Keyword field cannot be empty.');
      return;
    }
  
    setLoading(true);
  
    // Initialize a variable for the response outside the try-catch block
    let response;
  
    try {
      // Check if the service is "peopleAlsoAsk"
      if (service === 'peopleAlsoAsk') {
        // if (!userData.serpApiKeys || userData.serpApiKeys.length === 0) {
        //   showErrorToast('Please provide at least one SERP API key.');
        //   setLoading(false); // Ensure loading state is reset before exiting
        //   return; // Exit the function to prevent further execution
        // }
        // Make a request to the "People Also Ask" specific URL https://ppa-h6lhegca5a-uc.a.run.app/related-questions
        response = await axios.post('https://ppa-h6lhegca5a-uc.a.run.app/related-questions', {
          q: keyword,
          api_keys: userData.serpApiKeys,
          // Assume `numQuestions` is defined somewhere in your component; set a default if not
          question_number: numQuestions || 5, // Default to 5 questions if numQuestions is not defined
        });
        console.log(response.data)
        // Format the data specifically for "People Also Ask"
        // const formattedDataPPA = response.data.related_questions.map((question, index) => ({
        //   key: index,
        //   position: index + 1,
        //   title: question, // Assuming 'question' is the string you want to display
        // }));

        const formattedDataPPA = response.data.related_questions.map((item, index) => {
          if (typeof item === 'string') {
            return {
              key: index,
              position: index + 1,
              title: item,
            };
          } else {
            return {
              key: index,
              position: index + 1,
              title: item.question,
              date: item.date,
              link: item.link,
            };
          }
        });
     

        setData(formattedDataPPA);
      } else {
        // Handle other services like before
        response = await axios.post('gtrend/search', {
          service,
          subredditName: subreddit,
          keyword,
          sort: sortingOption,
          time: timeOption,
          startDate: startDate,
          endDate: endDate,
        });
        // Assuming the response data format is directly compatible with your table for other services
        console.log(response.data);
        setData(response.data);
      }
  
    } catch (error) {
      console.error('Error during search:', error);
  
      if (error.response) {
        // The request was made, and the server responded with a status code
        showErrorToast(`Error: ${error.response.data.error}`);
      } else {
        // The request was made but no response was received
        showErrorToast('An error occurred during the search.');
      }
    } finally {
      setLoading(false);
    }
  };
  


const columns = useMemo(() => {
  if (service === 'reddit') {
    return [
      {
        title: 'Question',
        dataIndex: 'title',
        key: 'title',
        width: '20%',
      },
      {
        title: 'Description',
        dataIndex: 'selftext',
        key: 'selftext',
        width: '40%',
      },
      {
        title: 'Url',
        dataIndex: 'permalink',
        key: 'permalink',
        width: '80',
        ellipsis: true,
        render: (permalink) => <a href={`https://www.reddit.com${permalink}`} target="_blank" rel="noopener noreferrer">{permalink}</a>,
      },
      {
        title: 'Up Votes',
        dataIndex: 'ups',
        key: 'ups',
        width: '70',
      },
      {
        title: 'Comment',
        dataIndex: 'num_comments',
        key: 'num_comments',
        width: '82',
      },
      {
        title: 'Created',
        dataIndex: 'created_utc',
        key: 'created_utc',
        width: '75',
        render: (text) => {
          const date = new Date(text * 1000); // Convert to Date object
          return timeAgo(date); // Assume timeAgo is defined elsewhere
        },
      },
    ];
  } else if (service === 'quora') {
    return [
      {
        title: 'Question',
        dataIndex: 'link',
        key: 'title',
        width: '50%',
        render: (link) => {
          // Extract the question part from the URL and replace hyphens with spaces
          const questionPart = link.replace('https://www.quora.com/', '').replaceAll('-', ' ');
      
          // Append a question mark at the end of the questionPart
          const readableTitle = `${questionPart}?`;
      
          return readableTitle; // Return the readable title with a question mark
        }
        // Define width or other properties as needed
      },
      {
        title: 'Url',
        dataIndex: 'link',
        key: 'link',
        // Define width or other properties as needed
        render: link => <a href={link} target="_blank" rel="noopener noreferrer">{link}</a>,
      },
    ];
  } else if (service === 'peopleAlsoAsk') {
    // For 'peopleAlsoAsk', define only one column for 'Title'
    // return [
    //   {
    //     title: 'Title',
    //     dataIndex: 'title',
    //     key: 'title',
    //     // Optionally define width or leave it for automatic adjustment
    //   },
    // ];
    let baseColumns = [
      {
        title: 'Question',
        dataIndex: 'title',
        key: 'title',
      }
    ];
  
    // Check if any item in the data includes a 'date' or 'link' property
    const hasDate = data.some(item => item.date);
    const hasLink = data.some(item => item.link);
  
    // Add 'Date' column only if at least one item has a 'date' property
    if (hasDate) {
      baseColumns.push({
        title: 'Date',
        dataIndex: 'date',
        key: 'date',
        // Optional formatting or rendering can be added here
      });
    }
  
    // Add 'Link' column only if at least one item has a 'link' property
    if (hasLink) {
      baseColumns.push({
        title: 'Link',
        dataIndex: 'link',
        key: 'link',
        render: (text, record) => record.link ? (<a href={record.link} target="_blank" rel="noopener noreferrer">Link</a>) : null,
      });
    }
  
    return baseColumns;
  }
  // You can add more services with else-if blocks or switch-case
}, [service, data]); // Recompute when `service` changes



const handleRangeChange = (dates, dateStrings) => {
  // Update the state with formatted date strings
  if (dates) {
    setStartDate(dateStrings[0]);
    setEndDate(dateStrings[1]);
  } else {
    // Clear dates if the selection is cleared
    setStartDate('');
    setEndDate('');
  }
};

let conditionalCols; // This will hold the conditional columns based on the service

  if (service === 'quora') {
    // If service is 'quora', prepare two columns with span=10
    conditionalCols = (
      <>
        <Col span={8}>
        <Input placeholder="Enter keyword" value={keyword} onChange={e => setKeyword(e.target.value)} />

        </Col>
        <Col span={8}>
        <RangePicker style={{ width: '100%' }} onChange={handleRangeChange} />
        </Col>
      </>
    );
  } else if (service === 'reddit') {
    // If service is 'reddit', prepare the specific columns for Reddit
    conditionalCols = (
      <>
        <Col span={4}>
          <Input placeholder="Enter subreddit" value={subreddit} onChange={e => setSubreddit(e.target.value)} />
        </Col>
        <Col span={4}>
          <Input placeholder="Enter keyword" value={keyword} onChange={e => setKeyword(e.target.value)} />
        </Col>
        <Col span={4}>
          <Select defaultValue="relevance" style={{ width: '100%' }} onChange={value => setSortingOption(value)}>
            <Option value="relevance">Relevance</Option>
            <Option value="hot">Hot</Option>
            <Option value="top">Top</Option>
            <Option value="new">New</Option>
            <Option value="comments">Comments</Option>
          </Select>
        </Col>
        <Col span={4}>
          <Select defaultValue="week" style={{ width: '100%' }} onChange={value => setTimeOption(value)}>
            <Option value="hour">Hour</Option>
            <Option value="day">Day</Option>
            <Option value="week">Week</Option>
            <Option value="month">Month</Option>
            <Option value="year">Year</Option>
            <Option value="all">All</Option>
          </Select>
        </Col>
      </>
    );
  } else if (service === 'peopleAlsoAsk') {
    conditionalCols = (
      <>
        <Col span={4}>
       <Input 
        type="number" 
        placeholder="Number of questions" 
        value={numQuestions} 
        onChange={e => setNumQuestions(e.target.value)} 
        min={4}
        max={30}
      />
        </Col>
        <Col span={12}>
        <Input placeholder="Enter keyword" value={keyword} onChange={e => setKeyword(e.target.value)} />
        </Col>
      </>
    );
  }

  const handleServiceChange = (value) => {
    // First, clear the existing data
    setData([]);
    // Then, update the service with the new value
    setService(value);
  };


  return (
    <>
<ToastContainer
        position="top-right"
        autoClose={15000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <div>
      <Row gutter={6} style={{ marginTop: '20px' }}>
        <Col span={4}>
        <Select defaultValue="Reddit" style={{ width: '100%' }} onChange={handleServiceChange}>
         <Option value="reddit">Reddit</Option>
        <Option value="quora">Quora</Option>
        <Option value="peopleAlsoAsk">People Also Ask</Option>
      </Select>
        </Col>
      
        {conditionalCols}


        <Col span={4}>
        <Button type="primary" className='mainQuoraButton' loading={loading} onClick={handleButtonClick}>
      Submit
    </Button>
        </Col>
      </Row>
      </div>

      <div className='mt-4'>
      <Table dataSource={data} columns={columns} />

      </div>
    </>
  )
}

export default Questions