import React, {useState} from "react";
import SignupNavbar from "../components/SignupNavbar";
// import { Form  } from 'react-bootstrap'; // Import necessary components from react-bootstrap
import { Link,  } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import axios from '../components/Axios';
import { LockOutlined, UserOutlined } from '@ant-design/icons';
import { Button, Checkbox, Form, Input } from 'antd';
import coverImage from "../assets/cover.jpg"

function LoginPage() {

  const [formData, setFormData] = useState({
    email: '',
    password: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');


  // const navigate = useNavigate();

  const handleChange = (e) => {
    // setFormData({ ...formData, [e.target.id]: e.target.value });
    setFormData({ ...formData, [e.target.name]: e.target.value });

  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');
    console.log(formData)
    try {
      const response = await axios.post('/auth/login', formData);
      console.log(response.data);
      localStorage.setItem("jsonToken", response.data.token);

      const config = {
        headers: { Authorization: `Bearer ${response.data.token}` }
      };

      try {
        const userResponse = await axios.get('/auth', config);
        localStorage.setItem("userData", JSON.stringify(userResponse.data));
        window.location.href = '/';
      } catch (fetchError) {
        console.error('Error fetching user data:', fetchError);
        setError(fetchError.response?.data?.error || 'Error fetching user data');
      }

      window.location.href = '/';


      // navigate('/'); // Navigate to dashboard or another route
    } catch (err) {
      setError(err.response?.data?.error || 'An error occurred');
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };



  return (
    <div>
      {/* <SignupNavbar /> style={{ marginTop: "80px" }} */}

      <div className="container" >
        <div id="main-wrapper" className="container">
          <div className="row justify-content-center pt-5">
            <div className="col-xl-10">
              <div className="card border-0">
                <div className="card-body p-0">
                  <div className="row no-gutters">
                    <div className="col-lg-6">
                      <div className="p-5" style={{ minHeight: "418px" }}>
                        <h6 className="h5 mb-3">Welcome back!</h6>
                       <p className="text-muted mb-3">Enter your email address and password to access our platform.</p>
                       

                       <Form
      name="normal_login"
      className="login-form"
      initialValues={{ remember: true }}
      // onFinish={onFinish}
      // onSubmit={handleSubmit}
    >
      {/* Email Field */}
      <Form.Item
        name="email"
        rules={[{ required: true, message: 'Please input your Email!' }]}
      >
        <Input 
        name="email"
          prefix={<UserOutlined className="site-form-item-icon" />} 
          placeholder="Email" 
          onChange={handleChange} 
        />
      </Form.Item>

      {/* Password Field */}
      <Form.Item
        name="password"
        rules={[{ required: true, message: 'Please input your Password!' }]}
      >
        <Input 
        name="password"
          prefix={<LockOutlined className="site-form-item-icon" />} 
          type="password" 
          placeholder="Password" 
          onChange={handleChange} 
        />
      </Form.Item>

      {/* Loading and Error Display */}
      {loading && <BeatLoader color="#1890ff" className="mb-1" />}
      {error && <div className="alert alert-danger">{error}</div>}

      {/* Remember Me Checkbox and Forgot Password Link */}
      <Form.Item>
        <Checkbox>Remember me</Checkbox>
        <a className="login-form-forgot" href="#">
          Forgot password
        </a>
      </Form.Item>

      {/* Submit Button */}
      <Form.Item>
        <Button type="primary" htmlType="submit" className="login-form-button mb-1" onClick={handleSubmit}>
          Log in
        </Button>
        {/* Or <Link to="/register" className="register-form ">register now!</Link> */}
        <div className="login-form-footer" style={{ display: 'flex', flexWrap: 'wrap' }}>
  Forgot your password?{" "} 
  <Link to="/reset-password" className="register-form" style={{ marginLeft: '4px', marginRight: '4px' }}>Reset password</Link>
  {" | "}
  <span style={{ marginLeft: '4px' }}>Or</span> <Link to="/register" className="register-form" style={{ marginLeft: '4px' }}>register now!</Link>
</div>

      </Form.Item>
    </Form>


                      </div>
                    </div>

                    <div className="col-lg-6 d-none d-lg-inline-block">
                      {/* <div className="account-block rounded-right">
                        <div className="overlay rounded-right"></div>
                        <div className="account-testimonial">
                          <h4 className="text-whited mb-4">
                          SEO is not complex, when you understand it!
                          </h4>
                          <p className="lead text-white">
                          "Best investment i made for a long time. Can only recommend it for other users."
                          </p>
                          <p>- Krishna Anubhav</p>
                        </div>
                      </div> */}
                      <img src={coverImage} />
                    </div>
                  </div>
                </div>
              </div>
              {/* <p className="text-muted text-center mt-3 mb-0">
              Don't have an account?{" "}
                <Link to="/register" className="text-primarymain ml-1">
                Register instead
                </Link>
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;

