import React, { createContext, useState, useContext } from 'react';

// Create the context with a new name
const SubredditProjectsContext = createContext();

// Provider component that wraps your app
export const SubredditProjectsProvider = ({ children }) => {
  const [subredditProjects, setSubredditProjects] = useState([]);

  return (
    <SubredditProjectsContext.Provider value={{ subredditProjects, setSubredditProjects }}>
      {children}
    </SubredditProjectsContext.Provider>
  );
};

// Custom hook to use the projects context
export const useSubredditProjects = () => useContext(SubredditProjectsContext);