import React, { useState, useContext } from "react";
import { Button, Form, Input, Select, Row, Col, Modal, Alert, InputNumber  } from "antd";
import axios from "../../components/Axios";
import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { KeywordContext } from "../../auth/KeywordContext"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const { Option } = Select;





function MainFormRankTr() {
  const { updateranktrackerData,formRankTrLanguage, setFormRankTrLanguage, formRankTrCountry, setFormRankTrCountry, countries, languageranktracker } = useContext(KeywordContext);
  const [website, setWebsite] = useState("");
  const [competitorWebsites, setCompetitorWebsites] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [keywords, setKeywords] = useState("");
  const [keywordsError, setKeywordsError] = useState(false);
  const [hRanking, setHRanking] = useState(30); // Initialize with default value 30
  const [loading, setLoading] = useState(false);
  const [frequency, setFrequency] = useState('daily');

  const userDataString = localStorage.getItem("userData");
  const userData = userDataString ? JSON.parse(userDataString) : null;

  const handleKeywordsChange = (e) => {
    const input = e.target.value;
    const lines = input.split('\n');
    console.log(lines, "lines")
      setKeywords(input);
   setKeywordsError(lines.length > 20);
  };

  const handleCompetitorChange = (index, event) => {
    const newCompetitorWebsites = [...competitorWebsites];
    newCompetitorWebsites[index] = event.target.value;
    setCompetitorWebsites(newCompetitorWebsites);
  };

  const showErrorToast = (message) => {
    toast.error(message, {
        position: "top-right",
        autoClose: 15000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
    });
};

// const scrapingRobotmyApi = localStorage.getItem('scrapingApi');
// const apiToUse = scrapingRobotmyApi || userData?.scrapingrobotApi;
const scrapingRobotmyApi = JSON.parse(localStorage.getItem('scrapingApi') || 'null');
const apiToUse = scrapingRobotmyApi || userData?.scrapingrobotApi;
const handleChange = (value) => {
  setFrequency(value);
};


const validateRequestBody = () => {
  if (!website) return "Website is required";
  if (keywords.length === 0) return "At least one keyword is required";
  if (!formRankTrCountry) return "Country selection is required";
  if (!formRankTrLanguage) return "Language selection is required";
  if (!userData?._id) return "User ID is missing";
  if (!apiToUse) return "Please provide scraping robot API";
  if (hRanking === undefined || hRanking === null) return "Please provide historic ranking numbers";
  if (typeof hRanking !== 'number' || hRanking < 1 || hRanking > 100) return "Historic ranking must be a number between 1 and 100";
  if (!frequency) return "Please provide frequency";
  // if (competitorWebsites.some(website => !website)) return "All competitor website fields must be filled";
  return null;
};


  const handleSubmit = async () => {
    // e e.preventDefault();
    const keywordArray = keywords.split('\n');
    setLoading(true); 

    const validationError = validateRequestBody();

  if (validationError) {
    showErrorToast(validationError);
    setLoading(false);
    return; // Cancel the submission
  }
    const requestBody = {
      queries: keywordArray,
      gl: formRankTrCountry.toLowerCase(),
      hl: formRankTrLanguage.toLowerCase(),
      userrobotApi: apiToUse,
      userId: userData?._id,
      websiteName: website,
      updateFrequency: frequency,
      pruneLimit: hRanking,
      competitorWebsites: competitorWebsites
    };

    console.log(requestBody, "requestBody")

    try {
      const response = await axios.post("/rank/ranktracker", requestBody);
      console.log("Response:", response.data);
      // Handle the response as needed
      updateranktrackerData(response.data)
    } catch (error) {
      console.error("Error submitting form:", error);
      // Handle the error as needed
      showErrorToast(error.response?.data?.message || "An error occurred while submitting the data");
    }finally {
      setLoading(false); // Stop loading after the API call is complete
    }
  };

  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    const lines = keywords.split('\n');
    if (lines.length <= 10) {
      setIsModalOpen(false);
      // Process the keywords
      console.log("Keywords:", lines);
    } else {
      setKeywordsError(true);
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  const renderModalFooter = () => (
    <>
      <Button type="dashed" key="back" onClick={handleCancel}>
        Cancel
      </Button>
      <Button key="submit" type="dashed" onClick={handleOk}>
        Save
      </Button>
    </>
  );

  const handleLanguageChange = (languageAbbreviation) => {
    setFormRankTrLanguage(languageAbbreviation);
  };
  
 
  return (
    <>
    <ToastContainer
position="top-right"
autoClose={3000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
         <Modal title="Keywords" open={isModalOpen} onOk={handleOk} onCancel={handleCancel}  footer={renderModalFooter()}>
         <Form>
          <Form.Item>
            <Input.TextArea 
              rows={6} 
              value={keywords} 
              onChange={handleKeywordsChange} 
              placeholder="One keyword per row" 
            />
        </Form.Item>
  {keywordsError && (
    <Alert showIcon message="Error: Maximum 10 keywords allowed at a time" type="error" />
  )}
  <p>Maximum 10 keywords can be added at a time.</p>
</Form>

      </Modal>
   
    <Form layout="vertical" onFinish={handleSubmit} className="mt-3">
      <Row gutter={4}>
        <Col span={5} >
          <Form.Item label="Your Website" >
            <Input
              placeholder="example.com"
              value={website}
              onChange={(e) => setWebsite(e.target.value)}
              size="large"
            />
          </Form.Item>
        </Col>
        <Col span={4}>
  <Form.Item label="Your Keywords">
    <Button 
      type="dashed" 
      className='' 
      onClick={showModal} 
      size='large' 
      style={{ width: '100%' }} 
      icon={<PlusOutlined />}
    >
      Add Keywords
    </Button>
  </Form.Item>
</Col>

        <Col span={3} >
        <Form.Item label="Language">
              <Select
                placeholder="Please select a Language"
                value={formRankTrLanguage}
                onChange={handleLanguageChange}
                size="large"
              >
                {Object.entries(languageranktracker).map(([abbr, name]) => (
                  <Option key={abbr} value={abbr}>{name}</Option>
                ))}
              </Select>
            </Form.Item>
        </Col>
        <Col span={4} >
        <Form.Item label="Country">
              <Select
                value={formRankTrCountry}
                onChange={setFormRankTrCountry}
                size="large"
              >
                {/* Map through countries */}
                {countries.map(country => (
                  <Option key={country.code} value={country.code}>{country.label}</Option>
                ))}
              </Select>
            </Form.Item>
        </Col>
        <Col span={2} >
        <Form.Item label="Frequency">
      <Select value={frequency} onChange={handleChange} size="large">
        {/* <Option value="mints">Minutes</Option> */}
        <Option value="daily">Daily</Option>
        <Option value="weekly">Weekly</Option>
      </Select>
    </Form.Item>
        </Col>
        <Col span={2} >
 <Form.Item label="H.Ranking" tooltip="Specify the number of past rankings to display.">
 <InputNumber min={15} max={90} value={hRanking} onChange={setHRanking} size="large" style={{ width: "100%" }} />
        </Form.Item>
        </Col>

        <Col span={4} >
          <Button  type="primary" loading={loading} className='mainQuoraButton mozmodal' htmlType="submit" size="large">
          {loading ? "Loading..." : "Submit"}
          </Button>
        </Col>
      </Row>
      <Row gutter={10}>
        <Form.List name="competitorWebsites">
          {(fields, { add, remove }) => (
            <>
              {fields.map(({ key, name, ...restField }, index) => (
                <Col key={key} span={6}>
                  <Form.Item
                    {...restField}
                    name={[name]}
                  >
                    <Input placeholder="https://example.com" style={{ width: 'calc(100% - 30px)' }} size="large"
                    onChange={e => handleCompetitorChange(index, e)} />
                    {index > 0 && (
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => remove(name)}
                        style={{ position: 'absolute', right: 0, top: '50%', transform: 'translateY(-50%)' }}
                      />
                    )}
                  </Form.Item>
                </Col>
              ))}
              <Col span={4}>
                <Button
                  type="dashed"
                  onClick={() => add()}
                  disabled={fields.length === 3}
                  icon={<PlusOutlined />}
                  style={{ width: '100%' }}
                  size="large"
                >
                  Add Competitor
                </Button>
              </Col>
            </>
          )}
        </Form.List>
      </Row>     
    </Form>
    </>
  );
}

export default MainFormRankTr;
