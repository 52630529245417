import React, { useState } from 'react';
import { Input } from 'antd';

const TextAreaComponent = ({ initialValue, placeholder, onChange }) => {
  const [value, setValue] = useState(initialValue);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setValue(newValue);
    onChange(newValue); // Propagate the change up to the parent component if needed
  };

  return (
    <Input.TextArea
      value={value}
      onChange={handleChange}
      placeholder={placeholder}
      autoSize={{ minRows: 7, maxRows: 14 }}
    />
  );
};


export default TextAreaComponent;