import React, {useState} from "react";
// import SignupNavbar from "../components/SignupNavbar";
// import { Form, Row, Col } from 'react-bootstrap'; // Import necessary components from react-bootstrap
import { Link, useNavigate  } from "react-router-dom";
import BeatLoader from "react-spinners/BeatLoader";
import axios from '../components/Axios';
// import {Button} from "antd"
import { Form, Input, Button, Row, Col } from 'antd';
import { UserOutlined, LockOutlined, IdcardOutlined } from '@ant-design/icons';
import coverImage from "../assets/cover.jpg"


function RegisterPage() {
  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    license: ''
  });
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');

  const handleChange = (e) => {
    // setFormData({ ...formData, [e.target.id]: e.target.value });
    setFormData({ ...formData, [e.target.name]: e.target.value });

  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    setError('');

    console.log(formData); // Logging the request body

    try {
      const response = await axios.post('/auth/register', formData);
      console.log(response.data);
      // Handle success (redirect or show success message)
      navigate('/loginPage');
    } catch (err) {
      setError(err.response?.data?.error || 'An error occurred');
      console.error('Error:', err);
    } finally {
      setLoading(false);
    }
  };




  return (
    <div>
      {/* <SignupNavbar /> style={{ marginTop: "80px" }} */}

      <div className="container" >
        <div id="main-wrapper" className="container">
          <div className="row justify-content-center pt-5">
            <div className="col-xl-10">
              <div className="card border-0">
                <div className="card-body p-0">
                  <div className="row no-gutters">
                    <div className="col-lg-6">
                      <div className="p-5" style={{ minHeight: "418px" }}>
                        <h6 className="h5 mb-3">Create an account</h6>
                       
                        <Form
      className="signup-form"
      onFinish={handleSubmit}
    >
      {/* License Field */}
      <Form.Item
        name="license"
        rules={[{ required: true, message: 'Please input your License!' }]}
      >
        <Input 
        name="license"
          prefix={<IdcardOutlined className="site-form-item-icon" />}
          placeholder="License" 
          onChange={handleChange} 
        />
      </Form.Item>

      {/* First and Last Name Fields */}
      <Row gutter={16}>
        <Col span={12}>
          <Form.Item
            name="firstName"
            rules={[{ required: true, message: 'Please input your First Name!' }]}
          >
            <Input 
             name="firstName"
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="First Name" 
              onChange={handleChange} 
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <Form.Item
            name="lastName"
            rules={[{ required: true, message: 'Please input your Last Name!' }]}
          >
            <Input 
             name="lastName"
              prefix={<UserOutlined className="site-form-item-icon" />}
              placeholder="Last Name" 
              onChange={handleChange} 
            />
          </Form.Item>
        </Col>
      </Row>

      {/* Email Field */}
      <Form.Item
        name="email"
        rules={[{ required: true, message: 'Please input your Email!' }]}
      >
        <Input
        name="email"
          prefix={<UserOutlined className="site-form-item-icon" />}
          type="email"
          placeholder="Email"
          onChange={handleChange}
        />
      </Form.Item>

      {/* Password Field */}
      <Form.Item
        name="password"
        rules={[{ required: true, message: 'Please input your Password!' }]}
      >
        <Input
        name="password"
          prefix={<LockOutlined className="site-form-item-icon" />}
          type="password"
          placeholder="Password"
          onChange={handleChange}
        />
      </Form.Item>

      {/* Loading and Error Display */}
      {loading && <BeatLoader color="#1890ff" className="mb-1" />}
      {error && <div className="alert alert-danger">{error}</div>}

      {/* Sign Up Button */}
      <Form.Item>
        <Button type="primary" htmlType="submit" className="login-form-button pb-1" onClick={handleSubmit}>
          Sign up
        </Button>
        {/* Or <Link to="/register" className=" ">register now!</Link> */}
        {/* Already have an account?{" "}
                <Link to="/loginPage" className="register-form ml-1">
                  Log in instead
                </Link> */}
                <div className="login-form-footer" style={{ display: 'flex', flexWrap: 'wrap', alignItems: 'center', marginTop: '4px' }}>
  Already have an account?{" "}
  <Link to="/loginPage" className="register-form" style={{ marginLeft: '4px' }}>
    Log in instead
  </Link>
  <span style={{ marginLeft: '4px', marginRight: '4px' }}>|</span>
  Forgot your password?{" "}
  <Link to="/reset-password" className="register-form" style={{ marginLeft: '4px' }}>
    Reset password
  </Link>
</div>

      </Form.Item>
    </Form>



                      </div>
                    </div>

                    <div className="col-lg-6 d-none d-lg-inline-block">
                      {/* <div className="account-block rounded-right">
                        <div className="overlay rounded-right"></div>
                        <div className="account-testimonial">
                          <h4 className="text-whited mb-4">
                            Emporing data driven SEO.
                          </h4>
                          <p className="lead text-white">
                            "Best investment i made for a long time. Can only
                            recommend it for other users."
                          </p>
                          <p>- Krishna Anubhav</p>
                        </div>
                      </div> */}
                       <img src={coverImage} />
                    </div>
                  </div>
                </div>
              </div>
              {/* <p className="text-muted text-center mt-3 mb-0">
                Already have an account?{" "}
                <Link to="/loginPage" className="text-primarymain ml-1">
                  Log in instead
                </Link>
              </p> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegisterPage;
