import React from 'react';
import { Table } from 'antd';

const OrderStatusTable = ({ orderData }) => {
  const columns = [
    {
      title: 'ID',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Charge',
      dataIndex: 'charge',
      key: 'charge',
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
    },
    {
      title: 'Start Count',
      dataIndex: 'start_count',
      key: 'start_count',
    },
    {
      title: 'Remains',
      dataIndex: 'remains',
      key: 'remains',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
    },
  ];

  // Transform the response data into an array of objects
  const data = Object.keys(orderData).map((id) => ({
    id,
    charge: orderData[id].charge,
    currency: orderData[id].currency,
    start_count: orderData[id].start_count,
    remains: orderData[id].remains,
    status: orderData[id].status,
  }));

  return <Table columns={columns} dataSource={data} />;
};

export default OrderStatusTable;
